import React, { useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Stack,
  Avatar,
  Typography,
  Box,
  Skeleton,
  Pagination,
  Grid,
  Card,
  useTheme,
  Tooltip,
  AvatarGroup,
  CardContent,
  CardActionArea,
  Icon,
} from '@mui/material';

import { Environment } from '../../shared/environment';

import { useModulesContext } from '../../shared/contexts';
import { EASCard, EASProgressList } from '../../shared/components';
import { EASButton, EASInput } from '../../shared/forms';
import AddIcon from '@mui/icons-material/Add';

import { useAuthContext , useMentoringContext } from '../../shared/contexts';
import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { useQuery } from 'react-query';
import { IModule } from '../../shared/contexts/ModulesContext/types';

export const SelectModuleList = (() => {


  const theme = useTheme();

  const { markModule, modules, isLoadingModule } = useModulesContext();
  const { markMentory } = useMentoringContext();

  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleSelectModule = (module:IModule) => {
    markMentory(null);
    markModule(module);
    navigate('/pagina-inicial');
  };

   
  return (
    <>          
      <EASCard 
        titleCard="Selecionar Módulo"
        bgcolorContent={theme.palette.background.default}>
            <Grid container spacing={2}>
            {isLoadingModule && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
              </Grid>
            </Grid>
            )}
            {!isLoadingModule && (modules && modules.map((module) => (
              <Grid key={module?.id_module} item xs={6} sm={4} md={3} lg={2}>
                <Card variant="outlined" sx={{p:'24px', borderRadius:'12px', cursor:'pointer'}} onClick={() => handleSelectModule(module)}>
                  <CardContent >
                    <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                      <Grid item xs={12}>
                        <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                          <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>{module?.icon_module}</Icon>
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} sx={{pt:1}}>
                        <Typography variant="body1" component="p" sx={{textAlign:'center', fontWeight:600, fontSize: '0.9375rem'}}>{module?.name_module}</Typography>
                        <Typography variant="body2" component="p" sx={{textAlign:'center', fontWeight:400, fontSize: '0.8125rem'}}>{module?.category_module}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              )))}
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Card variant="outlined" sx={{p:'24px', borderRadius:'12px', cursor:'pointer'}}    
                 onClick={() => {
                  const meduleShop:IModule = {
                                      id_module:'shop',
                                      name_module:'Aura ExO',
                                      category_module:'Shop',
                                      icon_module:'shopping_cart'
                                    };
                  handleSelectModule(meduleShop);
                }} >
                  <CardContent >
                    <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                      <Grid item xs={12}>
                        <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                          <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>shopping_cart</Icon>
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} sx={{pt:1}}>
                        <Typography variant="body1" component="p" sx={{textAlign:'center', fontWeight:600, fontSize: '0.9375rem'}}>Aura ExO</Typography>
                        <Typography variant="body2" component="p" sx={{textAlign:'center', fontWeight:400, fontSize: '0.8125rem'}}>Shop</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              {(user?.groups && user.groups.length > 0) && (
              <Grid item xs={6} sm={4} md={3} lg={2}>
                <Card variant="outlined" sx={{p:'24px', borderRadius:'12px', cursor:'pointer'}}    
                  onClick={() => {
                    const meduleShop:IModule = {
                                        id_module:'admin',
                                        name_module:'Aura ExO',
                                        category_module:'Administração',
                                        icon_module:'admin_panel_settings'
                                      };
                    handleSelectModule(meduleShop);
                  }} >
                  <CardContent >
                    <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                      <Grid item xs={12}>
                        <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                          <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>admin_panel_settings</Icon>
                        </Avatar>
                      </Grid>
                      <Grid item xs={12} sx={{pt:1}}>
                        <Typography variant="body1" component="p" sx={{textAlign:'center', fontWeight:600, fontSize: '0.9375rem'}}>Aura ExO</Typography>
                        <Typography variant="body2" component="p" sx={{textAlign:'center', fontWeight:400, fontSize: '0.8125rem'}}>Administração</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              )}
            </Grid>
      </EASCard>
  </>
  );
});

