import { LayoutDefault } from '../../shared/layouts';
import { MentoryFilesList } from './MentoryFilesList';

export const MentoryFiles = () => {

  return (
    <LayoutDefault>
      <MentoryFilesList/>
    </LayoutDefault>
  );
};
