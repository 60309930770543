import { styled, Card, CardHeader, CardContent, CardMedia, CardActions, Typography, useTheme, Grid, Box, Divider, Stack, Button, useMediaQuery, CardProps } from '@mui/material';
import { useNavigate, useSearchParams, useParams} from 'react-router-dom';

interface IEASCard extends CardProps  {
  titleCard?: string | React.ReactElement;
  actionCard?: React.ReactElement;
  breadcrumbsCard?: string;
  children: React.ReactNode;
  titleNavigation?: string;
  buttonSave?: string;
  buttonExit?: string;
  navigated?: string;
  searching?: string;
  bgcolorContent?:string;
}

export const EASCard:React.FC<IEASCard> = ({ children, titleCard, actionCard, titleNavigation, buttonSave, buttonExit, navigated, searching, bgcolorContent, ...props}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack spacing={2} direction='column' sx={{width:'100%', borderRadius:3}}>
      {titleCard && (
      <Card elevation={0} sx={{padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: 2}}>
        {
          titleCard  && (
            <Stack direction='row' sx={{width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography sx={{fontSize: '1.15rem', fontWeight: '500'}}>{titleCard}</Typography>
              {actionCard && actionCard}
            </Stack>
          )
        }
        {buttonSave || buttonExit ? (
          <>
          <Typography sx={{fontSize: '1.15rem', fontWeight: '500'}}>{titleCard}</Typography>
          <Stack direction='row' spacing={1}>
            {buttonSave ? (<Button variant="contained">{buttonSave}</Button>) : undefined}
            {buttonExit && navigated ? (<Button variant="outlined" onClick={() => navigate(navigated)}>{buttonExit}</Button>) : undefined}            
          </Stack>
          </>
        ) : undefined}
      </Card>
      )}
      <Card elevation={0} sx={{borderRadius: 2, width: '100%', backgroundColor:bgcolorContent, ...props.sx}}>
        {children}
      </Card>
    </Stack>
  );
};