import { IProductBuy } from '../../../contexts/ProductBuyContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface IProductBuyService {
  data?: IProductBuy;
}


interface IProductBuyListResponse extends IProductBuy {
  page?: number;
  per_page?: number;
  total?: number ;
  total_pages?: number;
  data?: Array<IProductBuy>;
}

const createProductBuy = async (productbuy: IProductBuy): Promise<IProductBuyService | Error> => {
  try {
    const { data } = await Api.post('/productbuy', { ProductBuy: productbuy } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateProductBuy = async (ProductBuy: IProductBuy): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/productbuy/${ProductBuy}`, ProductBuy);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateProductBuyAvatar = async (ProductBuy: IProductBuy, file:File): Promise<void | Error> => {
  try {

    const formData = new FormData();
    formData.append('image_avatar', file);
    const { data } = await  Api.post(`/productbuy/${ProductBuy}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteProductBuy = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/productbuy/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getProductBuy = async (id: string): Promise<IProductBuy> => {
  const { data } = await Api.get(`/productsbuy/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getProductBuys = async (page = 1, filter = '' ): Promise<IProductBuyListResponse> => {
  const urlRelativa = `/productsbuy?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;
  const  response = await Api.get(urlRelativa);
  return response.data;
};

export const ProductBuyService = {
  getProductBuy,
  getProductBuys,
  createProductBuy,
  updateProductBuy,
  updateProductBuyAvatar,
  deleteProductBuy,
};
