import { Box, styled, Container,  Typography, LinearProgress, Card, useTheme, CardContent } from '@mui/material';
import { useConnectContext, useEASThemeContext } from '../../contexts';


interface IEASConnectProps {
  children: React.ReactNode;
}
const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 640,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));

export const EASConnect: React.FC<IEASConnectProps> = ({ children }) => {

  const theme = useTheme();
  //const isConnected = true;
  const { themeName } = useEASThemeContext();
  const { isConnected } = useConnectContext();

  if (isConnected === true) 
  return (
    <>{children}</>
  );

  if (isConnected === false) 
  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden'}} bgcolor={theme.palette.background.default}>
      <Container>
        <ContentStyle>
          <Card>
            <CardContent>
              <Typography variant="h4" paragraph>
                Problemas na conexão!
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                Pedimos desculpas, mas não estamos conseguindo conectar com o servidor de serviços do aplicatvo.
                Tente novamente mais tarde. Caso o problema persista, informe o proprietário do aplicativo.
              </Typography>
              <Box
                component="img"
                src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
              />
            </CardContent>
          </Card>
        </ContentStyle>
      </Container>
    </Box> 
  );

  //handleConnect();
  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden'}} bgcolor={theme.palette.background.default}>
      <Container>
        <ContentStyle>
          <Card>
            <CardContent>
              <Typography variant="h4" paragraph>
                Conectando com o servidor!
              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                Aguarde uns instantes, estamos conectando com o servidor de serviços do aplicatvo.
                Este processo näo deve demorar mais que alguns segundos.
              </Typography>

              <Box
                component="img"
                src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
              />
            </CardContent>
            <LinearProgress color="primary" />
          </Card>
        </ContentStyle>
      </Container>
    </Box> 
  );
  
};

