import { useState } from 'react';

import {Rating, Grid, Paper, Typography, StepButton, Avatar, Box, Container, List, Button, Card, useTheme, Skeleton, CardHeader, CardContent } from '@mui/material/';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';

import { IEvaluation, IEvaluationsExternal } from '../../shared/types';

import { EvaluationsEditItem } from './EvaluationsEditItem';
import { EvaluationsEditText } from './EvaluationsEditText';

import { EvaluationsExternalService } from '../../shared/services/api/evaluations-external/EvaluationsExternalService';

import { useSnackbar } from 'notistack';
import { EASCard } from '../../shared/components/eas-card/EASCard';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EASDialogAlert } from '../../shared/components';
import { Environment } from '../../shared/environment';
import { useEASThemeContext } from '../../shared/contexts';



export const EvaluationsExternal = () => {

  const theme = useTheme();
  const { themeName } = useEASThemeContext();
  const { enqueueSnackbar } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [externalEvaluation, setExternalEvaluation] = useState<IEvaluationsExternal>({});
  
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const queryClient = useQueryClient();


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const evaluation = params.get('evaluation');

  const { isLoading } = useQuery(['evaluation-external', evaluation],() => EvaluationsExternalService.getEvaluationExternal(evaluation?.toString()), {
    staleTime: Infinity,
    onSuccess: data => {
      setExternalEvaluation(data);
    },
    onError: () => {
      setExternalEvaluation({});
      enqueueSnackbar('Não foi possivel carregar a Avaliação parcial', { variant:'error'});
    },
  });


  const { mutateAsync:updateEvaluation } = useMutation((evaluation:IEvaluation) => EvaluationsExternalService.updateEvaluation(evaluation), {
    onSuccess: () => {
      queryClient.invalidateQueries('evaluation-external');
      enqueueSnackbar('Avaliação parcial finalizada com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar finalizar esta avaliação parcial! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
      setLoadingDialog(false);
    },
  });


  const handleCancelDialog = () => {
    setOpenDialog(false);
    setLoadingDialog(false);
  };


  const handleConfirmeDialog = () => {
    setLoadingDialog(true);
    const evaluation: IEvaluation = {
      id_evaluation: externalEvaluation?.id_evaluation,
      status_evaluation:'2'
    };
    updateEvaluation(evaluation);
  };

  const handleNext = () => {

    if(externalEvaluation?.evaluation?.length && (activeStep < (externalEvaluation.evaluation.length - 1))){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  
    else{
      setOpenDialog(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };


  return ( 
    <Box width='100vw' minHeight='100vh' bgcolor={theme.palette.background.default}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EASCard sx={{ mt:'15px'}}>
              <Grid container spacing={3}>
                <Grid item zeroMinWidth sx={{ display: 'flex', flexDirection:'row', alignItems:'center', alignContent:'center', justifyContent: 'center'}}>
                  <Box
                    component="img"
                    src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                    sx={{ width: 150, height: 'auto', pl:5, pr:2}}
                  />
                </Grid>
                <Grid item xs={true}>
                  <Typography variant="h2" sx={{fontSize:'24px', textAlign:'left', fontWeight:'500', pt:3}}>
                    Avaliação Parcial em Processo de Mentoria Aura Exo {externalEvaluation?.user?.name}
                  </Typography>
                  <Typography variant="h6" sx={{fontSize:'14px', lineHeight: '20px',textAlign:'left', fontWeight:'500', mb:3, mt:1}}>
                    Você recebeu um convite para realizar a avaliação parcial de um processo de mentoria no sistema Aura Exo
                  </Typography>
                </Grid>
              </Grid>
            </EASCard>
          </Grid>
        </Grid>
        {isLoading && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Skeleton variant="rectangular" sx={{borderRadius:'8px', my:'15px'}} width="100%" height={310} />
              </Grid>
              <Grid item xs={8}>
                <Skeleton variant="rectangular" sx={{borderRadius:'8px', my:'15px'}} width="100%" height={310} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </Grid>
        )}
        {(!isLoading && externalEvaluation?.user) && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Card sx={{ borderRadius:'8px', my:'15px'}} elevation={0} >
                  <CardHeader sx={{pb:0}} title={<Typography color="text.secondary" variant="h6">Dados do Solicitante</Typography>} />
                  <CardContent>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Grid container spacing={1} >
                          <Grid item >
                            <Avatar sx={{width:65, height:65}}  alt={externalEvaluation?.user?.name} src={externalEvaluation?.user?.avatar ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + externalEvaluation?.user?.avatar  :  ''} />
                          </Grid>
                          <Grid item zeroMinWidth sm={true} sx={{mt:1, ml:2}}>
                            <Typography component="h6" variant="subtitle2" sx={{fontWeight:500}}>{externalEvaluation?.user?.name}</Typography>
                            <Typography component="p" variant="caption" sx={{fontStyle:'italic'}}>{externalEvaluation?.user?.email}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography component="p" variant="body2" >Processo: <strong>{externalEvaluation?.name_mentory}</strong></Typography>
                        <Typography component="p" variant="body2" >Data da Solicitação: <strong>{ externalEvaluation?.date_evaluation && new Date(externalEvaluation?.date_evaluation).toLocaleDateString('pt-BR')}</strong></Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={8}>
                <Card sx={{ borderRadius:'8px', my:'15px'}} elevation={0} >
                  <CardHeader sx={{pb:0}} title={<Typography color="text.secondary" variant="h6">Como Avaliar</Typography>} />
                  <CardContent sx={{pt:0}}>
                    <Grid container spacing={2}>
                      <Grid item zeroMinWidth sm={true}>
                        <Typography sx={{fontSize:'12px', lineHeight: '20px', mb:1, mt:1}}>
                          Abaixo estão as competências trabalhadas no processo de mentoria, com sua respectiva nota inicial.<br/>Indique, em cada item, como você percebe a evolução deste(a) mentorado(a), conforme indicado.<br/>
                          Obs.: Importante lembrar que esta avaliação é realizada em conceitos (ex.: percebido, pouco percebido), e não em notas.
                        </Typography>
                      </Grid>
                      <Grid item sm={true}>
                        <Grid container style={{ display: 'flex', justifyContent: 'center'}} spacing={1}>
                          <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                            <Rating value={1} readOnly={true} max={1} />
                          </Grid>
                          <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                            <Typography  sx={{fontSize:'12px'}} >
                              Não Percebido
                            </Typography> 
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                            <Rating value={2} readOnly={true} max={2} />
                          </Grid>
                          <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                            <Typography  sx={{fontSize:'12px'}} >
                              Pouco Percebido
                            </Typography> 
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                            <Rating value={3} readOnly={true} max={3} />
                          </Grid>
                          <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                            <Typography  sx={{fontSize:'12px'}} >
                              Percebido
                            </Typography> 
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                            <Rating value={4} readOnly={true} max={4} />
                          </Grid>
                          <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                            <Typography  sx={{fontSize:'12px'}} >
                              Muito Percebido
                            </Typography> 
                          </Grid>
                          <Grid item xs={6} sx={{textAlign:'right', height:'20px'}}>
                            <Rating value={5} readOnly={true} max={5} />
                          </Grid>
                          <Grid item xs={6}  sx={{textAlign:'left', mt:'5px', height:'20px'}}>
                            <Typography  sx={{fontSize:'12px'}} >
                              Excedeu as Expectativas
                            </Typography> 
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ borderRadius:'8px'}} elevation={0}>
                <CardHeader sx={{pb:2}} title={<Typography  color="text.secondary" variant="h6">Faça sua Avaliação</Typography>} />
                <CardContent sx={{pt:0}}>
                  <Stepper activeStep={activeStep} orientation="vertical" nonLinear={(externalEvaluation?.status_evaluation !== '2')  ? false : true} >
                    {externalEvaluation?.evaluation && (externalEvaluation?.evaluation.map((objective, index) => (
                      <Step key={objective.id_objective}  completed={externalEvaluation?.status_evaluation === '2' || activeStep > index ? true : false} >
                        <StepButton color="inherit" onClick={handleStep(index)}>
                          {objective.text_objective}
                        </StepButton>
                        <StepContent>
                          <List>
                          {objective.evaluation_detail && (objective.evaluation_detail.map((evaluationDetail, index) => (
                            <EvaluationsEditItem key={evaluationDetail.id_objective_detail} evaluationDetail={evaluationDetail} typeEvaluation={'0'} statusEvaluation={externalEvaluation?.status_evaluation} />
                          )))}
                          </List>
                          
                          <EvaluationsEditText 
                            disabled={(externalEvaluation?.status_evaluation === '2')} 
                            evaluationComment={objective.evaluation_comment} 
                          />
                          
                          {((externalEvaluation?.status_evaluation !== '2' && externalEvaluation?.evaluation?.length)) && (
                          <Box sx={{ mb: 2 }}>
                            <div>
                              
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                {index === externalEvaluation.evaluation.length - 1 ? 'Finalizar Avaliação' : 'Próxima Competência'}
                              </Button>
                              
                              {index !== 0 && externalEvaluation.evaluation.length > 1 && (
                              <Button
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                              >
                                Voltar a Competência Anterior
                              </Button>
                                )}
                            </div>
                          </Box>
                          )}
                        </StepContent>
                      </Step>
                    )))}
                  </Stepper>
                  {((externalEvaluation?.status_evaluation === '2')  && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography variant="h5">Esta avaliação parcial foi marcada como concluída</Typography>
                  </Paper>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        )}
    </Container>
    <EASDialogAlert
        type="warning"
        title="Finalizar Avaliação"
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        Você tem certeza que deseja finalizar esta avaliação parcial. Uma vez finalizada, você não poderá mais alterar os valores informados.
      </EASDialogAlert>
    </Box> 

  );
};