import React, { useState  } from 'react';
import {Grid, Alert, Box, Divider, Card, CardContent, Skeleton } from '@mui/material/';

import { useMentoringContext } from '../../shared/contexts';

import { ObjectiveService } from '../../shared/services/api/objective/ObjectiveService';

import { DetailingObjectivesNew } from './DetailingObjectivesNew';

import { DetailingObjectivesSkills } from './DetailingObjectivesSkills';
import { DetailingObjectivesDetail } from './DetailingObjectivesDetail';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';


interface IDetailingObjectivesProps {
  children?: () => void;
}  

export const DetailingObjectives: React.FC<IDetailingObjectivesProps> = () => {


  const { mentory } = useMentoringContext();
  const { enqueueSnackbar } = useSnackbar();

  const [valueTab, setValueTab] = useState<number>(0);

  const { data, isLoading } = useQuery(['objectives-list', [mentory?.id_mentory]],() =>   ObjectiveService.getObjectives(mentory?.id_mentory), {
    staleTime: Infinity,
    onSuccess: data => {
      if(data?.data && data.data.length && data.data[0]?.id_objective)
        setValueTab(parseInt(data.data[0].id_objective));
    },
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de competências.', { variant:'error'});
    },
  });

  return (
        <Box sx={{ flexGrow: 1, display: 'flex'}}>
          <Grid container spacing={2}>
            <Grid item xs={3}>  
              <Card sx={{borderRadius : 2}} variant='outlined' elevation={0}>
                <CardContent>
                  <DetailingObjectivesNew />
                </CardContent>
                <Divider />
                <CardContent>
                  {isLoading && (
                  <Box style={{ display: 'flex', justifyContent: 'center', flexDirection:'column', marginBottom:'15px'}}>
                    <Skeleton animation="wave" variant="rounded" sx={{my:'5px'}} width={'100%'} height={60} />
                    <Skeleton animation="wave" variant="rounded" sx={{my:'5px'}} width={'100%'} height={60} />
                    <Skeleton animation="wave" variant="rounded" sx={{my:'5px'}} width={'100%'} height={60} />
                  </Box> 
                  )}
                  {!isLoading && (data?.data && data.data.length  ? (
                  <DetailingObjectivesSkills objectives={data.data} valueTab={valueTab} handleChangeTable={(e) => setValueTab(e)} />
                  ) : (
                  <Alert severity="warning">Não há competências!</Alert>
                  ))}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={9}>
              {isLoading && (
              <Card sx={{borderRadius : 2}} variant='outlined' elevation={0}>
                <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>
                  <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={250} height={30} />
                  <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}}width={30} height={30} />
                  <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}} width={30} height={30} />
                </Box> 
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', mt:'15px'}}>
                  <Skeleton animation="wave" variant="rounded" width={'40%'} height={18} />
                  <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'80%'} height={15} />
                </Box> 
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom:'15px', mt:'10px'}}>
                  <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'75%'} height={45} />
                  <Skeleton animation="wave" variant="rounded" sx={{my:'15px', mx:'8px'}} width={80} height={45} />
                  <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={150} height={45} />
                </Box> 
                <Box sx={{ display: 'flex', justifyContent: 'center', px:'15px', width:'98%'}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item zeroMinWidth sm={true}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'90%'} height={18} />
                                </Grid> 
                                <Grid item xs={12} sm={6} >
                                    <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>                    
                                <Grid item xs={12} sm={6} >
                                    <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} >
                            <Grid item xs={4}>
                              <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'100%'} height={12} />
                                </Grid>
                                <Grid item xs={12} >
                                  <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> 
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item zeroMinWidth sm={true}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'90%'} height={18} />
                                </Grid> 
                                <Grid item xs={12} sm={6} >
                                  <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>                    
                                <Grid item xs={12} sm={6} >
                                  <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} >
                            <Grid item xs={4}>
                              <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'100%'} height={12} />
                                </Grid>
                                <Grid item xs={12} >
                                  <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> 
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item zeroMinWidth sm={true}>
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'90%'} height={18} />
                                </Grid> 
                                <Grid item xs={12} sm={6} >
                                    <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>                    
                                <Grid item xs={12} sm={6} >
                                    <Skeleton variant="rectangular" width={150} height={15} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={1} >
                            <Grid item xs={4}>
                              <Grid container spacing={1} >
                                <Grid item xs={12}>
                                  <Skeleton variant="rectangular" width={'100%'} height={12} />
                                </Grid>
                                <Grid item xs={12} >
                                  <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                            <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box> 
              </Card>
              )}
              {!isLoading && (data?.data && data.data.length ? (
              <DetailingObjectivesDetail allObjectives={data.data} valueTab={valueTab} />
              ) : (
              <Alert severity="warning" >Nenhuma competência foi cadastrada. Escolha uma, para começar.</Alert>
              ))}
            </Grid>
          </Grid>
        </Box>
  );
};