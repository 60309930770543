import { useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { useSnackbar } from 'notistack';

import { UtilService } from '../../shared/services/api/util/UtilService';
import { FilesService } from '../../shared/services/api/files/FilesService';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LayoutDefault } from '../../shared/layouts';

import FileTab, { IRefFile } from './FileTab';

import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { IFile } from '../../shared/contexts/FilesContext/types';






export const File = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refFile = useRef<IRefFile>(null);

    const storeDisplay = () => {
        if(refFile.current)
            refFile.current.saveFile();
    };

    const returnList = () => {
        queryClient.invalidateQueries('operator-file-edit');
        navigate('/arquivos');
    };

    const { data:tags, isLoading:loadingTag  } = useQuery(['tags-list'],() =>  UtilService.getTags(),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
      },
    });
  
    const { data:file, isLoading, isFetching } = useQuery(['operator-file-edit', id],() =>  FilesService.getFile(id),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
      },
    });
    
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateFile:IFile) =>FilesService.updateFile(updateFile), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-file-edit');
            queryClient.invalidateQueries('operator-file-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar file.', { variant:'error'});
        },
    });




    return (
            <LayoutDefault >
                <EASCard 
                    titleCard={'Editar Arquivo'}
                    breadcrumbsCard=""
                    actionCard={<Stack direction="row" spacing={1}>
                                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                    <EASButton variant="outlined" onClick={() => returnList()}>Sair</EASButton>
                                </Stack>} >
                    <Box sx={{mt: 2}}>
                        <FileTab ref={refFile} tags={tags} fileEdit={file} isLoading={(isFetching || isLoading || loadingTag || isLoadingStore)} storeUpdate={(file) => storeUpdate(file)} />
                    </Box>
                </EASCard>
            </LayoutDefault>
    );
};