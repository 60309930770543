import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { PresentationFinal } from './PresentationFinal';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { Box } from '@mui/material';

export const Presentations: React.FC = () => {
  const navigate = useNavigate();
  return (

    <LayoutDefault >
      <EASCard 
          titleCard={'Apresentação de Resultados'}
          breadcrumbsCard=""
          actionCard={<EASButton variant="contained" onClick={() => navigate('/apresentacao-final/imprimir?exportMode=true')} >Imprimir</EASButton>}
          sx={{maxHeight: 'calc(100vh - 200px)'}}> 
          <Box>
            <PresentationFinal toPrint={false} />
          </Box>
      </EASCard>
    </LayoutDefault>

  );
};