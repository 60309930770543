
import React, { useState, useEffect } from 'react';
import {Box, Typography, Container, Alert, Grid, LinearProgress, Button, Dialog, DialogTitle, DialogContent, DialogActions, Skeleton, Card, CardContent } from '@mui/material';

import { useAuthContext, useMentoringContext } from '../../contexts';
import { EASToolsLabelMappingItem } from './EASToolsLabelMappingItem';
import { EASToolsLabelMappingEdit } from './EASToolsLabelMappingEdit';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { EASButton } from '../../forms';
import { EASDialogAlert, EASCard } from '../';

export const EASToolsLabelMapping: React.FC = () => {

  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);

  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();


  const navigate = useNavigate();

  useEffect(() => {
    async function getInfluencers() {
      if(mentory?.id_mentory){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers(mentory?.id_mentory);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            if(!influencers.total)
              setMessageInfluencers(true);
            setTotalCount(influencers.total);

          }
          else{
            
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, []);

  
  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };


  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };
   
  return (
    <EASCard 
      titleCard="Influenciadores de Reputação"
      actionCard={<EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => navigate('/ferramentas')}>Sair</EASButton>}> 
      <Container>
        <Card sx={{borderRadius : 2, bgcolor: 'transparent', my:'30px'}} variant='outlined' elevation={0}>
          <CardContent>
            {isLoading && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', mt:'15px'}}>
                <Skeleton animation="wave" variant="rounded"  width={'80%'} height={18} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'40%'} height={18} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'80%'} height={58} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px', ml:'15px'}} width={150} height={58} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'35px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
            </>
            )}
            {!isLoading && (
            <Box sx={{pb:10}}>
              <EASCard>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
                    Quais são os rótulos positivos e negativos que estes influenciadores têm a seu respeito, na sua percepção?
                  </Typography>
                </Box>
                {mentory?.status_mentory=='2' && (
                <Box sx={{ p: 3 }}>
                  <Typography variant="h5">Este programa foi encerrado</Typography>
                  {mentory?.id_user_mentorado != user?.id && (
                    <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
                  )}
                </Box>
                )}
                <EASToolsLabelMappingEdit finishEditInfluencer={(e) => updateInfluencer(e)} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer} />
                <Grid container spacing={2}>
                  {(allInfluencers && allInfluencers.length) ? (allInfluencers.map((influencer) => (
                  <EASToolsLabelMappingItem
                    key={influencer.id_influencer} 
                    influencer={influencer} 
                    setInfluencer={(e) => setInfluencer(e)} 
                    //handleClickDelete={(e) => handleClickDelete(e)}
                    updateInfluencer={(e) => updateInfluencer(e)}
                  />
                  ))):(
                  <Alert severity="warning" >Nenhum influenciador cadastrado!</Alert>
                  )}
                </Grid>
              </EASCard>
            </Box>
            )}
            <EASDialogAlert 
              type="info"
              title="Excluir Influenciador"
              statusOpen={messageInfluencers}
              actionCancel={() => navigate('/ferramentas/influenciadores-de-reputacao')}
              titleCancel="Ir para o cadastro de influenciadores"            
              >
              <Typography>Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores</Typography>
            </EASDialogAlert>
          </CardContent>
        </Card>
      </Container>
    </EASCard>     
  );
};