import { Api } from '../axios-config';

interface IConnect {
  connectToken: string;
  status?: string;
}

const connect = async (public_token: string, client_id: string): Promise<IConnect> => {
  const { data } = await Api.post('/connect', { public_token: public_token, client_id: client_id } );
  return data;
};

export const ConnectService = {
  connect,
};
