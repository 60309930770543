import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  Stack,
  Avatar,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Chip,
  Box,
  Skeleton,
  Pagination,
  Grid,
} from '@mui/material';

import { IVideo } from '../../shared/contexts/VideosContext/types';
import { VideosService } from '../../shared/services/api/videos/VideosService';
import { Environment } from '../../shared/environment';
import { VideosNewVideo } from './VideosNewVideo';
import { EASCard, EASDialogAlert, PaginationComponent} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
//import { EASButtonVideo } from '../../shared/forms/EASButtonVideo';


export const VideosList:React.FC = (() => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idVideo, setIdVideo] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-video-list', [search, page]],() =>  VideosService.getVideos(page, search),  {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de vídeos.', { variant:'error'});
    },
  });

  const { mutateAsync:storeBlockVideo } = useMutation((blockVideo:IVideo) =>  VideosService.updateVideo(blockVideo), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-video-list');
      enqueueSnackbar('Vídeo ' + (blockText ? 'desbloqueado' : 'bloqueado') + ' com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' o vídeo!', { variant:'error'});
    },
  });


  const { mutateAsync:storeRemoveVideo } = useMutation((idVideo:string) => VideosService.deleteVideo(idVideo), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-video-list');
      enqueueSnackbar('Vídeo removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o vídeo!', { variant:'error'});
      setLoadingDialog(false);
    },
  });




  const handleClickRemoveVideo = (id_user?:string) => {
    if(id_user){
      setIdVideo(id_user);
      setTitleDialog('Excluir Vídeo');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene este arquivo? Não será mais possível recuperar este arquivo após sua exclusão.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockVideo = (id_user?:string) => {
    if(id_user){
      setIdVideo(id_user);
      setTitleDialog('Bloquear Vídeo');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso a este arquivo?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockVideo = (id_user?:string) => {
    if(id_user){
      setIdVideo(id_user);
      setTitleDialog('Desbloquear Vídeo');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso desta a este arquivo?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdVideo(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeVideo();
      break;
      case 'block':
        blockVideo(false);
      break;
      case 'unblock':
        blockVideo(true);
      break;
    }
  };

  const removeVideo = () => {
    setLoadingDialog(true);
    if(idVideo){
      storeRemoveVideo(idVideo);
    }
  };

  const blockVideo = (block:boolean) => {
    setLoadingDialog(true);
    if(idVideo){
      setBlockTest(block);
      const newBlockVideo:IVideo = {
        id_video:idVideo,
        status_video:(block ? '1' : '0')
      };
      storeBlockVideo(newBlockVideo);
    }
  };

  return (  
      <EASCard 
        titleCard="Vídeos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }
      >
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Tags
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((video) => (
            <TableRow 
                hover 
                key={video.id_video} 
                tabIndex={-1} 
                role="checkbox"
            >
              <TableCell align="center">
                {video?.id_video}
              </TableCell>
              <TableCell scope="row" >
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar src={video?.thumbnails} variant="rounded" sx={{width: '90px', height:'50px'}} />
                  </Grid>
                  <Grid item zeroMinWidth sm={true}>
                    <Typography variant="subtitle2">
                      {video?.name_video}
                    </Typography>
                    <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      Última Atualização: {video?.date_update ? video?.date_update  : video?.date_register}
                    </Typography>
                  </Grid>
                </Grid> 
              </TableCell>
              <TableCell align="center">
                <Chip label={video?.status_video == '1'? 'Ativo' : 'Bloqueado'} color={video?.status_video == '1' ? 'success' : 'error'} />
              </TableCell>
              <TableCell align="center">
              {video?.name_tags && video.name_tags.length > 0 && ( video.name_tags.map((tag, index) => (
                tag ? <Chip key={index} label={tag} size="small"  /> : ''
              )))}
              </TableCell>
              <TableCell align="center">
                <EASButtonList 
                    title="Editar Arquivo"
                    onClick={() => navigate(`/videos/detalhes/${video.id_video}`)} 
                    typeIcon="edit"
                />
                {video?.status_video == '0' ? (
                <EASButtonList 
                    title="Desbloquear Arquivo"
                    onClick={() => handleClickUmBlockVideo(video.id_video)} 
                    typeIcon="unblock"
                />
                ) : (
                <EASButtonList 
                  title="Bloquear Arquivo"
                  onClick={() => handleClickBlockVideo(video.id_video)} 
                  typeIcon="block"
                />
                )}
                <EASButtonList 
                  title="Remover Arquivo"
                  onClick={() => handleClickRemoveVideo(video.id_video)} 
                  typeIcon="remove"
                />
              </TableCell> 
            </TableRow>
            ))) : (
            <TableRow hover tabIndex={-1} role="checkbox" >
              <TableCell colSpan={6} scope="row" >
                Nenhum item encontrado 
              </TableCell>
            </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
        <VideosNewVideo open={open} setOpen={(e) => setOpen(e)} />
        <EASDialogAlert 
          type="warning"
          title={titleDialog}
          statusOpen={openDialog}
          actionCancel={handleCancelDialog}
          actionConfirme={handleConfirmeDialog}
          loading={loadingDialog}
        >
          {textDialog}
        </EASDialogAlert>
        <EASDialogAlert 
          type="success"
          title={titleDialog}
          statusOpen={openDialogSuccess}
          actionCancel={handleCancelDialog}
          loading={loadingDialog}
        >
          {textDialog}
        </EASDialogAlert> 
      </EASCard>
  );
});

