
import { LayoutDefault } from '../../shared/layouts';

import { MentoringResourcesHumanList } from './MentoringResourcesHumanList';

export const MentoringResourcesHuman = () => {


  return (
    <LayoutDefault>
        <MentoringResourcesHumanList />
    </LayoutDefault>
  );
};

