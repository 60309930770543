import React, { useState } from 'react';

import { Grid, Typography, Box, Button, Stack, useTheme, Skeleton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material/';

import { orange, grey, green } from '@mui/material/colors';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';

import { EvaluationFinalItem } from './EvaluationFinalItem';

import { EvaluationFinalService } from '../../shared/services/api/evaluation-final/EvaluationFinalService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../../shared/components';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EASDashboardLigthCard } from '../../shared/components/eas-dashboard-panels';


export const EvaluationFinalItems = () => {

  const { enqueueSnackbar } = useSnackbar();
  
  const theme = useTheme();
  
  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();

  const [activeStep, setActiveStep] = useState(0);
  
  const { data, isLoading } = useQuery(['evaluation-final'],() => EvaluationFinalService.getEvaluationFinal(mentory?.id_mentory));


  const handleNext = () => {
    if((data?.data && (activeStep < (data.data.length - 1))))
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
 

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <>
    <EASCard 
      titleCard="Avaliação Final"
      bgcolorContent={theme.palette.background.default}      
    >
      {isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item xs={3} >
                <Skeleton variant="rectangular" width="100%"  height="120px" sx={{borderRadius:'12px'}} />
              </Grid>
              <Grid item xs={3} >
                <Skeleton variant="rectangular" width="100%"  height="120px" sx={{borderRadius:'12px'}} />
              </Grid>
              <Grid item xs={3} >
                <Skeleton variant="rectangular" width="100%"  height="120px" sx={{borderRadius:'12px'}} />
              </Grid>
              <Grid item xs={3} >
                <Skeleton variant="rectangular" width="100%"  height="120px" sx={{borderRadius:'12px'}} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <Skeleton variant="rectangular" width="100%"  height="100px" sx={{borderRadius:'12px'}} />
          </Grid>
          <Grid item xs={12} >
            <Skeleton variant="rectangular" width="100%"  height="50px" sx={{borderRadius:'12px'}} />
          </Grid>
          <Grid item xs={12} >
            <Skeleton variant="rectangular" width="100%"  height="50px" sx={{borderRadius:'12px'}} />
          </Grid>
        </Grid>
      )}

      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <Grid container spacing={2}>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <EASDashboardLigthCard 
                    isLoading={isLoading}
                    title="Prioridades Eleitas"
                    icon="crisis_alert"
                    value={data?.prioritys?.segment}
                    subtitle={data?.prioritys?.total ? ('Em ' +  data.prioritys.total + (parseInt(data?.prioritys?.total) > 1 ? ' comportamentos' : ' comportamento')) : 'Nenhum comortamento'}
                    backgroundIcon="transparent"
                    
                    colorIcon="rgb(33, 150, 243)"
                    opcityIcon="1"
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <EASDashboardLigthCard 
                    isLoading={isLoading}
                    title="Avaliações Parciais"
                    icon="person_search"
                    value={data?.evaluations?.total}
                    subtitle={data?.evaluations?.segment ? ('Sendo ' +  data.evaluations.segment + (parseInt(data?.evaluations?.segment) > 1 ? ' autoavaliações' : ' autoavaliação')) : 'Nenhuma autoavaliação'}
                    backgroundIcon="transparent"
                  /* colorIcon="rgb(239, 108, 0)"*/
                    
                  colorIcon="rgb(255, 193, 7)"
                    /*colorIcon="rgb(153 33 243)"*/
                    opcityIcon="1"
                />
              </Grid>  
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <EASDashboardLigthCard 
                    isLoading={isLoading}
                    title="Tarefas"
                    icon="task"
                    value={data?.tasks?.total}
                    subtitle={data?.tasks?.segment ? ('Onde  ' +  data.tasks.segment + (parseInt(data?.tasks?.segment) > 1 ? ' estão finalizadas' : ' está finalizada')) : 'Nenhuma tarefa finalizada'}
                    backgroundIcon="transparent"
                    colorIcon="rgb(239, 90, 10)"
                    opcityIcon="1"
                />
              </Grid>          
              <Grid item lg={3} md={6} sm={6} xs={12}>


              <EASDashboardLigthCard 
                    isLoading={isLoading}
                    title="Média das Prioridades"
                    icon="functions"
                    value={data?.averages?.total}
                    subtitle={data?.averages?.segment ? ('Nota média geral ' + data.averages.segment) : 'Nenhuma há comportamento'}
                    background="rgb(153 33 243)"
                    backgroundIcon="transparent"
                    colorIcon="#FFFFFF"
                    color="#FFFFFF"
                    opcityIcon="1"
                />
              </Grid>  
            </Grid>

          </Grid>
          {data?.data && (data.data.map((objective, index) => (
          <Grid  key={objective.id_objective} item xs={12} >
            <Accordion expanded={activeStep == index} onChange={handleStep(index)} sx={{borderRadius:'12px !important', '&:before': {display: 'none'}}} elevation={0}>
              <AccordionSummary>
                <Stack direction='row' spacing={2} sx={{cursor:'pointer', display: 'flex', justifyContent:'flex-start', alignItems: 'center', }}>
                  {activeStep == index 
                    ? 
                      <RadioButtonCheckedIcon sx={{ fontSize: 25, color: orange[600] }}  /> 
                    : 
                    ( 
                      activeStep > index 
                        ? 
                          <CheckCircleIcon sx={{ fontSize: 25, color: green[600] }} /> 
                        : 
                          <PanoramaFishEyeIcon sx={{ fontSize: 25 , color: grey[600]}} />
                    )
                  }
                  <Typography 
                    variant="h6" 
                    color={activeStep == index 
                      ? 
                        orange[900]
                      : 
                      ( 
                        activeStep > index 
                          ? 
                          grey[900]
                          : 
                            grey[600]
                      )
                    } 
                    >
                      {objective.text_objective}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {objective.evaluation_final_items && (objective.evaluation_final_items.map((evaluationFinalItem, index) => (
                    <Grid key={evaluationFinalItem.id_objective_detail}  item xs={12} >
                      <EvaluationFinalItem evaluationFinalItem={evaluationFinalItem} />
                    </Grid>
                  )))} 
                  <Grid item xs={12}>
                  {( mentory?.status_mentory === '1'  ) && (
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant={(data?.data && index === data?.data.length - 1) ? 'outlined' : 'contained'}
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                        disabled={(data?.data && index === data?.data.length - 1)} 
                      >
                      Próxima Competência
                      </Button>
                      <Button
                        variant={(data?.data && index === data?.data.length - 1) ? 'contained' : 'outlined'}
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Voltar a Competência Anterior
                      </Button>
                    </div>
                  </Box>
                  )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          )))}
          
        </Grid>
      )}








      { /* !isLoading && (
        <Stepper activeStep={activeStep} orientation="vertical" nonLinear={true} >
          {data && (data.map((objective, index) => (
            <Step key={objective.id_objective}  completed={true} >
              <Card sx={{ borderRadius:'8px'}} elevation={0} >
                <CardHeader 
                  sx={{pb:0}} 
                  title={<Stack onClick={handleStep(index)} direction='row' spacing={2} sx={{cursor:'pointer', display: 'flex', justifyContent:'flex-start', alignItems: 'center', }}>
                          {activeStep == index 
                            ? 
                              <RadioButtonCheckedIcon sx={{ fontSize: 25, color: orange[600] }}  /> 
                            : 
                            ( 
                              activeStep > index 
                                ? 
                                  <CheckCircleIcon sx={{ fontSize: 25, color: green[600] }} /> 
                                : 
                                  <PanoramaFishEyeIcon sx={{ fontSize: 25 , color: grey[600]}} />
                            )
                          }
                          <Typography 
                            variant="h6" 
                            color={activeStep == index 
                              ? 
                                orange[900]
                              : 
                              ( 
                                activeStep > index 
                                  ? 
                                  grey[900]
                                  : 
                                    grey[600]
                              )
                            } 
                            >
                              {objective.text_objective}
                          </Typography>
                        </Stack>}
                      />
                <CardContent>
                  <StepContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} >
                      </Grid>
                      <Grid item xs={12} >

                    <Grid container spacing={2}>
                      {objective.evaluation_final_items && (objective.evaluation_final_items.map((evaluationFinalItem, index) => (
                        <Grid item xs={12} key={evaluationFinalItem.id_objective_detail}>
                          <Grid container spacing={2}>
                            <Grid item>
                              {evaluationFinalItem.text_objective_detail} 
                              {'Nota Inicial: ' + evaluationFinalItem.value_objective_detail }
                            </Grid>
                            <Grid item zeroMinWidth>
  sdasdaxda
                            </Grid>
                          </Grid>
                        </Grid>
                      )))}
                    </Grid>

                      </Grid>
                    </Grid>

                    {/* 
                    <List>
                    {objective.evaluation_final_items && (objective.evaluation_final_items.map((evaluationFinalItem, index) => (
                      <EvaluationFinalItem key={evaluationFinalItem.id_objective_detail} objectiveId={objective.id_objective} evaluationFinalItem={evaluationFinalItem} />
                    )))}
                    </List>
                    {( mentory?.status_mentory === '1'  ) && (
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === data.length - 1 ? 'Finalizar Programa' : 'Próxima Competência'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Voltar a Competência Anterior
                        </Button>
                      </div>
                    </Box>
                    )}
                     }
                  </StepContent>
                </CardContent>
              </Card>
            </Step>
          )))}
      </Stepper>
      ) */ }
    </EASCard>
   
    {/** 



    <Container>
      <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
        <EASCard>
          <ListItem>
            
            <ListItemAvatar sx={{marginRight:'15px'}}>
            <Avatar sx={{width:55, height:55}}  alt={mentory?.name_mentorado} src={mentory?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + mentory?.image_mentorado  :  ''} />
            </ListItemAvatar>
            <ListItemText 
              primary={<Typography variant="h6">{mentory?.name_mentorado}</Typography>} 
              secondary={<Typography  sx={{fontSize:'12px'}} >
                            MENTORADO
                         </Typography>}
              />
          </ListItem>          
        </EASCard>
      </Box>
      {isLoading && (
          <LinearProgress variant='indeterminate' sx={{ mt:'15px'}} />
      )}
      {!isLoading && (
      <>
      {allEvaluationFinalResponse && allEvaluationFinalResponse.length ? (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0' }}>
          <EASCard>
            <Stepper activeStep={activeStep} orientation="vertical" nonLinear={true} >
              {allEvaluationFinalResponse && (allEvaluationFinalResponse.map((objective, index) => (
                <Step key={objective.id_objective}  completed={true} >
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {objective.text_objective}
                  </StepButton>
                  <StepContent>
                    <List>
                    {objective.evaluation_final_items && (objective.evaluation_final_items.map((evaluationFinalItem, index) => (
                      <EvaluationFinalItem key={evaluationFinalItem.id_objective_detail} objectiveId={objective.id_objective} evaluationFinalItem={evaluationFinalItem} />
                    )))}
                    </List>
                    {( mentory?.status_mentory === '1'  ) && (
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === allEvaluationFinalResponse.length - 1 ? 'Finalizar Programa' : 'Próxima Competência'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Voltar a Competência Anterior
                        </Button>
                      </div>
                    </Box>
                    )}
                  </StepContent>
                </Step>
              )))}
            </Stepper>
            {(mentory?.status_mentory === '2' ) && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography variant="h5">Este programa foi encerrado</Typography>
              {(mentory?.id_user_mentorado === user?.id)  && (
                <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
              )}
            </Paper>
          )}

          </EASCard>
        </Box> 
        ) : (
          <Alert severity="warning">Não há nenhuma Competência e/ou Detalhamento cadastrado.</Alert>
        )}
      </>
      )} 
      <Dialog
          open={open}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            {'Finalizar Programa'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que deseja finalizar o programa?
              Uma vez finalizado, nâo será possível alterar os valores das notas cadastradas. Caso você precise reabrir basta solicitar ao seu mentor.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mr:2, mb:1}}>
            <Button onClick={handleCancel} variant="contained">Desistir</Button>
            <Button onClick={handleConfirme} variant="contained" autoFocus>
              Sim, Finalizar
            </Button>
          </DialogActions>
        </Dialog>    
    </Container>
    */}
    </>
  );
};