import { createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react';

import { MentoringService } from '../../services/api/mentoring/MentoringService';
import { setMentoryLocalStorange, getMentoryLocalStorange} from './util';
import { useAuthContext, useModulesContext } from '..';
import { IMentoringProviderProps, IMentoringProviderData, IMentory, IMentoryInvitation } from './types';
import { useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';


export const MentoringContext = createContext<IMentoringProviderData>({} as IMentoringProviderData);
export const MentoringProvider: React.FC<IMentoringProviderProps> = ({ children }) => {
  

  const { enqueueSnackbar } = useSnackbar();
  const { user, isAuthenticated } = useAuthContext();
  const { module } = useModulesContext();
  const queryClient = useQueryClient();

  const [mentoringBuy, setMentoringBuy] = useState<number>(0);
  const [mentoringInvitation, setMentoringInvitation] = useState<Array<IMentoryInvitation> | null>(null);
  const [mentoring, setMentoring] = useState<Array<IMentory> | null>(null);
  const [mentory, setMentory] = useState<IMentory | null>(null);


  useEffect(() => {
    (async () => {
      const userStorage = await getMentoryLocalStorange();
      if(userStorage){
        setMentory(userStorage);
      } else {
        setMentory(null);
        setMentoryLocalStorange(null);
      }
    })();
  
  }, []);




  const { isFetching:isLoadingMentory } = useQuery(['mentory', [user?.id, module?.id_module]], () => MentoringService.getMentoring(1,'',user?.id, module?.id_module), 
    {
      enabled: (!!(isAuthenticated) && (module?.id_module == '1' || module?.id_module == '2' || module?.id_module == '3')),
      staleTime: Infinity,
      onSuccess: data => {
        data?.data ? setMentoring(data.data) : setMentoring(null);
        data?.invitation ? setMentoringInvitation(data.invitation) : setMentoringInvitation(null);
        data?.buy ? setMentoringBuy(data.buy) : setMentoringBuy(0);
      },
      onError: () => {
        setMentoringBuy(0);
        setMentory(null);
        setMentoring(null);
        enqueueSnackbar('Erro ao tentar carregar a lista de mentorias!', { variant:'error'});
      }
    }
  );


  const getMentoring = useCallback(async () => {
    queryClient.invalidateQueries('mentory');
  }, []);

  const handleMentory = useCallback(async (mentory:IMentory | null) => {
      queryClient.invalidateQueries('mentory');
      if(mentory !== null && mentory.id_mentory){
        setMentoryLocalStorange(mentory);
        setMentory(mentory);
      }
      else{
        
        setMentoryLocalStorange(null);
        setMentory(null);
      }
  }, []);

  const isMentorySelected = useMemo(() => (!(mentory) ? null : true ), [mentory]);


  return (
    <MentoringContext.Provider value={{isMentorySelected, mentoringBuy, mentoringInvitation, isLoadingMentory, mentoring, mentory, getMentoring:() => getMentoring(), markMentory:(mentory) => handleMentory(mentory)}}>
      {children}
    </MentoringContext.Provider>
  );
};
export const useMentoringContext = () => useContext(MentoringContext);

