import { Box, Pagination } from '@mui/material';
import { Environment } from '../../environment';

interface IPaginationComponent {
    page?: number;
    total?: number | null;
    setSearchParams: (page: number) => void;
}

export const PaginationComponent:React.FC<IPaginationComponent> = ({page, total, setSearchParams}) => {
  const totalItems = total ? (total -1)  : 0;
    return (
      <Box>
        {(totalItems > 0 && totalItems > Environment.LIMITE_DE_LINHAS) && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
            <Pagination
              page={page}
              count={Math.ceil(totalItems  / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => setSearchParams(newPage)}
              variant="outlined" 
              shape="rounded"
              color="primary"
              siblingCount={3} 
              boundaryCount={1}
            />
          </Box>
        )}
      </Box>
    );
  };