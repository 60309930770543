import { useState } from 'react';
import { styled, Box, Container, CircularProgress, Typography, Theme, useMediaQuery, useTheme, Divider, Stack} from '@mui/material';
import { useAuthContext , useEASThemeContext } from '../../shared/contexts';

import * as yup from 'yup';
import { EASInput, EASButton } from  '../../shared/forms';
import { EASCard } from '../../shared/components';
import { Environment } from '../../shared/environment';

const ContentStyleLogin = styled('div')(() => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));


export const UpdatePassword = () => {

  const theme = useTheme();

  const { themeName } = useEASThemeContext();
  const { update, user } = useAuthContext();
  
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [show, setShow] = useState(false);

  const updatePasswordSchema = yup.object().shape({
    password: yup.string().required('Informe sua senha.'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'As senhas não são iguais').required('Informe sua senha.'),
  });

  const handleSubmit = () => {
    if(user && user.id){
      const id:string = user.id;
      setIsLoading(true);
      updatePasswordSchema
        .validate({ password, passwordConfirmation }, { abortEarly: false })
        .then(dadosValidados => {
          update(id, dadosValidados.password)
          .then(() => {
            setIsLoading(false);
          });
        })
        .catch((errors: yup.ValidationError) => {
          setIsLoading(false);

          errors.inner.forEach(error => {
            if (error.path === 'password') {
              setPasswordError(error.message);
            } else if (error.path === 'passwordConfirmation') {
              setPasswordConfirmationError(error.message);
            }
          });
        });
      }
  };

  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden'}} bgcolor={theme.palette.background.default}>
      <Container>
        <ContentStyleLogin>
          <EASCard>
            <Box display='flex' flexDirection='column' alignItems='left'  gap={2}  sx={{p:'40px 30px'}}>
              <Box
                display='flex' 
                flexDirection='column'
                component="img"
                alt={Environment.CLIENT_NAME}
                alignItems='center'
                height={'auto'}
                width={'150px'}
                src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                sx={{alignContent:'center', m:'auto'}}
              />
              <Typography variant="h6" sx={{fontWeight:600}} color="primary">Atualizar Senha</Typography>
              <Typography variant="subtitle1" sx={{lineHeight:1.25}}>Para continuar, é necessário que você cadastre uma nova senha.</Typography>
              <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
                <form>
                <Stack spacing={2}>
                  <EASInput
                    nameIconLeft='key'
                    nameIconRight={show ? 'visibility_off' : 'visibility'}
                    label='Senha'
                    type={show ? 'text' : 'password'}
                    value={password}
                    disabled={isLoading}
                    error={!!passwordError}
                    helperText={passwordError}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={() => setPasswordError('')}
                    handleIconRight={() => setShow(!show)}
                  />
                  <EASInput
                    nameIconLeft='key'
                    nameIconRight={show ? 'visibility_off' : 'visibility'}
                    label='Repetir Senha'
                    type={show ? 'text' : 'password'}
                    value={passwordConfirmation}
                    disabled={isLoading}
                    error={!!passwordConfirmationError}
                    helperText={passwordConfirmationError}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    onKeyDown={() => setPasswordConfirmationError('')}
                    handleIconRight={() => setShow(!show)}
                  />
                  <Box></Box>
                  </Stack>
                  <EASButton
                    variant='contained'
                    disabled={isLoading}
                    onClick={handleSubmit}
                    endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                  >
                    Atualizar Senha
                  </EASButton>
                </form>
              </Box>
              <Divider/>
            </Box>
            <Box display="flex"  flexDirection='column' alignItems='center' sx={{alignContent:'center', m:'auto', alignItems:'center'}}>
              <Box display="flex" sx={{mb:'18px'}}>
                <Typography display="flex" variant="caption" sx={{fontSize:'18px'}}>©</Typography>
                <Box                  
                  display="flex" 
                  component="img"
                  alt={Environment.CLIENT_NAME}
                  alignItems='center'
                  height={'20px'}
                  width={'20px'}
                  src={'/easistemas.png'}
                  sx={{mx:'3px', mt:'3px'}}     
                />
                <Typography display="flex" variant="caption" sx={{mt:'4px'}}>EASistemas</Typography>
              </Box>
            </Box>
          </EASCard>
        </ContentStyleLogin>
      </Container>
    </Box>
  );
};
