import { LayoutDefault } from '../../shared/layouts';
import  MeetingEdit, { IRefMeeting} from './MeetingEdit';


import React, { useRef } from 'react';
import { Box, Stack } from '@mui/material';

import { useParams, useNavigate} from 'react-router-dom';



import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

export const Meeting = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();

    const refMeeting = useRef<IRefMeeting>(null);

    const storeDisplay = () => {
        if(refMeeting.current)
            refMeeting.current.saveMeeting();
    };

    return (
        <LayoutDefault >
            <EASCard 
                titleCard={'Editar Sessão'}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                <EASButton variant="outlined" onClick={() => navigate('/sessoes')}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <MeetingEdit MeetingID={id} ref={refMeeting} />
                </Box>
            </EASCard>
        </LayoutDefault>
    );
};