import * as React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { EASButton } from '../../shared/forms';
import { orange } from '@mui/material/colors';


interface IHomeSlideProps {
  post: {
    description: string;
    image: string;
    imageText: string;
    linkText: string;
    title: string;
  };
}

export const HomeSlide:React.FC<IHomeSlideProps> = (({...props}) => {

  const { post } = props;

  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 4,
        height:{ xs: '320px', md: '400px'},
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundImage: `url(${post.image})`,
      }}
    >
      <Container>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.6)',
          }}
        />
        <Box
          sx={{
            position: 'relative',
            margin: 'auto',
            py: { xs: 4, md: 12 },
            textAlign:'center',
            maxWidth:'800px'
          }}
        >
          <Typography sx={{color:orange[800], fontWeight:'bold' }} component="h1" variant="h3" color="inherit" gutterBottom>
            {post.title}
          </Typography>
          <Typography sx={{fontWeight:'600' }}variant="h5" color="inherit" paragraph>
            {post.description}
          </Typography>
          <EASButton variant="outlined" color='primary'>
            {post.linkText}
          </EASButton>
        </Box>
      </Container>
    </Paper>
  );
});

