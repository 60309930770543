import { createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react';

import { ModulesService } from '../../services/api/modules/ModulesService';
import { setModuleLocalStorange, getModuleLocalStorange} from './util';
import { useAuthContext } from '..';
import { IModulesProviderProps, IModulesProviderData, IModule } from './types';
import { useQuery } from 'react-query';
import { Environment } from '../../environment';
import { useSnackbar } from 'notistack';

export const ModulesContext = createContext<IModulesProviderData>({} as IModulesProviderData);
export const ModulesProvider: React.FC<IModulesProviderProps> = ({ children }) => {
  
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();
  
  const [idModule, setIdModule] = useState<string | null>(null);
  //const [modules, setModules] = useState<Array<IModule> | null>(null);
  const [module, setModule] = useState<IModule | null>(null);


  useEffect(() => {
    (async () => {
      const userStorage = await getModuleLocalStorange();
      if(userStorage){
        setModule(userStorage);
        setIdModule(userStorage.id_module);
      } else {
        setModule(null);
        setModuleLocalStorange(null);
        setIdModule('');
      }
    })();
  }, []);
    
  const { data:modules, isFetching:isLoadingModule  } = useQuery('modules', () => ModulesService.getModules(), 
    {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Erro ao tentar carregar a lista de módulos!', { variant:'error'});
      }
    }
  );

  const handleModule = useCallback(async (module: IModule | null) => {
      if(module !== null && module.id_module){
        setModuleLocalStorange(module);
        setIdModule(module.id_module);
        setModule(module);
      }
      else{
        setModuleLocalStorange(null);
        setIdModule(null);
        setModule(null);
      }
  }, []);

  const isModuleSelected = useMemo(() => ((idModule == 'error') ? false : (!(idModule) && !(module) ? null : true )), [idModule]);
  
  return (
    <ModulesContext.Provider value={{isModuleSelected, modules, module, isLoadingModule, markModule:(module) => handleModule(module)}}>
      {children}
    </ModulesContext.Provider>
  );
};
export const useModulesContext = () => useContext(ModulesContext);

