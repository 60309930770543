import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { styled, Box, Container, CircularProgress, Typography, useTheme, Divider, Link} from '@mui/material';
import { useAuthContext , useEASThemeContext } from '../../shared/contexts';

import * as yup from 'yup';
import { EASInput, EASButton } from  '../../shared/forms';
import { EASCard } from '../../shared/components';
import { Environment } from '../../shared/environment';
import { Stack } from '@mui/system';

const ContentStyleLogin = styled('div')(() => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));


export const Login = () => {

  const theme = useTheme();

  const navigate = useNavigate();
  const { themeName } = useEASThemeContext();
  const { login } = useAuthContext();
  
  const [isLoading, setIsLoading] = useState(false);

  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);

  const loginSchema = yup.object().shape({
    email: yup.string().required('O campo e-mail é obrigatório.').email('Informe um e-mail válido'),
    password: yup.string().required('Informe sua senha.'),
  });

  const handleSubmit = () => {
    setIsLoading(true);
    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then(dadosValidados => {
        login(dadosValidados.email, dadosValidados.password)
        .then(() => {
          setIsLoading(false);
        });
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);

        errors.inner.forEach(error => {
          if (error.path === 'email') {
            setEmailError(error.message);
          } else if (error.path === 'password') {
            setPasswordError(error.message);
          }
        });
      });
  };

  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden'}} bgcolor={theme.palette.background.default}>
      <Container>
        <ContentStyleLogin>
          <EASCard>
            <Box display='flex' flexDirection='column' alignItems='left' gap={2}  sx={{p:'40px 30px 10px 30px'}}>
              <Box
                display='flex' 
                flexDirection='column'
                component="img"
                alt={Environment.CLIENT_NAME}
                alignItems='center'
                height={'auto'}
                width={'150px'}
                src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                sx={{alignContent:'center', m:'auto'}}
              />
              <Typography variant="h6" sx={{fontWeight:600}} color="primary">Olá, bem vindo</Typography>
              <Typography variant="subtitle1">Informe suas credenciais para continuar</Typography>
              <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
              <form>
                <Stack spacing={2}>
                <EASInput
                  nameIconLeft='alternate_email'
                  label='E-mail'
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={() => setEmailError('')}
                  helperText={emailError}
                  disabled={isLoading}
                  error={!!emailError}
                />
                <EASInput
                  nameIconLeft='key'
                  nameIconRight={show ? 'visibility_off' : 'visibility'}
                  label='Senha'
                  type={show ? 'text' : 'password'}
                  value={password}
                  disabled={isLoading}
                  error={!!passwordError}
                  helperText={passwordError}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={() => setPasswordError('')}
                  handleIconRight={() => setShow(!show)}
                />
                <Box></Box>
                </Stack>
                </form>
                <EASButton
                  variant='contained'
                  disabled={isLoading}
                  onClick={handleSubmit}
                  endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                >
                  Entrar
                </EASButton>
              </Box>
              <Divider/>
            </Box>
            <Box display="flex"  flexDirection='column' alignItems='center' sx={{alignContent:'center', m:'auto', alignItems:'center'}}>
            <Link
                  underline="none"
                  variant="subtitle2"
                  color="textSecondary"
                  onClick={() => navigate('/cadastre-se')}
                  sx={{pb:'10px', cursor:'pointer'}}
              >
                Quer se cadastrar?
              </Link>
              <Link
                  underline="none"
                  variant="subtitle2"
                  color="textSecondary"
                  onClick={() => navigate('/')}
                  sx={{pb:'30px', cursor:'pointer'}}
              >
                Que ir para a página inicial?
              </Link>
              <Box display="flex" sx={{mb:'18px'}}>
                <Typography display="flex" variant="caption" sx={{fontSize:'18px'}}>©</Typography>
                <Box                  
                  display="flex" 
                  component="img"
                  alt={Environment.CLIENT_NAME}
                  alignItems='center'
                  height={'20px'}
                  width={'20px'}
                  src={'/easistemas.png'}
                  sx={{mx:'3px', mt:'3px'}}
                  
                />
                <Typography display="flex" variant="caption" sx={{mt:'4px'}}>EASistemas</Typography>
              </Box>
            </Box>
          </EASCard>
        </ContentStyleLogin>
      </Container>
    </Box>
  );
};
