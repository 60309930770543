import { LayoutDefault } from '../../shared/layouts';
import { EvaluationsList } from './EvaluationsList';

export const Evaluations = () => {

  return (
    <LayoutDefault>
     <EvaluationsList />
    </LayoutDefault>
  );
};
