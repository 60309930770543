import React from 'react';
import { Divider, Drawer, List, Box, Typography, useMediaQuery, useTheme, Hidden} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDrawerContext, useEASThemeContext, useMentoringContext } from '../../contexts';
import { Environment } from '../../environment';
import { BtnSideBar } from './btnSideBar';
import { EASScrollbar } from '../../components';
import { SelectMentory } from './selectMentory';

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <BtnSideBar to={to} icon={icon} label={label} onClick={handleClick}></BtnSideBar>
  );
};

export const SideBar: React.FC = () => {
  const theme = useTheme();
  const { themeName } = useEASThemeContext();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  
  const { isMentorySelected } = useMentoringContext();

  

  const { isDrawerOpen, drawerOptions, toggleDrawerOpen } = useDrawerContext();

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 3 }, width: 'auto', }}>
      <Drawer 
        open={isDrawerOpen} 
        variant={mdDown ? 'temporary' : 'persistent'} 
        onClose={toggleDrawerOpen} 
        sx={{ 
              '& .MuiDrawer-paper': { 
                display:'block',
                borderWidth: 0,
                marginTop: {sx: 0, md: '64px'},
                boxSizing: 'border-box',
                overflow:'hidden',
                
          }}}
        >
        <EASScrollbar sx={{width: `${theme.spacing(29)}`, height: {sx: '100vh', sm: '100vh', md: 'calc(100vh - 70px)'}}}>     
          <Box  height="100%" display="flex" flexDirection="column" sx={{pr: mdDown ? 0.5 : 1}}>
            <Hidden mdUp>
              <Box display="flex" p={0}>
                <Box
                  component="img"
                  src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                  sx={{ mx:'auto',mt: 1, width: 100, height: 'auto'}}
                />
              </Box>
            </Hidden>
            
            <Box flex={1}>
              {isMentorySelected && <SelectMentory />}
              
              <List sx={{marginLeft:'18px'}}>
                {drawerOptions.map(drawerOption => (
                  <Box key={drawerOption.path}>
                  {drawerOption.divider && (<Divider sx={{marginY:'10px', mr:'10px'}}/>)}
                  {drawerOption.group && (<Typography variant="subtitle2" sx={{marginY:'10px'}}>{drawerOption.group}</Typography>)}
                  <ListItemLink
                    to={drawerOption.path}
                    key={drawerOption.path}
                    icon={drawerOption.icon}
                    label={drawerOption.label}
                    onClick={mdDown ? toggleDrawerOpen : undefined}
                  />                
                  </Box>

                ))}
              </List>
            </Box>
            <Box flex={1}>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography sx={{mb:'0px',fontSize:'10px'}}>
                  V:{Environment.VERSION}
                </Typography>
              </Box>
            </Box>
          </Box>
        </EASScrollbar>
      </Drawer>
    </Box>
  );
};
