import React, { ReactElement, useEffect, useMemo, useState, MouseEvent } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Chip, Box,  Grid, Card, Avatar, useTheme, Divider, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FeedIcon from '@mui/icons-material/Feed';

import { EASDialogAlert } from '../../shared/components';
import { EASButton } from '../../shared/forms';



import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { IMeeting } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';


import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useMutation, useQueryClient } from 'react-query';

interface IMeetingsListItemProps {
  meeting: IMeeting;
}

export const MeetingsListItem: React.FC<IMeetingsListItemProps> = ({meeting})  => {



  const theme = useTheme();
  const navigate = useNavigate();
  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openEl = Boolean(anchorEl);

  const [open, setOpen] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);
  const [textLocal, setTextLocal] = useState<string>('');

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idMeeting, setIdMeeting] = useState<string | null>(null);


  const queryClient = useQueryClient();

  const { mutateAsync:deleteMeeting } = useMutation((idMeeting:string) => MeetingsService.deleteMeeting(idMeeting), {
    onSuccess: data => {
      queryClient.invalidateQueries('meeting-list');
      enqueueSnackbar('Sessão de ação removido com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a sessão! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant: 'error' });
      setLoadingDialog(false);
    },
  });

  const { mutateAsync:updateMeeting } = useMutation((Meeting:IMeeting) => MeetingsService.updateMeeting(Meeting), {
    onSuccess: data => {
      queryClient.invalidateQueries('meeting-list');
      enqueueSnackbar('Sessão ' + (executeDialog=='unblock' ? 'reativada' : 'cancelada') + ' com sucesso!', { variant: 'success' }); 
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (executeDialog=='unblock' ? 'reativar' : 'cancelar') + ' a sessão!', { variant: 'error' });
      setLoadingDialog(false);
    },
  });

  const handleLocal = (local?: string) => {
    if(local)
      setTextLocal(local);

    setOpenLocal(true);
  };


  const handleTeamSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBlockMeeting = (id_meeting?: string) => {
    if (id_meeting) {
      setIdMeeting(id_meeting);
      setTitleDialog('Cancelar Sessão');
      setTextDialog(<Typography component="span">Você tem certeza que deseja Cancelar esta sessão?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  };
  const handleUnblockMeeting = (id_meeting?: string) => {
    if (id_meeting) {
      setIdMeeting(id_meeting);
      setTitleDialog('Reativar Sessão');
      setTextDialog(<Typography component="span">Você tem certeza que deseja Reativar esta sessão?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  };


  const handleRemoveMeeting = (id_meeting?: string) => {
    if (id_meeting) {
      setIdMeeting(id_meeting);
      setTitleDialog('Excluir Sessão');
      setTextDialog(<Typography component="span">Você tem certeza que deseja Excluir esta sessão? Todas as informações salvas nesta sessão serão perdidas.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  };
  

  const handleCancelDialog = () => {
    setIdMeeting(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch (executeDialog) {
      case 'remove':
        removeMeeting();
        break;
      case 'block':
        blockMeeting(false);
        break;
      case 'unblock':
        blockMeeting(true);
        break;
    }
  };



  const blockMeeting = (block: boolean) => {
    setLoadingDialog(true);
    if (idMeeting) {
      const statusMeeting: IMeeting = {
        id_meeting: idMeeting,
        status_meeting: (block ? '1' : '0')
      };
      updateMeeting(statusMeeting);

/*
      MeetingsService.updateMeeting(statusMeeting).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar ' + (block ? 'reativar' : 'cancelar') + ' a sessão!', { variant: 'error' });
          setLoadingDialog(false);
        } else {
          enqueueSnackbar('Sessão ' + (block ? 'reativada' : 'cancelada') + ' com sucesso!', { variant: 'success' });
          setLoadingDialog(false);
          setUpdateList();
          handleCancelDialog();
        }
      });*/
    }
    setLoadingDialog(false);
  };


  const removeMeeting = () => {
    setLoadingDialog(true);
    if (idMeeting) {
      deleteMeeting(idMeeting);
      /*MeetingsService.deleteMeeting(idMeeting).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar remover a sessão! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant: 'error' });
          setLoadingDialog(false);
        } else {
          enqueueSnackbar('Sugestão de ação removido com sucesso!', { variant: 'success' });
          setLoadingDialog(false);
          setUpdateList();
          handleCancelDialog();
        }
      });*/
    }
  };





  const colorMeeting = ['',
    '#37A2DA', '#8378EA', '#7289ab', '#3366E6', '#ff9f7f', '#fb7293',
    '#E062AE', '#E690D1', '#d48265', '#ca8622', '#B34D4D', '#c23531',
    '#4D8000', '#749f83', '#991AFF', '#B34D4D', '#4D8000', '#c23531',
    '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622',
    '#bda29a', '#6e7074', '#546570', '#c4ccd3', '#759aa0', '#e69d87',
    '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab',
    '#91ca8c', '#f49f42'];

  const somaMinuto = (dateMeeting: string, minutes: number) => {
    const newDate = new Date(dateMeeting);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate.toString();
  };

  const duracao = [
    0, 30, 60, 90, 120
  ];


  const handleCloseLocal = () => {
    setTextLocal('');
    setOpenLocal(false);
  };


  return (
    <Card variant="outlined" sx={{ p: '25px', borderRadius: '12px'}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
            <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Sessão</Typography>
              <Avatar sx={{ width: 20, height: 20, padding: '8px', bgcolor: colorMeeting[meeting?.ordering_meeting ? parseInt(meeting?.ordering_meeting) : 0] }} alt={meeting?.ordering_meeting}>
                {meeting?.ordering_meeting}
              </Avatar>
            </Grid>
            <Grid item xs={true} >
              <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'-20px', marginRight:'-20px' }}>
                <IconButton
                  onClick={handleTeamSelect}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openEl ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openEl ? 'true' : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={openEl}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {meeting?.status_meeting =='1' ? (
                  <MenuItem onClick={() => handleBlockMeeting(meeting?.id_meeting)}>
                    <Typography  sx={{fontSize:'12px'}} >
                      <strong>Cancelar</strong>
                    </Typography>
                  </MenuItem>
                  ) : (
                  <MenuItem onClick={() => handleUnblockMeeting(meeting?.id_meeting)}>
                    <Typography  sx={{fontSize:'12px'}} >
                      <strong>Reativar</strong>
                    </Typography>
                  </MenuItem>
                  )}
                  {user?.id == mentory?.id_user_mentorado && (
                    
                  <MenuItem onClick={() => handleRemoveMeeting(meeting?.id_meeting)}>
                    <Typography  sx={{fontSize:'12px'}} >
                      <strong>Excluir</strong>
                    </Typography>
                  </MenuItem>
                    
                  )}

                </Menu>
              </Box>
              <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'5px', marginRight:'10px' }}>
                {meeting?.status_meeting == '0' && (
                  <Chip label="Cancelada" color="error" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                )}
                {meeting?.status_meeting == '2' && (
                  <Chip label="Finalizada" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                )}
                {meeting?.status_meeting == '1' && (
                  <>
                  {meeting?.date_meeting && meeting?.duration_meeting && new Date() > new Date(somaMinuto(meeting?.date_meeting, duracao[parseInt(meeting.duration_meeting)])) ? (
                    <Chip label="Encerrada" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                    ) : (meeting?.date_meeting && new Date(meeting?.date_meeting) <= new Date() ? (
                      <Chip label="Em sessão" sx={{ backgroundColor: '#991AFF', color: '#fff', fontWeight: '500', letterSpacing: '0.5px' }} />
                      ) : (
                      <Chip label="Agendada" color="info"  sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                    )
                  )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight:'100px' }}>
            <Typography variant="caption">
              Assunto: <Typography variant="caption" sx={{ fontWeight: 600 }}>{meeting?.subject_meeting}</Typography>
            </Typography>
            <Typography variant="caption">
              Data e hora: <Typography variant="caption" sx={{ fontWeight: 600 }}>{meeting?.date_meeting && new Date(meeting?.date_meeting).toLocaleString('pt-BR', {dateStyle: 'medium', timeStyle: 'short'}) }</Typography>
            </Typography>
            <Typography variant="caption">
              Duração: 
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {
                  meeting?.duration_meeting == '1' ? ' 30 minutos' : 
                  meeting?.duration_meeting == '2' ? ' 60 minutos' : 
                  meeting?.duration_meeting == '3' ? ' 90 minutos' : 
                  meeting?.duration_meeting == '4' ? ' 120 minutos' : 
                  meeting?.duration_meeting == '5' ? ' Indeterminado' : null
                }
              </Typography>
            </Typography>
            <Typography variant='caption'>
              Tipo de sessão:
              <Typography variant='caption' sx={{fontWeight: 600}}>
                {meeting?.type_meeting == '0' ? ' Online' : ' Presencial'}
              </Typography>
            </Typography>
          </Box>
        </Grid>


        {/*
        <Grid item xs={12}>
          <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
          <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
        </Grid>
        */}
      </Grid>

      <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item xs={6}>

          <EASButton 
            color="primary" 
            variant="outlined" 
            startIcon={<FeedIcon sx={{pb: '4.3px'}}/>} 
            disabled={meeting?.status_meeting =='0'}
            onClick={() => navigate(`/sessoes/detalhes/${meeting.id_meeting}`)}
            fullWidth>Editar
          </EASButton>
          {/*
          <EASButton 
            color="primary" 
            variant="outlined" 
            startIcon={<FeedIcon sx={{pb: '4.3px'}}/>} 
            disabled={!(meeting?.status_meeting == '2')}
            
            fullWidth>Relatório
          </EASButton>
          */}
        </Grid>
                    {/*
            disabled={!(meeting?.date_meeting &&
                        meeting?.duration_meeting && 
                        new Date() >= new Date(somaMinuto(meeting?.date_meeting, -30)) && 
                        new Date() < new Date(somaMinuto(meeting?.date_meeting, duracao[parseInt(meeting.duration_meeting)]))
                      )}*/}
        <Grid item xs={6}>
          {meeting?.type_meeting == '0' ? (
          <Button 
            color="primary" 

            disabled={meeting?.status_meeting =='0' || !(meeting?.local_meeting)}
            variant="outlined"
            startIcon={<OpenInBrowserIcon sx={{pb: '1px'}}/>}
            target="_blank"
            sx={{borderRadius: 1, py:1,}}
            component="a"
            href={meeting?.local_meeting}
            fullWidth>Acessar
          </Button>
          ): (
          <EASButton 
            color="primary" 
            variant="outlined"
            disabled={meeting?.status_meeting =='0' || !(meeting?.local_meeting)}
            startIcon={<LocationOnIcon sx={{pb: '1px'}}/>} 
            onClick={() => handleLocal(meeting?.local_meeting)}
            fullWidth>Ver Local
          </EASButton>
          )}

        </Grid>
      </Grid>

      <EASDialogAlert
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <Dialog open={openLocal} onClose={handleCloseLocal} fullWidth={true} maxWidth={'xs'}>
        <DialogTitle>
          Local da Sessão
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <Typography>{textLocal}</Typography>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <EASButton variant='outlined' onClick={handleCloseLocal}>Fechar</EASButton>
        </DialogActions>
      </Dialog>
    </Card>      
  );
};

