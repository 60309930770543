import React, { useState } from 'react';
import {ListItemAvatar, Box, ListItem, ListItemText, Typography, Card, CardContent, Grid, useTheme, Icon, Avatar } from '@mui/material';
import { useSnackbar } from 'notistack';

import { IEvaluationFinalItem, IEvaluationFinal } from '../../shared/contexts/EvaluationFinalContext/types';
import { useMentoringContext } from '../../shared/contexts';

import { EvaluationFinalService } from '../../shared/services/api/evaluation-final/EvaluationFinalService';
import { EASInput } from '../../shared/forms';
import { useMutation, useQueryClient } from 'react-query';

interface IEvaluationFinalItemProps {
  evaluationFinalItem?: IEvaluationFinalItem;
}  

export const EvaluationFinalItem: React.FC<IEvaluationFinalItemProps> = ({ evaluationFinalItem }) => {

  const theme = useTheme();

  const queryClient = useQueryClient();

  const { mentory } = useMentoringContext();

  const [evaluationFinal, setEvaluationFinal] = useState<IEvaluationFinal | undefined>(evaluationFinalItem);

  const { enqueueSnackbar } = useSnackbar();


  const { mutateAsync:storeUpdate } = useMutation((updateEvaluationFinal:IEvaluationFinal) => EvaluationFinalService.updateEvaluationFinal(updateEvaluationFinal), {
    onSuccess: () => {
      queryClient.invalidateQueries('evaluation-final');
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar salvar a avaliação final!', { variant:'error'});
    },
  });


  const { mutateAsync:storeNew } = useMutation((newEvaluationFinal:IEvaluationFinal) => EvaluationFinalService.createEvaluationFinal(newEvaluationFinal), {
    onSuccess: data => {
      setEvaluationFinal(existingValues => ({
        ...existingValues,
        id_evaluation_final : data
      }));
      queryClient.invalidateQueries('evaluation-final');

    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar salvar a avaliação final!', { variant:'error'});
    },
  });

  const saveEvaluationFinal  = async () => {


    const saveEvaluationFinal: IEvaluationFinal = {
      id_evaluation_final:(evaluationFinal && evaluationFinal.id_evaluation_final ? evaluationFinal.id_evaluation_final : ''),
      id_mentory: mentory?.id_mentory,
      id_objective: evaluationFinal?.id_objective,
      id_objective_detail: evaluationFinal?.id_objective_detail,
      status_evaluation_final: '1',
      value_evaluation_final: evaluationFinal?.value_evaluation_final,
      text_evaluation_final: evaluationFinal?.text_evaluation_final,
      date_evaluation_final: new Date().toLocaleDateString('pt-BR'),
    };       
    
    if(evaluationFinal && evaluationFinal.id_evaluation_final)
      storeUpdate(saveEvaluationFinal);
    else
      storeNew(saveEvaluationFinal);

    
  };
  
  return (


    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Card elevation={0} sx={{paddingTop:'15px', paddingBottom:0, borderRadius: '12px', border:'solid 1px', borderColor:theme.palette.secondary.dark}}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
              <Card sx={{borderRadius: '12px', background:'rgb(153 33 243)', color:'rgb(255, 255, 255)', position:'relative', m:2, mt:0,}} elevation={0}>
                <CardContent sx={{p:'10px', }}>
                  <Typography variant="body2" sx={{position: 'absolute', left: '-17px', bottom: '-27px', color: 'rgb(255, 255, 255)', transform: 'rotate(25deg)'}}>
                      <Avatar sx={{ width:'120px', height:'120px', background: 'transparent' }}>
                          <Icon sx={{color:'rgb(255, 255, 255)', fontSize:'100px', opacity: '0.35'}} >flag</Icon>
                      </Avatar>
                  </Typography>
                  <Grid container spacing={1}>
                      <Grid item xs={12}>
                          <Typography variant="h3" sx={{ textAlign:'center', fontSize: '1.25rem', fontWeight: '600', lineheight:'1.167'}}>{evaluationFinalItem?.value_objective_detail}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                          <Typography variant="h5" sx={{ textAlign:'center', fontSize: '0.85rem', fontWeight: '200', lineheight:'1.334'}}>Nota inicial</Typography>
                      </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={9} >
              <ListItem>
                <ListItemAvatar  sx={{ background: 'trasnparent', marginRight:'15px' }} >
                    <Icon sx={{color:'rgb(0, 200, 83)', fontSize:'60px', opacity:'1'}}>offline_pin_two_tone</Icon>
                </ListItemAvatar>
                <ListItemText primary={<Typography variant="h3" sx={{ fontSize: '1.25rem', fontWeight: '500', lineheight:'1.167'}}>{evaluationFinalItem?.text_objective_detail}</Typography>} />

                <ListItemAvatar sx={{minWidth:'160px'}}>
                  <Box style={{ display: 'flex', justifyContent:'center', width:'160px'}}>
                      <EASInput
                        value={evaluationFinal?.value_evaluation_final}
                        onBlur={saveEvaluationFinal}
                        label="Nota Final"
                        type="number"

                        disabled={mentory?.status_mentory ==='2'}
                        min={mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0'}
                        max={mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10'}
                        step={mentory?.step_grade_mentory ? mentory.step_grade_mentory : '1.5'}
                        onChange={(e) => {
                            const min = mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0';
                            const max = mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10';
                            setEvaluationFinal(existingValues => ({
                              ...existingValues,
                              value_evaluation_final: (parseFloat(e.target.value) > parseFloat(max) ? max : (parseFloat(e.target.value) < parseFloat(min) ? min : e.target.value))
                            }));
                          }
                        }
                      />
                  </Box>
                  </ListItemAvatar>
              </ListItem>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};