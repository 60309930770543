import { LayoutDefault } from '../../shared/layouts';
import { EvaluationEdit } from './EvaluationEdit';

export const Evaluation = () => {

  return (
    <LayoutDefault>
     <EvaluationEdit />
    </LayoutDefault>
  );
};
