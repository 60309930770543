import React, { useMemo, useState } from 'react';

import { LayoutDefault } from '../../shared/layouts';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Stack,
  Avatar,
  Typography,
  Box,
  Skeleton,
  Pagination,
  Grid,
  Card,
  useTheme,
  Tooltip,
  AvatarGroup,
  CardMedia,
  Button,
  ButtonGroup,
  ButtonProps
} from '@mui/material';

import { Container, styled } from '@mui/system';
import { grey } from '@mui/material/colors';
import { NumericFormat } from 'react-number-format';


import { EASCard, EASProgressList } from '../../shared/components';
import { EASButton, EASInput } from '../../shared/forms';
import AddIcon from '@mui/icons-material/Add';

import { useAuthContext , useMentoringContext } from '../../shared/contexts';
import { ProductBuyService } from '../../shared/services/api/productbuy/ProductBuyService';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { useQuery } from 'react-query';
import { ProductBuyNew } from './ProductBuyNew';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
export const ProductBuyItem:React.FC = (() => {

  const { id = ''} = useParams<'id'>();

  const theme = useTheme();
  const navigate = useNavigate();
  
  //const [isLoading, setIsLoading] = useState(true);
  const [valueProductBuy, setValueProductBuy] = useState(0);
  const [valueProductBuyAmount, setValueProductBuyAmount] = useState(0);
  const [totalItemBuy, setTotalItemBuy] = useState(1);

  const [updateList, setUpdateList] = useState(false);


  const { user } = useAuthContext();
  const { markMentory } = useMentoringContext();

  const { data, isError, isLoading } = useQuery(['product-buy', id,], () => ProductBuyService.getProductBuy(id), { 
                                                                              onSuccess: data => {
                                                                                setValueProductBuy(data?.value_product_buy ? parseFloat(data.value_product_buy) : 0);

                                                                              // const message = 'success';
                                                                              //alert(data.total);
                                                                              },
                                                                              onError: () => {
                                                                                alert('there was an error');
                                                                              },                                  
                                                                              keepPreviousData: true, 
                                                                              retry:3,
                                                                              refetchInterval:false  });






  const handleDownItem= () => {
 
    if(totalItemBuy > 1) {
      setValueProductBuyAmount((totalItemBuy - 1 ) * valueProductBuy);
      setTotalItemBuy(totalItemBuy - 1 );
      

    }
  };
  const handleUpItem= () => {
 
      setValueProductBuyAmount((totalItemBuy+1) * valueProductBuy);
      setTotalItemBuy(totalItemBuy + 1);
      

   // }
  };


  
  return (
    <LayoutDefault>    
      <Container>  
      {isLoading && (   
        <EASCard >               
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} >
                <Grid item xs={12} md={6} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={400} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </EASCard>
                  )}
                  {!isLoading && (data && data !== Error && !isError) && (
        <EASCard >               
        <Grid container spacing={3} sx={{p:3}}>
          <Grid item xs={12}>
            <Grid container spacing={3} >
              <Grid item xs={12} md={6} >
                <Card variant="outlined" sx={{ borderRadius:'12px' }}>
                  <CardMedia
                    component="img"
                    height="400"
                    image="https://berrydashboard.io/assets/prod-5-Cl9js1ye.png"
                    alt="Paella dish"
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={6} >
                <Grid container spacing={3} >

                  <Grid item xs={12}>
                    <Typography variant="h5" component="h3">{data.name_product_buy}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">{data.description_product_buy}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <NumericFormat
                      value={valueProductBuyAmount > 0 ? valueProductBuyAmount : data.value_product_buy}
                      thousandsGroupStyle="lakh"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2} 
                      fixedDecimalScale 
                      displayType="text"
                      prefix="R$ "
                      renderText={(value) => <Typography variant="h4"  sx={{fontWeight:'bold'}} color="primary">{value}</Typography>}
                    />
                  </Grid>

                  <Grid item xs={12}>

                  <ButtonGroup size="small" >
                    <EASButton size="small" variant="contained" onClick={handleDownItem}>-</EASButton>
                    <EASButton size="small"  sx={{width:'80px'}} disableElevation >{totalItemBuy}</EASButton>
                    <EASButton size="small" variant="contained" onClick={handleUpItem}>+</EASButton>
                  </ButtonGroup>


                  </Grid>
                  <Grid item xs={12} sx={{mt:5}}>

                    <EASButton startIcon={<ShoppingCartIcon />} variant="contained" size="large">Comprar Agora</EASButton>




                  </Grid>



                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EASCard>
                   )}
      </Container> 
    </LayoutDefault>
  );
});

