

import React from 'react';
import { Typography, Link, Card, CardContent, Grid, Chip } from '@mui/material';
import { IFile }from '../../shared/contexts/FilesContext/types';
import { EASButtonFile } from '../../shared/forms/EASButtonFile';
import { EASButton } from '../../shared/forms';
import { Environment } from '../../shared/environment';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

interface IMentoryFileCardProps {
    file: IFile;
}  
 
export const MentoryFileCard: React.FC<IMentoryFileCardProps> = ({file}) => {

    return (
    <Card variant="outlined" sx={{borderRadius: '8px', border:'none'}}> 
      <CardContent sx={{p:'20px',height:'100%'}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{minHeight:'100px'}}>
              <Grid item  zeroMinWidth>
                <EASButtonFile file={file?.file_file} title="Visulizar/Baixar Arquivo" typeIcon={file?.extension_file} size="small" />
              </Grid>
              <Grid item xs={true}>
                <Typography variant="h5" sx={{ fontSize: '0.95rem', fontWeight: 500, lineheight:'1.334'}}>{file?.name_file}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {file?.name_tags && file.name_tags.length > 0 && ( file.name_tags.map((tag, index) => (
              tag ? <Chip key={index} label={tag} size="small" /> : ''
            )))}
          </Grid>    
          <Grid item xs={12}>
            <Link
                href={(Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'files/stories/files/' + file?.file_file} 
                target="_blank"
                underline="none"
            >
                <EASButton 
                    color="primary" 
                    variant="outlined" 
                    startIcon={<OpenInBrowserIcon/>} 
                    fullWidth>Abrir
                </EASButton>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
    );
};