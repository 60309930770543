import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { INotification, INotificationsList } from '../../../types';

const getNotifications = async (id_user = '', email = '', page = 1): Promise<INotificationsList> => {
  const urlRelativa = `/notifications?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&email=${email}&user=${id_user}`;
  const  {data} = await Api.get(urlRelativa);
  return data;
};

const createNotification = async (Notification: INotification): Promise<INotification> => {
  const { data } = await Api.post('/notifications', { Notification: Notification } );
  return data;
};

const updateNotification = async (Notification: INotification): Promise<void> => {
    const { data } = await  Api.put(`/notifications/${Notification.id_notification}`, Notification);
    return data; 
};

const deleteNotification = async (id: string): Promise<void> => {
    const { data } = await Api.delete(`/notifications/${id}`);
    return data;   
};


const getNotification = async (id: string): Promise<INotification> => {
    const { data } = await Api.get(`/notifications/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};


export const NotificationsService = {
  getNotifications,
  getNotification,
  createNotification,
  deleteNotification,
  updateNotification,

};
