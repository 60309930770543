import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  Stack,
  Avatar,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Chip,
  Box,
  Skeleton,
  Pagination,
  Grid,
} from '@mui/material';

import { IRating } from '../../shared/contexts/RatingsContext/types';
import { RatingsService } from '../../shared/services/api/ratings/RatingsService';
import { Environment } from '../../shared/environment';
import { RatingsNewRating } from './RatingsNewRating';
import { EASCard, EASDialogAlert} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';



export const RatingList:React.FC = (() => {


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { debounce } = useDebounce(1000, true);
  const [isLoading, setIsLoading] = useState(true);
  const [initState, setInitState] = useState(false);

  const [allRatings, setAllRatings] = useState<Array<IRating> | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idRating, setIdRating] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
      debounce(() => {
        if(initState) getRatings();
      });
  }, [search]);
  
  useEffect(() => {
    getRatings();
  }, [page,updateList]);



  async function getRatings() {
    setIsLoading(true);
    const ratingsResponse = await RatingsService.getRatings(page, search);
    if (ratingsResponse instanceof Error) {
      setAllRatings(null);
      setIsLoading(false);
      setTotalCount(0);
      setInitState(true);
    } else {
      if(ratingsResponse.data && ratingsResponse.total){
        const myRatings = JSON.parse(JSON.stringify(ratingsResponse));
        setAllRatings(myRatings.data);
        if(ratingsResponse.total)
          setTotalCount(ratingsResponse.total);
        setIsLoading(false);
        setInitState(true);
      }
      else{
        setAllRatings(null);
        setIsLoading(false);
        setTotalCount(0);
        setInitState(true);
      }
    }
  }


  const handleClickRemoveRating = (id_rating?:string) => {
    if(id_rating){
      setIdRating(id_rating);
      setTitleDialog('Excluir Pesquisa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta pesquisa? A exclusão removerá todo histórico vinculado a esta pesquisa.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockRating = (id_rating?:string) => {
    if(id_rating){
      setIdRating(id_rating);
      setTitleDialog('Bloquear Pesquisa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear esta pesquisa?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockRating = (id_rating?:string) => {
    if(id_rating){
      setIdRating(id_rating);
      setTitleDialog('Desbloquear Pesquisa');
      setTextDialog(<Typography component="span">Você deseja desbloquear esta pesquisa?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdRating(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeRating();
      break;
      case 'block':
        blockRating(false);
      break;
      case 'unblock':
        blockRating(true);
      break;
    }
  };

  const removeRating = () => {
    setLoadingDialog(true);
    if(idRating){
      RatingsService.deleteRating(idRating).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar remover a pesquisa! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant:'error'});
          setLoadingDialog(false);
        } else {
            enqueueSnackbar('Pesquisa removida com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            setUpdateList(!updateList);
            handleCancelDialog();
        }
      });
    }
  };

  const blockRating = (block:boolean) => {
    setLoadingDialog(true);
    if(idRating){
      const blockRating:IRating = {
        id_rating:idRating,
        status_rating:(block ? '1' : '0')
      };
      RatingsService.updateRating(blockRating).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar ' + (block ? 'desbloquear' : 'bloquear') + ' a pesquisa!', { variant:'error'});
          setLoadingDialog(false);
        } else {
            enqueueSnackbar('Pesquisa ' + (block ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            setUpdateList(!updateList);
            handleCancelDialog();
        }
      });
    }
  };

  return (
    <>          
      <EASCard 
        titleCard="Pesquisas NPS"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Título
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (allRatings && allRatings.length) ? (allRatings.map((rating) => (
              <TableRow 
                  hover 
                  key={rating.id_rating} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {rating?.id_rating}
                </TableCell>
                <TableCell scope="row" >
                  <Grid container spacing={2}>
                    <Grid item zeroMinWidth sm={true}>
                      <Typography variant="subtitle2">
                        {rating?.name_rating}
                      </Typography>
                      <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {rating?.question_rating}
                      </Typography>
                    </Grid>
                  </Grid> 
                </TableCell>
                <TableCell align="center">
                  <Chip label={rating?.status_rating == '1'? 'Ativo' : 'Bloqueado'} color={rating?.status_rating == '1' ? 'success' : 'error'} />
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Pesquisa"
                      onClick={() => navigate(`/pesquisas-nps/detalhes/${rating.id_rating}`)} 
                      typeIcon="edit"
                  />
                  {rating?.status_rating == '0' ? (
                  <EASButtonList 
                      title="Desbloquear Pesquisa"
                      onClick={() => handleClickUmBlockRating(rating.id_rating)} 
                      typeIcon="unblock"
                  />
                  ) : (
                  <EASButtonList 
                    title="Bloquear Pesquisa"
                    onClick={() => handleClickBlockRating(rating.id_rating)} 
                    typeIcon="block"
                  />
                  )}
                  <EASButtonList 
                    title="Remover Pesquisa"
                    onClick={() => handleClickRemoveRating(rating.id_rating)} 
                    typeIcon="remove"
                  />
                </TableCell> 
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
            <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
              <Pagination
                page={page}
                count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })}
                variant="outlined" 
                shape="rounded"
                color="primary"
                siblingCount={3} 
                boundaryCount={1}
              />
            </Box>
            )}
      </EASCard>
      <RatingsNewRating open={open} setOpen={(e) => setOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

