import { LayoutDefault } from '../../shared/layouts';
import { MentoryVideosList } from './MentoryVideosList';

export const MentoryVideos = () => {

  return (
    <LayoutDefault>
      <MentoryVideosList/>
    </LayoutDefault>
  );
};
