import { IHumanResource, IHumanResourcesListResponse} from '../../../contexts/HumanResourcesContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';


const createHumanResource = async (humanresources: object): Promise<IHumanResource> => {
  const { data } = await Api.post('/humanresources', { humanresources: humanresources } );
  return data;
};

const updateHumanResource= async (humanresources: IHumanResource): Promise<void> => {
  const { data } = await  Api.put(`/humanresources/${humanresources.id_human_resources}`, humanresources);
  return data;  
};

const deleteHumanResource = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/humanresources/${id}`);
  return data;   
};


const getHumanResource = async (id: string): Promise<IHumanResource> => {
  const { data } = await Api.get(`/humanresources/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getHumanResources = async (page = 1, filter = '', id_user = '', id_module = '' ): Promise<IHumanResourcesListResponse> => {
    const { data } = await Api.get(`/humanresources?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}&user=${id_user}&module=${id_module}`);
    return data;
};

export const HumanResourcesService = {
  getHumanResources,
  getHumanResource,
  createHumanResource,
  updateHumanResource,
  deleteHumanResource,
};
