
import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { orange, blue, green } from '@mui/material/colors';

interface IEASDashboardChartBarProps  {
    isLoading?:boolean;
    title?:string;
    subtitle?:string;
    description?:string;
    color?:string;
    background?:string;
    value?: string;
}


export const EASDashboardChartBar:React.FC<IEASDashboardChartBarProps> = ({ ...props }) => {
    const theme = useTheme();
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
      },
      legend: {
        textStyle:{
          color: theme.palette.text.primary,
        },
     
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      },
      yAxis: {
        type: 'value'
      },
      series: [
              {
                name: 'Mentor',
                type: 'bar',
                color: blue[500],
                stack: 'total',
                label: {
                  show: true
                },
                emphasis: {
                  focus: 'series'
                },
                data: [72, 77, 96, 50, 88, 70, 50,50,87,55,72,77]
              },
              {
                name: 'Mentorado',
                type: 'bar',
                color: green[500],
                stack: 'total',
                label: {
                  show: true
                },
                emphasis: {
                  focus: 'series'
                },
                data: [27, 31, 12, 35, 29, 32, 38,21,39,22,33,20]
              },
              {
                name: 'RH',
                type: 'bar',
                color: orange[600],
                stack: 'total',
                label: {
                  show: true
                },
                emphasis: {
                  focus: 'series'
                },
                data: [2, 3, 2, 4, 4, 10, 12,7,8,10,11,10]
              }
      ]
      };

    return (
        <Card sx={{border:'8px', background:props.background, color:props.color, position:'relative'}}>
            <CardContent sx={{p:'24px'}}>
                <Typography variant="h4" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.235}}>{props.title}</Typography>
                <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.subtitle}</Typography>
                <Box sx={{ maxHeight:'250px'}}>
                    <ReactEcharts style={{width:'100%'}}
                        option={{
                            ...option,
                        }}
                    />
                </Box>
                <Box sx={{height:'60px'}}>
                    <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.description}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};



