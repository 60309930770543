import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { OperatorsList } from './OperatorsList';

export const Operators = () => {

  return (
    <LayoutDefault>  
      <OperatorsList />        
    </LayoutDefault>
  );
};

