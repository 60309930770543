import { LayoutDefault } from '../../shared/layouts';
import { DashboardPanel } from './DashboardPanel';

export const Dashboard = () => {
 
  
  return (
    <LayoutDefault>
      <>
      </>
    </LayoutDefault>
  );
};
