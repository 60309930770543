import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { ProgramsList } from './ProgramsList';

export const Programs = () => {

  return (
    <LayoutDefault>  
      <ProgramsList />        
    </LayoutDefault>
  );
};

