import React, { Ref, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';

import { useAuthContext } from '../../shared/contexts';
import { EASInput, EASSelect } from '../../shared/forms';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';
import { IObjectivesSample } from '../../shared/contexts/ObjectivesSampleContext/types';
import { ObjectivesSamplesService } from '../../shared/services/api/objectives-sample/ObjectivesSampleService';

export interface IRefObjectiveSample {
  saveObjectiveSample: () => void;
}

type IObjectiveSampleTabProps = {
  ObjectiveSampleID: string;
};

const ObjectiveSampleTab = React.forwardRef<IRefObjectiveSample, IObjectiveSampleTabProps>(({ObjectiveSampleID}:IObjectiveSampleTabProps, ref:Ref<IRefObjectiveSample>) => {

  
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [objectiveSample, setObjectiveSample] = useState<IObjectivesSample | null>(null);
  const [objectiveSampleError, setObjectiveSampleError] = useState<IObjectivesSample | null>(null);

  const [objectiveList, setObjectiveList] = useState<Array<IList>>([]);
  const [tags, setTags] = useState<Array<IList>>([]);

  useEffect(() => {
    async function getObjectiveList() {
      setIsLoading(true);
      const ObjectiveSampleResponse = await UtilService.getObjectivesSample();
      if (ObjectiveSampleResponse instanceof Error) {
        setObjectiveList([]);    
      } else {
          if(ObjectiveSampleResponse){
              const newObjectiveSample = JSON.parse(JSON.stringify(ObjectiveSampleResponse));
              setObjectiveList(ObjectiveSampleResponse);
          }
          else{
            setObjectiveList([]);
          }
      }
    }
    async function getTags() {
      const tagsResponse = await UtilService.getTags();
      if (tagsResponse instanceof Error) {
          setTags([]);    
      } else {
          if(tagsResponse){
              const newTags = JSON.parse(JSON.stringify(tagsResponse));
              setTags(newTags);
          }
          else{
              setTags([]);
          }
      }
    }
    async function getObjectiveSample() {
      setIsLoading(true);
      const ObjectiveSampleResponse = await ObjectivesSamplesService.getObjectiveSample(ObjectiveSampleID);
      if (ObjectiveSampleResponse instanceof Error) {
          setObjectiveSample(null);
          setObjectiveSampleError(null);
          setIsLoading(false);
      } else {
          if(ObjectiveSampleResponse){
              const myObjectiveSample = JSON.parse(JSON.stringify(ObjectiveSampleResponse));
              setObjectiveSample(myObjectiveSample);
              setObjectiveSampleError(null);
              setIsLoading(false);
          }
          else{
              setObjectiveSample(null);
              setObjectiveSampleError(null);
              setIsLoading(false);
          }
      }
    }
    getObjectiveList().then(() => {
      getTags().then(() => {
        getObjectiveSample();
      });
    });
}, []);


  const isValidObjectiveSample = yup.object({
    name_objective_sample: yup.string().required('Este campo é obrigatório'),
    text_objective_sample: yup.string().required('Este campo é obrigatório')
  });

  useImperativeHandle(ref, () => ({
    saveObjectiveSample,
  }));


  const saveObjectiveSample = () => {
    if (!isLoading) {
      isValidObjectiveSample
        .validate( objectiveSample , { abortEarly: false })
        .then(validateObjectiveSample => {
            if(validateObjectiveSample) {
                setIsLoading(true); 
                const updateObjectiveSample: IObjectivesSample = {
                  id_objective_sample: objectiveSample?.id_objective_sample,
                  name_objective_sample: validateObjectiveSample.name_objective_sample,
                  text_objective_sample: validateObjectiveSample.text_objective_sample,          
                  tags: objectiveSample?.tags,
                  user_update: user?.id,
                };            
                ObjectivesSamplesService.updateObjectiveSample(updateObjectiveSample)
                .then((ObjectiveSampleResponse) => {
                    if (ObjectiveSampleResponse instanceof Error) {
                        setIsLoading(false);
                        enqueueSnackbar('Erro ao salvar comportamento.', { variant:'error'});
                    } else {
                        setIsLoading(false);
                        enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
                    }
                }).catch(() => {
                    setIsLoading(false);
                    enqueueSnackbar('Erro ao salvar comportamento.', { variant:'error'});
                });
            }
            else{
                setIsLoading(false);
                enqueueSnackbar('Erro ao salvar comportamento.', { variant:'error'});
            }
        })
        .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setObjectiveSampleError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    }
};



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
            <CardHeader
              title='Informações da Ação Sugerida'
              titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
            />
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isLoading}
                    label="Ação Sugerida"
                    type="text"
                    multiline
                    rows={2}
                    value={objectiveSample?.text_objective_sample}
                    onFocus={() => 
                      setObjectiveSampleError(existingValues => ({
                        ...existingValues,
                        text_objective_sample: '',
                        }))
                    }
                    onChange={(e) => 
                      setObjectiveSample(existingValues => ({
                            ...existingValues,
                            text_objective_sample: e.target.value,
                        }))
                    }
                    error={!!objectiveSampleError?.text_objective_sample}
                    helperText={objectiveSampleError?.text_objective_sample}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                  <EASSelect
                    isLoading={isLoading}
                    options={objectiveList}
                    getOptionLabel={(option) => option.text}
                    value={objectiveList.find(item => item.value == objectiveSample?.name_objective_sample)}                                  
                    onChangeSelect={(event, value:IList) => {
                                      if (value && value.value) {                            
                                        setObjectiveSample(existingValues => ({
                                            ...existingValues,
                                            name_objective_sample: value.value,
                                        })); 
                                      }
                                    }}
                    label="Competência"
                    onChangeTextField={(event) => {
                      setObjectiveSample(existingValues => ({
                          ...existingValues,
                          name_objective_sample: event.target.value,
                      })); 
                    }}
                    helperText={objectiveSampleError?.name_objective_sample}
                    error={!!objectiveSampleError?.name_objective_sample}
                    onFocus={() => 
                      setObjectiveSampleError(existingValues => ({
                        ...existingValues,
                        name_objective_sample: '',
                      }))
                    }
                    fullWidth={true}
                    freeSolo={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={8} >
                  <EASSelect 
                    isLoading={isLoading}
                    options={tags}
                    label="Tags da Ação Sugerida"
                    getOptionLabel={(option) => option.text}                                  
                    value={objectiveSample?.tags && objectiveSample?.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                    onChangeSelect={(event, values:Array<IList>) => {
                              const NewValue:Array<string> = [];
                              values.map((value) => NewValue.push(value.value));
                              setObjectiveSample(existingValues => ({
                                  ...existingValues,
                                  tags: NewValue,
                              }));
                            } 
                        } 
                    fullWidth
                    multipleSelect
                    filterSelectedOptions
                    disableClearable
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

ObjectiveSampleTab.displayName = 'ObjectiveSampleTab';

export default ObjectiveSampleTab;
