import { Link, Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';
import { useSnackbar } from 'notistack';
import { EASButton, EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';
import { Environment } from '../../shared/environment';
import { IMentory } from '../../shared/contexts/MentoringContext/types';
import * as yup from 'yup';
import { useAuthContext } from '../../shared/contexts';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import { PeopleNewPerson } from '../people/PeopleNewPerson';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { CompaniesNewCompany } from '../companies/CompaniesNewCompany';

type IMentoringAddProps = {
    open: boolean;
    setOpen: (open:boolean) => void;
};


export const MentoringAdd:React.FC<IMentoringAddProps> =  (({open, setOpen}) => {


    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const [openNewPerson, setOpenNewPerson] = useState(false);
    const [openNewCompany, setOpenNewCompany] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const { user } = useAuthContext();
    const [mentoryError, setMentoryError] = useState<IMentory | null>(null);
    const [mentory, setMentory] = useState<IMentory | undefined>({});



    
    const { data:mentoryTypes, isLoading:isLoadingMentoryTypes } = useQuery(['list-mentory-types'], () =>  UtilService.getMentoryTypes(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });
        
    const { data:companies, isLoading:isLoadingCompanies } = useQuery(['list-companies'], () =>  UtilService.getCompanies(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:tags, isLoading:loadingTags  } = useQuery(['tags-list'],() => UtilService.getTags(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant:'error'});
        },
    });
    
    const { data:people, isLoading  } = useQuery(['users-list'],() => UtilService.getUsers(),  {
        staleTime: Infinity,

        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Pessoas.', { variant:'error'});
        },
    });
    

    const { mutateAsync:storeCreate } = useMutation((updateMentory:IMentory) =>MentoringService.createMentory(updateMentory), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-people-list');
            queryClient.invalidateQueries('operator-person-edit');
            queryClient.invalidateQueries('users-list');
            enqueueSnackbar('Pessoa cadastrada com sucesso!', { variant:'success'});
            setOpen(false);
            setSaveLoading(false);
            setMentory({});
            setMentoryError(null);

        },
        onError: () => {
            enqueueSnackbar('Erro ao tentar cadastrar nova pessoa.', { variant:'error'});
            setSaveLoading(false);
        },
    });

const isValidMentory = yup.object({
    name_mentory: yup.string().required('Este campo é obrigatório'),
    id_user_mentorado: yup.string().required('Este campo é obrigatório'),
    id_user_mentor: yup.string().required('Este campo é obrigatório'),
    start_mentory: yup.string().required('Este campo é obrigatório'),
    end_mentory: yup.string().required('Este campo é obrigatório'),
    id_module: yup.string().required('Este campo é obrigatório'),
});

const saveMentory = () => {
    isValidMentory
        .validate( mentory , { abortEarly: false })
        .then(validateMentory => {
            const updateMentory: IMentory = {
                id_mentory: mentory?.id_mentory,
                name_mentory: validateMentory.name_mentory,
                id_user_mentorado: validateMentory.id_user_mentorado,
                id_user_mentor: validateMentory.id_user_mentor,
                start_mentory: validateMentory.start_mentory,
                end_mentory: validateMentory.end_mentory,
                id_module: validateMentory?.id_module,
                objective_sample_mentory: mentory?.objective_sample_mentory,
                min_grade_mentory: mentory?.min_grade_mentory,
                max_grade_mentory: mentory?.max_grade_mentory,
                step_grade_mentory: mentory?.step_grade_mentory,
                id_company: mentory?.id_company,
                tags: mentory?.tags,
                user_register: user?.id,
            };     
          storeCreate(updateMentory);
      })
      .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
              if(error.path){
                  const keyError = error.path;                    
                  setMentoryError(existingValues => ({
                                      ...existingValues,
                                      [keyError]: error.message
                                  }));                        
              }
          });
      });
    };

    const handleClose = () => {
        setMentory({});
        setMentoryError(null);
        setOpen(false);
    };

    return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Cadastrar Novo Processo</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', minWidth:'480px'}}>
                {saveLoading && (                   
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                    </Box>
                )}
                {!saveLoading && (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <EASSelect 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                options={people ? people : []}
                                onFocus={() => 
                                    setMentoryError(existingValues => ({
                                        ...existingValues,
                                        id_user_mentorado: '',
                                    }))
                                }
                                label="Mentorado"
                                getOptionLabel={(option) => option.text}
                                renderOption={(props, option) => (
                                        <ListItem {...props}>
                                            <ListItemAvatar>
                                                <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                            </ListItemAvatar>
                                            <ListItemText primary={option.text} />
                                        </ListItem>
                                )}
                                value={people && people.find(item => item.value == mentory?.id_user_mentorado)}                                  
                                onChangeSelect={(event, value:IList) => {
                                    const valueSelected = (value && value.value) ? value.value : '';
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        id_user_mentorado: valueSelected,
                                    }));
                                }} 
                                error={!!mentoryError?.id_user_mentorado}
                                helperText={mentoryError?.id_user_mentorado}
                            />
                            <Box sx={{pt:1}} >
                                <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                    Cadastrar nova pessoa
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <EASSelect 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                options={people ? people : []}
                                onFocus={() => 
                                    setMentoryError(existingValues => ({
                                        ...existingValues,
                                        id_user_mentor: '',
                                    }))
                                }
                                label="Mentor"
                                getOptionLabel={(option) => option.text}
                                renderOption={(props, option) => (
                                        <ListItem {...props}>
                                            <ListItemAvatar>
                                                <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                            </ListItemAvatar>
                                            <ListItemText primary={option.text} />
                                        </ListItem>
                                )}
                                value={people && people.find(item => item.value == mentory?.id_user_mentor)}                                  
                                onChangeSelect={(event, value:IList) => {
                                    const valueSelected = (value && value.value) ? value.value : '';
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        id_user_mentor: valueSelected,
                                    }));
                                }} 
                                error={!!mentoryError?.id_user_mentor}
                                helperText={mentoryError?.id_user_mentor}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EASInput
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                onFocus={() => 
                                    setMentoryError(existingValues => ({
                                        ...existingValues,
                                        name_mentory: '',
                                    }))
                                }
                                label="Título do Processo"
                                type='text'
                                value={mentory?.name_mentory}                                
                                onChange={(e) => 
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        name_mentory: e.target.value,
                                    }))
                                }
                                error={!!mentoryError?.name_mentory}
                                helperText={mentoryError?.name_mentory}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <EASPicker 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                onFocus={() => 
                                    setMentoryError(existingValues => ({
                                    ...existingValues,
                                    start_mentory: '',
                                    }))
                                }
                                onOpen={() => 
                                    setMentoryError(existingValues => ({
                                    ...existingValues,
                                    start_mentory: '',
                                    }))
                                }
                                label="Data de Inicio"
                                value={mentory?.start_mentory} 
                                error={!!mentoryError?.start_mentory}
                                helperText={mentoryError?.start_mentory}
                                onChangePiker={(e) => {  
                                                    if(e instanceof Date) {         
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            start_mentory: e.toISOString().split('T')[0],
                                                        }));
                                                    }}
                                                }
                            />
                        </Grid>                                          
                        <Grid item xs={12} sm={6} md={3}>
                            <EASPicker 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                onFocus={() => 
                                    setMentoryError(existingValues => ({
                                    ...existingValues,
                                    end_mentory: '',
                                    }))
                                }
                                onOpen={() => 
                                    setMentoryError(existingValues => ({
                                    ...existingValues,
                                    end_mentory: '',
                                    }))
                                }
                                label="Data de Término"
                                value={mentory?.end_mentory} 
                                error={!!mentoryError?.end_mentory}
                                helperText={mentoryError?.end_mentory}
                                onChangePiker={(e) => {  
                                                    if(e instanceof Date) {         
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            end_mentory: e.toISOString().split('T')[0],
                                                        }));
                                                    }}
                                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <EASInput 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                type="number"
                                min="-10"
                                max="100"                                                    
                                defaultValue="0"
                                label="Nota Mínima"
                                value={mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0'}
                                onChange={(e) => 
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        min_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                    }))      
                                }
                                error={!!mentoryError?.min_grade_mentory}
                                helperText={mentoryError?.min_grade_mentory}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6}  md={2}>
                            <EASInput 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                type="number"
                                min="-10"
                                max="100"  
                                defaultValue="10"
                                label="Nota Máxima"
                                value={mentory?.max_grade_mentory ? mentory?.max_grade_mentory : '10'}
                                onChange={(e) => 
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        max_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                    }))      
                                }
                                error={!!mentoryError?.max_grade_mentory}
                                helperText={mentoryError?.max_grade_mentory}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <EASInput 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                type="number"
                                min="0"
                                max="10"
                                defaultValue="1"
                                label="Incremento"
                                value={mentory?.step_grade_mentory ? mentory?.step_grade_mentory : '1'}
                                onChange={(e) => 
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        step_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                    }))      
                                }
                                error={!!mentoryError?.step_grade_mentory}
                                helperText={mentoryError?.step_grade_mentory}

                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <EASRadio
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                label="Sugerir Comportamentos"
                                options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                value={mentory?.objective_sample_mentory ? mentory?.objective_sample_mentory : '0'}
                                onChange={(e) => 
                                    setMentory(existingValues => ({
                                        ...existingValues,
                                        objective_sample_mentory: e.target.value,
                                    }))
                                }
                            />
                        </Grid> 
                        
                        <Grid item xs={12} md={6} lg={3}>
                            <EASSelect 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                defaultValue={null}
                                options={mentoryTypes ? mentoryTypes : []}
                                label="Tipo"
                                getOptionLabel={(option) => option.text}
                                value={mentoryTypes && mentoryTypes.find(item => item.value == mentory?.id_module)}                                  
                                onChangeSelect={(event, value:IList) => {
                                    if (value && value.value) {
                                        setMentory(existingValues => ({
                                            ...existingValues,
                                            id_module: value.value,
                                        }));
                                    }
                                    
                                }}  
                                error={!!mentoryError?.id_module}
                                helperText={mentoryError?.id_module}
                            />
                        </Grid>                        
                        <Grid item xs={12} md={6} lg={6}>
                            <EASSelect 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                options={companies ? companies :[] }
                                label="Empresa"
                                getOptionLabel={(option) => option.text}
                                renderOption={(props, option) => (
                                        <ListItem {...props}>
                                            <ListItemAvatar>
                                                <Avatar variant="square" alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + option?.image  :  '/'} />
                                            </ListItemAvatar>
                                            <ListItemText primary={option.text} />
                                        </ListItem>
                                )}
                                value={companies && companies.find(item => item.value == mentory?.id_company)}                               
                                onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setMentory(existingValues => ({
                                                ...existingValues,
                                                id_company: value.value,
                                            }));
                                        } else {
                                            if(event.target.nodeName == 'svg') {
                                                setMentory(existingValues => ({
                                                    ...existingValues,
                                                    id_company: '',
                                                }));
                                            }

                                        }
                                    }
                                }
                                error={!!mentoryError?.id_company}
                                helperText={mentoryError?.id_company}
                            />
                            <Box sx={{pt:1}} >
                                <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewCompany(true)} underline="none">
                                    Cadastrar nova empresa
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <EASSelect 
                                isLoading={(isLoading || loadingTags || isLoadingMentoryTypes || isLoadingCompanies)}
                                options={tags ? tags :[]}
                                label="Tags para o Processo"
                                getOptionLabel={(option) => option.text}                                  
                                value={mentory?.tags && tags && mentory.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                                onChangeSelect={(event, values:Array<IList>) => {
                                        const NewValue:Array<string> = [];
                                        values.map((value) => NewValue.push(value.value));
                                        setMentory(existingValues => ({
                                            ...existingValues,
                                            tags: NewValue,
                                        }));
                                        } 
                                    } 
                                fullWidth
                                multipleSelect
                                filterSelectedOptions
                                disableClearable
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>          
            <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} /> 
            <CompaniesNewCompany open={openNewCompany} setOpen={(e) => setOpenNewCompany(e)} />
        </DialogContent>
        <Divider/>
        <DialogActions>
            <EASButton variant="outlined" onClick={handleClose}>Cancelar</EASButton>
            <EASButton 
                variant='contained' 
                size="medium"            
                disabled={saveLoading}
                endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <PlaylistAddIcon/>}
                onClick={saveMentory}
            >
                Adicionar
            </EASButton>
        </DialogActions>
    </Dialog>   

    );
});