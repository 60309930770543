
import React, { useState , useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface IColorDataChart {
  color?: string;
}
interface IDataChart extends IColorDataChart {
  name?: string;
  value?: Array<number | null>;
  symbolSize?: number;
  itemStyle?: IColorDataChart;
  label?: {show?: boolean; position?: string; color?: string; formatter?: string}
}

interface IDataChartPie extends IColorDataChart {
  name?: string;
  value?: number;
  //symbolSize?: number;
  itemStyle?: IColorDataChart;
 // label?: {show?: boolean; position?: string; color?: string; formatter?: string}
}
interface IIndicator {
  max: number;
}

interface IEASToolsPerceptionOfInfluencersChartProps {
  allInfluencers: Array<IInfluencer> | null;
}  

export const EASToolsPerceptionOfInfluencersChart: React.FC<IEASToolsPerceptionOfInfluencersChartProps> = ({ allInfluencers }) => {

  const [dataChart, setDataChart] = useState<Array<IDataChart>>([]);
  const [dataChartPie, setDataChartPie] = useState<Array<IDataChartPie>>([]);
  const [dataChartRadar, setDataChartRadar] = useState<Array<IDataChart>>([]);
 
  const [indicator, setIndicator] = useState<Array<IIndicator>>([]);
  const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];

  useEffect(() => {
    async function getInfluencers() {

      const tempIndicator = Array<IIndicator>(0);

      for (let i = 0; i < 24; i++) 
        tempIndicator.push({max: 10});


      setIndicator(tempIndicator);
      if(allInfluencers){
        const valuesPromotor:Array<number> = [];
        const valuesNeutral:Array<number> = [];
        const valuesDetractor:Array<number> = [];

        allInfluencers.map((influencer) => {
          if(influencer.group_influencer){
            switch (true) {
              case (parseInt(influencer.group_influencer)>=9): valuesPromotor.push(10); break;
              case (parseInt(influencer.group_influencer)<=6): valuesDetractor.push(10); break;
              default: valuesNeutral.push(10); break;
            }
          }
        });

      setDataChartPie([{  name: 'Promotores',
          value: valuesPromotor.length,
          itemStyle: {
            color:'#4D8000',
          },
        },{
          name: 'Neutro',
          value: valuesNeutral.length,
          itemStyle: {
            color:'#bbacac',
          },
        },{
          name: 'Detratores',
          value: valuesDetractor.length,
          itemStyle: {
            color:'#c23531',
          },
        }]);      
      }
    }
    getInfluencers();
  }, [allInfluencers]);

  const option = {

    title: {
      text: 'Mapa de Proximidade',
      top: 0,
      left:'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a}<br/><strong>{b}</strong>'
    },
    radar: {
      indicator: indicator,
      radius: '80%',
      center: ['50%', '55%'],
      startAngle: 90,
      splitNumber: 5,
      shape: 'circle',
      axisLine: [],
      axisLabel: [],
    },
    series: [
      {
        name: 'Influenciadores e você',
        type: 'radar',
        symbolSize:25,
        data: dataChart,
        lineStyle:
          {
          width: 0,
          }
      }
    ]
  };

  const pie = {

    title: {
      text: 'Total por Percepção',
      top: 0,
      left:'center'
    },
    
    tooltip: {
      trigger: 'item'
    },    
    series: [
      {      
        name: 'Grupo',
        type: 'pie',
        radius: '70%',    
        label: {
          formatter: '{c} \n {b}',
          position: 'inside'
        },
        data: dataChartPie,
      }
    ]
  };
  return (
    <ReactEcharts style={{width:'100%', height:'100%', minHeight:'450px'}}
        option={{
            ...pie,
        }}
    />
  );
};