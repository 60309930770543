
import React, { useImperativeHandle, useState, Ref } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Skeleton, Avatar, Badge, Fab, Typography, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import * as yup from 'yup';

import { IOperator } from '../../shared/contexts/OperatorsContext/types';

import { EASCheck } from '../../shared/forms';

import { IList } from '../../shared/services/api/util/UtilService';
import { Environment } from '../../shared/environment';
import { useNavigate } from 'react-router-dom';


type IOperatorTabProps = {
    operatorEdit?: IOperator;
    isLoading?:boolean;
    userGroups?:Array<IList>;
    storeUpdate:(operator:IOperator) => void;
};

export interface IRefOperator {
    saveOperator: () => void;
}

interface IOperatorError {
	id_user?: string;
	id_user_group?: string;
}

const OperatorTab = React.forwardRef<IRefOperator, IOperatorTabProps>(({operatorEdit, userGroups, isLoading, storeUpdate}:IOperatorTabProps, ref:Ref<IRefOperator>) => {

    const navigate = useNavigate();

    const [operator, setOperator] = useState<IOperator | undefined>(operatorEdit);
    const [operatorError, setOperatorError] = useState<IOperatorError | null>(null);

    useImperativeHandle(ref, () => ({
        saveOperator,
    }));

    const isValidOperator = yup.object({
        id_user_group: yup.array().min(1,'Este campo é obrigatório').required('Este campo é obrigatório'),
    });

    const saveOperator = () => {
        isValidOperator
        .validate( operator , { abortEarly: false })
        .then(validateOperator => { 
            const updateOperator: IOperator = {
                id_user: operator?.id_user,
                id_user_group: validateOperator.id_user_group,
            };     
            storeUpdate(updateOperator);      
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setOperatorError(existingValues => ({
                        ...existingValues,
                        [keyError]: error.message
                    }));                        
                }
            });
        });
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                        <CardHeader
                            title='Avatar'
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            {isLoading  && (
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                    <Skeleton variant="circular" width={120} height={120} />
                                </Box>
                            )}
                            {!isLoading && (
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 1}}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <Tooltip title={'Editar Pessoa: ' + operator?.name_user}>
                                                <Fab size="small" color="primary" aria-label="add" onClick={() => navigate(`/pessoas/detalhes/${operator?.id_user}`)}>
                                                    <EditIcon />
                                                </Fab> 
                                            </Tooltip>
                                        }
                                    >
                                        <Avatar sx={{width: 120, height: 120}} alt={operator?.name_user} src={operator?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + operator?.image_user : ''}/>
                                    </Badge>
                                    <Typography sx={{ marginTop: 2}} variant="h6">{operator?.name_user}</Typography>
                                </Box>
                            </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                        <CardHeader
                            title="Permissões do Operador"
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sx={{ marginTop: 2}}>
                                    <EASCheck
                                        label="Nível de Acesso"
                                        isLoading={isLoading}
                                        onFocus={() => 
                                            setOperatorError(existingValues => ({
                                            ...existingValues,
                                            id_user_group: '',
                                            }))
                                        }
                                        options={userGroups}
                                        value={operator?.id_user_group}
                                        onChange={(e) =>{
                                            setOperatorError(existingValues => ({
                                            ...existingValues,
                                            id_user_group: '',
                                            }));
                                            const newUserGroup:Array<string> = operator?.id_user_group?.length ? 
                                                (e.target.checked === false ? 
                                                    operator.id_user_group.filter(item => item !== e.target.name)
                                                    : operator.id_user_group
                                                ) : [];

                                            if(e.target.checked === true)
                                                newUserGroup.push(e.target.name);

                                            setOperator(existingValues => ({
                                                ...existingValues,
                                                id_user_group:newUserGroup
                                            })) ;
                                        }}
                                        error={!!operatorError?.id_user_group}
                                        helperText={operatorError?.id_user_group}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        
    );
});

OperatorTab.displayName = 'OperatorTab';

export default OperatorTab;