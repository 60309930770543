import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Box, Dialog, DialogActions, DialogContent, DialogTitle, Theme, useMediaQuery, Typography, Chip }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { EASButton, EASInput } from '../../shared/forms';
import { useMutation, useQuery } from 'react-query';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { IProgram } from '../../shared/types';

interface IProductBuyNewProps {
  open: boolean;
  setOpen: (open:boolean) => void;
}

export const ProductBuyNew: React.FC<IProductBuyNewProps> = ({ open, setOpen }) => {
  
  const { enqueueSnackbar } = useSnackbar();

  //const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  //const [success, setSuccess] = useState<boolean>(false);


  const [codeProgram, setCodeProgram] = useState<string>('');
  const [codeProgramError, setCodeProgramError] = useState<string | null>(null);
  const [program, setProgram] = useState<IProgram>({});
  // const [codeProgramError, setCodeProgramError] = useState<string | null>(null);

  const productBuyNewSchema = yup.object().shape({
    codeProgram: yup.string().required('Informe do Código do Programa'),
  });
 // 686661961571206656

  const { data, isError, isLoading, isFetching, refetch } = useQuery('select-pogram-new', 
                                                () => ProgramsService.getProgramAddMentory(codeProgram), 
                                                { 
                                                  onSuccess: data => {
                                                    setProgram(data);
                                                   // if(data == 'Error: Request failed with status code 400')
                                                    setCodeProgramError('Código não encontrado');
                                                   // 686661961571206656
                                                  },
                                                  onError: () => {
                                                    setCodeProgramError('Erro na busca ou código inválido');
                                                  },  
                                                  keepPreviousData: true, 
                                                  retry:1,
                                                  refetchInterval:false,
                                                  enabled:false
                                                });
  
/*
  const { mutate, isLoading } = useMutation(createEmployee, {
    onSuccess: data => {
      console.log(data);
      const message = "success"
      alert(message)
    },
    onError: () => {
      alert("there was an error")
    },
    onSettled: () => {
      queryClient.invalidateQueries('create');
    }
  });
  const onSubmit = (data: Employee) => {
    const employee = {
      ...data
    };
    mutate(employee);
  };
/*
  useEffect(() => {
    async function loadEditObjectiveDetail() {
      setIsLoading(true);
      if(objectiveDetail)
        setOjectiveDetailEdit(objectiveDetail);      
    }
    if(open==true)
      loadEditObjectiveDetail().then(e => {
        setIsLoading(false);
      });

  }, [open]);
*/




  const searchCode = () => {

    productBuyNewSchema
      .validate({ codeProgram }, { abortEarly: false })
      .then(codeProgramVvalidation => {
        refetch();
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path)               
              setCodeProgramError(error.message);                        
            
        });
    });
  };

  function cancel()
  {
    setProgram({});
    setCodeProgram('');
    setCodeProgramError(null);
    setOpen(false);
  }


  return (
    <Dialog 
      open={open}
      keepMounted
      onClose={cancel}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{'Adicionar Novo Programa'}</DialogTitle>
      <DialogContent>
        {isFetching && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!isFetching && ( 
          program.id_program ? (

            <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
            <Grid sx={{width:'100%'}} container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                      <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Programa Encontrado</Typography>
                      </Grid>
                      <Grid item xs={true} >
                        <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'5px', marginRight:'10px' }}>
                          {program?.enroll_program == '0' && (
                            <Chip label="Inscrições Fechadas" color="error" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                          )}
                          {program?.enroll_program == '1' && (
                            <Chip label="Inscrições Abertas" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight:'100px' }}>
                      <Typography variant="caption" sx={{ fontSize: 14 }}>
                        Nome do Programa: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_program}</Typography>
                      </Typography>
                      <Typography variant="caption">
                        Início: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.start_program && new Date(program?.start_program).toLocaleString('pt-BR', {dateStyle: 'medium'}) }</Typography>
                      </Typography>
                      <Typography variant="caption">
                        Tipo: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_type_program}</Typography>
                      </Typography>
                      {program?.mentor_program ? (
                      <Typography variant="caption">
                        Mentor: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.mentor_program ? 'Fase 1' : 'Matching'}</Typography>
                      </Typography>
                      ) : (
                      <Typography variant="caption">
                        Fase: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>Matching</Typography>
                      </Typography>
                      )}
                      {program?.name_company && (
                      <Typography variant='caption'>
                        Empresa: <Typography variant='caption' sx={{fontWeight: 600, fontSize: 14 }}>{program?.name_company}</Typography>
                      </Typography>
                      )}

                    </Box>
                  </Grid>


                  {/* // 686661961571206656
                  <Grid item xs={12}>
                    <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
                    <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
                  </Grid>
                  */}
                </Grid>
              </Grid> 
          </Grid>
        </Box> 
         ) : (
        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid sx={{width:'100%'}} container spacing={2}>
            <Grid item xs={12}>
              <EASInput
                //isLoading={isLoading}
                label={'Insira o Código Aqui'}
                multiline
                disabled={isFetching}
                value={codeProgram}                
                onChange={(e) => setCodeProgram(e.target.value)}
                onFocus={() => setCodeProgramError(null)}
                fullWidth
                error={!!codeProgramError}
                helperText={codeProgramError}
              />    
            </Grid> 
          </Grid>
        </Box> 
        ))}
      </DialogContent>
      <DialogActions>

        <EASButton onClick={cancel} disabled={isFetching} variant="outlined">Cancelar</EASButton>

        {program.id_program ? (
        <EASButton 
        variant='contained'
        disabled={isFetching || program?.enroll_program == '0' }


        endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
        onClick={searchCode}>Inscrever-se</EASButton>
        ):(
          <EASButton 
          variant='contained'
          disabled={isFetching}
          endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <SearchIcon/>}
          onClick={searchCode}>Pesquisar</EASButton>
        )}





        {/*
        <EASButton onClick={dialogSave} disabled={isLoading} variant="contained" autoFocus>Adicionar</EASButton>
        */}
      </DialogActions>
    </Dialog>
  );
};
