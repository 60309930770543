import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const SBDCBox = styled(Box) ({
  boxShadow: '3px 2px 12px -5px #000000',
  backgroundColor: '#fff',

});

interface IEASBox {
    title?: string;
    height: number;
    children: React.ReactNode;
}

export const EASBoxSbdc:React.FC<IEASBox> = ({children, height, title}) => {

  const theme = useTheme();

  return (
    <SBDCBox width='100%' display="flex" alignItems="center" justifyContent="center" height={theme.spacing(height)} title={title}>   
      {children}
    </SBDCBox>
  );
};