
import { Routes, Route, Navigate } from 'react-router-dom';
import { Profile, SelectModule } from '../pages';

export const IsAuthSelectModule = () => {

  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/selecionar-modulo" element={<SelectModule />} />
      <Route path="*" element={<Navigate to="/selecionar-modulo" />} />
    </Routes>
  );
};
