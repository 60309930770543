import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

export const Objectives = () => {

  return (
    <LayoutDefault>
      awda
    </LayoutDefault>
  );
};

