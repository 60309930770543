import React, { useState , useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { TimelineContent, TimelineOppositeContent } from '@mui/lab';
import TimelineDot from '@mui/lab/TimelineDot';

import {Paper, Typography,  Box, styled , Alert, IconButton, Card, Grid, Skeleton, Avatar, useTheme  } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';




import { LearningNew } from './LearningNew';
import { LearningEdit } from './LearningEdit';

import { ILearning } from '../../shared/contexts/LearningContext/types';
import { useAuthContext , useMentoringContext } from '../../shared/contexts';

import { Environment } from '../../shared/environment';

import { LearningService } from '../../shared/services/api/learning/LearningService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../../shared/components/eas-card/EASCard';
import { EASButton, EASButtonList } from '../../shared/forms';
import { EASDialogAlert } from '../../shared/components';

interface ILearningListProps {
  children?: () => void;
}  

const IconButtonChecked = styled(IconButton)(() => ({
  color: green[500]
}));


export const LearningList: React.FC<ILearningListProps> = () => {

  const theme = useTheme();
  
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);

  const [learning, setLearning] = useState<ILearning | null>(null);


  

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();
  
  const [editOpen, setEditOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  

  const [updateList, setUpdateList] = useState(false);

  const [idLearning, setIdLearning] = useState<string | null>(null);
  const [allLearnings, setAllLearnings] = useState<Array<ILearning> | null>(null);
  const [filterLearning, setFilterLearning] = useState<number>(0);
  const [totalCount, setTotalCount] = useState(0);


  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  useEffect(() => {
    async function getLearnings() {
      
      if(mentory){
        setIsLoading(true);
        const learnings = await LearningService.getAllLearning(mentory?.id_mentory, filterLearning, pagina);
        if (learnings instanceof Error) {
          setAllLearnings(null);
          setTotalCount(0);
          setIdLearning('error');
          setLearning(null);
          setIsLoading(false);
        } else {
          if(learnings.data && learnings.total){
            const myLearnings = JSON.parse(JSON.stringify(learnings.data));
            setAllLearnings(myLearnings);
            setIsLoading(false);
            if(learnings.total)
              setTotalCount(learnings.total);
          }
          else{
            setAllLearnings(null);
            setTotalCount(0);
            setIdLearning('error');
            setLearning(null);
            setIsLoading(false);
          }
        }
      }
    }
    getLearnings();
  }, [filterLearning, searchParams, updateList]);


  const handleClickDelete  = (id_learning?:string) => {
    if(id_learning){
      setIdLearning(id_learning);
      setRemoveOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteLearning();
    setRemoveOpen(false);
  };

  const handleCancel = () => {
    setRemoveOpen(false);
    setIdLearning(null);
  };

  const deleteLearning = () => {

    if(idLearning){
      setIsLoading(true);
      LearningService.deleteLearning(idLearning).then((learning) => {
        if (learning instanceof Error) {
          setIdLearning(null);
          setLearning(null);
          setIsLoading(false);
          enqueueSnackbar('Erro ao tentar remover o item do Diário!', { variant:'error'});
        } else {
            enqueueSnackbar('Item do Diário removido com sucesso!', { variant:'success'});
            setIdLearning(null);
            setIsLoading(false);
            
        }
      });
    }
  };

  const handleClickEdit = (learning?:ILearning) => {

    if(learning){
      setLearning(learning);
      setEditOpen(true);
    }
  };



  return (
    <EASCard 
        titleCard="Diário de Aprendizagem"
        bgcolorContent={theme.palette.background.default}
        actionCard={(mentory?.status_mentory === '1') ? (
            <EASButton disabled={isLoading} isLoading={isLoading} variant='contained' onClick={() => setNewOpen(true)}  >Nova Postagem&nbsp;<AddIcon /></EASButton>
              ) : <></>}
    >
    <Box sx={{mt: 2}}>
        <LearningNew open={newOpen} setOpen={(e) => setNewOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
        <LearningEdit open={editOpen} setOpen={(e) => setEditOpen(e)} setUpdateList={() => setUpdateList(!updateList)}  learning={learning} />
          {isLoading && (
        <>
          <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', height:'50px'}} >
            <Skeleton variant="rectangular" width={100} height={20} />
          </Grid>
          <Grid item xs={12} sx={{marginBottom:'10px'}}  >
            <Grid container spacing={1}>
              <Grid item xs={2} sx={{marginLeft:'50px'}} >
              </Grid>
              <Grid item xs={3} sx={{marginRight:'-50px'}} >
                <Skeleton variant="rectangular" width="100%" height={20} sx={{borderRadius : 2, marginTop:'20px'}}/>
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                    <Skeleton variant="circular" width={60} height={60} />
                  </Grid>
                  <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                    <Skeleton variant="rectangular" width={5} height={80} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} >
                <Skeleton variant="rectangular" width="100%" height={120} sx={{borderRadius : 2, }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{marginBottom:'10px'}} >
            <Grid container spacing={1}>
              <Grid item xs={5} >
                <Skeleton variant="rectangular" width="100%" height={120} sx={{borderRadius : 2, marginLeft:'50px', marginRight:'-50px'}} />
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                    <Skeleton variant="circular" width={60} height={60} />
                  </Grid>
                  <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                    <Skeleton variant="rectangular" width={5} height={80} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} >
                <Skeleton variant="rectangular" width="100%" height={20} sx={{borderRadius : 2, marginTop:'20px'}}/>
              </Grid>
              <Grid item xs={2} sx={{}} >
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} >
            <Grid container spacing={1}>
              <Grid item xs={2} sx={{marginLeft:'50px'}} >
              </Grid>
              <Grid item xs={3} sx={{marginRight:'-50px'}} >
                <Skeleton variant="rectangular" width="100%" height={20} sx={{borderRadius : 2, marginTop:'20px'}}/>
              </Grid>
              <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', marginRight:'-50px'}} >
                <Skeleton variant="circular" width={60} height={60} />
              </Grid>
              <Grid item xs={5} >
                <Skeleton variant="rectangular" width="100%" height={120} sx={{borderRadius : 2, }} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {!isLoading && (
        <>
          {allLearnings && allLearnings.length ? (
          <>
            <Grid item xs={12} sx={{marginTop:'-15px'}}>
              <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.1em', textAlign:'center'}}>                
                  Nota do Dia
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{marginTop:'-20px'}}>
              <Timeline position="alternate" sx={{ '& .MuiTimelineItem-root:before': {flex: 0,}, p:0}}>
                {allLearnings.map((learning) => (
                <TimelineItem sx={{marginTop:'15px;'}} key={learning.id_learning} >
                  <TimelineOppositeContent color="textSecondary">
                    <Typography variant="h6">                
                      {learning.date_learning ? new Date(learning.date_learning ).toLocaleDateString('pt-BR', {dateStyle: 'long', timeZone: 'UTC'}) : ''}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator sx={{flex:0, marginTop:'-15px'}}>
                  <TimelineDot color={learning?.value_learning ? (parseInt(learning?.value_learning) <= 4 ?  'error' : (parseInt(learning?.value_learning) <= 6 ?  'warning' : (parseInt(learning?.value_learning) <= 8 ?  'info' : 'success') ) ) : 'error'}>
                    <Avatar sx={{ bgcolor: 'transparent'}}>
                    {learning.value_learning && Number(learning?.value_learning).toFixed(1).replace(/[.,]0+$/, '')}
                    </Avatar>
                  </TimelineDot>
                  <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card variant="outlined" sx={{ p: '25px', borderRadius: '8px', border:'none'}}>
                      <Grid container spacing={2}>
                        <Grid item zeroMinWidth>
                          {(mentory?.status_mentory === '1' && user?.id === mentory?.id_user_mentorado) && (
                          <Box sx={{display:'flex', alignItems:'flex-end', alignContent:'flex-end', justifyContent:'flex-end', justifyItems:'flex-end', flexDirection:'row'}}>
                            <EASButtonList 
                              title="Editar item do diário"
                              onClick={() => handleClickEdit(learning)}
                              typeIcon="edit"
                            />
                            <EASButtonList 
                              title="Remover item do diário"
                              onClick={() => handleClickDelete(learning.id_learning)} 
                              typeIcon="remove"
                            />
                          </Box>
                          )}
                        </Grid>
                        <Grid item xs={true} sx={{textAlign:'left'}}>
                          <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">O que você fez de positivo que justifica esta nota?</Typography>
                          <Typography variant="body1" sx={{mb:'8px'}} gutterBottom>{learning.text_learning}</Typography>
                          <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">O que faltou para que sua nota fosse &rdquo;dez&rdquo;?</Typography>
                          <Typography variant="body2" sx={{mb:'8px'}} gutterBottom>{learning.lack_learning ? learning.lack_learning : '-'}</Typography>
                          <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">Causas</Typography>
                          <Typography variant="body2" sx={{mb:'8px'}} gutterBottom>{learning.cause_learning ? learning.cause_learning : '-'}</Typography>
                          <Typography variant="subtitle2" sx={{mb:'0', fontSize:'1.0em'}} gutterBottom component="div">Plano de Melhoria</Typography>
                          <Typography variant="body2" gutterBottom>{learning.plan_learning ? learning.plan_learning : '-'}</Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
                ))}
              </Timeline> 
            </Grid>
          </>
          ) : (
          <Grid item xs={12} sx={{marginTop:'-15px'}}>
            <Alert severity="warning">Não há registros no diário de aprendizagem</Alert>
          </Grid>
          )}
        </>
      )}
    </Box>
    <EASDialogAlert 
      type="warning"
      title="Excluir Item do Diário de Aprendizagem"
      statusOpen={removeOpen}
      actionCancel={handleCancel}
      actionConfirme={handleConfirme}
      loading={isLoading}
    >
      Você tem certeza que deseja excluir este item do diário de aprendizagem?
    </EASDialogAlert>
  </EASCard>
  );
};