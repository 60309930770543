import { LayoutDefault } from '../../shared/layouts';
import { EASToolsInfluencersList, EASToolsPerceptionOfInfluencers, EASToolsProximityMap, EASToolsLabelMapping} from '../../shared/components';
import { useLocation } from 'react-router-dom';



export const ToolsDetail = () => {
  const location = useLocation();

  function getStepContent(pathname: string) {
    switch (pathname) {
      case '/ferramentas/influenciadores-de-reputacao':
        return <EASToolsInfluencersList />;
      case '/ferramentas/mapa-de-proximidade':
        return <EASToolsProximityMap />;
      case '/ferramentas/mapeamento-dos-rotulos':
        return <EASToolsLabelMapping />;
      case '/ferramentas/score-dos-influneciadores':
        return <EASToolsPerceptionOfInfluencers />;
                  
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <LayoutDefault>
      {getStepContent(location.pathname)}
    </LayoutDefault>
  );
};
