import React, { useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import { IProgram } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';
import { UtilService} from '../../shared/services/api/util/UtilService';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import ProgramTabProfile, { IRefProfile } from './ProgramTabProfile';

export const Program = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();



    const refProfile = useRef<IRefProfile>(null);

    const storeDisplay = () => {
        if(refProfile.current)
            refProfile.current.saveProgram();
    };

     const { data:program, isFetching:isLoadingProgram } = useQuery(['operator-program-edit', id], () => ProgramsService.getProgram(id), 
    {
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
      },
    });




    const { data:programTypes, isLoading:isLoadingProgramTypes } = useQuery(['list-mentory-types'], () =>  UtilService.getMentoryTypes(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });
    
    const { data:users, isLoading:isLoadingUsers } = useQuery(['list-users'], () =>  UtilService.getUsers(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });
    
    const { data:companies, isLoading:isLoadingCompanies } = useQuery(['list-companies'], () =>  UtilService.getCompanies(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:tags, isLoading:loadingTags  } = useQuery(['tags-list'],() => UtilService.getTags(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant:'error'});
        },
    });
   
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateProgram:IProgram) =>ProgramsService.updateProgram(updateProgram), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-program-list');
            queryClient.invalidateQueries('operator-program-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant:'error'});
        },
    });

    return (
            <LayoutDefault >
                <EASCard 
                    titleCard={'Editar Programa'}
                    breadcrumbsCard=""
                    actionCard={<Stack direction="row" spacing={1}>
                                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                    <EASButton variant="outlined" onClick={() => navigate('/programas')}>Sair</EASButton>
                                </Stack>} >
                    <Box sx={{mt: 2}}>
                        <ProgramTabProfile 
                            programEdit={program} 
                            isLoading={ isLoadingProgram || isLoadingStore || isLoadingProgramTypes || isLoadingCompanies || loadingTags || isLoadingUsers} 
                            programTypes={programTypes} 
                            companies={companies}
                            tags={tags}
                            users={users} 
                            storeUpdate={(program) => storeUpdate(program)}
                            ref={refProfile} 
                        />
                    </Box>
                </EASCard>
            </LayoutDefault>
    );
};