
import React, { useState , useEffect, useMemo } from 'react';
import { useSearchParams} from 'react-router-dom';
import { Container, Pagination, Box, LinearProgress, Grid, Alert, TextField, Fab } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { EASTip } from './EASTip';
import { ITip } from '../../contexts/TipsContext/types';

import { EASCard } from '../eas-card/EASCard';
import { useCourseContext , useTeamContext} from '../../contexts';
import { Environment } from '../../environment';
import { TipsService } from '../../services/api/tips/TipsService';

export const EASTips = () => {

  const [Tips, setTips] = useState<Array<ITip> | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterTaskPlan, setFilterTaskPlan] = useState<number>(0);
  const [updateList, setUpdateList] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>('');

  const { ...team } = useTeamContext();
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  
  useEffect(() => {
    async function getTips() {
      setIsLoading(true);
      if(team.type_tean){
        const tips = await TipsService.getAllTips(team.type_tean, search, pagina);
        if (tips instanceof Error) {
          setTips(null);
          setTotalCount(0);
          setIsLoading(false);
          setUpdateList(false);
        } else {
          if(tips.data && tips.total){
            const myTips = JSON.parse(JSON.stringify(tips.data));
            setTips(myTips);
            setIsLoading(false);
            if(tips.total)
              setTotalCount(tips.total);
            setUpdateList(false);
          }
          else{
            setTips(null);
            setTotalCount(0);
            setIsLoading(false);
            setUpdateList(false);
          }
        }
      }
    }
    getTips();
  }, [filterTaskPlan, searchParams, updateList, team.id_equipe]);  


    
  return(
    <Container>
      <Box width="100%" sx={{pb: '15px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', height: 'auto', marginBottom: '15px' }}>
          <EASCard>
            <Box style={{ display: 'flex', justifyContent: 'center', height: 'auto' }}>
              <TextField
                variant="outlined"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                fullWidth
                label={Environment.INPUT_DE_BUSCA}
                type={Environment.INPUT_DE_BUSCA}
              />
              <Box sx={{ m: 1, position: 'relative' }}>
                <Fab
                  size="small"
                  aria-label="save"
                  color="primary"
                  onClick={() => setSearchParams({ busca:search, pagina:'1' }, { replace: true })}
                >
                  {<SearchIcon />}
                </Fab>
              </Box>
            </Box>
          </EASCard>
        </Box>
        {isLoading && (
          <LinearProgress variant='indeterminate' />
        )}
        {!isLoading && (Tips && Tips.length>0 ? (
          <Grid container alignItems="center" spacing={2} sx={{ mb: 2}} >
          {Tips.map((tip, index) => (
            <Grid key={index} item xs={12} sm={12} sx={{width:'100%'}}>
              <EASTip tip={tip}/>
            </Grid>
          ))}
          </Grid>
        ) : (
          <Alert severity="warning">Ocorreu um erro ao carregar as dicas!</Alert>
        ))}
        {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
            <Pagination
              page={pagina}
              count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
              onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
              variant="outlined" 
              shape="rounded"
              color="primary"
              siblingCount={3} 
              boundaryCount={1}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};