import React, { useState }  from 'react';

import { FormControlLabel, FormLabel, useTheme, Skeleton, FormGroup, SwitchProps, Switch, FormControl, FormHelperText, Box } from '@mui/material';
import { IList } from '../../shared/services/api/util/UtilService';
import { useEASThemeContext } from '../contexts';

type EASSwitchProps = {
    isLoading?: boolean | undefined;
    label: string;
    value?:string;
    error?: boolean;
    helperText?:string;
  } & SwitchProps;

export const EASSwitch: React.FC<EASSwitchProps> = ({...props}) => {

    return (
        <>
        {props.isLoading && (
            <Box sx={{display: 'flex', flexDirection: 'column', ml:3  }}>
                <Box sx={{display: 'flex', mt:2, mr:1  }}>
                    <Skeleton variant="rectangular" width={20} height={20} />
                    <Skeleton variant="text" width={100} sx={{ fontSize: '1rem', ml:1 }} />
                </Box>
            </Box>
        )}
        {!props.isLoading && (
        <FormControl error={props.error} >
            <FormControlLabel 
                label={props.label} 
                control={<Switch checked={props.value == '1' ? true : false} onChange={props.onChange} />} 
            />
            <FormHelperText>{props.helperText}</FormHelperText>
        </FormControl>
        )}       
        </>
    );
};