import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { PeopleList } from './PeopleList';

export const People = () => {

  return (
    <LayoutDefault>  
      <PeopleList />        
    </LayoutDefault>
  );
};

