import { useState } from 'react';
import * as yup from 'yup';
import { Stack, CircularProgress} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';


import { useAuthContext, useMentoringContext } from '../../contexts';
import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASButton, EASInput } from '../../forms';

interface IToolsInfluencersListAddProps {
  addInfluencer:(influencer : IInfluencer) => void;
}


export const EASToolsInfluencersListAdd: React.FC<IToolsInfluencersListAddProps> = ({ addInfluencer })  => {


  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();

  const { enqueueSnackbar } = useSnackbar();

  const [nameInfluencer, setNameInfluencer] = useState<string>('');
  const [nameInfluencerError, setNameInfluencerError] = useState<string>('');
  
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);


  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const InfluencerSchema = yup.object().shape({
    nameInfluencer: yup.string().required('Informe o nome do influenciador')
  });



  const handleButtonClick = () => {
    if (!loading) {
     InfluencerSchema
      .validate({ nameInfluencer }, { abortEarly: false })
      .then(validateValues => {
        setSuccess(false);
        setLoading(true);
        const influencer: IInfluencer = {
          id_influencer: '',
          id_mentory: mentory?.id_mentory,
          name_influencer: validateValues.nameInfluencer,
          order_influencer: '0',
          positive_influencer:  '',
          negative_influencer: '',
          group_influencer: '',
          distance_influencer: '',

        };                 // const date = new Date(newValue);
        InfluencersService.createInfluencers(influencer)
          .then((InfluencerResponse) => {
            if (InfluencerResponse instanceof Error) {
              setLoading(false);
              //setSuccess(false);
              enqueueSnackbar('Erro ao cadastrar Influenciador.', { variant:'error'});
            } else {
              influencer.id_influencer = InfluencerResponse.toString();
              setLoading(false);
              setSuccess(true);
              enqueueSnackbar('Influenciador cadastrado com sucesso!', { variant:'success'});
              addInfluencer(influencer);
              setNameInfluencer(''); 
              setNameInfluencerError('');
              setSuccess(false);
            }
          }).catch(() => {
            //alert(taskPlan);
            setLoading(false);
            //setSuccess(false);
            enqueueSnackbar('Erro ao cadastrar Influenciador.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setLoading(false);
        errors.inner.forEach(error => {
          if (error.path === 'nameInfluencer') {
            setNameInfluencerError(error.message);
          }
        });
      });
    }
  };
  
  return (
        <Stack spacing={2} direction="row">
          <EASInput
            label="Nome do Influenciador"
            variant="outlined"
            value={nameInfluencer}
            onChange={(event) => setNameInfluencer(event.target.value)}
            onFocus={() => setNameInfluencerError('')}
            fullWidth
            error={!!nameInfluencerError}
            helperText={nameInfluencerError}
          />
          <EASButton 
            variant='contained' 
            sx={{width:'180px', height: '50px'}}             
            disabled={loading}
            // eslint-disable-next-line react/jsx-no-undef
            endIcon={loading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
            onClick={handleButtonClick}
          >Adicionar</EASButton>
      </Stack>    
  );
};