import { useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Grid, CircularProgress} from '@mui/material';

import { useSnackbar } from 'notistack';
import { EASButton, EASInput } from '../../shared/forms';
import { IProgram, IProgramsInvitation } from '../../shared/types';
import { useMutation, useQueryClient } from 'react-query';
import { ProgramsInvitationService } from '../../shared/services/api/programsinvitation/ProgramsInvitationService';
import { useAuthContext } from '../../shared/contexts';


interface IProgramInviteAddProps {
  program?: IProgram;
  open: boolean;
  setOpen: (open:boolean) => void;
  listInvitation?: Array<IProgramsInvitation> | null;
}

export const ProgramInviteAdd: React.FC<IProgramInviteAddProps> = ({ program, open, setOpen, listInvitation}) => {
  
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [saveLoading, setSaveLoading] = useState(false);
  const [programsInvitation, setProgramsInvitation] = useState<IProgramsInvitation>({});
  const [programsInvitationError, setProgramsInvitationError] = useState<IProgramsInvitation | null>(null);

  
  const { mutateAsync:storeCreate, } = useMutation((newProgramsInvitation:IProgramsInvitation) => ProgramsInvitationService.createProgramsInvitation(newProgramsInvitation), {
    onSuccess: () => {
        queryClient.invalidateQueries('program-invitation-list');
        enqueueSnackbar('Convite enviado com sucesso!', { variant:'success'});
        setOpen(false);
        setSaveLoading(false);
        setProgramsInvitation({});
        setProgramsInvitationError(null);

    },
    onError: () => {
        enqueueSnackbar('Erro ao tentar enviar convite.', { variant:'error'});
        setSaveLoading(false);
    },
});

const isUniqueMail = (email_program_invitation:string):boolean => {
  
  if(email_program_invitation && listInvitation && listInvitation?.length > 0){
    return !(listInvitation && listInvitation.find(item => item.email_program_invitation == email_program_invitation));
  
  }
  return true;
}; 

  const programsInvitationSchema = yup.object().shape({
    name_program_invitation: yup.string().required('Informe o nome do convidado'),
    email_program_invitation: yup.string().test('email_program_invitation', 'Convite já enviado para este e-mail', (email) => (email ? isUniqueMail(email) :  false)).email('Informe um e-mail válido').required('Informe uma Nota')
  });


  const handleCancelDialog = () => {
    setOpen(false);
    setSaveLoading(false);
    setProgramsInvitation({});
    setProgramsInvitationError(null);
  };


  const handleConfirmeDialog = async () => {
    programsInvitationSchema
    .validate( programsInvitation, { abortEarly: false })
    .then(validateValues => {
      setSaveLoading(true);
      const newProgramsInvitation: IProgramsInvitation = {
        id_program: program?.id_program,
        status_program_invitation: '1',
        name_program_invitation: validateValues.name_program_invitation,
        email_program_invitation: validateValues.email_program_invitation,
        user_register: user?.id,
      }; 
      storeCreate(newProgramsInvitation);
    })
    .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setProgramsInvitationError(existingValues => ({
                                              ...existingValues,
                                              [keyError]: error.message
                                          }));                        
            }
        });
    });
  };
  
  return ( <Dialog
                open={open}
                onClose={handleCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  {'Cadastrar novo convidado'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  Informe o nome e o endereço de e-mail para que possamos enviar uma mensagem contendo o convite para a participação neste programa.
                  </DialogContentText>
                  <Box sx={{mt:2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {saveLoading && (                   
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress variant='indeterminate' />
                        </Box>
                    )}
                    {!saveLoading && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <EASInput
                          onFocus={() => 
                              setProgramsInvitationError(existingValues => ({
                              ...existingValues,
                              name_program_invitation: '',
                              }))
                          }
                          label="Nome"
                          type='text'
                          value={programsInvitation?.name_program_invitation}                                
                          onChange={(e) => 
                            setProgramsInvitation(existingValues => ({
                                  ...existingValues,
                                  name_program_invitation: e.target.value,
                              }))
                          }
                          error={!!programsInvitationError?.name_program_invitation}
                          helperText={programsInvitationError?.name_program_invitation}
                        /> 
                      </Grid>
                      <Grid item xs={12}>
                        <EASInput
                          onFocus={() => 
                              setProgramsInvitationError(existingValues => ({
                              ...existingValues,
                              name_program_invitation: '',
                              }))
                          }
                          label="E-mail"
                          type='text'
                          value={programsInvitation?.email_program_invitation}                                
                          onChange={(e) => 
                            setProgramsInvitation(existingValues => ({
                                  ...existingValues,
                                  email_program_invitation: e.target.value,
                              }))
                          }
                          error={!!programsInvitationError?.email_program_invitation}
                          helperText={programsInvitationError?.email_program_invitation}
                        /> 
                      </Grid>
                    </Grid>
                    )}
                  </Box>            
                
                </DialogContent>
                <DialogActions sx={{mr:2, mb:1}}>
                  <EASButton onClick={handleCancelDialog} disabled={saveLoading} variant="outlined">Cancelar</EASButton>
                  <EASButton onClick={handleConfirmeDialog} disabled={saveLoading} variant="contained" autoFocus>
                    Convidar
                  </EASButton>
                </DialogActions>
              </Dialog>  

  );
};