import { IOperator, IOperatorsList } from '../../../contexts/OperatorsContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface IOperatorsService {
  data?: IOperator;
}

const createOperator = async (operators: IOperator): Promise<IOperator> => {
    const { data } = await Api.post('/operators', { Operator: operators } );
    return data;
};

const updateOperator = async (Operator: IOperator): Promise<void> => {
    const { data } = await  Api.put(`/operators/${Operator.id_user}`, Operator);
    return data;  
};

const updateOperatorAvatar = async (Operator: IOperator, file:File): Promise<void | Error> => {
  try {
    const formData = new FormData();
    formData.append('image_avatar', file);
    const { data } = await  Api.post(`/operators/${Operator.id_user}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteOperator = async (id: string): Promise<void> => {
    const { data } = await Api.delete(`/operators/${id}`);
    return data;   
};

const getOperator = async (id: string): Promise<IOperator> => {
    const { data } = await Api.get(`/operators/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getOperators = async (page = 1, search = '' ): Promise<IOperatorsList> => {
    const  {data} = await Api.get(`/operators?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
    return data;
};

export const OperatorsService = {
  getOperators,
  getOperator,
  createOperator,
  updateOperator,
  updateOperatorAvatar,
  deleteOperator,
};
