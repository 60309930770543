import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { CompaniesList } from './CompaniesList';

export const Companies = () => {

  return (
    <LayoutDefault>  
      <CompaniesList />        
    </LayoutDefault>
  );
};

