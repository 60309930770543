import { IPerson, IPeopleList } from '../../../contexts/PeopleContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';


const createPerson = async (people: IPerson, ipv4 = '', ipv6 = ''): Promise<IPerson> => {
    const { data } = await Api.post('/people', { Person: people , ipv4:ipv4, ipv6:ipv6} );
    return data;
};

const updatePerson = async (Person: IPerson): Promise<void> => {
    const { data } = await  Api.put(`/people/${Person.id_user}`, Person);
    return data;  
};

const updatePersonAvatar = async (Person: IPerson, file:File): Promise<void | Error> => {
  try {

    const formData = new FormData();
    formData.append('image_avatar', file);
    const { data } = await  Api.post(`/people/${Person.id_user}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deletePerson = async (id: string): Promise<void | Error> => {
    const { data } = await Api.delete(`/people/${id}`);
    return data;   
};

const getPerson = async (id?: string): Promise<IPerson> => {
    const { data } = await Api.get(`/people/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getPeople = async (page = 1, search = '' ): Promise<IPeopleList> => {
    const  {data} = await Api.get(`/people?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
    return data;
};

export const PeopleService = {
  getPeople,
  getPerson,
  createPerson,
  updatePerson,
  updatePersonAvatar,
  deletePerson,

};
