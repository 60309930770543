import React from 'react';

import { LayoutDefault } from '../../shared/layouts';
import { TaskPlanItens } from './TaskPlanItens';

export const TaskPlan: React.FC = () => {

  return (

    <LayoutDefault >
      <TaskPlanItens />
    </LayoutDefault>

  );
};