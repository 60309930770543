
import { Avatar, Card, CardContent, Grid, Icon, Typography } from '@mui/material';

interface IEASDashboardLigthCardProps  {
    isLoading?:boolean;
    title?:string;
    subtitle?:string;
    value?:string;
    icon?:string;
    color?:string;
    background?:string;
    backgroundIcon?:string;
    colorIcon?:string;
    opcityIcon?:string;
    border?:string;
}


export const EASDashboardLigthCard:React.FC<IEASDashboardLigthCardProps> = ({ ...props }) => {

    return (
        <Card sx={{borderRadius: '12px', background:props.background, color:props.color, position:'relative', border:props.border}} elevation={0}>
            <CardContent sx={{p:'24px'}}>
                <Typography component="div" variant="body2" sx={{position: 'absolute',right: '24px', top: '24px'}}>
                    <Avatar sx={{ width:'80px', height:'80px', background: props.backgroundIcon }}>
                        <Icon sx={{color:props.colorIcon, fontSize:'60px', opacity:props.opcityIcon}} >{props.icon}</Icon>
                    </Avatar>
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ fontSize: '0.85rem', fontWeight: 600, lineheight:'1.334'}}>{props.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" sx={{ fontSize: '1.25rem', fontWeight: '600', lineheight:'1.167'}}>{props.value}</Typography>
                    </Grid>    
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ fontSize: '0.75rem', fontWeight: 300, lineheight:'1.57'}}>{props.subtitle}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

