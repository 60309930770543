import React, { useState , useEffect, useMemo } from 'react';
import {Box, Typography, Chip, Card, Pagination, Skeleton, Switch, Alert, IconButton, Grid, Badge, Divider, CardContent } from '@mui/material/';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useSnackbar } from 'notistack';

import { useSearchParams } from 'react-router-dom';

import { useMentoringContext} from '../../shared/contexts';
import { IObjective, IObjectiveDetail } from '../../shared/contexts/ObjectiveContext/types';

import { ObjectiveService } from '../../shared/services/api/objective/ObjectiveService';
import { ObjectiveDetailService } from '../../shared/services/api/objective-detail/ObjectiveDetailService';

import { DetailingObjectivesEdit } from './DetailingObjectivesEdit';
import { DetailingObjectivesDetailNew } from './DetailingObjectivesDetailNew';
import { DetailingObjectivesDetailEdit } from './DetailingObjectivesDetailEdit';
import { EASButtonList } from '../../shared/forms';
import { DetailingDialogTaskPlan } from './DetailingDialogTaskPlan';
import { EASDialogAlert, PaginationComponent } from '../../shared/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface IDetailingObjectivesDetailProps {
  children?: () => void;
  valueTab: number;
  allObjectives?: Array<IObjective> | null;
}  

export const DetailingObjectivesDetail: React.FC<IDetailingObjectivesDetailProps> = ({valueTab, allObjectives}) => {

  const { enqueueSnackbar } = useSnackbar();
  const { mentory } = useMentoringContext();

  const queryClient = useQueryClient();
  
 /// const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAll, setIsLoadingAll] = useState<boolean>(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openTaskPlan, setOpenTaskPlan] = useState(false);
  const [openEditObjective, setOpenEditObjective] = useState(false);
  const [openEditObjectiveDetail, setOpenEditObjectiveDetail] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [idObjective, setIdObjective] = useState<string | null>(null);
  const [objective, setObjective] = useState<IObjective | null>(null);

  const [objectiveDetail, setObjectiveDetail] = useState<IObjectiveDetail | null>(null);
  const [idObjectiveDetail, setIdObjectiveDetail] = useState<string | null>(null);



  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const { data, isLoading } = useQuery(['objectives-detail-list', [valueTab, page]],() =>   ObjectiveDetailService.getObjectiveDetals(valueTab, page), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de competências.', { variant:'error'});
    },
  });


  const viewTaskPlan = (objectiveDetail:IObjectiveDetail) => {
    setObjectiveDetail(objectiveDetail);
    setOpenTaskPlan(true);
  };

  const handleClickPriority  = (event: React.ChangeEvent<HTMLInputElement>, objectiveDetail?:IObjectiveDetail) => {
    if(objectiveDetail && objectiveDetail.id_objective_detail){
      objectiveDetail.priority_objective_detail = event.target.checked ? '1': '0';
      ObjectiveDetailService.updateObjectiveDetail(objectiveDetail).then((idObjectiveDetail) => {
        if (idObjectiveDetail instanceof Error) {
          enqueueSnackbar((event.target.checked ? 'Erro ao definir prioridade!' : 'Erro ao desmarcar prioridade!'), { variant:'error'});
        } else {
          enqueueSnackbar((event.target.checked ? 'Prioridade definida com sucesso!' : 'Prioridade removida com sucesso!'), { variant:'success'});
        }
      });
    }
  }; 

  const handleClickDeleteDetail  = (id_objective_detail?:string) => {
    if(id_objective_detail){
      setIdObjectiveDetail(id_objective_detail);
      setOpenDetail(true);
    }
  }; 

  const handleConfirmeDetail = () => {
    if(idObjectiveDetail){
      setLoadingDialog(true);
      storeRemoveObjectiveDetail(idObjectiveDetail);
    }
  };

  const handleCancelDetail = () => {
    setOpenDetail(false);
    setIdObjectiveDetail(null);
  };

  const { mutateAsync:storeRemoveObjectiveDetail } = useMutation((idObjectiveDetail:string) => ObjectiveDetailService.deleteObjectiveDetail(idObjectiveDetail), {
    onSuccess: () => {
      queryClient.invalidateQueries('objectives-detail-list');
      queryClient.invalidateQueries('task-plan-detailings');
      enqueueSnackbar('Detalhamento de competência removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      setIdObjectiveDetail(null); 
      setOpenDetail(false); 
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o detalhamento desta competência!', { variant:'error'});
      setIdObjectiveDetail(null);
      setLoadingDialog(false);
    },
  });

  const handleClickDelete  = (id_objective?:string) => {
    if(id_objective){
      setIdObjective(id_objective);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    if(idObjective){
      setIsLoadingAll(true);
      storeRemoveObjective(idObjective);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setIdObjective(null);
  };
    
  const { mutateAsync:storeRemoveObjective } = useMutation((idObjective:string) => ObjectiveService.deleteObjective(idObjective), {
    onSuccess: () => {
      queryClient.invalidateQueries('objectives-list');
      queryClient.invalidateQueries('objectives-detail-list');
      queryClient.invalidateQueries('task-plan-detailings');
      enqueueSnackbar('Competência removida com sucesso!', { variant:'success'});
      setIsLoadingAll(false);
      setIdObjective(null);      
      setOpen(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a competência!', { variant:'error'});
      setIdObjective(null);
      setLoadingDialog(false);
    },
  });


  const handleClickEditDetail = (objectiveDetail?:IObjectiveDetail) => {
    if(objectiveDetail){
      setObjectiveDetail(objectiveDetail);
      setOpenEditObjectiveDetail(true);
    }
  };


  const handleClickEdit = (objective?:IObjective) => {
    if(objective){
      setObjective(objective);
      setOpenEditObjective(true);
    }
  };


  



  return (
    <div style={{marginBottom:'20px'}}>
      <Card sx={{borderRadius : 2, '& .MuiCardHeader-root':{border:'solid 1px', p:0, m:0}, '& .MuiCardHeader-subheader':{border:'solid 1px', p:0, m:0}}} variant='outlined' elevation={0}>
        <CardContent>
          {isLoadingAll && (
          <>
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>
              <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'40%'} height={40} />
              <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}}width={40} height={40} />
              <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}} width={40} height={40} />
            </Box> 
            <Skeleton animation="wave" variant="rounded" sx={{mt:'25px'}} width={'100%'} height={90} />
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px'}}>
              <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'70%'} height={45} />
              <Skeleton animation="wave" variant="rounded" sx={{my:'15px', mx:'8px'}} width={'20%'} height={45} />
              <Skeleton animation="wave" variant="circular" sx={{my:'15px', mx:'8px'}} width={40} height={45} />
            </Box> 
          </>
          )}
          {!isLoadingAll && (allObjectives && allObjectives.length ) && (allObjectives.map((objective) => (
          <div
            role="tabpanel"
            key={objective.id_objective}
            hidden={!(objective.id_objective && valueTab == parseInt(objective.id_objective))}
          >
            <Grid container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} spacing={2}>
              <Grid item>
                <Typography component="h6" variant="h6" sx={{fontWeight:500}}>{objective.text_objective}</Typography>
              </Grid>
              <Grid item>
                {(!(mentory?.status_mentory != '1' )) && ( 
                  <> 
                    <EASButtonList 
                      title="Editar Competência"
                      onClick={() => handleClickEdit(objective)}
                      typeIcon="edit"
                    />
                    <EASButtonList 
                      title="Remover Competência"
                      onClick={() => handleClickDelete(objective.id_objective)} 
                      typeIcon="remove"
                    />
                  </>  
                  )}
              </Grid>
            </Grid>
            {(mentory?.status_mentory =='1' ) && (   
            <DetailingObjectivesDetailNew id_objective={objective.id_objective} />
            )}
          </div>
          )))}
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {isLoading && (
            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px', width:'100%'}}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item zeroMinWidth sm={true}>
                      <Grid container spacing={1} >
                        <Grid item xs={12}>
                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                        </Grid> 
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>                    
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} >
                        <Grid item xs={4}>
                          <Grid container spacing={1} >
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width={'100%'} height={12} />
                            </Grid>
                            <Grid item xs={12} >
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item zeroMinWidth sm={true}>
                      <Grid container spacing={1} >
                        <Grid item xs={12}>
                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                        </Grid> 
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>                    
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} >
                        <Grid item xs={4}>
                          <Grid container spacing={1} >
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width={'100%'} height={12} />
                            </Grid>
                            <Grid item xs={12} >
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> 
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item zeroMinWidth sm={true}>
                      <Grid container spacing={1} >
                        <Grid item xs={12}>
                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                        </Grid> 
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>                    
                        <Grid item xs={12} sm={6} >
                          <Skeleton variant="rectangular" width={150} height={15} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1} >
                        <Grid item xs={4}>
                          <Grid container spacing={1} >
                            <Grid item xs={12}>
                              <Skeleton variant="rectangular" width={'100%'} height={12} />
                            </Grid>
                            <Grid item xs={12} >
                              <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                        <Grid item xs={4} sx={{display:'flex', justifyContent:'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box> 
            )}
            <Grid container spacing={2}>
              {!isLoading && (data?.data && data.data.length ? (data.data.map((objectiveDetail, index) => (
              <Grid item xs={12} key={objectiveDetail.id_objective_detail}>
                <Grid container spacing={2}>
                  <Grid item zeroMinWidth sm={true}>
                    <Grid container spacing={0} >
                      <Grid item xs={12}>
                        <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{objectiveDetail.text_objective_detail}</Typography>
                      </Grid>    
                      <Grid item xs={12} sm={6} sx={{marginTop:'5px'}}>
                        <Typography component="h5" variant="caption">{(objectiveDetail.value_objective_detail 
                          ? ('Nota Inicial: ' + objectiveDetail.value_objective_detail) : (
                            <Chip
                              label="Nota inicial não informada"
                              icon={<ErrorOutlineIcon />}
                              size="small"
                            />
                          ))}
                        </Typography>
                      </Grid>    
                      <Grid item xs={12} sm={6} sx={{marginTop:'5px'}}>
                        <Grid container spacing={0} >
                          <Grid item>
                            <Typography component="h5" variant="caption" sx={{pr:'10px'}}>{'Tarefas Associadas:'}</Typography>
                          </Grid>
                          <Grid item zeroMinWidth sm={true}>
                            <IconButton sx={{float:'left'}} onClick={() => viewTaskPlan(objectiveDetail)}>
                              <Badge color="primary" badgeContent={objectiveDetail.total_task_plan}></Badge>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{textAlign:'center'}} >
                    <Grid container spacing={1} >
                      <Grid item sx={{textAlign:'center'}}  xs={6}>
                        <Typography sx={{textAlign: 'center',  fontSize: '12px'}}>
                          Prioridade
                        </Typography>
                        {(!(mentory?.status_mentory !='1')) ? ( 
                            <Switch
                              onChange={(event) => handleClickPriority(event, objectiveDetail)}
                              defaultChecked={objectiveDetail.priority_objective_detail == '1'}
                              color="primary" />
                        ) : (
                          (objectiveDetail.priority_objective_detail == '1' ? (
                            /*<EASButton />*/
                            <CheckCircleIcon sx={{mt:'0.3em'}} color="primary" />
                          ) : (
                            <RemoveCircleOutlineIcon sx={{mt:'0.3em'}} />
                          ))
                        )}
                      </Grid>
                      {!(mentory?.status_mentory !='1') && ( 
                      <>
                      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt:2}}>
                        <EASButtonList 
                          title="Editar Detalhamento de Objetivo"
                          onClick={() => handleClickEditDetail(objectiveDetail)}
                          typeIcon="edit"
                        />
                      </Grid>
                      <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', mt:2}}>
                        <EASButtonList 
                          title="Excluir Detalhamento de Objetivo"
                          onClick={() => handleClickDeleteDetail(objectiveDetail.id_objective_detail)}
                          typeIcon="remove"
                        />
                      </Grid> 
                      </> 
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {data?.data && index < data.data.length -1 && (
                  <Divider sx={{mt:'10px'}} />
                )}
              </Grid>
              ))) : (
              <Grid item xs={true}>
                <Alert severity="warning">Nenhum detalhamento de competência cadastrado para exibir!</Alert>
              </Grid>
            ))}
            </Grid>
          </Box>
          {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })} />
          )}
        </CardContent>
      </Card>
      <DetailingObjectivesEdit open={openEditObjective} setOpen={(e)=>setOpenEditObjective(e)} objective={objective} />
      <DetailingObjectivesDetailEdit open={openEditObjectiveDetail} setOpen={(e)=>setOpenEditObjectiveDetail(e)} objectiveDetail={objectiveDetail} />
      <DetailingDialogTaskPlan open={openTaskPlan} setOpen={(e)=>setOpenTaskPlan(e)} objectiveDetail={objectiveDetail} mentory={mentory} />
      <EASDialogAlert 
          type="warning"
          title="Excluir Competência"
          statusOpen={open}
          actionCancel={handleCancel}
          actionConfirme={handleConfirme}
          loading={loadingDialog}
        >
          <Typography>Você tem certeza que deseja excluir esta competência e todos os comportamentos cadastrados?</Typography>
        </EASDialogAlert>

        <EASDialogAlert 
          type="warning"
          title="Excluir Comportamento de Competência"
          statusOpen={openDetail}
          actionCancel={handleCancelDetail}
          actionConfirme={handleConfirmeDetail}
          loading={loadingDialog}
        >
          <Typography>Você tem certeza que deseja excluir este comportamento desta competência?</Typography>
        </EASDialogAlert>
    </div>
  );
};