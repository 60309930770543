import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { ITip } from '../../../contexts/TipsContext/types';

interface ITipsListResponse extends ITip {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<ITip> | null;
}



const getAllTips = async (type_tean = '', filter = '', page = 1 ): Promise<ITipsListResponse | Error> => {
  try {
   
    if(!type_tean)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/tips?public_token=${Environment.PUBLIC_TOKEN}&t=${type_tean}&f=${filter}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const  {data} = await Api.get(urlRelativa);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


export const TipsService = {
  getAllTips,
};
