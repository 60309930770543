

import React, { useState } from 'react';
import { Card, Grid, Link, Box, Hidden, Fab, useTheme, styled, Chip} from '@mui/material';
import { IWeblink }from '../../contexts/WeblinksContext/types';

import AttachmentIcon from '@mui/icons-material/Attachment';

import ReplyIcon from '@mui/icons-material/Reply';

interface IfileCardMediaProps {
    weblink: IWeblink;
}  
 
const ProjectName = styled('span')(({ theme }) => ({
    marginLeft: 24,
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: { marginLeft: 4 },
}));

const LinkRange = styled(Fab)(({ theme }) => ({
    marginLeft: 0,
    boxShadow: 'none',
    color: 'white !important',
    background: '#df6d0b !important',
}));
export const EASWeblink: React.FC<IfileCardMediaProps> = ({weblink}) => {
    const { palette } = useTheme();
    const [elevation, setElevation] = useState(1);

    return (
        <Link
            href={weblink.path_material} 
            target="_blank"
            underline="none"
            onMouseEnter={() => setElevation(3)}
            onMouseLeave={() => setElevation(1)}
        >
            <Card sx={{ py: 1, px: 2, }} className="project-card" elevation={elevation}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Box display="flex" alignItems="center" >
                    <Box>
                      <LinkRange size="small" >
                        <ReplyIcon sx={{transform: 'scaleX(-1)'}} />
                      </LinkRange>
                    </Box>
                    <ProjectName title={weblink.name_material} sx={{marginLeft:'8px', marginRight:'8px', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{weblink.name_material}</ProjectName>
                  </Box>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={0} sm={4}>
                    <Chip label={weblink.name_material_tag} variant="outlined"  />
                  </Grid>
                </Hidden>
              </Grid>
            </Card>
        </Link>
    );
};