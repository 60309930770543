import  React  from 'react';
import { set, sub } from 'date-fns';
import { useState } from 'react';
import PagesIcon from '@mui/icons-material/Pages';
import { EASScrollbar } from '../../components';

import {
  Box,
  Button,
  Avatar,
  Divider,
  Popover,
  Typography,
  IconButton,
  Grid,
  useTheme,
  Icon,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useModulesContext, useMentoringContext, useAuthContext, useHumanResourcesContext } from '../../contexts';
import { IModule } from '../../contexts/ModulesContext/types';

export const SelectModulePopover = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const { modules, markModule } = useModulesContext();
  const { markMentory } = useMentoringContext();
  const { markHumanResource } = useHumanResourcesContext();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSelectModule = (module:IModule) => {
    markMentory(null);
    markHumanResource(null);
    markModule(module);
    setOpen(false);
    navigate('/pagina-inicial');
  };


  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen}>
        <PagesIcon />
      </IconButton>
      <Popover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 6,
            width: 320,
            overflow: 'hidden',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" sx={{fontWeight:500}}>Acessar Outro Módulo</Typography>
          </Box>
        </Box>
        <EASScrollbar sx={{height:'414px' }}>
          <Grid container 
            sx={{
              '--Grid-borderWidth': '1px',
              borderTop: 'var(--Grid-borderWidth) solid',
              borderLeft: 'var(--Grid-borderWidth) solid',
              borderColor: 'divider',
              '& > div': {
                borderRight: 'var(--Grid-borderWidth) solid',
                borderBottom: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
              },
            }}
          >
            {modules && modules.map((module) => (
            <Grid key={module?.id_module} item xs={6}>
              <Button sx={{p:0, m:0, width:'100%', height:'100%', color:'inherit'}} onClick={() => handleSelectModule(module)} >
                <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                  <Grid item xs={12}>
                    <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                      <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>{module?.icon_module}</Icon>
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} sx={{pt:1}}>
                    <Typography variant="body1" sx={{fontWeight:600, fontSize: '0.9375rem'}}>{module?.name_module}</Typography>
                    <Typography variant="body2" sx={{fontWeight:400, fontSize: '0.8125rem'}}>{module?.category_module}</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>))}
            <Grid item xs={6}>
              <Button 
                sx={{p:0, m:0, width:'100%', height:'100%', color:'inherit'}} 
                onClick={() => {
                  const meduleShop:IModule = {
                                      id_module:'shop',
                                      name_module:'Aura ExO',
                                      category_module:'Shop',
                                      icon_module:'shopping_cart'
                                    };
                  handleSelectModule(meduleShop);
                }} 
              >
                <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                  <Grid item xs={12}>
                    <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                      <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>shopping_cart</Icon>
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} sx={{pt:1}}>
                    <Typography variant="body1"  sx={{fontWeight:600, fontSize: '0.9375rem'}}>Aura ExO</Typography>
                    <Typography variant="body2" sx={{fontWeight:400, fontSize: '0.8125rem'}}>Shop</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {(user?.groups && user.groups.length > 0) && (
              <Grid item xs={6}>
                <Button 
                  sx={{p:0, m:0, width:'100%', height:'100%', color:'inherit'}} 
                  onClick={() => {
                    const meduleShop:IModule = {
                          id_module:'admin',
                          name_module:'Aura ExO',
                          category_module:'Administração',
                          icon_module:'admin_panel_settings'
                        };
                    handleSelectModule(meduleShop);
                  }} 
                >
                <Grid container sx={{px:4, py:2, flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                  <Grid item xs={12}>
                    <Avatar sx={{width: 56, height: 56, background:theme.palette.background.default}}>
                      <Icon sx={{fontSize: 34,color:theme.palette.text.primary}}>admin_panel_settings</Icon>
                    </Avatar>
                  </Grid>
                  <Grid item xs={12} sx={{pt:1}}>
                    <Typography variant="body1" sx={{fontWeight:600, fontSize: '0.9375rem'}}>Aura ExO</Typography>
                    <Typography variant="body2" sx={{fontWeight:400, fontSize: '0.8125rem'}}>Administração</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            )}

          </Grid>
        </EASScrollbar>
      </Popover>
    </>
  );
};
