import * as React from 'react';


import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HomeHeader } from './HomeHeader';
import { HomeSlide } from './HomeSlide';
import { HomeFooter }from './HomeFooter';

import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import { Box, Toolbar, Typography } from '@mui/material';

import { orange, grey } from '@mui/material/colors';

/*
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';*/

const sections = [
  { title: 'Nossa Soluçâos', url: '#' },
  { title: 'Politicas de Uso', url: '#' },
];

const homeSlideParams = {
  title: 'Aura Exo Mentoring ',
  description: 'Organize os programas de mentoria utilizando o Aura Exo Mentoring e preocupe-se apenas em evoluir',
  image: '/img-home.jpg',
  imageText: 'Aura Exo Mentoring ',
  linkText: 'Saiba Mais…',
};

const featuredPosts = [

  {
    title: 'Nossa Solução',
    date: 'Nov 11',
    description:
      'Organize os programas de mentoria utilizando o Aura Exo Mentoring e preocupe-se apenas em evoluir.',
    image: 'https://source.unsplash.com/random?wallpapers',
    imageLabel: 'Image Text',
  },
  {
    title: 'Politicas de Uso',
    date: 'Nov 11',
    description:
      'Organize os programas de mentoria utilizando o Aura Exo Mentoring e preocupe-se apenas em evoluir.',
    image: 'https://source.unsplash.com/random?wallpapers',
    imageLabel: 'Image Text',
  }, 
];
/*
const posts = [post1, post2, post3];
*/
const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Facebook', icon: FacebookIcon },
  ],
};

// TODO remove, this demo shouldn't need to reset the theme.



const theme = createTheme({
  palette: {
    primary: orange,
    secondary: grey,
  },
});

export const Home = () => {



  return (
    <ThemeProvider theme={theme}>
      <HomeHeader title="Blog" sections={sections} />

        <main>
          <HomeSlide post={homeSlideParams} />
          <Container>
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          </Container>
        </main>
      <HomeFooter
        title="AuraExO"
        description="Desenvolvimento Comportamental"
      />
    </ThemeProvider>
  );
};

