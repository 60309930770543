import { IUser, IAuth } from '../../../contexts/AuthContext/types';
import { Api } from '../axios-config';


const auth = async (auth?: IAuth): Promise<IUser> => {
    const { data } = await Api.post('/auth', { email:auth?.email, password:auth?.password } );
    return data;
};

const update = async (id: string, password:string): Promise<IAuth | Error> => {
  try {
    const { data } = await  Api.put(`/auth/${id}`, { password:password});
    
    if (data) {
      return data;
    }

    return new Error('Erro ao atualizar senha.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar senha.');
  }
};

const authTeams = async (email: string): Promise<IAuth | Error> => {
  try {
    const { data } = await Api.post('/authteams', { email:email });
    
    if (data) {
      return data;
    }

    return new Error('Erro no login.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro no login.');
  }
};


export const AuthService = {
  auth,
  authTeams,
  update,
};
