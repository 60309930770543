import axios from 'axios';
import { responseInterceptor, requestInterceptor, errorInterceptor } from './interceptors';
import { Environment } from '../../../environment';

const Api = axios.create({
  baseURL: (Environment.AMBIENT == '1' ? (Environment.API_URL + Environment.API_FOLDER) : (Environment.API_URL_SANDBOX + Environment.API_FOLDER_SANDBOX)),
  //headers: { 'Api-key': Environment.PUBLIC_TOKEN },
  timeout: 10000, 
});

Api.interceptors.request.use(
  (request) => requestInterceptor(request),
  (error) => errorInterceptor(error)
);

Api.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
);


export { Api };
