import { createContext, useEffect, useMemo, useState, useContext } from 'react';

import { ConnectService } from '../../services/api/connect/ConnectService';
import { Environment } from '../../environment';
import { IConnectContextData, IConnectProviderProps } from './types';
import { useQuery } from 'react-query';

export const ConnectContext = createContext<IConnectContextData>({} as IConnectContextData);

export const ConnectProvider: React.FC<IConnectProviderProps> = ({ children }) => {
  
  const [connectToken, setConnectToken] = useState<string | null>(null);
  
  const { data } = useQuery('connect', () => ConnectService.connect(Environment.PUBLIC_TOKEN, Environment.CLIENT_ID), 
    {
      staleTime: Infinity,
      onSuccess: data => {
        if(data.connectToken){
          localStorage.setItem(Environment.CLIENT_ID, data.connectToken);
          setConnectToken(JSON.stringify(data.connectToken, null, 2));
        }
      },
      onError: () => {
        setConnectToken('error');
      },
    }
  );

  const isConnected = useMemo(() => ((connectToken === 'error') ? false : (!(connectToken) ? null : true )), [connectToken]);
  
  return (
    <ConnectContext.Provider value={{ isConnected, connectToken }}>
      {children}
    </ConnectContext.Provider>
  );
};

export const useConnectContext = () => useContext(ConnectContext);
