

import React from 'react';
import { Card, CardHeader, Theme, useMediaQuery } from '@mui/material';
import { ITip }from '../../contexts/TipsContext/types';

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

interface IfileCardMediaProps {
    tip: ITip;
}  

export const EASTip: React.FC<IfileCardMediaProps> = ({tip}) => {

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
      <Card sx={{ py: 1, px: 2, }}  elevation={1}>
        <CardHeader avatar={!xsDown && !smDown && !mdDown && (<TipsAndUpdatesIcon />)} title={tip.text_tips} />
      </Card>
    );
};