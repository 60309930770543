import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDrawerContext } from '../shared/contexts';
import {
  DashboardMentory,
  TaskPlan,
  Learning,
  Tools,
  ToolsDetail,
  Evaluations,
  Evaluation,
  EvaluationFinal,
  Presentations,
  PresentationsPrint,
  MaintenancePlan,

  Detailing,
  Meetings,
  Meeting,
  SelectMentoring,
  MentoryVideos,
  MentoryVideo,
  MentoryFiles,
  Profile,

} from '../pages';


export const IsAuth2Routes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
      setDrawerOptions([
        {
          icon: 'home',
          path: '/pagina-inicial',
          label: 'Página inicial',
          group: 'Principal',
        },
        {
          icon: 'hail',
          path: '/selecionar-programas',
          label: 'Meus Programas',
        },
        {
          divider: true,
          group: 'PDI',
          icon: 'person',
          path: '/sessoes',
          label: 'Sessôes',
        },
        {
          icon: 'adjust',
          path: '/Competências',
          label: 'Competências',
        },
        {
          icon: 'task',
          path: '/plano-de-tarefas',
          label: 'Plano de Tarefas',
        },
        {
          icon: 'historyEdu',
          path: '/diario-de-aprendizagem',
          label: 'Diário de Aprendizagem',
        },
        {
          icon: 'person_search',
          path: '/avaliacoes',
          label: 'Avaliações Parciais',
        },
        {
          icon: 'how_to_reg',
          path: '/avaliacao-final',
          label: 'Avaliação Final',
        },        
        {
          icon: 'school',
          path: '/plano-de-manutencao',
          label: 'Plano de Manutenção',
        },
        {
          icon: 'co_present',
          path: '/apresentacao-final',
          label: 'Apresentação Final',
        },
        {
          divider: true,
          group: 'Instrumentos de Apoio',
          icon: 'connect_without_contact',
          path: '/ferramentas',
          label: 'Ferramentas',
        },        {
          icon: 'inventory',
          path: '/arquivos',
          label: 'Arquivos',
        },
        {
          icon: 'ondemand_video',
          path: '/videos',
          label: 'Videos',
        },
      ]);

      
  }, []);

  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/pagina-inicial" element={<DashboardMentory />} />
      <Route path="/sessoes"element={<Meetings />} />
      <Route path="/sessoes/detalhes/:id" element={<Meeting />} />
      <Route path="/Competências" element={<Detailing />} />
      <Route path="/plano-de-tarefas" element={<TaskPlan />} />
      <Route path="/diario-de-aprendizagem" element={<Learning />} />
      <Route path="/avaliacoes" element={<Evaluations />} />
      <Route path="/avaliacao/detalhes/:id" element={<Evaluation />} />
      <Route path="/avaliacao-final" element={<EvaluationFinal />} />
      <Route path="/plano-de-manutencao" element={<MaintenancePlan />} />
      <Route path="/apresentacao-final" element={<Presentations />} />
      <Route path="/apresentacao-final/imprimir" element={<PresentationsPrint />} />

      <Route path="/meus-processos" element={<SelectMentoring />} />

      <Route path="/ferramentas" element={<Tools />} />
      <Route path="/ferramentas/influenciadores-de-reputacao" element={<ToolsDetail />} />
      <Route path="/ferramentas/mapa-de-proximidade" element={<ToolsDetail />} />
      <Route path="/ferramentas/mapeamento-dos-rotulos" element={<ToolsDetail />} />
      <Route path="/ferramentas/score-dos-influneciadores" element={<ToolsDetail />} />

      <Route path="/arquivos" element={<MentoryFiles />} />
      
      <Route path="/videos" element={<MentoryVideos />} />

      <Route path="/video/:id" element={<MentoryVideo />} />
      

      {/*
      <Route path="/apresentacoes" element={<Presentations />} />
      <Route path="/apresentacoes/reuniao-de-encerramento" element={<ClosingMeeting />} />
      <Route path="/apresentacoes/apresentacao-dos-resultados" element={<ResultsPresentation />} />
      

      <Route path="/dicas" element={<Tips />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/weblinks" element={<Weblinks />} />
      <Route path="/biblioteca" element={<Library />} />
      */}
      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
