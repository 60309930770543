
import { useEffect } from 'react';
import { useHumanResourcesContext } from '../../shared/contexts';
import { LayoutDefault } from '../../shared/layouts';
import { SelectHumanResourceList } from './SelectHumanResourceList';

export const SelectHumanResource = () => {

  const { markHumanResource } = useHumanResourcesContext();
  
  useEffect(() => {
    markHumanResource(null);
   }, []);

  
  
  
  return (
    <LayoutDefault hideSidebar={true}>
      <SelectHumanResourceList />
    </LayoutDefault>
  );
};