import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluationsExternal, IEvaluation,  } from '../../../types';


const updateEvaluation = async (evaluation: IEvaluation): Promise<void> => {
  const { data } = await  Api.put(`/evaluationsexternal/${evaluation.id_evaluation}`, evaluation);
  return data;  
};

const getEvaluationExternal = async (token?: string): Promise<IEvaluationsExternal> => {
  const { data } = await Api.get(`/evaluationsexternal/?public_token=${Environment.PUBLIC_TOKEN}&t=${token}`);
  return data;
};

export const EvaluationsExternalService = {
  getEvaluationExternal,
  updateEvaluation,
};
