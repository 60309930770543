
import React, { useState , useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable,  DropResult } from 'react-beautiful-dnd';
import {Box, Typography, Container, Alert, List, Card, CardContent, Skeleton  } from '@mui/material/';

import { EASToolsInfluencersListAdd } from './EASToolsInfluencersListAdd';
import { EASToolsInfluencersListEdit } from './EASToolsInfluencersListEdit';
import { EASToolsInfluencersListItem } from './EASToolsInfluencersListItem';

import { useAuthContext, useMentoringContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASButton } from '../../forms';
import { EASDialogAlert, EASCard } from '../';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
}  

export const EASToolsInfluencersList: React.FC = () => {
  
  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();


  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [update, setUpdate] = useState<boolean>(true);
  
  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
  const [totalCount, setTotalCount] = useState(0);


  const [open, setOpen] = useState(false);
  const [idInfluencer, setIdInfluencer] = useState<string | null>(null);
  const [influencer, setInfluencer] = useState<IInfluencer | null>(null);
  const [column, setColumn] = useState<IColumnItem>({id:'Influenciadores',list: [],});

  useEffect(() => {
    async function getInfluencers() {
      if(mentory?.id_mentory){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers(mentory?.id_mentory);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setTotalCount(0);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
            //if(influencers.total)
              setTotalCount(influencers.total);
             /*let a = 3;
              myInfluencers.map(influencerMap => {
                if (influencerMap.group_influencer == '0'|| influencerMap.group_influencer == undefined || influencerMap.group_influencer == null ) {
                  a = 0;
                } 
              });   */

              const newColunm : IColumnItem ={id:'Influenciadores',list: [],};
              if(myInfluencers){
                myInfluencers.map(influencer => {
                    newColunm.list?.push(influencer);
                }); 
              }
              setColumn(newColunm);
              setLoading(false);
          }
          else{
            setAllInfluencers(null);
            setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, [update]);

  const handleClickCancelEdit = () => {
      setInfluencer(null);
  };

  const handleClickDelete  = (id_influencer?:string) => {
    if(id_influencer){
      setIdInfluencer(id_influencer);
      setOpen(true);
    }
  }; 

  const handleConfirme = () => {
    deleteInfluencer();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setIdInfluencer(null);
  };
  
  const deleteInfluencer = () => {

    if(idInfluencer){
      InfluencersService.deleteInfluencers(idInfluencer).then((influencer) => {
        if (influencer instanceof Error) {
          setIdInfluencer(null);
          setInfluencer(null);
          enqueueSnackbar('Erro ao tentar remover o Influenciador!', { variant:'error'});
        } else {
          if(idInfluencer && column && column.list){
            const newList = column.list.filter(
              (_: any, idx: number) => _.id_influencer !== idInfluencer
            );
            //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
      
            const newCol = {
              id: column.id,
              list: newList
            };
            setColumn(newCol);
            if(newList.length < 1){
              setUpdate(!update);
            }
            enqueueSnackbar('Influenciador removido com sucesso!', { variant:'success'});
            setIdInfluencer(null);  
          }

 
        }
      });
    }
  };


  const uploadOrder = ( influencer:IInfluencer, newOrder:number) => {


    //alert(newOrder +1 );
    //alert(influencer.order_influencer);
    if(influencer && Number.isInteger(newOrder)) {

      const moveInfluencer: IInfluencer = {
        id_influencer: influencer.id_influencer,
        id_mentory: influencer.id_mentory,
        order_influencer: (newOrder+1).toString(),
        distance_influencer: influencer.order_influencer,
      };    
      InfluencersService.moveInfluencer(moveInfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
          } 
          else {
            /*
            if(column.list && column.list?.length>0){
              const newAllInfluencers = column.list.map((influencer, index) => {
                influencer.order_influencer = (index +1).toString();
                return influencer;
              });
              setAllInfluencers(newAllInfluencers);
            }*/
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (column as any);   
    const end = (column as any);
  
    uploadOrder(start.list[source.index], destination.index );

    const newList = start.list.filter(
      (_: IInfluencer, idx: number) => idx !== source.index
    );

    newList.splice(destination.index, 0, start.list[source.index]);

    if(newList){
      const finalNewList = newList.map((influencer:IInfluencer, index:number) => {
        influencer.order_influencer = (index +1).toString();
        return influencer;
      });

      const newCol = {
        id: start.id,
        list: finalNewList
      };
      setAllInfluencers(finalNewList);
      setColumn(newCol);


    }

    return null;

  };

  const addInfluencer = (newInfluencer:IInfluencer) => {
    if(newInfluencer){
      
      const newAllInfluencers = allInfluencers;
      newInfluencer.order_influencer = column.list?.length ? (column.list?.length + 1 ).toString() : '1';
      newAllInfluencers?.push(newInfluencer);
      setAllInfluencers(newAllInfluencers);

      if(column.list) {

        const newList = column.list;
        newList.push(newInfluencer);
        //newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : 0), 0, influencerUpdate);
  
        const newCol = {
          id: column.id,
          list: newList
        };
        setColumn(newCol);
        if(newList.length == 1){
          setUpdate(!update);
        }
         
      }
    }
  };

  const updateAllInfluencers = (influencerUpdate:IInfluencer) => {
    if(allInfluencers){

      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }

    if(influencerUpdate.order_influencer && column && column.list){
      const newList = column.list.filter(
        (_: any, idx: number) => _.id_influencer !== influencerUpdate.id_influencer
      );
      newList.splice((influencerUpdate.order_influencer ? parseInt(influencerUpdate.order_influencer)-1 : column.list.length), 0, influencerUpdate);

      const newCol = {
        id: column.id,
        list: newList
      };
      setColumn(newCol);
    }

  };


  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };



  return (
    <EASCard 
      titleCard="Influenciadores de Reputação"
      actionCard={<EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => navigate('/ferramentas')}>Sair</EASButton>}> 
      <Container>
        <Card sx={{borderRadius : 2, bgcolor: 'transparent', my:'30px'}} variant='outlined' elevation={0}>
          <CardContent>
            {isLoading && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', mt:'15px'}}>
                <Skeleton animation="wave" variant="rounded"  width={'80%'} height={18} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'40%'} height={18} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'80%'} height={58} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px', ml:'15px'}} width={150} height={58} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'35px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
            </>
            )}
            <Box sx={{display:(isLoading ? 'none': 'block')}}>
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
                  Quais são as pessoas que exercem maior influência sobre a sua reputação na organização? Classifique em ordem de importância.
                </Typography>
              </Box>
              {(mentory?.status_mentory=='2') ? (
              <Box sx={{ p: 3 }}>
                <Typography variant="h5">Este programa foi encerrado</Typography>
                {((mentory?.id_user_mentorado != user?.id)  && (
                  <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
                ))}
              </Box>
              ):(
              <Box sx={{ p: 3 }}>
                <EASToolsInfluencersListAdd addInfluencer={(e) => addInfluencer(e)} />
              </Box>
              )}
              <EASToolsInfluencersListEdit finishEditInfluencer={(e) => updateAllInfluencers(e)} cancelEditInfluencer={() => handleClickCancelEdit()} influencer={influencer}  />
              <List>
                <DragDropContext onDragEnd={onDragEnd}>
                  {!loading && column.list && column.list.length > 0 && column.id ?  (
                  <Droppable droppableId={column.id}>
                    {provided => (
                      <div  {...provided.droppableProps} ref={provided.innerRef}>
                      {column.list && column.list.length > 0 && (column.list.map((influencer, index) => (
                        <EASToolsInfluencersListItem 
                          index={index}
                          key={influencer.id_influencer} 
                          influencer={influencer} 
                          setInfluencer={(e) => setInfluencer(e)} 
                          handleClickDelete={(e) => handleClickDelete(e)}
                          updateInfluencer={(e) => updateInfluencer(e)}
                        />
                      )))}

                      {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  ): (
                  <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
                  )}
                </DragDropContext>
              </List>
              <EASDialogAlert 
                type="warning"
                title="Excluir Influenciador"
                statusOpen={open}
                actionCancel={handleCancel}
                actionConfirme={handleConfirme}
                loading={isLoading}
              >
                <Typography>Você tem certeza que deseja excluir este Influenciador?</Typography>
              </EASDialogAlert>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </EASCard>  
  );
};