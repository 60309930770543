import { createContext, useCallback, useEffect, useMemo, useContext, useState } from 'react';

import { HumanResourcesService } from '../../services/api/humanresources/HumanResourcesService';
import { setHumanResourceLocalStorange, getHumanResourceLocalStorange} from './util';
import { useAuthContext, useModulesContext } from '..';
import { IHumanResourcesProviderProps, IHumanResourcesProviderData, IHumanResource } from './types';
import { useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';


export const HumanResourcesContext = createContext<IHumanResourcesProviderData>({} as IHumanResourcesProviderData);
export const HumanResourcesProvider: React.FC<IHumanResourcesProviderProps> = ({ children }) => {
  

  const { enqueueSnackbar } = useSnackbar();
  const { user, isAuthenticated } = useAuthContext();
  const { module } = useModulesContext();
  const queryClient = useQueryClient();

  //const [idSigin, setIdSigin] = useState<string | null>(null);
  
  const [humanResourceBuy, setHumanResourceBuy] = useState<number>(0);
  const [humanResources, setHumanResources] = useState<Array<IHumanResource> | null>(null);
  const [humanResource, setHumanResource] = useState<IHumanResource | null>(null);


  useEffect(() => {
    (async () => {
      const userStorage = await getHumanResourceLocalStorange();
      if(userStorage){
        setHumanResource(userStorage);
      } else {
        setHumanResource(null);
        setHumanResourceLocalStorange(null);
      }
    })();
  
  }, []);
  
  const { isFetching:isLoadingHumanResource } = useQuery(['humanresource', [user?.id, module?.id_module]], () => HumanResourcesService.getHumanResources(1,'',user?.id, module?.id_module), 
    {
      enabled: (!!(isAuthenticated) && module?.id_module == '5'),
      staleTime: Infinity,
      onSuccess: data => {
        data?.data ? setHumanResources(data.data) : setHumanResources(null);
        data?.buy ? setHumanResourceBuy(data.buy) : setHumanResourceBuy(0);
      },
      onError: () => {
        setHumanResource(null);
        enqueueSnackbar('Erro ao tentar carregar a lista de mentorias!a', { variant:'error'});
      }
    }
  );


  const getHumanResources = useCallback(async () => {
    queryClient.invalidateQueries('humanresource');
  }, []);

  const handleHumanResource = useCallback(async (humanresource:IHumanResource | null) => {
      queryClient.invalidateQueries('humanresource');
      if(humanresource !== null && humanresource.id_human_resources){
        setHumanResourceLocalStorange(humanresource);
        setHumanResource(humanresource);
      }
      else{
        
        setHumanResourceLocalStorange(null);
        setHumanResource(null);
      }
  }, []);

  const isHumanResourceSelected = useMemo(() => (!(humanResource) ? null : true ), [humanResource]);


  return (
    <HumanResourcesContext.Provider value={{isHumanResourceSelected, humanResourceBuy, isLoadingHumanResource, humanResources, humanResource, getHumanResources:() => getHumanResources(), markHumanResource:(humanresource) => handleHumanResource(humanresource)}}>
      {children}
    </HumanResourcesContext.Provider>
  );
};
export const useHumanResourcesContext = () => useContext(HumanResourcesContext);

