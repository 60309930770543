import React, { useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Stack,
  Avatar,
  Typography,
  Box,
  Skeleton,
  Pagination,
  Grid,
  Card,
  useTheme,
  Tooltip,
  AvatarGroup,
  CardContent,
  CardActionArea,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Icon,
  Button,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Environment } from '../../shared/environment';
import { EASCard, EASProgressList } from '../../shared/components';
import { EASButton, EASInput } from '../../shared/forms';
import AddIcon from '@mui/icons-material/Add';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import { useAuthContext , useMentoringContext } from '../../shared/contexts';
import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SelectMentoringNew } from './SelectMentoringNew';
import { IProgramsInvitation } from '../../shared/types';
import { ProgramsInvitationService } from '../../shared/services/api/programsinvitation/ProgramsInvitationService';
import { useSnackbar } from 'notistack';

export const SelectMentoringList = (() => {


  const theme = useTheme();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();


  const { mentoring, mentoringInvitation, isLoadingMentory, mentoringBuy, markMentory } = useMentoringContext();

  //const [isLoading, setIsLoading] = useState(true);
  const [statusInvitation, setStatusInvitation] = useState<string>();
  //const [totalCount, setTotalCount] = useState(0);
  //const [mentoring, setMentoring] = useState<Array<IMentory> | null> (null);


  const { mutateAsync:markInvitation, isLoading } = useMutation((resendProgramsInvitation:IProgramsInvitation) => ProgramsInvitationService.updateProgramsInvitation(resendProgramsInvitation), {
    onSuccess: () => {
      queryClient.invalidateQueries('mentory');
      queryClient.invalidateQueries('program-invitation-list');
      enqueueSnackbar('Convite ' + (statusInvitation == '2' ? 'aceito' : 'rejeitado') +' com sucesso.', { variant:'success'});
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (statusInvitation == '2' ? 'aceitar' : 'rejeitar') +' convite.', { variant:'error'});
    },
  });


  const  handleUpdateInvite = (id_program_invitation?:string, status_program_invitation?:string) => {
    if(id_program_invitation){
      setStatusInvitation(status_program_invitation);
      const updateInvitation: IProgramsInvitation = {
        id_program_invitation: id_program_invitation,
        status_program_invitation: status_program_invitation,
        id_user: user?.id,
      };  
      markInvitation(updateInvitation);
    }
  };

  const handleSelectMentory = (mentory:IMentory) => {
     markMentory(mentory);
     navigate('/pagina-inicial');
  };

  return (
    <>          
      <EASCard 
        titleCard="Meus Processos"
        bgcolorContent={theme.palette.background.default}
      >
        <Grid container spacing={2}>
            {isLoadingMentory && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
              </Grid>
            </Grid>
            )}
            {!isLoadingMentory && (mentoringInvitation?.length && mentoringInvitation?.map((mentory) => (
            <Grid item key={mentory.id_program_invitation} xs={12} sm={6} lg={4} xl={3}> 
              <Card variant="outlined" sx={{p:'24px', borderRadius:'12px' }}>
                <CardContent >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{display:'flex', alignItems: 'center', justifyContent: 'center', flexShrink: '0'}}>
                      {mentory?.status_program_invitation == '0' && (
                      <Tooltip title="Convite Rejeitado">
                        <CancelIcon  color="error" sx={{ width:60, height:60 }} />
                      </Tooltip>
                      )}
                      {mentory?.status_program_invitation == '1' && (
                      <Tooltip title="Convite Pendente">
                        <PendingIcon color="info" sx={{ width:60, height:60 }} />
                      </Tooltip>
                      )}
                      {mentory?.status_program_invitation == '2' && (
                      <Tooltip title="Convite Aceito">
                        <CheckCircleIcon color="success" sx={{ width:60, height:60 }}   />
                      </Tooltip>
                      )}
                    </Grid>
                    <Grid item xs={12} >
                      <Typography component="h4" sx={{textAlign:'center', fontWeight: 500, fontSize: '1.095rem', lineHeight: '1rem'}} >Convite Para Participar de Processo</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography color="primary"component="h6" sx={{textAlign:'center', fontWeight: 400, fontSize: '0.75rem', lineHeight: '0'}} >{mentory?.name_program}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:'flex', alignItems: 'center', justifyContent: 'center', flexShrink: '0'}}>
                      <Box>
                      {mentory?.name_company ? (
                        <Grid container spacing={2}>
                          <Grid item>
                            <Avatar variant="square" alt={mentory?.name_company} src={mentory?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + mentory?.logo_company  :  '/'} />
                          </Grid>
                          <Grid item zeroMinWidth sm={true} >
                            <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>Empresa</Typography>
                            <Typography variant="subtitle2">{mentory?.name_company}</Typography>
                          </Grid>
                        </Grid> 
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="caption">{'Empresa não definida'}</Typography>
                          </Grid> 
                        </Grid>  
                      )} 
                      </Box>
                    </Grid> 
                  </Grid>
                </CardContent> 
                <Typography component="h3" sx={{textAlign:'center', fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.6rem'}} >
                {mentory?.status_program_invitation == '1' ? 'Você tem um convite para participar de um processo de Mentoria' : (mentory?.status_program_invitation == '2' ? 'Aguarde a definição de sua dupla' : 'Você recusou este convite.')}</Typography>
                {mentory?.status_program_invitation == '1' ? (
                <Grid container spacing={2} sx={{mt:0}}>
                  <Grid item xs={6}>
                    <EASButton 
                      color="primary" 
                      variant="outlined" 
                      startIcon={<ThumbDownIcon sx={{pb: '4.3px'}}/>} 
                      fullWidth
                      disabled={isLoading}
                      onClick={() => handleUpdateInvite(mentory?.id_program_invitation, '0')}
                    >
                      Recusar
                    </EASButton>
                  </Grid>
                  <Grid item xs={6}>
                    <EASButton 
                      color="primary" 
                      variant="contained" 
                      startIcon={<ThumbUpIcon sx={{pb: '4.3px'}}/>} 
                      fullWidth
                      disabled={isLoading}
                      onClick={() => handleUpdateInvite(mentory?.id_program_invitation, '2')}
                    >
                      Aceitar
                    </EASButton>
                  </Grid>
                </Grid>
                ) : (
                <Grid container spacing={2} sx={{mt:0}}>
                  <Grid item xs={12}>
                    <EASButton color="primary" disabled={true} variant="outlined" fullWidth >
                      {mentory?.status_program_invitation == '0' ? 'Convite Recusado' : 'Convite Aceito'}
                    </EASButton>
                  </Grid>
                </Grid>
                )}

              </Card>
            </Grid>
             )))}
            {!isLoadingMentory && (mentoring && mentoring?.map((mentory) => (
            <Grid item key={mentory.id_mentory} xs={12} sm={6} lg={4} xl={3}> 
              <Card variant="outlined" sx={{p:'24px', borderRadius:'12px' }}>
                <CardContent >
                  <Grid container spacing={1} sx={{mb:'12px'}}>
                    <Grid item xs={12} sx={{display:'flex', alignItems: 'center', justifyContent: 'center', flexShrink: '0'}}>
                      <Avatar sx={{ border:'3px solid #EA5B0C', width:60, height:60 }}  alt={mentory?.id_user_mentor == user?.id ? mentory?.name_mentorado : mentory?.name_mentor} src={(Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + (mentory?.id_user_mentor == user?.id ? mentory?.image_mentorado : mentory?.image_mentor)} />
                    </Grid>
                    <Grid item xs={12} >
                      <Typography component="h3" sx={{textAlign:'center', fontWeight: 400, fontSize: '0.95rem', lineHeight: '1.6rem'}} >{mentory?.id_user_mentor == user?.id ? mentory?.name_mentorado : mentory?.name_mentor}</Typography>
                      <Typography color="primary"component="h6" sx={{textAlign:'center', fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.66'}} >{mentory?.id_user_mentor == user?.id ? 'MENTORADO' : 'MENTOR'}</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography component="h4" sx={{textAlign:'center', fontWeight: 500, fontSize: '1.095rem', lineHeight: '1.6rem'}} >{mentory?.name_mentory}</Typography>
                    </Grid>
                  </Grid>
                  <EASProgressList
                      dateInit={mentory?.start_mentory}
                      dateFinish={mentory.end_mentory}
                      progress={mentory?.progress_mentory}
                      textProgress={mentory?.name_progress_mentory}
                    />   
                </CardContent> 
                <Button color="primary" disabled={mentory?.status_mentory == '0' ?  true : false} variant="outlined" fullWidth onClick={() => handleSelectMentory(mentory)}><OpenInBrowserIcon sx={{mr:1}}/> Acessar Processo</Button>
               
              </Card>
            </Grid>
             )))}
            {!isLoadingMentory && (mentoringBuy > 0 && (
              <Grid item xs={12} sm={6} lg={4} xl={3}> 
                <Card onClick={() => navigate('/novo-processo')} variant="outlined" sx={{p:'25px', cursor:'pointer', borderRadius: '12px', border:'4px dashed', borderColor:theme.palette.text.secondary, color:theme.palette.text.secondary, background:'transparent'}}>  
                  <CardContent >
                    <Grid container sx={{px:4,flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                      <Grid item xs={12}>
                        <Avatar sx={{width: 80, height: 80, background:theme.palette.background.default}}>
                          <Icon sx={{fontSize: 50,color:theme.palette.text.secondary}}>control_point</Icon>
                        </Avatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" component="span" sx={{textAlign:'center', fontWeight:600, fontSize: '1.0375rem'}}>Novo Processo</Typography>
                      </Grid>
                      <Grid item xs={12}  sx={{textAlign:'center'}}>
                        <Typography variant="body2" component="span" sx={{textAlign:'center', fontWeight:400, fontSize: '0.9125rem'}}>Configure seu Processo de Mentoria</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="span" sx={{ pt:5, textAlign:'center', fontWeight:200, fontSize: '0.6125rem'}}>{mentoringBuy + (mentoringBuy > 1 ? ' licenças' : ' licença')} restantes</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card> 
              </Grid>
            ))} 
            {!isLoadingMentory && (!mentoring && mentoringBuy <= 0) && (
              <Grid item xs={12} > 
                  Nenhum item encontrado 
              </Grid>
            )} 
          </Grid>
      </EASCard>
  </>
  );
});

