import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Skeleton, Pagination, Grid, useTheme, Alert } from '@mui/material';

import { Environment } from '../../shared/environment';
import { EASCard, PaginationComponent } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';

import AddIcon from '@mui/icons-material/Add';

import { IMeeting } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { useMentoringContext } from '../../shared/contexts';

import { MeetingRelatory } from './MeetingRelatory';

import { MeetingNew } from './MeetingNew';
import { MeetingsListItem } from './MeetingsListItem';
import { useQuery, useQueryClient } from 'react-query';

export const MeetingsList: React.FC = (() => {

  const theme = useTheme();
  const navigate = useNavigate();
  const { mentory } = useMentoringContext();

  const [isLoadings, setIsLoadings] = useState(true);
  const [initState, setInitState] = useState(false);

  const [allMeetings, setAllMeetings] = useState<Array<IMeeting> | null>(null);
  const [meeting, setMeeting] = useState<IMeeting | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);

  const [openNew, setOpenNew] = useState(false);





  const [open, setOpen] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);
  const [textLocal, setTextLocal] = useState<string>('');
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idObjectivesSample, setIdObjectivesSample] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isError, isLoading } = useQuery(['meeting-list'],() => MeetingsService.getMeetings(page, mentory?.id_mentory), {
    onSuccess: data => {
      queryClient.invalidateQueries('meeting-item');
    },
  });


/*
  useEffect(() => {
    getMeetings();
  }, [page, updateList]);

  async function getMeetings() {
    setIsLoading(true);
    const meetingsResponse = await MeetingsService.getMeetings(page, mentory?.id_mentory);
    if (meetingsResponse instanceof Error) {
      setAllMeetings(null);
      setIsLoading(false);
      setTotalCount(0);
      setInitState(true);
    } else {
      if (meetingsResponse.data && meetingsResponse.total) {
        const myMeetings = JSON.parse(JSON.stringify(meetingsResponse));
        setAllMeetings(myMeetings.data);
        if (meetingsResponse.total)
          setTotalCount(meetingsResponse.total);
        setIsLoading(false);
        setInitState(true);
      }
      else {
        setAllMeetings(null);
        setIsLoading(false);
        setTotalCount(0);
        setInitState(true);
      }
    }
  }

 */




  const colorMeeting = ['',
    '#37A2DA', '#8378EA', '#7289ab', '#3366E6', '#ff9f7f', '#fb7293',
    '#E062AE', '#E690D1', '#d48265', '#ca8622', '#B34D4D', '#c23531',
    '#4D8000', '#749f83', '#991AFF', '#B34D4D', '#4D8000', '#c23531',
    '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622',
    '#bda29a', '#6e7074', '#546570', '#c4ccd3', '#759aa0', '#e69d87',
    '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab',
    '#91ca8c', '#f49f42'];

  const somaMinuto = (dateMeeting: string, minutes: number) => {
    const newDate = new Date(dateMeeting);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate.toString();
  };

  const duracao = [
    0, 30, 60, 90, 120
  ];

  const handleRelatory = (meeting: IMeeting) => {
    setMeeting(meeting);
    setOpen(true);
  };

  const handleCloseRelatory = () => {
    setMeeting(null);
    setOpen(false);
  };

  const handleLocal = (local?: string) => {
    if(local)
      setTextLocal(local);

    setOpenLocal(true);
  };

  const handleCloseLocal = () => {
    setTextLocal('');
    setOpenLocal(false);
  };



  return (
    <>
      <EASCard bgcolorContent={theme.palette.background.default} 
               titleCard="Sessões"
               actionCard={
                <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpenNew(true)}>Nova Sessão&nbsp;<AddIcon /></EASButton>
      }>

        <MeetingNew open={openNew} setOpen={(e) => setOpenNew(e)} setUpdateList={() => setUpdateList(!updateList)}/>
        <Grid container spacing={2}>
          {isLoading && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isLoading && (data?.data && data?.data?.length ? data?.data?.map((meeting) => (
            <Grid item key={meeting?.id_meeting} xs={12} sm={6} lg={4} xl={3}>
              <MeetingsListItem meeting={meeting} />
            </Grid>
            )) : (
            <Grid item xs={12} >
              <Alert severity="warning">Não há sessões cadastradas</Alert>
            </Grid>
            ))}
        </Grid>
        {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
      <MeetingRelatory open={open} handleCloseRelatory={handleCloseRelatory} meeting={meeting} />
    </>
  );
});

