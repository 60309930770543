import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { ILearning } from '../../../contexts/LearningContext/types';

interface ILearningService {
  Teams?: string;
}

interface ILearningListResponse extends ILearning {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: ILearning | null;
}

const createLearning = async (learning: object): Promise<ILearningService | Error> => {
  try {
    const { data } = await Api.post('/learning', { learning: learning } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateLearning = async (Learning: ILearning): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/learning/${Learning.id_learning}`, Learning);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteLearning = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/learning/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getLearning = async (mentory: string, client_id: string): Promise<ILearningService | Error> => {
  try {
    const { data } = await Api.post('/learning', { userEnrollment: mentory, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllLearning = async (id_mentory = '', filterLearning = 0, page = 1, filter = '' ): Promise<ILearningListResponse | Error> => {
  try {
    if(!id_mentory )
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/learning?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&f=${filterLearning}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const LearningService = {
  getAllLearning,
  getLearning,
  createLearning,
  updateLearning,
  deleteLearning,

};
