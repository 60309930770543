import React, { useState }  from 'react';
import { TextFieldProps, IconButton, InputAdornment, TextField, useTheme, Icon, Skeleton } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { useEASThemeContext } from '../contexts';

type EASInputProps = {
    isLoading?: boolean | undefined;
    mask?: string;
    nameIconLeft?: string | undefined;
    nameIconRight?: string | undefined;
    handleIconRight?: (e: any) => void | undefined;
    multiline?: boolean;
    min?:string;
    max?:string;
    step?:string;
} & TextFieldProps;

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    mask: string;
}

const TextMaskCPF = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
    <IMaskInput
        {...other}
        mask="000.000.000-00"
        definitions={{'#': /[1-9]/}}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })} 
        overwrite={false}
    />
    );
});
TextMaskCPF.displayName = 'TextMaskCPF';

const TextMaskCNPJ = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
    <IMaskInput
        {...other}
        mask="00.000.000/0000-00"
        definitions={{'#': /[1-9]/}}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })} 
        overwrite={false}
    />
    );
});
TextMaskCNPJ.displayName = 'TextMaskCNPJ';

const TextMaskCEP = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
    <IMaskInput
        {...other}
        mask="00000-000"
        definitions={{'#': /[1-9]/}}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
    />
    );
});
TextMaskCEP.displayName = 'TextMaskCEP';

const TextMaskTel = React.forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
    <IMaskInput
        {...other}
        mask={[{ mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }]}
        definitions={{'#': /[1-9]/}}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
    />
    );
});
TextMaskTel.displayName = 'TextMaskTel';
    
    
export const EASInput: React.FC<EASInputProps> = ({handleIconRight, ...props}) => {
    const theme = useTheme();
    const { themeName } = useEASThemeContext();
    const [isFocused, setIsFocused] = useState(false);


    const autoFillDark = { WebkitBoxShadow: '0 0 0 500px #1f1f1f inset', borderRadius: props.nameIconLeft ? '0  8px 8px 0' : 8 };
    const autoFillLight = { WebkitBoxShadow: '0 0 0 500px eas.inputBackground inset', borderRadius: props.nameIconLeft ? '0  8px 8px 0' : 8 };
   
    const mask = (()=> { 
        switch(props.mask) {
          case 'maskCEP': return TextMaskCEP;
          case 'maskCPF': return TextMaskCPF;
          case 'maskCNPJ': return TextMaskCNPJ;
          case 'maskTel': return TextMaskTel;
          default: return null;
     }})();
    
    return (
        <>
            {props.isLoading && (
                <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
            )}
            {!props.isLoading && (
                props.mask ? (
                    <TextField
                        InputProps={
                            {   autoComplete:'off',
                                inputProps: { 
                                    min: (props.type == 'number' && props?.min ? props.min : undefined), 
                                    max: (props.type == 'number' && props?.max ? props.max : undefined),
                                    step: (props.type == 'number' && props?.step ? props.step : undefined)
                                 },
                                sx: {borderRadius: 2,  backgroundColor: 'eas.inputBackground'}, 
                                ...(props.nameIconLeft) ? {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Icon style={{fontSize: 18, color: isFocused ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconLeft}</Icon>
                                    </InputAdornment>
                                    )
                                } : undefined, 

                                ...(props.nameIconRight && handleIconRight) ?
                                (
                                    {endAdornment: (                            
                                    <InputAdornment position='end'>
                                        <IconButton onClick={(e) => handleIconRight(e)}>
                                        <Icon style={{fontSize: 18, color: props.onFocus ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                        </IconButton>
                                    </InputAdornment>)}

                                ) :
                                (
                                    props.nameIconRight ? 
                                    {
                                        endAdornment: (
                                            <Icon style={{fontSize: 18, color: props.onFocus ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                        )
                                    } : undefined
                                ),

                                ...props.InputProps,
                                inputComponent: mask as any,
                            }
                        }
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        label={props.label}
                        type={props.type}
                        value={props.value ? props.value : ''}
                        onChange={props.onChange}  
                        onKeyDown={props.onKeyDown} 
                        size={props.size}
                        error={props.error}
                        helperText={props.helperText}
                        disabled={props.disabled}
                        fullWidth
                        multiline={props.multiline}
                        rows={props.rows}
                        maxRows={props.maxRows}
                        minRows={props.minRows}
                        sx={props.sx}
                        id={props.id}
                        //{...props}
                        InputLabelProps={{ shrink: true }}  
                    />
                ):(
                    <TextField
                        inputProps={  {  ...(themeName == 'light') ? {style: autoFillLight} : {style: autoFillDark}}}
                        InputProps={
                            {   
                                
                                inputProps: { 
                                    min: (props.type == 'number' && props?.min ? props.min : undefined), 
                                    max: (props.type == 'number' && props?.max ? props.max : undefined),
                                    step: (props.type == 'number' && props?.step ? props.step : undefined)
                                 },
                                
                                autoComplete:'off',
                                sx: {borderRadius: 2,  backgroundColor: 'eas.inputBackground'}, 
                                ...(props.nameIconLeft) ? {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <Icon style={{fontSize: 18, color: props.onFocus ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconLeft}</Icon>
                                    </InputAdornment>
                                    )
                                } : undefined, 
                                ...(props.nameIconRight && handleIconRight) ?
                                (
                                    {endAdornment: (                            
                                    <InputAdornment position='end'>
                                        <IconButton onClick={(e) => handleIconRight(e)}>
                                        <Icon style={{fontSize: 18, color: props.onFocus ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                        </IconButton>
                                    </InputAdornment>)}

                                ) :
                                (
                                    props.nameIconRight ? 
                                    {
                                        endAdornment: (
                                            <Icon style={{fontSize: 18, color: props.onFocus ? `${theme.palette.primary.main}` : undefined}}>{props.nameIconRight}</Icon>
                                        )
                                    } : undefined
                                )
                            }
                        }
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        label={props.label}
                        type={props.type}
                        value={props.value ? props.value : ''}
                        onChange={props.onChange}  
                        onKeyDown={props.onKeyDown} 
                        size={props.size}
                        error={props.error}
                        helperText={props.helperText}
                        disabled={props.disabled}
                        fullWidth
                        multiline={props.multiline}
                        rows={props.rows}
                        maxRows={props.maxRows}
                        minRows={props.minRows}
                        sx={props.sx}
                        id={props.id}
                        InputLabelProps={{ shrink: true }}  
                    />
                )
            )}
        </>
    );
};