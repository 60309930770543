
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Avatar, ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { EASSelect } from '../../shared/forms';

import { UtilService, IList } from '../../shared/services/api/util/UtilService';

import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';

import { PeopleNewPerson } from '../people/PeopleNewPerson';

import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import { useMutation, useQuery, useQueryClient } from 'react-query';


type IMentoryDialogUpdateProps = {
    open: boolean;
    type: string;
    mentory?:IMentory ;
    setOpen: (open:boolean) => void;
};

export interface IOperatorError {
	id_user?: string;
	id_user_group?: string;
}

export const MentoryDialogUpdate:React.FC<IMentoryDialogUpdateProps> =  (({open, type, mentory, setOpen }) => {


    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    
    const [userID, setUserID] = useState<string | null>(null);
    const [userIDError, setUserIDError] = useState<string | null>(null);

    const [openNewPerson, setOpenNewPerson] = useState(false);
    const [updateListPeople, setUpdateListPeople] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    //const [isLoading, setIsLoading] = useState(true);
    //const [people, setPeople] = useState<Array<IList>>([]);
    
    const handleClose = () => {
        setUserID(null);
        setUserIDError(null);
        setOpen(false);
    };
  



    const { data:people, isLoading  } = useQuery(['users-list'],() => UtilService.getUsers(),  {
        staleTime: Infinity,
        onSuccess: () => {

            switch(type) {
                case 'Mentorado':
                    mentory?.id_user_mentorado && setUserID(mentory?.id_user_mentorado);
                    break;
                case 'Mentor':
                    mentory?.id_user_mentor && setUserID(mentory?.id_user_mentor);
                    break;
                case 'Gestor': 
                    mentory?.id_user_gestor && setUserID(mentory?.id_user_gestor);
                    break;
                case 'RH de Apoio': 
                    mentory?.id_user_rh && setUserID(mentory?.id_user_rh);
                    break;
                case 'Supervisor': 
                    mentory?.id_user_supervisor && setUserID(mentory?.id_user_supervisor);
                    break;
            }           
        },
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Pessoas.', { variant:'error'});
        },
    });


    const { mutateAsync:storeUpdate } = useMutation((updateMentory:IMentory) =>MentoringService.updateMentory(updateMentory), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-mentory-edit');
            queryClient.invalidateQueries('operator-mentoring-list');
            setOpen(false);
             setSaveLoading(false);
            enqueueSnackbar(type + 'atualizado com sucesso!', { variant:'success'});
        },
        onError: () => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao atualizar o '+ type.toLowerCase() + '!', { variant:'error'});
        },
    });


/*
    useEffect(() => {
        async function getLists() {
            setUserID(null);
            setUserIDError(null);
            setIsLoading(true);
            const PeopleResponse = await UtilService.getUsers();
            if (PeopleResponse instanceof Error) {
                setPeople([]);
                setIsLoading(false);
            } else {
                if(PeopleResponse){
                    setPeople(PeopleResponse);
                    setIsLoading(false);

                    switch(type) {
                        case 'Mentor':
                            mentory?.id_user_mentor && setUserID(mentory?.id_user_mentor);
                            break;
                        case 'Gestor': 
                            mentory?.id_user_gestor && setUserID(mentory?.id_user_gestor);
                            break;
                        case 'RH de Apoio': 
                            mentory?.id_user_rh && setUserID(mentory?.id_user_rh);
                            break;
                        case 'Supervisor': 
                            mentory?.id_user_supervisor && setUserID(mentory?.id_user_supervisor);
                            break;
                    }
            
                }
                else{
                    setPeople([]);
                    setIsLoading(false);
                }
            }
        }
        if(open==true)
            getLists();
    }, [open]);
*/
    const isValidMentoryID = yup.object({
        userID: yup.string().nullable().required('Este campo é obrigatório')
    });
    
    const updateMentory = () => {
        isValidMentoryID
        .validate( { userID } , { abortEarly: false })
        .then(validateMentoryID => { 
            setSaveLoading(true);
          
            const updateMentory: IMentory = {
                id_mentory:mentory?.id_mentory,
                user_update:user?.id,
            };     
            switch(type){
                case 'Mentorado':
                    updateMentory.id_user_mentorado = validateMentoryID.userID;
                break;
                case 'Mentor':
                    updateMentory.id_user_mentor = validateMentoryID.userID;
                break;
                case 'Gestor':
                    updateMentory.id_user_gestor = validateMentoryID.userID;
                break;
                case 'RH de Apoio':
                    updateMentory.id_user_rh = validateMentoryID.userID;
                break;
                case 'Supervisor':
                    updateMentory.id_user_supervisor = validateMentoryID.userID;
                break;
            }
            storeUpdate(updateMentory);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){               
                    setUserIDError(error.message);                        
                }
            });
        });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle>{'Alterar ' + type}</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', minWidth:'480px'}}>
                        {saveLoading && (                   
                            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress variant='indeterminate' />
                            </Box>
                        )}
                        {!saveLoading && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <EASSelect 
                                        isLoading={isLoading}
                                        options={people ? people : []}
                                        onFocus={() => 
                                            setUserIDError('')
                                        }
                                        label="Escolha uma Pessoa"
                                        getOptionLabel={(option) => option.text}
                                        renderOption={(props, option) => (
                                                <ListItem {...props}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={option.text} />
                                                </ListItem>
                                        )}
                                        value={people && people.find(item => item.value == userID)}                                  
                                        onChangeSelect={(event, value:IList) => {
                                            const valueSelected = (value && value.value) ? value.value : '';
                                            setUserID(valueSelected);
                                        }} 

                                        error={!!userIDError}
                                        helperText={userIDError}
                                    />
                                    <Box sx={{pt:1}} >
                                        <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                            Cadastrar nova pessoa
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Box>            
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
                    <Button variant="contained" onClick={updateMentory} disabled={saveLoading}>Confirmar</Button>
                </DialogActions>
            </Dialog>   
            <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} />
        </>
    );
});

