import { BrowserRouter } from 'react-router-dom';
import { useAuthContext, DrawerProvider, useMentoringContext, useModulesContext, useHumanResourcesContext} from '../shared/contexts';


import { IsAuthSelectModule } from './isAuthSelectModule';
import { NoAuthRoutes } from './noAuthRoutes';
import { IsAuth1Routes } from './isAuth1Routes';
import { IsAuth2Routes } from './isAuth2Routes';
import { IsAuth3Routes } from './isAuth3Routes';
import { IsAuth4Routes } from './isAuth4Routes';
import { IsAuth5Routes } from './isAuth5Routes';
import { IsAuthOperatorsRoutes } from './isAuthOperatorsRoutes';
import { IsAuthSelectMentoring } from './isAuthSelectMentoring';
import { ResetPassword } from './resetPassword';
import { EvaluationExternal } from './evaluationExternal';
import { IsAuthAuraShoping } from './isAuthAuraShoping';
import { IsAuthSelectHumanResource } from './isAuthSelectHumanResource';

export const AppRoutes = () => {

  const { isAuthenticated, user } = useAuthContext();
  const { isMentorySelected } = useMentoringContext();
  const { isHumanResourceSelected } = useHumanResourcesContext();
  const { module, isModuleSelected } = useModulesContext();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const evaluation = params.get('evaluation');
                
              /*(
                isSelected === true 
                  ? <IsAuthRoutes/> 
                  :
                    ( user?.groups && user.groups.length > 0 && mentory === null 
                      ? <IsAuthOperatorsRoutes/> 
                      : <SelectMentoring/>
                    ) 
              )*/
  return (
    <DrawerProvider>
      <BrowserRouter>
        { evaluation && evaluation.toString().length > 0 ?
          <EvaluationExternal/>
          :
          (
            isAuthenticated 
            ? 
            (user?.activation === '1' 
              ?
              (<ResetPassword/>)
              :
              ( 
                isModuleSelected === true ? 
                (
              () => {
                switch(module?.id_module) {
                  case '1': return isMentorySelected ? <IsAuth1Routes/> : <IsAuthSelectMentoring/>;
                  case '2': return isMentorySelected ? <IsAuth2Routes/> : <IsAuthSelectMentoring/>;
                  case '3': return isMentorySelected ? <IsAuth3Routes/> : <IsAuthSelectMentoring/>;
                  case '4': return <IsAuth4Routes/>;
                  case '5': return isHumanResourceSelected ? <IsAuth5Routes/> : <IsAuthSelectHumanResource/>;
                  case 'admin': return <IsAuthOperatorsRoutes/>;
                  case 'shop': return <IsAuthAuraShoping/>;
                  default: 
                  return <IsAuthSelectModule/>;
              }})()
              :
              <IsAuthSelectModule/>
            )
            )
            : 
            <NoAuthRoutes/>
          )
        }
      </BrowserRouter>
    </DrawerProvider>
  );
};