import { useEffect, useState } from 'react';
import { styled, Box, Container, CircularProgress, Typography, useTheme, Divider, Link, FormControlLabel, Checkbox, FormControl, FormHelperText, Stack} from '@mui/material';
import { publicIpv4, publicIpv6} from 'public-ip';
import { useEASThemeContext } from '../../shared/contexts';

import { isValidCPF, uniqueCPF, uniqueMail } from '../../shared/services/api/util/UtilService';
import { IPerson } from '../../shared/contexts/PeopleContext/types';
import { PeopleService } from '../../shared/services/api/people/PeopleService';

import * as yup from 'yup';
import { EASInput, EASButton } from  '../../shared/forms';
import { EASCard } from '../../shared/components';
import { Environment } from '../../shared/environment';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const ContentStyleLogin = styled('div')(() => ({
  maxWidth: 440,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',
}));


export const SignUp = () => {

  const theme = useTheme();

  const { themeName } = useEASThemeContext();
  
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const [isLoading, setIsLoading] = useState(false);
  const [person, setPerson] = useState<IPerson>({});
  const [personError, setPersonError] = useState<IPerson | null>(null);
  const [ipV4, setIpV4] = useState<string>('');
  const [ipV6, setIpV6] = useState<string>('');
  const [checkAcceptError, setCheckAcceptError] = useState<string>('');
  const [checkAccept, setCheckAccept] = useState<boolean>(false);

  const [show, setShow] = useState(false);

  const isValidPerson = yup.object({
    name_user: yup.string().required('Este campo é obrigatório'),
    email_user: yup.string().test('email_user', 'Email já cadastrado para outra pessoa', (email) => (email ? uniqueMail(email, '') : false)).email('Informe um e-mail válido').required('Este campo é obrigatório'),
    cpf_user: yup.string().test('cpf_user', 'CPF já cadastrado', (cpf) => (cpf ? (isValidCPF(cpf) && uniqueCPF(cpf, '')) :  false)).test('cpf_user', 'Informe um CPF válido', (cpf) => (cpf ? isValidCPF(cpf) :  false)).required('Este campo é obrigatório'),
    password_user: yup.string().required('Informe sua senha.'),
    terms_check_user: yup.boolean().oneOf([true], 'É necessário concordar com os Termos e Politicas de uso').required('É necessário concordar com os Termos e Politicas de uso'),
  });

  useEffect(() => {
    async function getIps() {
      const ip4 = await publicIpv4();
      setIpV4(ip4);
      
      const ip6 = await publicIpv6();
      setIpV6(ip6);
    }
    getIps();
  }, []);


  const { mutateAsync:storeCreate, } = useMutation((newPerson:IPerson) => PeopleService.createPerson(newPerson, ipV4, ipV6), {
    onSuccess: () => {
        enqueueSnackbar('Cadastro realizado com sucesso!', { variant:'success'});
        setIsLoading(false);
        navigate('/acessar');

    },
    onError: () => {
        enqueueSnackbar('Erro ao criar novo cadastro.', { variant:'error'});
        
        setIsLoading(false);
    },
});

  const savePerson = () => {
    isValidPerson
    .validate( person , { abortEarly: false })
    .then(validatePerson => { 
      setIsLoading(true);
        const newPerson: IPerson = {
            status_user: '1',
            name_user: validatePerson.name_user,
            email_user: validatePerson.email_user,
            block_user: '0',
            public_user: '1',
            password_user: validatePerson.password_user,
            cpf_user: validatePerson.cpf_user,
        };   
        storeCreate(newPerson);
    })
    .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;    
                if(error.path == 'termsCheck')
                  setCheckAcceptError(error.message);
                else                
                  setPersonError(existingValues => ({
                                  ...existingValues,
                                  [keyError]: error.message
                                }));                        
            }
        });
    });
  };

  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden'}} bgcolor={theme.palette.background.default}>
      <Container>
        <ContentStyleLogin>
          <EASCard>
            <Box display='flex' flexDirection='column' alignItems='left' gap={3}  sx={{p:'40px 30px 10px 30px'}}>
              <Box
                display='flex' 
                flexDirection='column'
                component="img"
                alt={Environment.CLIENT_NAME}
                alignItems='center'
                height={'auto'}
                width={'150px'}
                src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
                sx={{alignContent:'center', m:'auto'}}
              />
              <Typography variant="h6" sx={{fontWeight:600}} color="primary">Inscrever-se</Typography>
              <Typography variant="subtitle1">Cadastre-se com seu CPF e endereço de e-mail</Typography>
              <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
              <form>
                <Stack spacing={2}>
                  <EASInput
                      isLoading={isLoading}
                      onFocus={() => 
                          setPersonError(existingValues => ({
                          ...existingValues,
                          name_user: '',
                          }))
                      }
                      label="Nome Completo"
                      type="text"
                      value={person?.name_user}
                      onChange={(e) => 
                          setPerson(existingValues => ({
                              ...existingValues,
                              name_user: e.target.value,
                          }))
                      }
                      
                      error={!!personError?.name_user}
                      helperText={personError?.name_user}
                  />
                  <EASInput
                    id="cpf_user"
                    onFocus={() => 
                        setPersonError(existingValues => ({
                        ...existingValues,
                        cpf_user: '',
                        }))
                    }
                    mask={'maskCPF'}
                    isLoading={isLoading}
                    label='CPF'
                    type='text'
                    value={person?.cpf_user}                                
                    onChange={(e) => 
                        setPerson(existingValues => ({
                            ...existingValues,
                            cpf_user: e.target.value,
                        }))
                    }
                    error={!!personError?.cpf_user}
                    helperText={personError?.cpf_user}
                />
                <EASInput
                    isLoading={isLoading}
                    onFocus={() => 
                        setPersonError(existingValues => ({
                        ...existingValues,
                        email_user: '',
                        }))
                    }
                    label="E-mail"
                    type='text'
                    value={person?.email_user}                                
                    onChange={(e) => 
                        setPerson(existingValues => ({
                            ...existingValues,
                            email_user: e.target.value,
                        }))
                    }
                    error={!!personError?.email_user}
                    helperText={personError?.email_user}
                />
                <EASInput
                  nameIconRight={show ? 'visibility_off' : 'visibility'}
                  label='Senha'
                  id="new_password"
                  type={show ? 'text' : 'password'}
                  value={person?.password_user}
                  disabled={isLoading}
                  error={!!personError?.password_user}
                  helperText={personError?.password_user}
                  onChange={(e) => 
                    setPerson(existingValues => ({
                        ...existingValues,
                        password_user: e.target.value,
                    }))
                  }
                  handleIconRight={() => setShow(!show)}
                />
                                </Stack>
                </form>
                <Box>
                <FormControl error={!!personError?.terms_check_user} >
                  <FormControlLabel
                    onFocus={() => setPerson(existingValues => ({
                                    ...existingValues,
                                    terms_check_user: false,
                                }))}
                    control={
                      <Checkbox
                        id="termsCheck"
                        name="termsCheck"
                        color="primary"
                        required
                      />
                    }
                    id="termsCheck"
                    name="termsCheck"

                    

                    value={person?.terms_check_user}
                    onChange={(ele, e) => setPerson(existingValues => ({
                          ...existingValues,
                          terms_check_user: e,
                      }))}
                    label={ <Typography>Concordo com os <Link href="https://www.auraexo.com/termo-de-consentimento-para-tratamento-de-dados-app-aura-exo-mentoring/" target="_blank">Termos</Link> e <Link href="https://www.auraexo.com/politica-de-privacidade-e-uso-de-cookies-app-aura-exo-mentoring/" target="_blank">Políticas</Link> de uso
                            </Typography>}
                  />
                  <FormHelperText>{personError?.terms_check_user}</FormHelperText>
                </FormControl>

                </Box>
                <EASButton
                  variant='contained'
                  disabled={isLoading}
                  onClick={savePerson}
                  endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                >
                  Salvar Cadastro
                </EASButton>
              </Box>
              <Divider/>
            </Box>
            <Box display="flex"  flexDirection='column' alignItems='center' sx={{alignContent:'center', m:'auto', alignItems:'center'}}>
              <Link
                  underline="none"
                  variant="subtitle2"
                  color="textSecondary"
                  onClick={() => navigate('/acessar')}
                  sx={{pb:'10px', cursor:'pointer'}}
              >
                Já tem uma conta?
              </Link>
              <Link
                  underline="none"
                  variant="subtitle2"
                  color="textSecondary"
                  onClick={() => navigate('/')}
                  sx={{pb:'30px', cursor:'pointer'}}
              >
                Que ir para a página inicial?
              </Link>
              <Box display="flex" sx={{mb:'18px'}}>
                <Typography display="flex" variant="caption" sx={{fontSize:'18px'}}>©</Typography>
                <Box                  
                  display="flex" 
                  component="img"
                  alt={Environment.CLIENT_NAME}
                  alignItems='center'
                  height={'20px'}
                  width={'20px'}
                  src={'/easistemas.png'}
                  sx={{mx:'3px', mt:'3px'}}
                  
                />
                <Typography display="flex" variant="caption" sx={{mt:'4px'}}>EASistemas</Typography>
              </Box>
            </Box>
          </EASCard>
        </ContentStyleLogin>
      </Container>
    </Box>
  );
};
