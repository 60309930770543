import React, { useState, useEffect } from 'react';
import {ListItemAvatar, Box, ListItem, ListItemText, Rating, Divider, Typography, Hidden } from '@mui/material/';

import { IEvaluationDetail } from '../../shared/types';

import { useSnackbar } from 'notistack';

import { EvaluationsDetailService } from '../../shared/services/api/evaluations-detail/EvaluationsDetailService';
import { useMutation } from 'react-query';

interface IEvaluationsEditItemProps {
  evaluationDetail: IEvaluationDetail | null;
  typeEvaluation?: string;
  statusEvaluation?: string;
  statusEvaluationFinal?: string;
}  

export const EvaluationsEditItem: React.FC<IEvaluationsEditItemProps> = ( {evaluationDetail, typeEvaluation, statusEvaluation, statusEvaluationFinal}) => {

  const [valueRating, setValueRating] = useState<number | null>(0);
  const [hoverRating, setHoverRating] = useState(-1);
  const [evaluationDetailEdit,  setEvaluationDetailEdit ] = useState<IEvaluationDetail | null>(evaluationDetail);
  

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(evaluationDetail){
        setEvaluationDetailEdit(evaluationDetail);
        if(evaluationDetail.value_evaluation_detail)
          setValueRating(parseInt(evaluationDetail.value_evaluation_detail));
      }  
    }
    loadEditObjectiveDetail();
  }, [evaluationDetail]);

  const { mutateAsync:updateEvaluationRating } = useMutation((evaluationDetail:IEvaluationDetail) => EvaluationsDetailService.updateEvaluationDetail(evaluationDetail), {
    onError: () => {
      enqueueSnackbar('Erro ao tentar finalizar esta avaliação parcial! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
    },
  });

  const { mutateAsync:createEvaluationRating } = useMutation((evaluationDetail:IEvaluationDetail) => EvaluationsDetailService.createEvaluationDetail(evaluationDetail), {
    onError: () => {
      enqueueSnackbar('Erro ao tentar finalizar esta avaliação parcial! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
    },
  });

  const handleClickRating  = async (newValueRating : number) => {
   //const tempValue = valueRating;
    setValueRating(newValueRating);
    if(evaluationDetailEdit && evaluationDetailEdit.id_evaluation_detail){
      evaluationDetailEdit.value_evaluation_detail = newValueRating.toString();
      evaluationDetailEdit.date_evaluation_detail = new Date().toLocaleDateString('pt-BR');
      updateEvaluationRating(evaluationDetailEdit);
    }
    else{
      if(evaluationDetailEdit && evaluationDetailEdit.objective_detail?.id_objective_detail) {
        const newEvaluationDetail: IEvaluationDetail = {
          id_evaluation_detail: '',
          id_evaluation: evaluationDetailEdit.id_evaluation,
          id_objective: evaluationDetailEdit.id_objective,
          id_objective_detail: evaluationDetailEdit.objective_detail?.id_objective_detail,
          status_evaluation_detail: '1',
          value_evaluation_detail: newValueRating.toString(),
          fragility_evaluation_detail: '0',
          text_evaluation_detail: '',
          date_evaluation_detail: new Date().toLocaleDateString('pt-BR')
        }; 
        setEvaluationDetailEdit(newEvaluationDetail);
        createEvaluationRating(newEvaluationDetail);
      }
      else {
        enqueueSnackbar('Está ocorrendo um erro ao tentar salvar a informação de avaliação. Tente novamente. Caso o erro persista, informe o administrador do sistema.', { variant: 'error' });
      }
    }
  }; 

  const Ratinglabels: { [index: string]: string } = {
    1: 'Não Percebido',
    2: 'Pouco Percebido',
    3: 'Percebido',
    4: 'Muito Percebido',
    5: 'Excedeu as Expectativas',
  };

  const getLabelText = (value: number) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${Ratinglabels[value]}`;
  };

  return (
    <>
      {evaluationDetail && (
      <>
       <ListItem>
          <ListItemText primary={evaluationDetail.objective_detail?.text_objective_detail} secondary={'Nota Inicial: ' + evaluationDetail.objective_detail?.value_objective_detail }/>
          <Hidden smDown>
            <ListItemAvatar>
              <Box style={{ display: 'flex', justifyContent:'center', width:'158px'}}>
                <Rating 
                  name={'value_' + evaluationDetail.objective_detail?.id_objective_detail}
                  defaultValue={0} 
                  value={valueRating}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    if(newValue)
                    handleClickRating(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHoverRating(newHover);
                  }}
                  readOnly={((typeEvaluation === '1' || statusEvaluation === '2' || statusEvaluationFinal == '2')? true : false)}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent:'center', width:'158px'}}>
                {valueRating !== null && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {(
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) ?
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) :
                      (<>&nbsp;</>)
                    )}
                  </Typography>
                )}
              </Box>
            </ListItemAvatar>
          </Hidden>
        </ListItem>
        <Hidden smUp>
          <Box style={{marginBottom:'10px', marginLeft:'10px'}}>
            <Box style={{ display: 'flex', justifyContent:'left', width:'158px'}}>
                <Rating 
                  name={'value_' + evaluationDetail.objective_detail?.id_objective_detail}
                  defaultValue={0} 
                  value={valueRating}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    if(newValue)
                    handleClickRating(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHoverRating(newHover);
                  }}
                  readOnly={((typeEvaluation === '1' || statusEvaluation === '2' || statusEvaluationFinal == '1')? true : false)}
                />
              </Box>
              <Box style={{ display: 'flex', justifyContent:'left', width:'158px', marginLeft:'5px'}}>
                {valueRating !== null && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {(
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) ?
                      (Ratinglabels[hoverRating !== -1 ? hoverRating : valueRating]) :
                      (<>&nbsp;</>)
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </Hidden>
        <Divider variant="inset" component="li" style={{marginLeft:'-10px'}} />
      </>
      )}
      </>
  );
};