
import { Routes, Route, Navigate } from 'react-router-dom';
import { UpdatePassword } from '../pages';

export const ResetPassword = () => {
  return (
    <Routes>
      <Route path="/alterar-senha" element={<UpdatePassword />} />
      <Route path="*" element={<Navigate to="/alterar-senha" />} />
    </Routes>
  );
};
