
import { EASCard } from '../../shared/components';
import { LayoutDefault } from '../../shared/layouts';

import { useTheme } from '@mui/material';
import { DetailingObjectives } from './DetailingObjectives';

export const Detailing = () => {

  const theme = useTheme();
  return (
    <LayoutDefault>
       <EASCard titleCard="Competências" bgcolorContent={theme.palette.background.default} >
          <DetailingObjectives />
        </EASCard>
    </LayoutDefault>
  );
};

