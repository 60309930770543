import React,  { useState } from 'react';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, useHumanResourcesContext, useMentoringContext, useModulesContext } from '../../contexts';
import { Environment } from '../../environment';

const MENU_OPTIONS = [

  {
    label: 'Perfil',
    icon: 'eva:person-fill',
    link: '/editar-perfil'

  },
  {
    label: 'Alterar Senha',
    icon: 'eva:settings-2-fill',
    link: '/alterar-senha'
  },
];

// ----------------------------------------------------------------------

export const AccountPopover: React.FC = () => {

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const [open, setOpen] = useState(false);
  
  const {logout, profile } = useAuthContext();

  const { markModule } = useModulesContext();
  const { markMentory } = useMentoringContext();
  const { markHumanResource } = useHumanResourcesContext();

  const handleLogout = () => {
    markModule(null);
    markMentory(null);
    markHumanResource(null);
    logout();
  };
  
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);  
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ml:3,
          width: 30,
          height: 30,
          ...(open && {
            '&:before': {
              zIndex: 1,
              width: 30,
              height: 30,
              position: 'absolute',
            },
          }),
        }}
      >
        <Avatar sx={{width:30, height:30}}  alt={profile?.name_user} src={profile?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + profile?.image_user   :  ''} />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 6,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.name_user}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profile?.email_user}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => navigate(option.link)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </Popover>
    </>
  );
};