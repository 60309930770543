import React, { useState , useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography, Grid,  CircularProgress, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Paper, Alert, useTheme, Skeleton, Box } from '@mui/material/';

import AddIcon from '@mui/icons-material/Add';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { IEvaluation } from '../../shared/types';
import { EvaluationsService } from '../../shared/services/api/evaluations/EvaluationsService';
import { EASCard, PaginationComponent } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { EvaluationsListItem } from './EvaluationsListItem';

import { useMutation, useQuery, useQueryClient } from 'react-query';

export interface IResend {
  resend?: string;
  id_evaluation?: string;
  mentory?: string;
}

export const EvaluationsList = () => {

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();
  
  const [loadingNew, setLoadingNew] = useState<boolean>(false);
  const [loadingExt, setLoadingExt] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [openExternalEvaluation, setOpenExternalEvaluation] = useState<boolean>(false);

  const [emailEvaluation, setEmailEvaluation] = useState<string>('');
  const [emailEvaluationError, setEmailEvaluationError] = useState<string>('');

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const externalEvaluationSchema = yup.object().shape({
    emailEvaluation: yup.string().email('Informe um e-mail válido').required('Informe uma Nota')
  });

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['evaluation-list', [page, mentory?.id_mentory]],() => EvaluationsService.getEvaluations(page, mentory?.id_mentory), {
    staleTime: Infinity,
    onSuccess: () =>  {
      queryClient.invalidateQueries('evaluation-autoevaluation');
    },
  });

  const { mutateAsync:storeExternalEvaluation } = useMutation((evaluation:IEvaluation) => EvaluationsService.createEvaluation(evaluation), {
    onSuccess: () =>  {
      queryClient.invalidateQueries('evaluation-list');
      enqueueSnackbar('Avaliação parcial criada e e-mail enviado com sucesso.', { variant:'success'});
      setEmailEvaluation('');
      setEmailEvaluationError('');
      setOpenExternalEvaluation(false);
      setLoadingExt(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao criar convite para avaliação parcial externa.', { variant:'error'});
      setLoadingExt(false);
    },
  });

  const { mutateAsync:storeNewEvaluation } = useMutation((evaluation:IEvaluation) => EvaluationsService.createEvaluation(evaluation), {
    onSuccess: data => {
      queryClient.invalidateQueries('evaluation-list');
      navigate('/avaliacao/detalhes/' + data);
      setLoadingNew(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao iniciar nova autoavaliação parcial.', { variant:'error'});
      setLoadingNew(false);
    },
  });

  const handleClickNewEvaluation = async () => {

    setLoadingNew(true);
    const evaluation: IEvaluation = {
      id_evaluation: '',
      id_mentory: mentory?.id_mentory,
      type_evaluation: '0',
      status_evaluation: '0',
      text_evaluation: '',
      mail_evaluation: '-',
      token_evaluation: uuidv4().toString(),
    }; 
    storeNewEvaluation(evaluation);

  };

  const handleConfirmeExternalEvaluation = async () => {
      externalEvaluationSchema
      .validate({ emailEvaluation}, { abortEarly: false })
      .then(validateValues => {
        setLoadingExt(true);
        const evaluation: IEvaluation = {
          id_evaluation: '',
          id_mentory: mentory?.id_mentory,
          type_evaluation: '1',
          status_evaluation: '0',
          text_evaluation: '',  
          mail_evaluation: validateValues.emailEvaluation,
          token_evaluation: uuidv4().toString(),
        }; 
        storeExternalEvaluation(evaluation);
      })
      .catch((errors: yup.ValidationError) => {
        //setLoading(false);
        errors.inner.forEach(error => {
          setEmailEvaluationError(error.message);
        });
      });
  };

  const handleCancelExternalEvaluation = () => {
    setOpenExternalEvaluation(false);
  };

   return (
    <EASCard 
      titleCard="Avaliações"
      bgcolorContent={theme.palette.background.default}
      actionCard={(mentory?.status_mentory === '1') ? (
          <Stack minWidth='500px' spacing={2} direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
            <EASButton disabled={loadingNew} isLoading={isLoading} variant='contained' onClick={handleClickNewEvaluation}>Nova Autoavaliação&nbsp;{loadingNew ? (<CircularProgress size={24} color="inherit" /> ):(<AddIcon />)}</EASButton>
            <EASButton disabled={loadingExt} isLoading={isLoading} variant='contained' onClick={() => setOpenExternalEvaluation(true)}>Solicitar Avaliação Externa&nbsp;{loadingExt ? (<CircularProgress size={24} color="inherit" />):(<ForwardToInboxIcon />)}</EASButton>
          </Stack>) : <></>
      }
    >
    
      {(mentory?.status_mentory === '2' ) && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography variant="h5">Este programa foi encerrado</Typography>
          {(mentory?.id_user_mentorado === user?.id)  && (
            <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
          )}
        </Paper>
      )}
      <Grid container spacing={2}>
        {isLoading && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} >
              <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
            </Grid>
            <Grid item xs={12} md={3} >
              <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
            </Grid>
            <Grid item xs={12} md={3} >
              <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
            </Grid>
            <Grid item xs={12} md={3} >
              <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
            </Grid>
          </Grid>
        </Grid>
        )}
        {!isLoading && (data?.data && data?.data.length ? (data?.data.map((evaluation) => (
        <Grid item key={evaluation?.id_evaluation} xs={12} sm={6} lg={4} xl={3}>
          <EvaluationsListItem evaluation={evaluation} />
        </Grid>
        ))) :
        <Grid item xs={12}>
          <Alert severity="warning">Não há nenhuma avaliação parcial a exibir.</Alert>
        </Grid>
        )}
      </Grid>
      {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
      )}
      <Dialog
        open={openExternalEvaluation}
        onClose={handleCancelExternalEvaluation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {'Solicitar Avaliação Parcial Externa'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe o endereço de e-mail para que seja enviado o link contendo o formulário para preenchimento da avaliação parcial.
          </DialogContentText>
          {loadingExt && (                      
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!loadingExt && (   
          <TextField  
            label="E-mail"
            value={emailEvaluation} 
            onChange={(event) => setEmailEvaluation(event.target.value)}  
            onFocus={() => setEmailEvaluationError('')}
            error={!!emailEvaluationError}
            helperText={emailEvaluationError}
            fullWidth
          />  
         )}
        </DialogContent>
        <DialogActions sx={{mr:2, mb:1}}>
          <Button onClick={handleCancelExternalEvaluation} variant="contained" disabled={loadingExt}>Desistir</Button>
          <Button onClick={handleConfirmeExternalEvaluation} variant="contained" disabled={loadingExt} autoFocus>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>    
    </EASCard> 
  );
};