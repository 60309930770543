
import { Routes, Route } from 'react-router-dom';
import {
  Home,
  Login,
  SignUp,
} from '../pages';

export const NoAuthRoutes = () => {
  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Home />} />
      <Route path="/acessar" element={<Login />} />
      <Route path="/cadastre-se" element={<SignUp />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};
