import React, { useState, useEffect } from 'react';
import {TextField, Theme, useMediaQuery} from '@mui/material/';
import { useSnackbar } from 'notistack';

import { IEvaluationComment } from '../../shared/types';
import { EvaluationsCommentService } from '../../shared/services/api/evaluations-comment/EvaluationsCommentService';
import { useMutation } from 'react-query';

interface IEvaluationsEditTextProps {
  disabled: boolean;
  evaluationComment?: IEvaluationComment;
}  

export const EvaluationsEditText: React.FC<IEvaluationsEditTextProps> = ( {disabled, evaluationComment}) => {

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [evaluationCommentEdit,  setEvaluationCommentEdit] = useState<IEvaluationComment | null>(null);
  const [textEvaluationCommentEdit, setTextEvaluationCommentEdit] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  
  useEffect(() => {
    function loadTextEvaluationComment() {
      if(evaluationComment){
        setEvaluationCommentEdit(evaluationComment);
        if(evaluationComment.text_evaluation_comment)
          setTextEvaluationCommentEdit(evaluationComment.text_evaluation_comment);
      }
    }
    loadTextEvaluationComment();
  }, [evaluationComment]);



  const { mutateAsync:updateEvaluationComment } = useMutation((evaluationComment:IEvaluationComment) =>  EvaluationsCommentService.updateEvaluationComment(evaluationComment), {
    onSuccess: () => {
    /*  queryClient.invalidateQueries('evaluation-external');
      enqueueSnackbar('Avaliação parcial finalizada com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();*/
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar salvar o comentário! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
    },
  });

  const { mutateAsync:createEvaluationComment } = useMutation((evaluationComment:IEvaluationComment) =>  EvaluationsCommentService.createEvaluationComment(evaluationComment), {
    onSuccess: () => {
    /*  queryClient.invalidateQueries('evaluation-external');
      enqueueSnackbar('Avaliação parcial finalizada com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();*/
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar salvar o comentário! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
    },
  });




  const storeTextEvaluationComment  = async () => {
 
 
    if(evaluationCommentEdit && evaluationCommentEdit.id_evaluation_comment){
      evaluationCommentEdit.text_evaluation_comment = textEvaluationCommentEdit;
      updateEvaluationComment(evaluationCommentEdit);
    }
    else{
      if(evaluationCommentEdit && evaluationCommentEdit.id_evaluation && evaluationCommentEdit.id_objective) {
        const newEvaluationComment: IEvaluationComment = {
          id_evaluation_comment: '',
          id_evaluation: evaluationCommentEdit.id_evaluation,
          id_objective: evaluationCommentEdit.id_objective,
          status_evaluation_comment: '1',
          text_evaluation_comment: textEvaluationCommentEdit,
        }; 
        updateEvaluationComment(newEvaluationComment);
        createEvaluationComment(newEvaluationComment);
      }
      else {
        enqueueSnackbar('Erro ao tentar salvar o comentário! Tente novamente. Caso o erro persista, informe o administrador do sistema', { variant: 'error' });
      }
    }
  }; 



/*



  const storeTextEvaluationComment1 = async () => {
    if(evaluationCommentEdit){
      if(evaluationCommentEdit.id_evaluation_comment){
        evaluationCommentEdit.text_evaluation_comment = textEvaluationCommentEdit;
        EvaluationsCommentService.updateEvaluationComment(evaluationCommentEdit).then((responseEvaluationComment) => {
          if (responseEvaluationComment instanceof Error) {
            enqueueSnackbar('Erro ao salvar comentário', { variant:'error'});
          } else {
            setEvaluationCommentEdit(evaluationCommentEdit);
          }
        });
      }
      else {
        if(evaluationCommentEdit.id_evaluation && evaluationCommentEdit.id_objective) {
          const newEvaluationComment: IEvaluationComment = {
            id_evaluation_comment: '',
            id_evaluation: evaluationCommentEdit.id_evaluation,
            id_objective: evaluationCommentEdit.id_objective,
            status_evaluation_comment: '1',
            text_evaluation_comment: textEvaluationCommentEdit,
          };  
          
          const idEvaluationComment = await EvaluationsCommentService.createEvaluationComment(newEvaluationComment);
          if (idEvaluationComment instanceof Error) {
            enqueueSnackbar('Erro ao tentar marcar avaliação parcial!', { variant:'error'});
          } else {
            newEvaluationComment.id_evaluation_comment = idEvaluationComment.toString(),
            setEvaluationCommentEdit(newEvaluationComment);
            if(evaluationComment){
                evaluationComment.id_evaluation_comment = idEvaluationComment.toString(),
                evaluationComment.id_evaluation = newEvaluationComment.id_evaluation;
                evaluationComment.id_objective = newEvaluationComment.id_objective;
                evaluationComment.status_evaluation_comment = newEvaluationComment.status_evaluation_comment;
                evaluationComment.text_evaluation_comment = newEvaluationComment.text_evaluation_comment;
            }
          }
        }
      }
    }
  };

  */

  return (
    <TextField
      id={'text_comment_' + ((evaluationComment && evaluationComment.id_objective) ? evaluationComment.id_objective  : '' )}
      label={!xsDown && !smDown && !mdDown ? ('Adicione um comentário sobre esta competência.'):('Comentário sobre está competência.')}
      multiline
      fullWidth
      disabled={disabled}
      rows={4}
      sx={{my:2}}
      value={(disabled && !textEvaluationCommentEdit)? 'Nenhum comentário adicionado' : textEvaluationCommentEdit}
      onChange={(event) => setTextEvaluationCommentEdit(event.target.value)}
      onBlur={storeTextEvaluationComment}
    />
  );
};