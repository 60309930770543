import { useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import CompanyTabProfile, { IRefProfile } from './CompanyTabProfile';

import { ICompany } from '../../shared/types';
import { CompaniesService } from '../../shared/services/api/companies/CompaniesService';
import { UtilService} from '../../shared/services/api/util/UtilService';

export const Company = () => {


    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();


    const refProfile = useRef<IRefProfile>(null);

    const storeDisplay = () => {
        if(refProfile.current)
            refProfile.current.saveCompany();
    };

    const returnList = () => {
        queryClient.invalidateQueries('operator-company-edit');
        navigate('/empresas');
    };


    const { data:company, isFetching:isLoadingCompany } = useQuery(['operator-company-edit', id], () => CompaniesService.getCompany(id), 
    {
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
      },
    });


    const { data:estados, isLoading:isLoadingEstados } = useQuery(['list-estados'], () =>  UtilService.getEstados(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:users, isLoading:isLoadingUsers  } = useQuery(['users-list'],() => UtilService.getUsers(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Pessoas.', { variant:'error'});
        },
    });

   
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateCompany:ICompany) =>CompaniesService.updateCompany(updateCompany), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-company-list');
            queryClient.invalidateQueries('operator-company-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant:'error'});
        },
    });

    return (
            <LayoutDefault >
                <EASCard 
                    titleCard={'Editar Empresa'}
                    breadcrumbsCard=""
                    actionCard={<Stack direction="row" spacing={1}>
                                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                    <EASButton variant="outlined" onClick={returnList}>Sair</EASButton>
                                </Stack>} >
                    <Box sx={{mt: 2}}>
                        <CompanyTabProfile 
                            companyEdit={company} 
                            isLoading={ isLoadingCompany || isLoadingEstados || isLoadingStore} 
                            estados={estados}
                            users={users}
                            storeUpdate={(company) => storeUpdate(company)}
                            ref={refProfile} 
                        />
                    </Box>
                </EASCard>
            </LayoutDefault>
    );
};