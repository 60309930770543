import React, { useRef } from 'react';
import { Box, Stack } from '@mui/material';

import { useParams, useNavigate} from 'react-router-dom';

import { LayoutDefault } from '../../shared/layouts';

import ObjectiveSampleTab, { IRefObjectiveSample } from './ObjectiveSampleTab';

import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

export const ObjectiveSample = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();

    const refObjectiveSample = useRef<IRefObjectiveSample>(null);

    const storeDisplay = () => {
        if(refObjectiveSample.current)
        refObjectiveSample.current.saveObjectiveSample();
    };

    return (
        <LayoutDefault >
            <EASCard 
                titleCard={'Editar Ação Sugerida'}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                <EASButton variant="outlined" onClick={() => navigate('/acoes-sugeridas')}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <ObjectiveSampleTab ObjectiveSampleID={id} ref={refObjectiveSample} />
                </Box>
            </EASCard>
        </LayoutDefault>
    );
};