import React from 'react';
import type { CSSProperties } from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { IChartEvaluation } from '../../types';


interface IChartsEvaluationProps  {
    isLoading?:boolean;
    values?:Array<IChartEvaluation>;
    title?:string;
    color?:string;
    background?:string;
    styleChart?: CSSProperties;
} 

export const ChartsEvaluation:React.FC<IChartsEvaluationProps> = ({...props }) => {

    const option = {
        title: [
          {
            left: 'center',
            text: props.title
          }
        ],
        
        tooltip: {
          formatter: '{b}',
          show: true,
          showTitle: true,
          confine: true,
          extraCssText:'width: 250px; white-space: normal;',
          textStyle: {
            overflow: 'breakAll',
            width: 40,
          },
        },
        grid: {  
          containLabel: true,
          top:    50,
          bottom: 20,
          left:   '10%',
          right:  '10%',
        },
        legend: {
          top: 'bottom',
        },
      /*
        itemStyle: {
          borderRadius: [[20, 20, 0, 0],[20, 20, 0, 0]],
        },*/
        xAxis: {  type: 'category',  
                  show:false, 
                  showGrid: false
              },
        yAxis: {  type: 'value',
                  min: -2,
                  max: 2,
      
                  axisLabel: {
                    formatter: function (value:number) {
                      switch(value){
                        case -2:
                          return 'Não percebido';
                        break;
                        case -1:
                          return 'Pouco percebido';
                        break;
                        case 0:
                          return 'Percebido';
                        break;
                        case 1:
                          return 'Muito percebido';
                        break;
                        case 2:
                          return 'Excedeu as expectativas';
                        break;
                        default:
                          return 'Não percebido';
                        break;
                      }
                    }
                  },
                },
        series: props.values,
        height: '180px',
      };
        

    return (
        <>
            {props.isLoading ? (
                <Skeleton variant="rectangular" style={props.styleChart && (props.styleChart)} sx={{borderRadius:'8px'}}/>
            ) : (
              <Card elevation={0} sx={{background:props.background, color:props.color, position:'relative', width:'100%', paddingTop:'15px', paddingBottom:0, borderRadius: '8px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}} >
                <CardContent sx={{p:'24px'}}>
                  <ReactEcharts style={props.styleChart && (props.styleChart)}
                    option={{
                      ...option,
                    }}
                  />
                </CardContent>
              </Card>
            )}
        </>
    );
};

