
import { Box, Card, CardContent, Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { red, orange, blue, green, grey } from '@mui/material/colors';

interface IChartsStageProps  {
    isLoading?:boolean;
    title?:string;
    subtitle?:string;
    description?:string;
    color?:string;
    background?:string;
    colorFixed?:string;
    value?: string;
}


export const ChartsStage:React.FC<IChartsStageProps> = ({ ...props }) => {

    const value = props.value ? parseInt(props.value) : 0;

    const colorRender = (()=> { 
        switch(true) {
          case (!!(props.colorFixed)): return props.colorFixed;
          case (value === 0): return grey[600];
          case (value > 0 && value < 11): return red[600];
          case (value > 10 && value < 41): return orange[600];
          case (value > 41 && value < 90): return blue[600];
          case (value > 90): return green[600];
          default: return null;
    }})();


    const option = {

        series: [
            {
              type: 'gauge',
              startAngle: 185,
              endAngle: -5,
              pointer: {
                show: false
              },
              progress: {
                show: true,
                overlap: false,

                roundCap: true,
                clip: false,
                itemStyle: {
                  borderWidth: 0,
                  //borderColor: '#464646'
                  color:colorRender,
                }
              },/*
              axisLine: {
                roundCap: true,
                lineStyle: {
                  width: 25,
                }
              },*/
              axisLine: {
                roundCap: true,
                lineStyle: {
                  width: 25,
                  color: [
                    [0.2, 'rgb(255, 193, 7, 0.1)'],
                    [0.75, 'rgb(33, 150, 243, 0.1)'],
                    [1, 'rgb(0, 200, 83, 0.1)']
                  ]
                }
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              
              axisLabel: {
                color: '#464646',
                fontSize: 15,
                distance: -30,
                rotate: 'tangential',
                formatter: function (value:number) {
                  if (value === 90) {
                    return 'Perpetuar';
                  /*} else if (value === 50) {*/
                    return 'Resignificar';
                  } else if (value === 50) {
                    return 'Agir';
                  } else if (value === 10) {
                    return 'Preparar';
                  }
                  return '';
                }
              },
              data: [
                      {
                        value: props.value,
                        detail: {
                          offsetCenter: ['0%', '-20%']
                        }
                      },
                    ],
              detail: {
                width: '100%',
                fontSize: '1.5rem',
                color: colorRender,
                formatter: '{value}%'
              }
            }
          ]
      };

    return ( 
      
        <Card elevation={0} sx={{background:props.background, color:props.color, position:'relative', width:'100%', paddingTop:'15px', paddingBottom:0, borderRadius: '8px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}} >
            <CardContent sx={{p:'24px', height:'100%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                <Typography variant="h4" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.235, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>{props.title}</Typography>
                <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.subtitle}</Typography>
                <Box sx={{maxHeight:'200px', marginTop:'-30px', height:'100%'}}>
                    <ReactEcharts style={{width:'100%'}}
                        option={{
                            ...option,
                        }}
                    />
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
                    <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.description}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};



