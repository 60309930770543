
import React, { useImperativeHandle, useState, Ref } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Skeleton, Badge, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Tabs, Tab, Hidden, Chip, Alert } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import BackupIcon from '@mui/icons-material/Backup';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { IFile } from '../../shared/contexts/FilesContext/types';
import { useAuthContext } from '../../shared/contexts';

import { EASInput, EASSelect } from '../../shared/forms';

import { EASFileUpload } from '../../shared/components/eas-file-upload/EASFileUpload';


import { IList} from '../../shared/services/api/util/UtilService';
import { EASButtonFile } from '../../shared/forms/EASButtonFile';


interface IAcceptFile{
    [key: string]: string[];
}

export interface IRefFile {
    saveFile: () => void;
}

type IFileTabProps = {
    fileEdit?: IFile;
    tags?:Array<IList>;
    isLoading?:boolean;
    storeUpdate:(file:IFile) => void;
};

const FileTab = React.forwardRef<IRefFile, IFileTabProps>(({fileEdit, tags, isLoading, storeUpdate}:IFileTabProps, ref:Ref<IRefFile>) => {

    const { user } = useAuthContext();
  
    const [fileError, setFileError] = useState<IFile | null>(null);
  
    const [file, setFile] = useState<IFile | undefined>(fileEdit);
  
    const { enqueueSnackbar } = useSnackbar();
    const [isLoadingUpload, setIsLoadingUpload ] = useState(false);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [fileTemp, setFileTemp] = useState<File | null>(null);

    const [base64, setBase64] = useState<string | ArrayBuffer | null>();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  


    const loadFile = (selectedFile:File) => {
        setIsLoadingUpload(true);
        setFileTemp(selectedFile);
        setFileName(selectedFile.name);

        convertToBase64(selectedFile).then((response) => {
            if (response instanceof Error) {
                setFileName(undefined);
                setFileTemp(null);
                setBase64(null);
                setIsLoadingUpload(false);
                enqueueSnackbar('Erro ao carregar arquivo.', { variant:'error'});
            }
            else {
                setBase64(response);
                setIsLoadingUpload(false);
                setOpen(false);
            }
        });
    };


    useImperativeHandle(ref, () => ({
        saveFile,
    }));

    const isValidFile = yup.object({
        name_file: yup.string().required('Este campo é obrigatório'),
     });

    const saveFile = () => {
        isValidFile
        .validate( file , { abortEarly: false })
        .then(validateFile => {
            const updateFile: IFile = {
                id_file: file?.id_file,
                name_file: validateFile.name_file,
                description_file: file?.description_file,
                tags: file?.tags,
                user_update: user?.id,
                //tmp_file: fileTemp ? fileTemp : null
                base64_file: base64 ? base64.toString() : undefined,
                name_temp_file:fileName
            };     
            storeUpdate(updateFile);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setFileError(existingValues => ({
                        ...existingValues,
                        [keyError]: error.message
                    }));                      
                }
            });
        });
    };

    const convertToBase64 = (file:File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
            resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
            reject(error);
            };
        });
    };





  const NewacceptFile : IAcceptFile = {'application/pdf':['.pdf'],
                                       'application/msword': ['.doc', '.docx'], 
                                       'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                       'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
                                       'text/plain': ['.txt', '.csv']};

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent', minHeight:'320px'}} variant='outlined' elevation={0}>
                        <CardHeader
                            title='Aquivo'
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            {(isLoading || isLoadingUpload )  && (
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                    <Skeleton variant="rectangular" width={150} height={150} />
                                </Box>
                            )}
                            {(!isLoading && !isLoadingUpload) && (
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                    {fileTemp && fileName ? (
                                    <>
                                        <Box sx={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                                            <EASButtonFile title="Novo Arquivo" typeIcon={fileName.split('.').pop()} size="medium" /> 
                                            <PublishedWithChangesIcon sx={{m:'-15px', p:0, margin:'auto', textAlign:'center'}} />
                                            <EASButtonFile title="Arquivo que será substituido" typeIcon={file?.extension_file} size="medium" /> 
                                        </Box>
                                        <Alert severity="info" sx={{marginTop:'15px'}}>Salve para confirmar a substituição do arquivo</Alert>
                                    </>
                                    ) : (
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                                                <BackupIcon />
                                            </Fab> 
                                        }
                                    >
                                        <EASButtonFile file={file?.file_file} title="Visulizar/Baixar Arquivo" typeIcon={file?.extension_file} size="large" />
                                    </Badge>
                                    )}
                                </Box>
                            </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            title='Informações do Aquivo'
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <EASInput
                                        isLoading={isLoading}
                                        label="Nome"
                                        type="text"
                                        value={file?.name_file}
                                        onChange={(e) => 
                                            setFile(existingValues => ({
                                                ...existingValues,
                                                name_file: e.target.value,
                                            }))
                                        }
                                        error={!!fileError?.name_file}
                                        helperText={fileError?.name_file}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <EASInput
                                        isLoading={isLoading}
                                        label="Descição"
                                        type='text'
                                        value={file?.description_file}                                
                                        onChange={(e) => 
                                            setFile(existingValues => ({
                                                ...existingValues,
                                                description_file: e.target.value,
                                            }))
                                        }
                                        multiline
                                        rows="4"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <EASSelect 
                                        isLoading={isLoading}
                                        options={tags ? tags : []}
                                        label="Tags do Vídeo"
                                        getOptionLabel={(option) => option.text}                                  
                                        value={file?.tags && tags && file?.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                                        onChangeSelect={(event, values:Array<IList>) => {
                                            const NewValue:Array<string> = [];
                                            values.map((value) => NewValue.push(value.value));
                                            setFile(existingValues => ({
                                                ...existingValues,
                                                tags: NewValue,
                                            }));
                                                } 
                                            } 
                                        fullWidth
                                        multipleSelect
                                        filterSelectedOptions
                                        disableClearable
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enviar Arquivo</DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>
                        <Box sx={{maxWidth:'380px'}}>
                            <Typography variant="subtitle2">
                                Adicione ou atualize o arquivo. Recomendamos arquivos menores que 5 MB.
                            </Typography>
                        </Box>
                    </DialogContentText>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3}}>
                        <EASFileUpload 
                            acceptFile={NewacceptFile}
                            //isLoadingUpload={isLoadingUpload}
                            elementIsDragAccept={<Box sx={{p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed green', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                            elementIsDragReject={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed red', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                            elementIsNotDragActive={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                            onSelected={(e) => loadFile(e)}
                        />
                     </Box>            
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </Box>
        
    );
});

FileTab.displayName = 'FileTab';

export default FileTab;