
import React, { useState, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import { TaskPlanItensColumn } from './TaskPlanItensColumn';

import { TaskPlanService } from '../../shared/services/api/task-plan/TaskPlanService';
import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { useAuthContext } from '../../shared/contexts';

interface IColumnItem {
  id?: string;
  list?: Array<ITaskPlan>;
}  

interface IColumnGroup extends IColumnItem {
  Previsto: IColumnItem;
  Executando: IColumnItem;
  Pausado: IColumnItem;
  Finalizado: IColumnItem;
}  

interface ITaskPlanItensContainerProps {
  columns?: IColumnGroup;
  setOpenEdit:(TaskPlan : ITaskPlan) => void;
  updateTaskPlan:(TaskPlan : ITaskPlan) => void;
  handlePresentation:(TaskPlan : ITaskPlan, status:boolean) => void;
  handleFinished:(TaskPlan : ITaskPlan, status:boolean) => void;
  removeTaskPlan:(idTaskPlan?:string) => void;
}  

export const TaskPlanItensContainer: React.FC<ITaskPlanItensContainerProps> = ({ columns, updateTaskPlan, handlePresentation, handleFinished, removeTaskPlan, setOpenEdit }) => {

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  
  const [columnsContainer, setColumnsContainer] = useState<IColumnGroup>({Previsto: {id: 'Previsto',list: [],}, Executando: {id: 'Executando',list: [],},Pausado: {id: 'Pausado',list: [],},Finalizado: {id:'Finalizado',list: [],}});

  const StyledColumns = styled('div') ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    width: '100%',
    gap: '15px'
  });

  useEffect(() => {
    async function updateColuns() {
      if(columns)
        setColumnsContainer(columns);
    }
    updateColuns();
  }, [columns]);

  const uploadPosition = ( task_plan:ITaskPlan, position:string):ITaskPlan => {

    if(task_plan && position) {
  
      const status_task_plan = (()=> { 
        switch(position) {
          case 'Pausado': return '0';
          case 'Previsto': return '1';
          case 'Finalizado': return '2';
          case 'Executando': return '3';
          default: return '1';
     }})();

      const moveTaskPlan: ITaskPlan = {
        id_task_plan: task_plan.id_task_plan,
        status_task_plan: status_task_plan,
        date_update: user?.id,
      };   

      switch(status_task_plan) {
        case '0': moveTaskPlan.pause_task_plan = new Date().toISOString().split('T')[0]; break;
        case '2': moveTaskPlan.finish_task_plan = new Date().toISOString().split('T')[0]; break;
        case '3': moveTaskPlan.execute_task_plan = new Date().toISOString().split('T')[0]; break;
      }

      TaskPlanService.updateTaskPlan(moveTaskPlan)
        .then((Response) => {
          if (Response instanceof Error) {
            enqueueSnackbar('Erro ao mover tarefa.', { variant:'error'});
          } 
          else {

            task_plan.status_task_plan = moveTaskPlan.status_task_plan;
            task_plan.date_update = moveTaskPlan.date_update;
            switch(status_task_plan) {
              case '0': task_plan.pause_task_plan = moveTaskPlan.pause_task_plan; break;
              case '2': task_plan.finish_task_plan = moveTaskPlan.finish_task_plan; break;
              case '3': task_plan.execute_task_plan = moveTaskPlan.execute_task_plan; break;
            }
            updateTaskPlan(moveTaskPlan);
          }
      }).catch(() => {
        enqueueSnackbar('Erro ao mover tarefa.', { variant:'error'});
      });
    }
    else{
      enqueueSnackbar('Erro ao mover influenciador.', { variant:'error'});
    }
    return task_plan;

  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;
    if (source === undefined || source === null) return null;
    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    ) return null;

    const start = (columnsContainer as any)[source.droppableId];   
    const end = (columnsContainer as any)[destination.droppableId];
  
    
      // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        list: newList
      };

      // Update the state
      setColumnsContainer(state => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      );

      // Create a new start column
      const newStartCol = {
        id: start.id,
        list: newStartList
      };
      // Make a new end list array
      const newEndList = end.list;

      //const moveInfluencer:IInfluencer = start.list[source.index];
      

      const newTaskPlan = uploadPosition(start.list[source.index], end.id );

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, newTaskPlan);

      //alert(JSON.stringify(newEndList));
      // Create a new end column
      const newEndCol = {
        id: end.id,
        list: newEndList
      };

      // Update the state
      setColumnsContainer(state => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }));

     // group_influencer

    //  alert(JSON.stringify(newEndCol));
      return null;

    }
  };

  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <StyledColumns>
          {Object.values(columnsContainer).map(col => (
            <TaskPlanItensColumn setOpenEdit={(idTaskPlan) => setOpenEdit(idTaskPlan)} updateTaskPlan={(taskPlan) => updateTaskPlan(taskPlan)}  removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} item={col} key={col.id} />
          ))}
        </StyledColumns>
      </DragDropContext>
  );
};