import { LayoutDefault } from '../../shared/layouts';
import { ObjectivesSampleList } from './ObjectivesSampleList';

export const ObjectivesSample = () => {

  return (
    <LayoutDefault>
      <ObjectivesSampleList/>
    </LayoutDefault>
  );
};
