import { createContext, useCallback, useContext, useMemo, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { DarkTheme, LightTheme } from '../../themes';
import { EASScrollbar } from '../../components';

declare module '@mui/material/styles' {
  interface Palette {
    eas: Palette['primary'];
  }

  interface PaletteOptions {
    eas?: {
      inputBackground?: string;
      backgroundTask?: string;
    };
  }
}

interface IThemeContextData {
  themeName: string;
  toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useEASThemeContext = () => {
  return useContext(ThemeContext);
};

interface IEASThemeProps {
  children: React.ReactNode;
}

export const EASThemeProvider: React.FC<IEASThemeProps> = ({ children }) => {

  const themeStorage: string = useMemo(() => {
    const value = localStorage.getItem('themeStyle');
    return value ? value : 'light';
  }, []);

  const [themeName, setThemeName] = useState<string>(themeStorage ? themeStorage : 'light');

  const setThemeStorage = () => {
    localStorage.setItem('themeStyle', themeName === 'light' ? 'dark' : 'light');
  };

  const toggleTheme = useCallback(() => {
    setThemeStorage();
    setThemeName(oldThemeName => oldThemeName === 'light' ? 'dark' : 'light');
  }, []);

  const theme = useMemo(() => {
    if (themeName === 'light') return LightTheme;
    if (themeName === 'contrast') return DarkTheme;
    return DarkTheme;

  }, [themeName]);

  useEffect(() => {
    setThemeName(initTeamsTheme(themeStorage ? themeStorage : themeName));
  }, []);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <EASScrollbar sx={{width:'100vw', height:'100vh', bgcolor:theme.palette.background.paper, '& > .simplebar-track.simplebar-vertical': {marginTop: '66px', width: 15, }, '& .simplebar-scrollbar': {'&:before': {backgroundColor: theme.palette.primary.main }}}}>
          {children}
        </EASScrollbar>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const initTeamsTheme = (theme: string | undefined) => {
  switch (theme) {
  case 'dark':
    return 'dark';
  case 'contrast':
    return 'dark';
  default:
    return 'light';
  }
};