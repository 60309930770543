import React, { ReactElement, useEffect, useMemo, useState, MouseEvent } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Chip, Box,  Grid, Card, Avatar, useTheme, Divider, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, MenuItem, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FeedIcon from '@mui/icons-material/Feed';

import { EASDialogAlert } from '../../shared/components';
import { EASButton } from '../../shared/forms';



import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { IMeeting, IMeetingsList } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';


import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useMutation, useQueryClient } from 'react-query';

interface IMentoryTabMeetingsItemProps {
  meeting: IMeeting;
}

export const MentoryTabMeetingsItem: React.FC<IMentoryTabMeetingsItemProps> = ({meeting})  => {



  const theme = useTheme();
  const navigate = useNavigate();
  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openEl = Boolean(anchorEl);

  const [open, setOpen] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);
  const [textLocal, setTextLocal] = useState<string>('');

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idMeeting, setIdMeeting] = useState<string | null>(null);



  const colorMeeting = ['',
    '#37A2DA', '#8378EA', '#7289ab', '#3366E6', '#ff9f7f', '#fb7293',
    '#E062AE', '#E690D1', '#d48265', '#ca8622', '#B34D4D', '#c23531',
    '#4D8000', '#749f83', '#991AFF', '#B34D4D', '#4D8000', '#c23531',
    '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622',
    '#bda29a', '#6e7074', '#546570', '#c4ccd3', '#759aa0', '#e69d87',
    '#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab',
    '#91ca8c', '#f49f42'];

  const somaMinuto = (dateMeeting: string, minutes: number) => {
    const newDate = new Date(dateMeeting);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate.toString();
  };

  const duracao = [
    0, 30, 60, 90, 120
  ];


  const handleCloseLocal = () => {
    setTextLocal('');
    setOpenLocal(false);
  };


  return (
    <Card variant="outlined" sx={{ p: '25px', borderRadius: '12px'}}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
            <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Sessão</Typography>
              <Avatar sx={{ width: 20, height: 20, padding: '8px', bgcolor: colorMeeting[meeting?.ordering_meeting ? parseInt(meeting?.ordering_meeting) : 0] }} alt={meeting?.ordering_meeting}>
                {meeting?.ordering_meeting}
              </Avatar>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight:'80px' }}>
            <Typography variant="caption">
              Assunto: <Typography variant="caption" sx={{ fontWeight: 600 }}>{meeting?.subject_meeting}</Typography>
            </Typography>
            <Typography variant="caption">
              Data e hora: <Typography variant="caption" sx={{ fontWeight: 600 }}>{meeting?.date_meeting && new Date(meeting?.date_meeting).toLocaleString('pt-BR', {dateStyle: 'medium', timeStyle: 'short'}) }</Typography>
            </Typography>
            <Typography variant="caption">
              Duração: 
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {
                  meeting?.duration_meeting == '1' ? ' 30 minutos' : 
                  meeting?.duration_meeting == '2' ? ' 60 minutos' : 
                  meeting?.duration_meeting == '3' ? ' 90 minutos' : 
                  meeting?.duration_meeting == '4' ? ' 120 minutos' : 
                  meeting?.duration_meeting == '5' ? ' Indeterminado' : null
                }
              </Typography>
            </Typography>
            <Typography variant='caption'>
              Tipo de sessão:
              <Typography variant='caption' sx={{fontWeight: 600}}>
                {meeting?.type_meeting == '0' ? ' Online' : ' Presencial'}
              </Typography>
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{mt: 0}}>
        <Grid item xs={12}>
            <Box sx={{   textAlign: 'center', marginTop:'5px', marginRight:'10px' }}>
              {meeting?.status_meeting == '0' && (
                <Chip label="Cancelada" color="error" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
              )}
              {meeting?.status_meeting == '2' && (
                <Chip label="Finalizada" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
              )}
              {meeting?.status_meeting == '1' && (
                <>
                {meeting?.date_meeting && meeting?.duration_meeting && new Date() > new Date(somaMinuto(meeting?.date_meeting, duracao[parseInt(meeting.duration_meeting)])) ? (
                  <Chip label="Encerrada" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                  ) : (meeting?.date_meeting && new Date(meeting?.date_meeting) <= new Date() ? (
                    <Chip label="Em sessão" sx={{ backgroundColor: '#991AFF', color: '#fff', fontWeight: '500', letterSpacing: '0.5px' }} />
                    ) : (
                    <Chip label="Agendada" color="info"  sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                  )
                )}
                </>
              )}
            </Box>



        </Grid>
      </Grid>
    </Card>      
  );
};

