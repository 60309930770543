import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Skeleton, Pagination, Grid, useTheme, Alert } from '@mui/material';

import { Environment } from '../../shared/environment';
import { EASCard, PaginationComponent } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';

import AddIcon from '@mui/icons-material/Add';

import { IMeeting } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { useMentoringContext } from '../../shared/contexts';
import { MentoryTabMeetingsItem } from './MentoryTabMeetingsItem';
import { useQuery, useQueryClient } from 'react-query';
import { IMentory } from '../../shared/contexts/MentoringContext/types';



interface IMentoryTabMeetingsProps {
  mentory?:IMentory;
}  

export const MentoryTabMeetings: React.FC<IMentoryTabMeetingsProps> = ({mentory}) => {



  const [meeting, setMeeting] = useState<IMeeting | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [openLocal, setOpenLocal] = useState(false);
  const [textLocal, setTextLocal] = useState<string>('');

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const { enqueueSnackbar } = useSnackbar();


  const { data, isLoading } = useQuery(['resources-human-meeting-list',[page, mentory?.id_mentory]],() => MeetingsService.getMeetings(page, mentory?.id_mentory), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de sessões.', { variant:'error'});
    },
  });


  const somaMinuto = (dateMeeting: string, minutes: number) => {
    const newDate = new Date(dateMeeting);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate.toString();
  };

  const duracao = [
    0, 30, 60, 90, 120
  ];

  const handleRelatory = (meeting: IMeeting) => {
    setMeeting(meeting);
    setOpen(true);
  };

  const handleCloseRelatory = () => {
    setMeeting(null);
    setOpen(false);
  };

  const handleLocal = (local?: string) => {
    if(local)
      setTextLocal(local);

    setOpenLocal(true);
  };

  const handleCloseLocal = () => {
    setTextLocal('');
    setOpenLocal(false);
  };



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', margin:'-30px'}}>
        <Grid container spacing={2}>
          {isLoading && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={310} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isLoading && (data?.data && data.data?.length ? data.data.map((meeting) => (
          <Grid item key={meeting?.id_meeting} xs={12} sm={6} lg={4} xl={3}>
            <MentoryTabMeetingsItem meeting={meeting} />
          </Grid>
          )) : (
          <Grid item xs={12} >
            <Alert severity="warning">Não há sessões cadastradas</Alert>
          </Grid>
          ))}
        </Grid>
        {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })} />
        )}
    </Box>
  );
};

