import { LayoutDefault } from '../../shared/layouts';
import { VideosList } from './VideosList';

export const Videos = () => {

  return (
    <LayoutDefault>
      <VideosList/>
    </LayoutDefault>
  );
};
