import React, { useState , useEffect } from 'react';
import {Grid, Alert, Box, Card, CardContent, Skeleton } from '@mui/material/';


import { IObjective } from '../../shared/contexts/ObjectiveContext/types';

import { IMentory } from '../../shared/contexts/MentoringContext/types';

import { ObjectiveService } from '../../shared/services/api/objective/ObjectiveService';


import { MentoryTabDetailingDetail } from './MentoryTabDetailingDetail';
import { MentoryTabDetailingSkills } from './MentoryTabDetailingSkills';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';


interface IMentoryTabDetailingProps {
  mentory?:IMentory;
}  

export const MentoryTabDetailing: React.FC<IMentoryTabDetailingProps> = ({mentory}) => {

  const [valueTab, setValueTab] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  
  const { data, isLoading } = useQuery(['resources-human-objectives-list', [mentory?.id_mentory]],() =>   ObjectiveService.getObjectives(mentory?.id_mentory), {
    staleTime: Infinity,
    onSuccess: data => {
      if(data?.data && data.data.length && data.data[0]?.id_objective)
        setValueTab(parseInt(data.data[0].id_objective));
    },
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de competências.', { variant:'error'});
    },
  });

  return (
        <Box sx={{ flexGrow: 1, display: 'flex', margin:'-30px'}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={3}>  
              <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                <CardContent>
                  {isLoading && (
                    <>
                      <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
                      <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px', marginTop:'10px'}}/>
                      <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px', marginTop:'10px'}}/>
                    </>
                  )}
                  {!isLoading && (data?.data && data?.data?.length ? (
                  <MentoryTabDetailingSkills objectives={data?.data} valueTab={valueTab} handleChangeTable={(e) => setValueTab(e)} />
                  ) :
                  <Alert severity="warning">Não há competências!</Alert>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item  xs={12} md={12} lg={9}>  
              <Card sx={{borderRadius : 2, bgcolor: 'transparent', '& .MuiCardHeader-root':{border:'solid 1px', p:0, m:0}, '& .MuiCardHeader-subheader':{border:'solid 1px', p:0, m:0}}} variant='outlined' elevation={0}>
                <CardContent>
                  {isLoading && (
                    <>
                      <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'25px'}}>
                        <Skeleton animation="wave" variant="rounded" sx={{my:'15px'}} width={'40%'} height={40} />
                      </Box> 
                      <Box style={{ display: 'flex', justifyContent: 'center', marginBottom:'15px', width:'100%'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item zeroMinWidth sm={true}>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                                      </Grid> 
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>                    
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'100%'} height={12} />
                                      </Grid>
                                      <Grid item xs={12} >
                                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                      </Grid>
                                  </Grid>
                              </Grid>
                            </Grid>
                          </Grid> 
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item zeroMinWidth sm={true}>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                                      </Grid>                                      
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>                    
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'100%'} height={12} />
                                      </Grid>
                                      <Grid item xs={12} >
                                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                      </Grid>
                                  </Grid>
                              </Grid>
                            </Grid>
                          </Grid> 
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item zeroMinWidth sm={true}>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'90%'} height={18} />
                                      </Grid>                                      
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>                    
                                      <Grid item xs={12} sm={6} >
                                          <Skeleton variant="rectangular" width={150} height={15} />
                                      </Grid>
                                  </Grid>
                              </Grid>
                              <Grid item>
                                  <Grid container spacing={1} >
                                      <Grid item xs={12}>
                                          <Skeleton variant="rectangular" width={'100%'} height={12} />
                                      </Grid>
                                      <Grid item xs={12} >
                                      <Skeleton variant="circular" sx={{marginLeft:'10px'}} width={30} height={30} /> 
                                      </Grid>
                                  </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box> 
                    </>
                  )}
                  {!isLoading && (data?.data && data?.data?.length ? (
                    <MentoryTabDetailingDetail mentory={mentory} allObjectives={data?.data} valueTab={valueTab} />
                  ) :
                    <Alert severity="warning">Nenhuma competência foi cadastrada.</Alert>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
  );
};