import React, { useState , useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSnackbar } from 'notistack';

import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme, Grid, Skeleton, SwipeableDrawer, Drawer  } from '@mui/material/';


import { EASButton } from '../../shared/forms';

import AddIcon from '@mui/icons-material/Add';

import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { useMentoringContext } from '../../shared/contexts';
import { TaskPlanService } from '../../shared/services/api/task-plan/TaskPlanService';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';

import { TaskPlanNew } from './TaskPlanNew';
import { TaskPlanEdit } from './TaskPlanEdit';

import { EASCard, EASDialogAlert } from '../../shared/components';
import { TaskPlanItensContainer } from './TaskPlanItensContainer';
import { useMutation, useQuery, useQueryClient } from 'react-query';


interface ITaskPlanItensProps {
  children?: () => void;
}  
export const TaskPlanItens: React.FC<ITaskPlanItensProps> = () => {

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const { mentory } = useMentoringContext();

  const [openDialog, setOpenDialog] = useState(false);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [newOpen, setNewOpen] = useState(false);

  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [idTaskPlan, setIdTaskPlan] = useState<string>();
  const [detailings, setDetailings] = useState<Array<IList>>([]);
  const [taskPlan, setTaskPlan] = useState<ITaskPlan>({});



  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['task-plan-list'],() =>  TaskPlanService.getTaskPlansColumnGroup(mentory?.id_mentory, 3),  {
    //staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema ao tentar carregar suas tarefas.', { variant:'error'});
    },
  });
  const { isError } = useQuery(['task-plan-detailings'], () =>  UtilService.getDetailings(mentory?.id_mentory), {
    onSuccess: data => {
      setDetailings(data);
    },
    onError: () => {
      enqueueSnackbar('Ocorreu um problema ao tentar carregar seus objetivos.', { variant:'error'});
    },
  });

  const { mutateAsync:storePresentationOn } = useMutation((taskPlan11:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan11), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa adicionada na apreentação final com sucesso!', { variant:'success'});
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar adicionar a tarefa na apresentação final!', { variant:'error'});
    },
  });

  const { mutateAsync:storePresentationOff } = useMutation((taskPlan11:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan11), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa removida da apreentação final com sucesso!', { variant:'success'});
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a tarefa da apresentação final!', { variant:'error'});
    },
  });

  const handlePresentation = (task_plan:ITaskPlan, status:boolean) => {
    if(task_plan && task_plan.id_task_plan){
      task_plan.presentation_task_plan = status ? '1' : '0';
      if(status) 
        storePresentationOn(task_plan);
      else 
        storePresentationOff(task_plan);
    }
  }; 


  const { mutateAsync:storeFinisheOn } = useMutation((taskPlan:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa marcada como finalizada.', { variant:'success'});
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar marcada como finalizada!', { variant:'error'});
    },
  });

  const { mutateAsync:storeFinisheOff } = useMutation((taskPlan:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa marcada como não finalizada.', { variant:'success'});
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar marcada como não finalizada!', { variant:'error'});
    },
  });

  const handleFinished = async (task_plan:ITaskPlan, status:boolean) => {
    if(task_plan && task_plan.id_task_plan){
      task_plan.status_task_plan = status ? '2' : '3';
      if(status) 
        storeFinisheOn(task_plan);
      else 
        storeFinisheOff(task_plan);
    }
  }; 

  const { mutateAsync:storeUpdate } = useMutation((taskPlan:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar marcada como não finalizada!', { variant:'error'});
    },
  });

  const updateTaskPlan = (task_plan?:ITaskPlan) => {
    if (task_plan ) {
      storeUpdate(task_plan);
    }
  };

  const { mutateAsync:storeUpdateEdit } = useMutation((taskPlan:ITaskPlan) =>  TaskPlanService.updateTaskPlan(taskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Alterações salvas com sucesso.', { variant:'success'});
      setOpenDrawer(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar salvar alterações na tarefa!', { variant:'error'});
    },
  });
  

  const updateEditTaskPlan = (task_plan?:ITaskPlan) => {
    if (task_plan ) {
      storeUpdateEdit(task_plan);
    }
  };

  const setOpenEdit = (task_plan : ITaskPlan) => {
    if(task_plan){
      setTaskPlan(task_plan);
      setOpenDrawer(true);
    }
  }; 



  const { mutateAsync:storeNew } = useMutation((taskPlan:ITaskPlan) =>  TaskPlanService.createTaskPlan(taskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa criada com sucesso.', { variant:'success'});
      setNewOpen(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar criar nova tarefa!', { variant:'error'});
    },
  });
  
  const storeTaskPlan = (task_plan?:ITaskPlan) => {
    if (task_plan ) {
      storeNew(task_plan);
    }
  };



  const { mutateAsync:removeTask } = useMutation((idTaskPlan:string) =>  TaskPlanService.deleteTaskPlan(idTaskPlan), {
    onSuccess: () => {
      queryClient.invalidateQueries('task-plan-list');
      enqueueSnackbar('Tarefa removida com sucesso!', { variant:'success'});
      setNewOpen(false);
      setIsLoadingRemove(false);
      setIdTaskPlan('');
      setOpenDialog(false);       
      setOpenDrawer(false);      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover tarefa!', { variant:'error'});
      setIsLoadingRemove(false);
    },
  });
  const removeTaskPlan = (idTaskPlan?:string) => {
    if(idTaskPlan){
      setIdTaskPlan(idTaskPlan);
      setOpenDialog(true);
    }
  }; 

  const handleConfirmeRemove = () => {
    if(idTaskPlan){
      setIsLoadingRemove(true);
      removeTask(idTaskPlan);  
    }
  };




  return (

    <EASCard titleCard="Plano de Tarefas"
             bgcolorContent={theme.palette.background.default}
             actionCard={(mentory?.status_mentory === '1') ? (
                  <EASButton disabled={isLoading} isLoading={isLoading} variant='contained' onClick={() => setNewOpen(true)}  >Nova Tarefa&nbsp;<AddIcon /></EASButton>
                    ) : <></>}
    >
      {isLoading && (
        <Grid container spacing={2} sx={{mt:'5px'}}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} >
                <Skeleton variant="rectangular" sx={{borderRadius:'12px'}} width="100%" height={320} />
              </Grid>
              <Grid item xs={12} md={3} >
                <Skeleton variant="rectangular" sx={{borderRadius:'12px'}} width="100%" height={320} />
              </Grid>
              <Grid item xs={12} md={3} >
                <Skeleton variant="rectangular" sx={{borderRadius:'12px'}} width="100%" height={320} />
              </Grid>
              <Grid item xs={12} md={3} >
                <Skeleton variant="rectangular" sx={{borderRadius:'12px'}} width="100%" height={320} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!isLoading && (
      <Box sx={{mt: 2}}>
        <TaskPlanItensContainer 
          handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)}
          handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)}
          removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)}
          setOpenEdit={(idTaskPlan) => setOpenEdit(idTaskPlan)}
    
          columns={data} 
          updateTaskPlan={(e) => updateTaskPlan(e)} />
          <Drawer
            disableEnforceFocus
            anchor="right"
            open={openDrawer}       
          >
            <TaskPlanEdit Detailings={detailings} TaskPlan={taskPlan} setOpenDrawer={(e) => setOpenDrawer(e)} updateEditTaskPlan={(newTaskPlan) => updateEditTaskPlan(newTaskPlan)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} />
          </Drawer>
       
        <TaskPlanNew setOpen={(e) => setNewOpen(e)} open={newOpen} Detailings={detailings} storeTaskPlan={(newTaskPlan) => storeTaskPlan(newTaskPlan)} />
        
        <EASDialogAlert 
          type="warning"
          title="Excluir Tarefa"
          statusOpen={openDialog}
          actionCancel={() => setOpenDialog(false)}
          actionConfirme={handleConfirmeRemove}
          loading={isLoadingRemove}
        >
        <Typography>Você tem certeza que deseja excluir definitivamene esta tarefa?</Typography>
      </EASDialogAlert>
      </Box>
      )}
</EASCard>

      
  );
};