
import { Box, Card, CardContent, Typography } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { red, orange, blue, green, grey } from '@mui/material/colors';

interface IEASDashboardChartGaugeProps  {
    isLoading?:boolean;
    title?:string;
    subtitle?:string;
    description?:string;
    color?:string;
    background?:string;
    value?: string;
}


export const EASDashboardChartGauge:React.FC<IEASDashboardChartGaugeProps> = ({ ...props }) => {

    const value = props.value ? parseInt(props.value) : 0;

    const colorRender = (()=> { 
        switch(true) {
          case (value === 0): return grey[600];
          case (value > 0 && value < 11): return red[600];
          case (value > 10 && value < 41): return orange[600];
          case (value > 41 && value < 90): return blue[600];
          case (value > 90): return green[600];
          default: return null;
    }})();


    const option = {

        series: [
            {
              type: 'gauge',
              startAngle: 185,
              endAngle: -5,
              pointer: {
                show: false
              },
              progress: {
                show: true,
                overlap: false,
                clip: false,
                itemStyle: {
                  borderWidth: 0,
                  //borderColor: '#464646'
                  color:colorRender,
                }
              },
              axisLine: {
                lineStyle: {
                  width: 30
                }
              },
              splitLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
              },
              data: [
                      {
                        value: props.value,
                        detail: {
                          offsetCenter: ['0%', '-20%']
                        }
                      },
                    ],
              detail: {
                width: '100%',
                fontSize: '1.5rem',
                color: colorRender,
                formatter: '{value}%'
              }
            }
          ]
      };

    return (
        <Card sx={{border:'8px', background:props.background, color:props.color, position:'relative'}}>
            <CardContent sx={{p:'24px'}}>
                <Typography variant="h4" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.235}}>{props.title}</Typography>
                <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.subtitle}</Typography>
                <Box sx={{maxHeight:'200px', marginTop:'-30px'}}>
                    <ReactEcharts style={{width:'100%'}}
                        option={{
                            ...option,
                        }}
                    />
                </Box>
                <Box sx={{height:'60px'}}>
                    <Typography variant="h6" sx={{ fontSize: '0.75rem', lineheight:'1.57'}}>{props.description}</Typography>
                </Box>
            </CardContent>
        </Card>
    );
};



