import { IOrder } from '../../../contexts/OrdersContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface IOrdersService {
  data?: IOrder;
}

interface IOrdersListResponse extends IOrder {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IOrder> | null;
}

const createOrder = async (orders: IOrder): Promise<IOrdersService | Error> => {
  try {
    const { data } = await Api.post('/orders', { Order: orders } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateOrder = async (Order: IOrder): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/orders/${Order.id_user}`, Order);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateOrderAvatar = async (Order: IOrder, file:File): Promise<void | Error> => {
  try {

    const formData = new FormData();
    formData.append('image_avatar', file);
    const { data } = await  Api.post(`/orders/${Order.id_user}`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteOrder = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/orders/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getOrder = async (id: string): Promise<IOrdersService | Error> => {
  try {
    const { data } = await Api.get(`/orders/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getOrders = async (page = 1, filter = '' ): Promise<IOrdersListResponse | Error> => {
  try {
    const urlRelativa = `/orders?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const OrdersService = {
  getOrders,
  getOrder,
  createOrder,
  updateOrder,
  updateOrderAvatar,
  deleteOrder,
};
