import { Avatar, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Skeleton, Switch, Typography } from '@mui/material';
import { EASButton, EASInput, EASSelect, EASSwitch } from '../../shared/forms';
import { IList, UtilService, getVideo } from '../../shared/services/api/util/UtilService';
import { useEffect, useState } from 'react';
import { IVideo } from '../../shared/contexts/VideosContext/types';
import { useAuthContext } from '../../shared/contexts';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { VideosService } from '../../shared/services/api/videos/VideosService';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type IVideosNewVideo = {
  open: boolean;
  setOpen: (open:boolean) => void;
};

export const VideosNewVideo:React.FC<IVideosNewVideo> =  (({open, setOpen}) => { 

    
    const [saveLoading, setSaveLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    //const [isLoading, setIsLoading] = useState(true);
    
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);
    const [tumbVideo, setTumbVideo] = useState<string | null>(null);

   // const [tags, setTags] = useState<Array<IList>>([]);
    const [video, setVideo] = useState<IVideo>({});
    const [videoError, setVideoError] = useState<IVideo | null>(null);


    const { user } = useAuthContext();

    const { enqueueSnackbar } = useSnackbar();

    const queryClient = useQueryClient();

    const { data:tags, isLoading  } = useQuery(['tags-list'],() =>  UtilService.getTags(),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
      },
    });
  
    const { mutateAsync:storeNew } = useMutation((newVideo:IVideo) => VideosService.createVideo(newVideo), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-video-list');
            setVideo({});
            setSaveLoading(false);
            enqueueSnackbar('Vídeo cadastrado com sucesso!', { variant:'success'});
            setOpen(false);
            setTumbVideo(null);
        },
        onError: () => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar vídeo.', { variant:'error'});
        },
    });

    const isValidVideo = yup.object({
        name_video: yup.string().required('Este campo é obrigatório'),
        code_video: yup.string().required('Este campo é obrigatório'),
    });

    
    const saveVideo = () => {
        isValidVideo
        .validate( video , { abortEarly: false })
        .then(validateVideo => { 
            setSaveLoading(true);
            const newVideo: IVideo = {
                status_video: '1',
                name_video: validateVideo.name_video,
                code_video: validateVideo.code_video,
                description_video: video?.description_video,
                option_video: video?.option_video,
                tags: video?.tags,
                user_register: user?.id,
            };          
            storeNew(newVideo);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setVideoError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    const handleClose = () => {
      setVideo({});
      setVideoError(null);
      setTumbVideo(null);
      setOpen(false);
    };
    

    const getTumbVideo = (code_video:string) => {
        if(code_video){
            setIsLoadingVideo(true);
            getVideo(code_video)
            .then(videoResp => { 
                if(typeof videoResp !== 'boolean' && videoResp?.thumbnails)
                    setTumbVideo(videoResp?.thumbnails);
                setIsLoadingVideo(false);   
            })
            .catch(() => {
                setTumbVideo(null);
                setIsLoadingVideo(false);
            });
        }
      };
  

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Cadastrar Novo Vídeo</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                {saveLoading && (                   
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress variant='indeterminate' />
                    </Box>
                )}
                {!saveLoading && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            {isLoading && (
                                <Skeleton variant="rectangular" height={180} sx={{borderRadius:'8px'}}/>
                            )} 
                            {!isLoading && ( 
                            <>
                            {isLoadingVideo && (
                                <Box sx={{ p: 2, mb:3, borderRadius:'8px', height:180, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                                    <CircularProgress />
                                </Box>    
                            )}

                            {!isLoadingVideo  && ( 
                                tumbVideo ? (
                                <Avatar src={tumbVideo ? tumbVideo : ''} variant="rounded" sx={{width: '100%', height:180}} />
                                ):(
                                <Box sx={{ p: 2, mb:3, borderRadius:'8px', height:180, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                                    <Typography color="grey" variant="h6">Vídeo</Typography>
                                </Box>    
                                )
                            )}
                            </>
                                )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EASInput
                                            isLoading={isLoading}
                                            label="Código do Vídeo"
                                            type="text"
                                            value={video?.code_video}
                                            onChange={(e) => 
                                                setVideo(existingValues => ({
                                                    ...existingValues,
                                                    code_video: e.target.value,
                                                }))
                                            }
                                            error={!!videoError?.code_video}
                                            helperText={videoError?.code_video}
                                            onBlur={(e) => getTumbVideo(e.target.value) }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EASInput
                                            isLoading={isLoading}
                                            label="Nome"
                                            type="text"
                                            value={video?.name_video}
                                            onChange={(e) => 
                                                setVideo(existingValues => ({
                                                    ...existingValues,
                                                    name_video: e.target.value,
                                                }))
                                            }
                                            error={!!videoError?.name_video}
                                            helperText={videoError?.name_video}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <EASInput
                                            isLoading={isLoading}
                                            label="Descrição"
                                            type="text"
                                            multiline
                                            rows={3}
                                            value={video?.description_video}
                                            onChange={(e) => 
                                                setVideo(existingValues => ({
                                                    ...existingValues,
                                                    description_video: e.target.value,
                                                }))
                                            }
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <EASSwitch                  
                                            isLoading={isLoading}
                                            label="Usar nome e descrição do youtube"
                                            value={video?.option_video}
                                            onChange={(e) => 
                                            setVideo(existingValues => ({
                                                ...existingValues,
                                                option_video: e.target.checked ? '1' : '0',
                                            }))
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <EASSelect 
                                            isLoading={isLoading}
                                            options={tags ? tags : []}
                                            label="Tags do Vídeo"
                                            getOptionLabel={(option) => option.text}                                  
                                            value={(video?.tags && tags) && video?.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                                            onChangeSelect={(event, values:Array<IList>) => {
                                                const NewValue:Array<string> = [];
                                                values.map((value) => NewValue.push(value.value));
                                                setVideo(existingValues => ({
                                                    ...existingValues,
                                                    tags: NewValue,
                                                }));
                                                    } 
                                                } 
                                            fullWidth
                                            multipleSelect
                                            filterSelectedOptions
                                            disableClearable
                                            freeSolo      
                                        />
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box> 
        </DialogContent>
        <Divider/>
        <DialogActions>
            <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
            <EASButton variant="contained" onClick={saveVideo} disabled={saveLoading}>Confirmar</EASButton>
        </DialogActions>
    </Dialog>
  );
});