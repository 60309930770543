import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Stack, Avatar, TableHead, TableRow, TableBody, TableCell, TableContainer, Typography, Skeleton, Grid } from '@mui/material';


import { ICompany } from '../../shared/types';
import { CompaniesService } from '../../shared/services/api/companies/CompaniesService';

import { Environment } from '../../shared/environment';
import { CompaniesNewCompany } from './CompaniesNewCompany';
import { EASCard, EASDialogAlert, PaginationComponent} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const CompaniesList:React.FC = (() => {


  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);

  const [idCompany, setIdCompany] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-company-list', [search, page]],() =>  CompaniesService.getCompanies(page, search), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de Empresas.', { variant:'error'});
    },
  });

  const { mutateAsync:storeBlockCompany } = useMutation((blockCompany:ICompany) =>  CompaniesService.updateCompany(blockCompany), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-company-list');
      enqueueSnackbar('Empresa ' + (blockText ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' a empresa!', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemoveCompany } = useMutation((idCompany:string) => CompaniesService.deleteCompany(idCompany), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-company-list');
      enqueueSnackbar('Empresa removida com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a empresa!', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  
  const handleClickBlockCompany = (id?:string) => {
    if(id){
      setIdCompany(id);
      setTitleDialog('Bloquear Empresa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso desta empresa?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockCompany = (id?:string) => {
    if(id){
      setIdCompany(id);
      setTitleDialog('Desbloquear Empresa');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso desta empresa?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 

  const handleClickRemoveCompany = (id_user?:string) => {
    if(id_user){
      setIdCompany(id_user);
      setTitleDialog('Excluir Empresa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta empresa?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdCompany(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeCompany();
      break;
      case 'block':
        blockCompany(false);
      break;
      case 'unblock':
        blockCompany(true);
      break;
    }
  };


  const removeCompany = () => {
    setLoadingDialog(true);
    if(idCompany){
      storeRemoveCompany(idCompany);
    }
  };

  const blockCompany = (block:boolean) => {
    setLoadingDialog(true);
    if(idCompany){
      setBlockTest(block);
      const newBlockCompany:ICompany = {
        id_company:idCompany,
        block_company:(block ? '0' : '1'),
        status_company:(block ? '1' : '0')
      };
      storeBlockCompany(newBlockCompany);
    }
  };

  return (
    <>          
      <EASCard 
        titleCard="Empresas"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  CNPJ
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Cidade/UF
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((company) => (
              <TableRow 
                  hover 
                  key={company.id_company} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {company?.id_company}
                </TableCell>
                <TableCell scope="row" >
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar variant="square" alt={company?.razao_social_company} src={company?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + company?.logo_company  :  '/'} />
                    </Grid>
                    <Grid item zeroMinWidth sm={true} >
                      <Typography variant="subtitle2">
                        {company?.razao_social_company}
                      </Typography>
                      <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {company?.nome_fantasia_company}
                      </Typography>
                    </Grid>
                  </Grid> 
                </TableCell>
                <TableCell align="center">
                  {company?.cnpj_company}
                </TableCell>
                <TableCell align="center">
                    {company?.name_cidade_estado}
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Empresa"
                      onClick={() => navigate(`/empresas/detalhes/${company.id_company}`)} 
                      typeIcon="edit"
                  />
                  {company?.status_company == '0' ? (
                  <EASButtonList 
                      title="Desbloquear Pessoa"
                      onClick={() => handleClickUmBlockCompany(company.id_company)} 
                      typeIcon="unblock"
                  />
                  ) : (
                  <EASButtonList 
                    title="Bloquear Pessoa"
                    onClick={() => handleClickBlockCompany(company.id_company)} 
                    typeIcon="block"
                  />
                  )}
                  <EASButtonList 
                    title="Remover Empresa"
                    onClick={() => handleClickRemoveCompany(company.id_company)} 
                    typeIcon="remove"
                  />
                </TableCell>
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
              </TableBody>
            </Table>
          </TableContainer>
        {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
      <CompaniesNewCompany open={open} setOpen={(e) => setOpen(e)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

