import { useState, useEffect } from 'react';
import { CircularProgress, Box, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Link, Card }from '@mui/material';

import { IObjective, IObjectiveSample } from '../../shared/contexts/ObjectiveContext/types';
import { useMentoringContext } from '../../shared/contexts';
import { EASButton } from '../../shared/forms';

interface IDetailingObjectivesNewSampleProps {
  objectiveSample?: string;
  saveObjectiveSample:(Objective : IObjective) => void;
}

  
export const DetailingObjectivesNewSample: React.FC<IDetailingObjectivesNewSampleProps> = ({ objectiveSample, saveObjectiveSample }) => {
  

  const { mentory } = useMentoringContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    function loadEditObjectiveDetail() {
      if(objectiveSample){
        setOpenDialog(true);
      }
      
    }
    loadEditObjectiveDetail();
  }, [objectiveSample]);


  const selectSample = (obs_objective_sample?:string | boolean) => {
    setLoading(true);
    const objective: IObjective = {
      id_objective: '',
      id_mentory: mentory?.id_mentory,
      status_objective: '0',
      text_objective: objectiveSample,
      date_objective: new Date().toLocaleDateString('pt-BR'),
      
    }; 
    if(obs_objective_sample !== false && obs_objective_sample !== true)
      objective.sample_objective = obs_objective_sample;

    saveObjectiveSample(objective);

    setLoading(false);
    setOpenDialog(false);
  };

  return (
    <Dialog 
      open={openDialog}
      keepMounted
      onClose={() => setOpenDialog(false)}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <DialogTitle>{'Adicionar Ações Sugeridas'}</DialogTitle>
      <DialogContent>
        {loading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loading && (
        <Box style={{ display: 'flex', justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid container style={{ display: 'flex', justifyContent: 'center', padding:'10px 0 20px 0'}} spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
              <Typography sx={{textAlign: 'justify',  fontSize: '18px', marginBottom:'15px'}}>
                A competência <strong>{objectiveSample}</strong> contém sugestões de ações que uma pessoa excelente nesta competência faria. Você pode adicionar estas sugestões em seu programa. 
              </Typography> 
            </Grid> 
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}> 
              <Typography sx={{textAlign: 'justify',  fontSize: '18px', marginBottom:'15px'}}>
              <strong>Lembre-se de atribuir notas iniciais para cada ação, assim como definir as prioridades e excluir aquelas que não se aplicam à sua função.</strong>
              </Typography>  
            </Grid> 
            {
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <EASButton fullWidth variant='contained' onClick={() => selectSample(objectiveSample)}>Sim, adicione as ações sugeridas</EASButton>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <EASButton fullWidth variant='outlined' onClick={() => selectSample('')}>Não, adicione apenas a competência.</EASButton>
                </Grid>
              </Grid>
            } 
          </Grid> 
        </Box> 
        )}
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <Button onClick={() => selectSample(false)} variant="contained">Desistir</Button>
      </DialogActions>
    </Dialog>
  );
};
