import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { EASDashboardTips, EASDashboardLigthCard } from '../../shared/components/eas-dashboard-panels';
import { ChartsEvaluation, ChartsStage } from '../../shared/components';
import { IDashboardMentory } from '../../shared/types';
import { DashboardService } from '../../shared/services/api/dashboard/DashboardService';
import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';



interface IMentoryTabDashboardProps {
    mentory?:IMentory;
} 

export const MentoryTabDashboard: React.FC<IMentoryTabDashboardProps> = ({mentory}) => {

    const { enqueueSnackbar } = useSnackbar();

    const { data, isFetching, isLoading } = useQuery(['mentory-dashboard',  [mentory?.id_mentory, mentory?.id_module] ], () =>  DashboardService.getDashboardMentory( mentory?.id_mentory, mentory?.id_module, '1'), 
    {
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do dashboard.', { variant:'error'});
      },
    });
  
    return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Comportamentos"
                                icon="psychology"
                                value={data?.cards?.card1}
                                subtitle="Total de comportamentos"
                                backgroundIcon="transparent"
                                colorIcon="rgb(255, 10, 7)"
                                opcityIcon="1"
                                border="solid 1px #DEDEDE"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Sessões"
                                icon="group"
                                value={data?.cards?.card2}
                                subtitle="Total de sessões"
                                backgroundIcon="transparent"
                                colorIcon="rgb(0, 200, 83)"
                                opcityIcon="1"
                                border="solid 1px #DEDEDE"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Tarefas Planejadas"
                                icon="assignment_turned_in"
                                value={data?.cards?.card3}
                                subtitle="total de tarefas"
                                backgroundIcon="transparent"
                                colorIcon="rgb(33, 150, 243)"
                                opcityIcon=""
                                border="solid 1px #DEDEDE"
                            />
                        </Grid>
                        
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Avaliações"
                                icon="insert_chart"
                                value={data?.cards?.card4}
                                subtitle="total de avalições"
                                backgroundIcon="transparent"
                                colorIcon="rgb(153 33 243)"
                                opcityIcon="1"
                                border="solid 1px #DEDEDE"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Card sx={{border:'solid 1px #DEDEDE', borderRadius: '12px',}} elevation={0}>
                                <ChartsEvaluation title="Avaliações Parciais" values={data?.chart_evaluation} />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{display: 'flex'}}>
                            <Card sx={{border:'solid 1px #DEDEDE', borderRadius: '12px',}} elevation={0}>
                            
                                <Typography variant="h4" sx={{ fontSize: '1rem', fontWeight: 600, lineHeight: 1.235, textAlign: 'center', mt:4}}>Tempo de Processo</Typography>
                                <ChartsStage            
                                    description="Percentual de tempo levando em conta a data de início e previsão de término do processo."
                                    colorFixed="rgb(0, 200, 83)"
                                    value={data?.progress}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);
};