
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Hidden, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IRating } from '../../shared/contexts/RatingsContext/types';
import { RatingsService } from '../../shared/services/api/ratings/RatingsService';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { isValidCPF, UtilService, IList, uniqueCPF, uniqueMail } from '../../shared/services/api/util/UtilService';

import { useAuthContext } from '../../shared/contexts';




type IRatingsNewRatingProps = {
    open: boolean;
    setOpen: (open:boolean) => void;
    setUpdateList:() => void;
};



export const RatingsNewRating:React.FC<IRatingsNewRatingProps> =  (({open, setOpen, setUpdateList}) => {

    const [saveLoading, setSaveLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [rating, setRating] = useState<IRating | null>(null);
    const [ratingError, setRatingError] = useState<IRating | null>(null);
    const [interval, setInterval] = useState<Array<IList>>([]);
    const [period, setPeriod] = useState<Array<IList>>([]);
    const [repeat, setRepeat] = useState<Array<IList>>([]);

    const { user } = useAuthContext();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setRating(null);
      setOpen(false);
    };
  

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        async function getLists() {
            const intervalResponse = await UtilService.getInterval();
            setInterval(intervalResponse);
            const periodResponse = await UtilService.getPeriod();
            setPeriod(periodResponse);
            const repeatResponse = await UtilService.getRepeat();
            setRepeat(repeatResponse);
            setIsLoading(false);
        }
        getLists();        
    }, [open]);

    const isValidRating = yup.object({
        name_rating: yup.string().required('Este campo é obrigatório'),
        question_rating: yup.string().required('Este campo é obrigatório'),
        start_rating: yup.string().required('Este campo é obrigatório'),
        period_rating: yup.string().required('Este campo é obrigatório'),
        repeat_rating: yup.string().required('Este campo é obrigatório'),
    });

    const saveRating = () => {
        isValidRating
        .validate( rating , { abortEarly: false })
        .then(validateRating => { 
            setSaveLoading(true);
            if(validateRating) {
                const newRating: IRating = {
                    status_rating: '1',
                    name_rating: validateRating.name_rating,
                    question_rating: validateRating.question_rating,
                    start_rating: validateRating.start_rating,
                    period_rating: validateRating.period_rating,
                    repeat_rating: validateRating.repeat_rating,
                    limit_rating: rating?. limit_rating,
                    interval_rating: rating?.interval_rating,
                    user_register: user?.id,
                };            
                RatingsService.createRating(newRating)
                .then((RatingResponse) => {
                    if (RatingResponse instanceof Error) {
                        setSaveLoading(false);
                        enqueueSnackbar('Erro ao cadastrar pesquisa.', { variant:'error'});
                    } else {
                        enqueueSnackbar('Pesquisa cadastrada com sucesso!', { variant:'success'});
                        setOpen(false);
                        setUpdateList();
                        setSaveLoading(false);
                    }
                }).catch(() => {
                    setSaveLoading(false);
                    enqueueSnackbar('Erro ao cadastrar pesquisa.', { variant:'error'});
                });
            }
            else{
                setSaveLoading(false);
                enqueueSnackbar('Erro ao cadastrar pesquisa.', { variant:'error'});
            }
        })
        .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setRatingError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>Cadastrar Nova Pesquisa</DialogTitle>
            <Divider/>
            <DialogContent>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {saveLoading && (                   
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress variant='indeterminate' />
                        </Box>
                    )}
                    {!saveLoading && ( 
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={8}>
                                        <EASInput
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                name_rating: '',
                                                }))
                                            }
                                            label="Nome da Pesquisa"
                                            type="text"
                                            value={rating?.name_rating}
                                            onChange={(e) => 
                                                setRating(existingValues => ({
                                                    ...existingValues,
                                                    name_rating: e.target.value,
                                                }))
                                            }
                                            
                                            error={!!ratingError?.name_rating}
                                            helperText={ratingError?.name_rating}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <EASPicker 
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                start_rating: '',
                                                }))
                                            }
                                            onOpen={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                start_rating: '',
                                                }))
                                            }
                                            label="Data de Abertura"
                                            value={rating?.start_rating} 
                                            error={!!ratingError?.start_rating}
                                            helperText={ratingError?.start_rating}
                                            onChangePiker={(e) => {  
                                                if(e instanceof Date) {         
                                                setRating(existingValues => ({
                                                        ...existingValues,
                                                        start_rating: e.toISOString().split('T')[0],
                                                    }));
                                                }}
                                            }
                                            />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <EASInput
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                question_rating: '',
                                                }))
                                            }
                                            label="Pergunta"
                                            type='text'
                                            value={rating?.question_rating}                                
                                            onChange={(e) => 
                                                setRating(existingValues => ({
                                                    ...existingValues,
                                                    question_rating: e.target.value,
                                                }))
                                            }
                                            error={!!ratingError?.question_rating}
                                            helperText={ratingError?.question_rating}
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASSelect
                                            isLoading={isLoading}
                                            options={period}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                period_rating: '',
                                                }))
                                            }
                                            label="Escolha um Período" 
                                            getOptionLabel={(option) => option.text}
                                            value={period.find(item => item.value == rating?.period_rating)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                if (value && value.value) {
                                                    setRating(existingValues => ({
                                                        ...existingValues,
                                                        period_rating: value.value,
                                                    }));
                                                }
                                            }
                                            }  
                                            error={!!ratingError?.period_rating}
                                            helperText={ratingError?.period_rating}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASSelect
                                            isLoading={isLoading}
                                            options={repeat}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                repeat_rating: '',
                                                }))
                                            }
                                            label="Aplicar essa Pesquisa" 
                                            getOptionLabel={(option) => option.text}
                                            value={repeat.find(item => item.value == rating?.repeat_rating)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                if (value && value.value) {
                                                    setRating(existingValues => ({
                                                        ...existingValues,
                                                        repeat_rating: value.value,
                                                    }));
                                                }
                                            }
                                            }  
                                            error={!!ratingError?.repeat_rating}
                                            helperText={ratingError?.repeat_rating}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASSelect
                                            isLoading={isLoading}
                                            options={repeat}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                limit_rating: '',
                                                }))
                                            }
                                            label="Limite de Tentativas" 
                                            getOptionLabel={(option) => option.text}
                                            value={repeat.find(item => item.value == rating?.limit_rating)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                if (value && value.value) {
                                                    setRating(existingValues => ({
                                                        ...existingValues,
                                                        limit_rating: value.value,
                                                    }));
                                                }
                                            }
                                            }  
                                            error={!!ratingError?.limit_rating}
                                            helperText={ratingError?.limit_rating}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASSelect
                                            isLoading={isLoading}
                                            options={interval}
                                            onFocus={() => 
                                                setRatingError(existingValues => ({
                                                ...existingValues,
                                                interval_rating: '',
                                                }))
                                            }
                                            label="Escolha um Intervalo" 
                                            getOptionLabel={(option) => option.text}
                                            value={interval.find(item => item.value == rating?.interval_rating)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                if (value && value.value) {
                                                    setRating(existingValues => ({
                                                        ...existingValues,
                                                        interval_rating: value.value,
                                                    }));
                                                }
                                            }
                                            }  
                                            error={!!ratingError?.interval_rating}
                                            helperText={ratingError?.interval_rating}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
                <Button variant="contained" onClick={saveRating} disabled={saveLoading}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
});

