import  React,  { useState } from 'react';

import { useNavigate} from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { NotificationsService } from '../../services/api/notifications/NotificationsService';
import { useAuthContext, useModulesContext } from '../../contexts';
import { EASScrollbar } from '../../components';
import { INotification } from '../../types';
 

import {
  Box,
  List,
  Badge,
  Grid,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  CircularProgress,
} from '@mui/material';
import { maxHeight } from '@mui/system';


export const NotificationsPopover = () => {
  
  const { user } = useAuthContext();
  const { modules, markModule } = useModulesContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


  const { data, isLoading } = useQuery(['notification-popover-list', [user?.id, user?.email]],() =>  NotificationsService.getNotifications(user?.id, user?.email), {
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de Notificações.', { variant:'error'});
    },
  });

  const { mutateAsync:storeUpdateRead } = useMutation((blockCompany:INotification) =>  NotificationsService.updateNotification(blockCompany), {
    onSuccess: () => {
      queryClient.invalidateQueries('notification-popover-list');
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar marcar anotificação como lida!', { variant:'error'});
    },
  });


  const totalUnRead =  data?.data ? data.data.filter((item) => item.status_notification === '2').length : 0;

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNotification = (notification:INotification) => {

    if(notification.id_module &&  modules){
      const selectModule = modules.find(item => item.id_module == notification.id_module);
      if(selectModule)
        markModule(selectModule);
    }
      
    if(notification.navigator_notification)
      navigate(notification.navigator_notification);

    if(notification.status_notification == '2'){
      const updateNotification:INotification = {
        id_notification:notification.id_notification,
        status_notification:'1',
      };
      storeUpdateRead(updateNotification);
    }
  };
/*
  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };
*/
  return (
    
    <>



      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} >
        {isLoading && (
          <NotificationsIcon />
        )}
        {!isLoading && (
          <Badge badgeContent={totalUnRead} color="primary">
            <NotificationsIcon />
          </Badge>
        )}
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 6,
            width: 360,
            overflow: 'hidden',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
            {isLoading && (
              <Box sx={{display:'flex'}}>
                <CircularProgress size={14} color="inherit" sx={{mt:'3px', mr:'10px'}} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}> Procurando novas notificações</Typography>
              </Box>
                          )}
            {!isLoading && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {totalUnRead > 1 ? (
                <>Você tem {totalUnRead} novas notificações</>
              ):(totalUnRead > 1 ? (
                <>Você tem {totalUnRead} nova notificaçâo</>
              )
            :(<>Não há nenhuma nova notificaçâo</>))}
            </Typography>
            )}
          </Box>

          { /*!isLoading && totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          )*/}
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <EASScrollbar sx={{ maxHeight:'350px' }}>
          {!isLoading && data?.data && data.data.length >0 && (
          <List disablePadding >
            {data.data.map((notification) => (
              <NotificationItem key={notification.id_notification} notification={notification} handleNotification={(notification) => handleNotification(notification)} />
            ))}
          </List>
          )}
        </EASScrollbar>
{/*
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>*/}
      </Popover>
    </>
  );
};

interface INotificationItemProps {
  notification: INotification;
  handleNotification:(notification:INotification) => void;
}
const NotificationItem: React.FC<INotificationItemProps> = ({ notification, handleNotification }) => {

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status_notification == '2' && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => handleNotification(notification)}
    >
      <ListItemText
        primary={ 
          <Grid container spacing={2}>
            <Grid item xs={true}>
              <Typography variant="subtitle2" >{notification.name_notification}</Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography variant="caption" sx={{color: 'text.disabled',fontWeight: 600}}>
                {notification?.date_register && new Date(notification.date_register).toLocaleString('pt-BR', {dateStyle: 'short', timeStyle: 'short'}) }
              </Typography>  
            </Grid>
          </Grid>
        }
        secondary={
          <Grid component="span"container spacing={2}>
            <Grid component="span" item xs={true} sx={{ mt:0.5}}>
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                {notification.description_notification}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </ListItemButton>
  );
};

// ----------------------------------------------------------------------

