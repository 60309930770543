import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { TagsList } from './TagsItens';

export const Tags = () => {

  return (
    <LayoutDefault>  
      <TagsList />        
    </LayoutDefault>
  );
};

