
import React, { useImperativeHandle, useEffect, useState, Ref } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Skeleton, Avatar, Badge, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@mui/material';

import BackupIcon from '@mui/icons-material/Backup';

import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { IOrder } from '../../shared/contexts/OrdersContext/types';
import { OrdersService } from '../../shared/services/api/orders/OrdersService';

import { IPerson } from '../../shared/contexts/PeopleContext/types';

import { PeopleService } from '../../shared/services/api/people/PeopleService';

import { EASCheck } from '../../shared/forms';
import { EASFileUploadCrop } from '../../shared/components/eas-file-upload/EASFileUploadCrop';

import {  UtilService, IList } from '../../shared/services/api/util/UtilService';
import { Environment } from '../../shared/environment';


interface IAcceptFile{
    [key: string]: string[];
  }

type IOrderTabProps = {
    OrderID: string;
};

export interface IRefOrder {
    saveOrder: () => void;
}

export interface IOrderError {
	id_user?: string;
	id_user_group?: string;
}

const OrderTab = React.forwardRef<IRefOrder, IOrderTabProps>(({OrderID}:IOrderTabProps, ref:Ref<IRefOrder>) => {

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUpdateAvatar, setIsLoadingUpdateAvatar ] = useState(false);
    const [isLoadingUpload, setIsLoadingUpload ] = useState(false);
    
    const [blob, setBlod] = useState<Blob | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);


    const [userGroups, setUserGroups] = useState<Array<IList>>([]);
    const [order, setOrder] = useState<IOrder>({});
    const [orderError, setOrderError] = useState<IOrderError | null>(null);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getOrder() {
            setOrderError(null);
            setIsLoading(true);
            const userGroupsResponse = await UtilService.getUserGroups();
            setUserGroups(userGroupsResponse);
            const orderResponse = await OrdersService.getOrder(OrderID);
            if (orderResponse instanceof Error) {
                setOrder({});
                setIsLoading(false);
            } else {
                if(orderResponse){
                    const newOrder = JSON.parse(JSON.stringify(orderResponse));
                    setOrder(newOrder);
                    setIsLoading(false);
                }
                else{
                    setOrder({});
                    setIsLoading(false);
                }
            }
        }
        getOrder();
    }, []);

    useImperativeHandle(ref, () => ({
        saveOrder,
    }));

    const storeAvatar = () =>{


        if (blob && fileName) {
            
            setIsLoadingUpdateAvatar(true);
            setIsLoadingUpload(true);
            const newPerson:IPerson = {
                id_user:OrderID,
            };

            const newImage = new File([blob], fileName, { type: blob.type });

            PeopleService.updatePersonAvatar(newPerson, newImage)
            .then((PersonResponse) => {
                if (PersonResponse instanceof Error) {
                    setIsLoadingUpdateAvatar(false);
                    setIsLoadingUpload(false);
                    enqueueSnackbar('Erro ao salvar a imagem.', { variant:'error'});
                } else {
                    if(typeof PersonResponse === 'string')
                        setOrder(existingValues => ({
                            ...existingValues,
                            image_user: PersonResponse,
                        }));
                    enqueueSnackbar('Imagem salva com sucesso!', { variant:'success'});
                    setOpen(false);
                    setIsLoadingUpload(false);
                    setIsLoadingUpdateAvatar(false);
                }
            }).catch(() => {
                setIsLoadingUpdateAvatar(false);
                setIsLoadingUpload(false);
                enqueueSnackbar('Erro ao salvar a imagem.', { variant:'error'});
            });
        }
    };

    const isValidOrder = yup.object({
        id_user_group: yup.array().min(1,'Este campo é obrigatório').required('Este campo é obrigatório'),
    });
    

    const saveOrder = () => {
        isValidOrder
        .validate( order , { abortEarly: false })
        .then(validateOrder => { 
            setIsLoading(true);
            if(validateOrder) {

                const newOrder: IOrder = {
                    id_user: OrderID,
                    id_user_group: validateOrder.id_user_group,
                };            
                OrdersService.updateOrder(newOrder)
                .then((OrderResponse) => {
                    if (OrderResponse instanceof Error) {
                        setIsLoading(false);
                        enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
                    } else {
                        enqueueSnackbar('Operador cadastrado com sucesso!', { variant:'success'});
                        setIsLoading(false);
                    }
                }).catch(() => {
                    setIsLoading(false);
                    enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
                });
            }
            else{
                setIsLoading(false);
                enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
            }
        })
        .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setOrderError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };
    
  const NewacceptFile : IAcceptFile = {'image/png':['.png'],
                                       'image/jpg': ['.jpg'], 
                                       'image/jpeg': ['.jpeg']};


    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                        <CardHeader
                            title='Avatar'
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            {(isLoading || isLoadingUpdateAvatar )  && (
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                    <Skeleton variant="circular" width={120} height={120} />
                                </Box>
                            )}
                            {(!isLoading && !isLoadingUpdateAvatar) && (
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 1}}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                                                <BackupIcon />
                                            </Fab> 
                                        }
                                    >
                                        
                                    </Badge>
                                    <Typography sx={{ marginTop: 2}} variant="h6">Nome XXXX</Typography>
                                </Box>
                            </Box>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                        <CardHeader
                            title="Permissões do Operador"
                            titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sx={{ marginTop: 2}}>
                                    <EASCheck
                                        label="Nível de Acesso"
                                        isLoading={isLoading}
                                        onFocus={() => 
                                            setOrderError(existingValues => ({
                                            ...existingValues,
                                            id_user_group: '',
                                            }))
                                        }
                                        options={userGroups}
                                        value={order?.id_user_group}
                                        onChange={(e) =>{
                                            setOrderError(existingValues => ({
                                            ...existingValues,
                                            id_user_group: '',
                                            }));
                                            const newUserGroup:Array<string> = order?.id_user_group?.length ? 
                                                (e.target.checked === false ? 
                                                    order.id_user_group.filter(item => item !== e.target.name)
                                                    : order.id_user_group
                                                ) : [];

                                            if(e.target.checked === true)
                                                newUserGroup.push(e.target.name);

                                            setOrder(existingValues => ({
                                                ...existingValues,
                                                id_user_group:newUserGroup
                                            })) ;
                                        }}
                                        error={!!orderError?.id_user_group}
                                        helperText={orderError?.id_user_group}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enviar Imagem</DialogTitle>
                <Divider/>
                <DialogContent>
                    <DialogContentText>
                        <Box sx={{maxWidth:'380px'}}>
                            <Typography variant="subtitle2">
                                Adicione ou atualize a imagem da pessoa. Recomendamos imagens quadradas.
                            </Typography>
                        </Box>
                    </DialogContentText>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3}}>
                        {/*
                        <EASFileUploadCrop 
                            titleButton="Enviar Arquivo"
                            acceptFile={NewacceptFile}
                            isLoadingUpload={isLoadingUpload}
                            elementIsDragAccept={<Box sx={{p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed green', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                            elementIsDragReject={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed red', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                            elementIsNotDragActive={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                            setBlod={(e) => setBlod(e)}
                            setFileName={(e) => setFileName(e)}
                        />
                        */}
                     </Box>            
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>Cancelar</Button>
                    <Button variant="contained" onClick={storeAvatar} disabled={isLoadingUpload}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </Box>
        
    );
});

OrderTab.displayName = 'OrderTab';

export default OrderTab;