//import { useState } from 'react';
import { styled, Box, Button, Grid, Card, Avatar, Container, CardContent, LinearProgress, Typography,  useTheme,} from '@mui/material';
//Theme, useMediaQuery,

import { EASCard } from '../eas-card/EASCard';



import { useAuthContext,  useCourseContext, useTeamContext, useEASThemeContext  } from '../../contexts';
import { ITeam } from '../../contexts/TeamContext/types';

//useEASThemeContext,
const ContentStyle = styled('div')({
  maxWidth: 640,
  margin: 'auto',
  
  minHeight: '100vh',
  display: 'flex',
  //flexDirection: 'column',
  textAlign: 'center', 
  alignItems: 'center',
});


interface ILoginProps {
  children: React.ReactNode;
}


export const EASTeam: React.FC<ILoginProps> = ({ children }) => {
 
 
 
  const theme = useTheme();
  
  //const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  //const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  //const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  //const [themeName, setThemeName] = useState<'light' | 'dark' | 'contrast' >('light');
  
  const { themeName } = useEASThemeContext();
  
  const { ...user } = useAuthContext();

  const { ...enrollment } = useCourseContext();

  const { isSelected, allTeams, accessTeam } = useTeamContext();

  //const [isLoading, setIsLoading] = useState(false);

  const handleClickTeam = (team ?: ITeam) => {
      if(team)
        accessTeam(team);
  };

  if (isSelected) return (
    <>{children}</>
  );

  if (isSelected === false) 
  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Nenhum Curso Encontrado
            </Typography>

            <Box width="100%" sx={{ mt: theme.spacing(1)}} height={theme.spacing(15)} display="flex" alignItems="center" justifyContent="center">
              <Avatar
                sx={{ border:'2.4px solid #019DAA', height: theme.spacing(12), width: theme.spacing(12) }}
                src={user.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + user.avatar : ''}
              />
            </Box>
            <Box width="100%" display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{mb:'0px'}}>
                Olá {user.name}
              </Typography>
            </Box>

            <Typography sx={{ color: 'text.secondary' }}>
              Infelizmente não conseguimos localizar nenhum curso para este app, que esteja vinculado a esta conta Microsoft.
              Se o seu curso está vinculado a sua conta Microsoft, modifique o usuário ativo em Gerenciar Contas ou execute logoff/login.
            </Typography>

            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );

  if (isSelected === null && allTeams != null && allTeams.length > 1) 
    return (
      <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}} >
        <Container>
          <ContentStyle>
            <EASCard>
              <Typography variant="h6" paragraph sx={{mb:'0px'}}>
                Escolha sua Dupla
              </Typography>
              <Box width="100%" height={theme.spacing(15)} display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  sx={{ border:'2.4px solid #019DAA', height: theme.spacing(12), width: theme.spacing(12) }}
                  src={user.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + user.avatar : ''}
                />
              </Box>
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Typography sx={{mb:'0px'}}>
                  Olá {user.name}
                </Typography>
              </Box>
              <Typography sx={{ color: 'text.secondary',mb:'10px' }}>
                Encontramos os processos abaixo para o programa {enrollment.name_evento}.
              </Typography>
              <Typography sx={{ color: 'text.secondary',mb:'10px' }}>
                Escolha um para continuar.
              </Typography>
              
              <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                {allTeams.map((team, key) => {
                  return (
                    <Grid item xs={12} sm={6} key={key} >
                      <Button sx={{ width:'100%', height:'100%', p:0, m:0 }} onClick={() => handleClickTeam(team)} variant='contained'>
                        <Card key={key} sx={{ display: 'inline-block', width:'100%', height:'100%', backgroundColor: theme.palette.secondary.light, }} >
                          <CardContent sx={{display: 'flex', alignItems: 'center', paddingTop: theme.spacing(2), '&:last-child': { paddingBottom: theme.spacing(2)}}}>
                            <Avatar
                                sx={{ border:'2.4px solid #019DAA', height: theme.spacing(8), width: theme.spacing(8) }}
                                src={team.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + team.avatar : ''}
                            />
                            <Box px={2} sx={{lineHeight: 1, textAlign:'left'}} >
                                <Typography variant="h6" sx={{lineHeight: 1, fontSize:'12px', textAlign:'left'}} >
                                    {team.name}
                                </Typography>
                                <Typography variant="caption" color="textSecondary" sx={{lineHeight: 1, textAlign:'left'}}>
                                    {team.type_tean=='1' ? 'Mentorado' : 'Mentor'}
                                </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Button>
                    </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </EASCard>
          </ContentStyle>
        </Container>
      </Box> 
    );


  return (
    <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{overflowX:'hidden',}}>
      <Container>
        <ContentStyle>
          <EASCard>
            <Typography variant="h4" paragraph>
              Carregando Duplas!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Aguarde uns instantes, estamos carregando informações de sua(s) dupla(s) neste curso.
              Este processo näo deve demorar mais que alguns segundos.
            </Typography>
            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{  mx: 'auto', my: { xs: 5, sm: 5 } }}
            />
            <LinearProgress color="primary" />
          </EASCard>
        </ContentStyle>
      </Container>
    </Box> 
  );
};
