import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IVideo, IVideosList } from '../../../contexts/VideosContext/types';


const getVideo = async (id:string): Promise<IVideo> => {
    const { data } = await Api.get(`/videos/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getVideos = async (page = 1, search = '', id_mentory = '', id = '' ): Promise<IVideosList> => {
    const  {data} = await Api.get(`/videos?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}&mentory=${id_mentory}&id=${id}`);
    return data;
};

const updateVideo = async (Video: IVideo): Promise<void | Error> => {
    const { data } = await  Api.put(`/videos/${Video.id_video}`, Video);
    return data;  
};

const deleteVideo = async (id: string): Promise<void> => {
    const { data } = await Api.delete(`/videos/${id}`);
    return data;   
};

const createVideo = async (video: IVideo): Promise<IVideo> => {
    const { data } = await Api.post('/videos', { Video: video } );
    return data;
};


export const VideosService = {
  getVideo,
  getVideos,
  updateVideo,
  deleteVideo,
  createVideo
};
