

import React, {useCallback, useState,  useEffect, ReactElement, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import { Box, Button } from '@mui/material';

interface IAcceptFile{
  [key: string]: string[];
}


interface IEASFileUploadProps {
  onSelected: (file: File) => void;
  acceptFile?:IAcceptFile;
  elementIsDragAccept?: ReactElement;
  elementIsDragReject?: ReactElement;
  elementIsNotDragActive?: ReactElement;
  elementIsDragActive?: ReactElement;
  titleButton?: string;
}
  
export const EASFileUploadDropZone:React.FC<IEASFileUploadProps> = ({
  onSelected, 
  acceptFile, 
  elementIsDragAccept,
  elementIsDragReject,
  elementIsNotDragActive,
  elementIsDragActive,
  titleButton
}) => {
  
  const NewacceptFile : IAcceptFile = {'image/png':['.png'],
                                       'image/jpg': ['.jpg'], 
                                       'image/jpeg': ['.jpeg']};

  const [acceptFiles, setAcceptFiles] = useState<IAcceptFile>(NewacceptFile);

  useEffect(() => {
      if(acceptFile)
        setAcceptFiles(acceptFile);
  }, []);


  const onSelectedRef = useRef(onSelected);
    onSelectedRef.current = onSelected;


  const dropHandler = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length < 1) {
      return;
    }
    const file = acceptedFiles[0];
    if (!file) return;
    onSelectedRef.current(file);
  }, []);

  
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept:acceptFiles,
  /* accept: {
    'image/png': ['.png'],
    'image/jpg': ['.jpg'],
    'image/jpeg': ['.jpeg'],
    },*/
    onDrop: dropHandler
  });

  return (
    <>    
      <Box component="span">
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
            {elementIsDragAccept && isDragAccept && (elementIsDragAccept)}
            {elementIsDragReject && isDragReject && (elementIsDragReject)}
            {elementIsNotDragActive && !isDragActive && (elementIsNotDragActive)}
            {elementIsDragActive && isDragActive && (elementIsDragActive)}
        </div>
      </Box>
      {titleButton && (
        <Box sx={{mt:5}}>
          <Button variant="contained" onClick={open}>{titleButton}</Button>
        </Box>
      )}
    </>
  );
  };