import React, { useState , useEffect } from 'react';

import { Alert, Avatar, CircularProgress, Paper, Skeleton, Typography} from '@mui/material';
import { ResultsPresentationService } from '../../shared/services/api/results-presentation/ResultsPresentationService';
import { useMentoringContext } from '../../shared/contexts';
import { IResultsPresentation } from '../../shared/contexts/ResultsPresentationContext/types';
import { Environment } from '../../shared/environment';
import { PresentationFinalComparation } from './PresentationFinalComparation';
import { PresentationFinalTaskPlan } from './PresentationFinalTaskPlan';
import { PresentationFinalMaintencePlanLesson } from './PresentationFinalMaintencePlanLesson';

import {
  FlexBox,
  Heading,
  AnimatedProgress,
  Slide,
  Deck,
  Text,
  Grid,
  Box,
  Quote,
} from 'spectacle';

// SPECTACLE_CLI_THEME_START
const theme = {
  fonts: {
    header: '"Open Sans Condensed", Helvetica, Arial, sans-serif',
    text: '"Open Sans Condensed", Helvetica, Arial, sans-serif'
  }, 
  backdropStyle: {
    position:'relative', 
    width:'100%', 
    height:'100%', 
    maxHeight: 'calc(100vh - 200px)'
  },
  sizes:{
    width:'50%', 
    height:'7%', 
  }, 
  letterSpacings:{
    letterSpacings:'5',
  },
};

interface IPresentationFinalProps {
  toPrint:boolean;
}

export const PresentationFinal:React.FC<IPresentationFinalProps> = ({toPrint}) => {

  const { mentory } = useMentoringContext();
 
  const [isLoading, setIsLoading] = useState(true);
  const [allResultsPresentation, setAllResultsPresentation] = useState<IResultsPresentation | null>(null);

  const min = (mentory?.min_grade_mentory ? parseFloat(mentory.min_grade_mentory) : 0);
  const max = (mentory?.max_grade_mentory ? parseFloat(mentory.max_grade_mentory) : 10);

  const template = () => (
    <FlexBox
      justifyContent="space-between"
      position="absolute"
      bottom={0}
      width={1}
    >
      {toPrint===false && (
      <Box padding="1em" margin="auto">
        <AnimatedProgress color="#000000" />
      </Box>
      )}
    </FlexBox>
  );

  useEffect(() => {
    async function getResultsPresentation() {
      if(mentory?.id_mentory){
        setIsLoading(true);
        const resultsPresentationResponse = await ResultsPresentationService.getAllResultsPresentation(mentory?.id_mentory, 0, 0);
        if (resultsPresentationResponse instanceof Error) {
          setAllResultsPresentation(null);
         // setTotalCount(0);
          setIsLoading(false);
        } else {
          if(resultsPresentationResponse.data && resultsPresentationResponse.total){
            const myResultsPresentation = JSON.parse(JSON.stringify(resultsPresentationResponse.data));          
              setAllResultsPresentation(myResultsPresentation);
              setIsLoading(false);
           // if(resultsPresentationResponse.total)
             // setTotalCount(resultsPresentationResponse.total); 
              
          }
          else{
            setAllResultsPresentation(null);
            //setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getResultsPresentation();
  }, []);





  return (
    <>
    {isLoading && (
      <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={610} />
    )}
    {!isLoading && (allResultsPresentation?.items && allResultsPresentation?.items.length>0 ? (      
      <>

        <Deck theme={theme} template={template} >
          <Slide backgroundImage="url(/slide-resultados.png)" backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#FFFFFF')}>
            <Box backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.variant1) ? (allResultsPresentation.color_palette.variant1) : '#019DAA')} position="absolute" bottom="0px" right="0px" width="650px" height="350px"></Box>
            <Box backgroundColor="trasnparent" position="absolute" top="280px" left="42%" width="700px">
              <Text fontWeight="900" fontSize="72px" color="#000000">Apresentação de Resultados</Text>
            </Box> 
          </Slide>
          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#FFFFFF')}>
            <Box  backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.variant1) ? (allResultsPresentation.color_palette.variant1) : '#019DAA')} 
                  position="absolute" 
                  top="280px" 
                  left="0px" 
                  width="250px" 
                  height="150px">
            </Box>
            <Box backgroundColor={(( allResultsPresentation && 
                                    allResultsPresentation.color_palette && 
                                    allResultsPresentation.color_palette.variant8) ? 
                                      (allResultsPresentation.color_palette.variant8) : 
                                      '#FFFFFF'
                                    )} 
                  position="absolute" 
                  top="0px" 
                  left="40%" 
                  width="850px" 
                  height="1000px">
            </Box>
            <Box backgroundColor="trasnparent" position="absolute" top="275px" left="35px" width="200px">
              <Text fontWeight="900" fontSize="66px" color="#000000">Dados</Text>
            </Box> 

            <Box backgroundColor="trasnparent" position="absolute" top="20px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="36px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Programa
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="80px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="28px"
                    color="#000000">
                  {allResultsPresentation?.name_mentory}
              </Text>
            </Box>
            <Box backgroundColor="trasnparent" position="absolute" top="200px" left="45%" width="700px">
              <Avatar sx={{width:80, height:80}}  alt={mentory?.name_mentorado} src={mentory?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentory?.image_mentorado  :  ''} />
            </Box>
            <Box backgroundColor="trasnparent" position="absolute" top="180px" left="50%" width="700px">
              <Text fontWeight="700" 
                    fontSize="28px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Mentorado
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="210px" left="50%" width="700px">
              <Text fontWeight="700" 
                  fontSize="22px"
                  color="#000000">
                {mentory?.name_mentorado}
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="320px" left="45%" width="700px">
              <Avatar
                sx={{ border:'3px solid ' + (( allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.variant1) ? (allResultsPresentation.color_palette.variant1) : '#019DAA'), height: '80px', width: '80px' }} 
                alt={mentory?.name_mentor} src={mentory?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentory?.image_mentor  :  ''} 
              />
            </Box>
  
            <Box backgroundColor="trasnparent" position="absolute" top="300px" left="50%" width="700px">
              <Text fontWeight="700" 
                    fontSize="28px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Mentor
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="330px" left="50%" width="700px">
                <Text fontWeight="700" 
                    fontSize="22px"
                    color="#000000">
                  {mentory?.name_mentor}
                </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="450px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Competências Eleitas
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="450px" left="65%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px"
                    color="#000000">
                  {allResultsPresentation?.total_objective}
              </Text>
            </Box>

            <Box backgroundColor="trasnparent" position="absolute" top="490px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Competências Detalhadas
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="490px" left="65%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px"
                    color="#000000">
                  {allResultsPresentation?.total_objective_detail}
              </Text>
            </Box>

            <Box backgroundColor="trasnparent" position="absolute" top="530px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Prioridades
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="530px" left="65%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px"
                    color="#000000">
                  {allResultsPresentation?.total_priority_objective_detail}
              </Text>
            </Box>
            <Box backgroundColor="trasnparent" position="absolute" top="570px" left="42%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px" 
                    color={(( allResultsPresentation && 
                              allResultsPresentation.color_palette && 
                              allResultsPresentation.color_palette.variant1) ? 
                                (allResultsPresentation.color_palette.variant1) : 
                                '#019DAA'
                            )}>
                Tarefas Finalizadas
              </Text>
            </Box> 
            <Box backgroundColor="trasnparent" position="absolute" top="570px" left="65%" width="700px">
              <Text fontWeight="700" 
                    fontSize="22px"
                    color="#000000">
                  {allResultsPresentation?.total_task_plan_finished}
              </Text>
            </Box>
          </Slide>
          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#000000')}>
              <Heading fontWeight="400" fontSize="36px" margin="0px"  color="#000000">COMPETÊNCIAS ORGANIZACIONAIS ELEITAS</Heading>
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="130px" 
                  borderRadius="50%"
                  left="450px"
                  width="10px"
                  height="10px">
              </Box>
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="134px" 
                  left="455px"
                  width="452px"
                  height="2px">
              </Box>
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="130px" 
                  borderRadius="50%"
                  right="450px"
                  width="10px"
                  height="10px">
              </Box>
              
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="190px" 
                  borderRadius="50%"
                  left="680px"
                  width="10px"
                  height="10px">
              </Box>
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="192px" 
                  left="684px"
                  width="2px"
                  height="322px">
              </Box>
              <Box backgroundColor="#858585" 
                  position="absolute" 
                  top="510px" 
                  borderRadius="50%"
                  left="680px"
                  width="10px"
                  height="10px">
              </Box>

              {(allResultsPresentation && 
                allResultsPresentation.items && 
                allResultsPresentation.items.length) && 
                  (allResultsPresentation.items.map((resultsPresentation, index) => (
              
              <div key={resultsPresentation.id_objective} >
                <Box backgroundColor="#e9e9e9" 
                    position="absolute" 
                    top={(240 + (Math.trunc(index/2)*100)) + 'px'} 
                    left={(index%2) == 0 ? '200px' : '760px'}  
                    width="400px" 
                    height="60px">
                </Box>
                <Box backgroundColor="#e9e9e9" 
                    borderRadius="50%" 
                    position="absolute" 
                    top={(240 + (Math.trunc(index/2)*100)) + 'px'} 
                    left={(index%2) == 0 ? '570px' : '1130px'} 
                    width="60px" 
                    height="60px">
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      borderRadius="50%" 
                      position="absolute" 
                      top={(240 + (Math.trunc(index/2)*100)) + 'px'} 
                      left={(index%2) == 0 ? '170px' : '730px'}   
                      width="60px" 
                      height="60px">
                </Box>

                <Box backgroundColor="trasnparent" 
                    position="absolute" 
                    top={(225 + (Math.trunc(index/2)*100)) + 'px'} 
                    left={(index%2) == 0 ? '210px' : '770px'}   
                    width="400px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        color="#000000">
                      {resultsPresentation.text_objective}
                  </Text>
                </Box>
              </div>
            )))}
          </Slide>

          

          
          {(allResultsPresentation && 
            allResultsPresentation.items && 
            allResultsPresentation.items.length) && 
            (allResultsPresentation.items.map((Objective, index) => {
              if( allResultsPresentation && 
                  allResultsPresentation.items && 
                  (
                    (index > 0 && (index+1) % 2 == 0)
                    ||
                    (index == allResultsPresentation.items.length -1)
                  ) 
                ){
                return (<PresentationFinalComparation  indexKey={index} Key={index}
                                                          Objectives={allResultsPresentation.items.slice((index == allResultsPresentation.items.length -1 && !(index > 0 && (index+1) % 2 == 0) ? index : index-1), (index + 1) )} 
                                                          primary={((allResultsPresentation && 
                                                                      allResultsPresentation.color_palette && 
                                                                      allResultsPresentation.color_palette.variant1) ? 
                                                                      allResultsPresentation.color_palette.variant1 : 
                                                                      '#000000')} 
                                                          background={((allResultsPresentation && 
                                                                        allResultsPresentation.color_palette && 
                                                                        allResultsPresentation.color_palette.background) ? 
                                                                        allResultsPresentation.color_palette.background : 
                                                                        '#000000')} 
                />);

              } 
            }
          ))}


          {(allResultsPresentation && 
            allResultsPresentation.task_plan_finished && 
            allResultsPresentation.task_plan_finished.length) && 
            (allResultsPresentation.task_plan_finished.map((item, index) => {
              

              if( allResultsPresentation && 
                  allResultsPresentation.task_plan_finished && 
                  (
                    (index > 0 && (index+1) % 2 == 0)
                    ||
                    (index == allResultsPresentation.task_plan_finished.length -1)
                  ) 
                ){
                  
                  
                return (<PresentationFinalTaskPlan key={index} 
                                                      taskPlans={allResultsPresentation.task_plan_finished.slice((index == allResultsPresentation.task_plan_finished.length -1 && !(index > 0 && (index+1) % 2 == 0) ? index : index-1), (index + 1) )} 
                                                      primary={((allResultsPresentation && 
                                                                allResultsPresentation.color_palette && 
                                                                allResultsPresentation.color_palette.variant8) ? 
                                                                allResultsPresentation.color_palette.variant8 : 
                                                                '#000000')} 
                                                      background={((allResultsPresentation && 
                                                                    allResultsPresentation.color_palette && 
                                                                    allResultsPresentation.color_palette.background) ? 
                                                                    allResultsPresentation.color_palette.background : 
                                                                    '#000000')} 
                />);

              } 
            }
          ))}


          {(allResultsPresentation && 
            allResultsPresentation.maintenance_plan && 
            allResultsPresentation.maintenance_plan.lessons && 
            allResultsPresentation.maintenance_plan.lessons.length) && 
            (allResultsPresentation.maintenance_plan.lessons.map((item, index) => {
              

              if( allResultsPresentation && 
                  allResultsPresentation.maintenance_plan && 
                  allResultsPresentation.maintenance_plan.lessons && 
                  (
                    (index > 0 && (index+1) % 2 == 0)
                    ||
                    (index == allResultsPresentation.maintenance_plan.lessons.length -1)
                  ) 
                ){
                  
                  
                return (<PresentationFinalMaintencePlanLesson key={index} 
                                                              lessons={allResultsPresentation.maintenance_plan.lessons.slice((index == allResultsPresentation.maintenance_plan.lessons.length -1 && !(index > 0 && (index+1) % 2 == 0) ? index : index-1), (index + 1) )} 
                                                              primary={((allResultsPresentation && 
                                                                        allResultsPresentation.color_palette && 
                                                                        allResultsPresentation.color_palette.variant8) ? 
                                                                        allResultsPresentation.color_palette.variant8 : 
                                                                        '#000000')} 
                                                              background={((allResultsPresentation && 
                                                                            allResultsPresentation.color_palette && 
                                                                            allResultsPresentation.color_palette.background) ? 
                                                                            allResultsPresentation.color_palette.background : 
                                                                            '#000000')} 
                />);

              } 
            }
          ))}


          {allResultsPresentation?.maintenance_plan && (allResultsPresentation?.maintenance_plan.routine_maintenance_plan || allResultsPresentation?.maintenance_plan.team_maintenance_plan) && (
          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#000000')}>
            <Heading fontWeight="600" fontSize="28px" margin="0px"  color="#000000">
              Estratégias / ações que estão sendo executadas para a sustentabilidade do desenvolvimento após o processo de Mentoria:
            </Heading>
            <Paper elevation={2} sx={{position:'absolute', width: '1000px', height: '520px', left: '200px', top:'150px', borderRadius:'12px'}}>
              <Box backgroundColor="trasnparent" 
                  position="absolute" 
                  top="20px"
                  left="20px"
                  width="940px"
                  height="480px"
                  style={{flexDirection:'column', justifyContent:'center'}}
                  display="flex">
                {allResultsPresentation?.maintenance_plan.routine_maintenance_plan && (
                  <Box backgroundColor="trasnparent" 
                      verticalAlign="center"
                      marginBottom="20px">   
                    <Typography variant="h6">Rotina</Typography>               
                    <Quote fontWeight="200"
                          fontSize="18px"
                          margin="auto 0"
                          color="#000000"
                        >
                      {allResultsPresentation?.maintenance_plan.routine_maintenance_plan}
                    </Quote>                
                  </Box> 
                )}
                {allResultsPresentation?.maintenance_plan.team_maintenance_plan && (
                  <Box backgroundColor="trasnparent" 
                      verticalAlign="center"
                      marginBottom="20px">   
                    <Typography variant="h6">Equipe</Typography>               
                    <Quote fontWeight="200"
                          fontSize="18px"
                          margin="auto 0"
                          color="#000000"
                        >
                      {allResultsPresentation?.maintenance_plan.team_maintenance_plan}
                    </Quote>                
                  </Box> 
                )}
              </Box>
            </Paper>
          </Slide>
          )}
          {allResultsPresentation?.maintenance_plan && (allResultsPresentation?.maintenance_plan.leader_maintenance_plan || allResultsPresentation?.maintenance_plan.clients_and_peers_maintenance_plan) && (
          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#000000')}>
            <Heading fontWeight="600" fontSize="28px" margin="0px"  color="#000000">
              Estratégias / ações que estão sendo executadas para a sustentabilidade do desenvolvimento após o processo de Mentoria:
            </Heading>
            <Paper elevation={2} sx={{position:'absolute', width: '1000px', height: '520px', left: '200px', top:'150px', borderRadius:'12px'}}>
              <Box backgroundColor="trasnparent" 
                  position="absolute" 
                  top="20px"
                  left="20px"
                  width="940px"
                  height="480px"
                  style={{flexDirection:'column', justifyContent:'center'}}
                  display="flex">
                {allResultsPresentation?.maintenance_plan.leader_maintenance_plan && (
                  <Box backgroundColor="trasnparent" 
                      verticalAlign="center"
                      marginBottom="20px">   
                    <Typography variant="h6">Lider</Typography>               
                    <Quote fontWeight="200"
                          fontSize="18px"
                          margin="auto 0"
                          color="#000000"
                        >
                      {allResultsPresentation?.maintenance_plan.leader_maintenance_plan}
                    </Quote>                
                  </Box> 
                )}
                {allResultsPresentation?.maintenance_plan.clients_and_peers_maintenance_plan && (
                  <Box backgroundColor="trasnparent" 
                      verticalAlign="center"
                      marginBottom="20px">   
                    <Typography variant="h6">Clientes e Pares</Typography>               
                    <Quote fontWeight="200"
                          fontSize="18px"
                          margin="auto 0"
                          color="#000000"
                        >
                      {allResultsPresentation?.maintenance_plan.clients_and_peers_maintenance_plan}
                    </Quote>                
                  </Box> 
                )}
              </Box>
            </Paper>
          </Slide>
          )}


          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#000000')}>
            <Heading fontWeight="600" fontSize="28px" margin="0px"  color="#000000">
              COMPARATIVO DA AVALIAÇÃO INICIAL E FINAL 
            </Heading>

            <Grid gridTemplateColumns="4fr 3fr 4fr" gridColumnGap={15} border="solid">
              <Box>
                <Heading fontWeight="600" fontSize="32px" textAlign="right" padding="35px 0 0 0" color="#000000">
                AVALIAÇÃO INICIAL
                </Heading>

                <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="60px 0 0 100px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="252px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial : 0)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="250px"
                      left={400 - (allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial : 15)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_excede_as_expectativas_inicial + '%'}
                  </Text>
                </Box>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="342px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial : 0)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="340px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial : 15)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_atende_as_expectativas_inicial + '%'}
                  </Text>
                </Box>


                <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="432px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial : 0)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="430px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial : 15)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial : 15)*4 + 'px'}  
                      minWidth="50px"
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_inicial + '%'}
                  </Text>
                </Box>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="522px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial ? allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial : 0)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial ? allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="25px 0 0 25px"
                      top="520px" 
                      left={400 - (allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial ? allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial : 15)*4 + 132 + 'px'}  
                      width={(allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial ? allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_insatisfatorio_inicial + '%'}
                  </Text>
                </Box>
              </Box>
              <Box>
                <Heading fontWeight="600" fontSize="52px"color="#000000">
                  VS
                </Heading>
                <Heading fontWeight="700" fontSize="22px" lineHeight="70px" padding="0px" margin="0px"color="#000000">
                  EXCEDE AS EXPECTATIVAS
                </Heading>
                <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px"color="#000000" style={{marginTop:'-30px', marginBottom:'15px'}}>
                  (maior ou igual a {((min-max)*8 + 0*max - 10*min) /(0-10)})
                </Heading>
                <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px"color="#000000">
                  ATENDE AS EXPECTATIVAS
                </Heading>
                <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px"color="#000000" style={{marginTop:'-25px', marginBottom:'15px'}}>
                  (maior ou igual a {((min-max)*7 + 0*max - 10*min) /(0-10)} e menor que {((min-max)*8 + 0*max - 10*min) /(0-10)})
                </Heading>
                <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px"color="#000000">
                  ABAIXO DAS EXPECTATIVAS
                </Heading>
                <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px"color="#000000" style={{marginTop:'-25px', marginBottom:'15px'}}>
                  (maior ou igual a {((min-max)*5 + 0*max - 10*min) /(0-10)} e menor que {((min-max)*7 + 0*max - 10*min) /(0-10)})
                </Heading>
                <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px"  color="#000000">
                  INSATISFATÓRIO
                </Heading>
                <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px"color="#000000" style={{marginTop:'-25px', marginBottom:'15px'}}>
                  (menor que {((min-max)*5 + 0*max - 10*min) /(0-10)})
                </Heading>
              </Box>
              <Box>
                <Heading fontWeight="600" fontSize="32px" textAlign="left" padding="35px 0 0 0" color="#000000">
                AVALIAÇÃO FINAL
                </Heading>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="60px 0 0 0"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="252px" 
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_excede_as_expectativas_final ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_final : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="250px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_excede_as_expectativas_final ? allResultsPresentation?.total_expectation?.total_excede_as_expectativas_final : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_excede_as_expectativas_final + '%'}
                  </Text>
                </Box>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="342px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_atende_as_expectativas_final ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_final : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="340px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_atende_as_expectativas_final ? allResultsPresentation?.total_expectation?.total_atende_as_expectativas_final : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_atende_as_expectativas_final + '%'}
                  </Text>
                </Box>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="432px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_final ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_final : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="430px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_final ? allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_final : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_abaixo_das_expectativas_final + '%'}
                  </Text>
                </Box>
                <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"  
                    height="50px"
                    >
                </Box>
                <Box backgroundColor={((allResultsPresentation && 
                                        allResultsPresentation.color_palette && 
                                        allResultsPresentation.color_palette.variant1 ) ? 
                                          (allResultsPresentation.color_palette.variant1) : 
                                          '#019DAA'
                                      )} 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="522px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_insatisfatorio_final ? allResultsPresentation?.total_expectation?.total_insatisfatorio_final : 0)*4 + 'px'}  
                      height="50px">
                </Box>
                <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      borderRadius="0 25px 25px 0"
                      top="520px"
                      left="893px" 
                      width={(allResultsPresentation?.total_expectation?.total_insatisfatorio_final ? allResultsPresentation?.total_expectation?.total_insatisfatorio_final : 15)*4 + 'px'}  
                      height="50px">
                  <Text fontWeight="200" 
                        fontSize="22px"
                        margin="auto"
                        textAlign="center"
                        minWidth="50px"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.total_insatisfatorio_final + '%'}
                  </Text>
                </Box>
              </Box>
            </Grid>   




          </Slide>
          <Slide backgroundColor={((allResultsPresentation && allResultsPresentation.color_palette && allResultsPresentation.color_palette.background) ? (allResultsPresentation.color_palette.background) : '#000000')}>
            <Heading fontWeight="600" fontSize="34px" margin="0px"  color="#000000">
              MÉDIA GERAL DE EVOLUÇÃO 
            </Heading>
            <Heading fontWeight="400" fontSize="22px" lineHeight="15px" padding="0px" margin="0px"  color="#000000">
              MÉDIA	DAS	NOTAS	ATRIBUÍDAS EM CADA COMPETÊNCIA NO INÍCIO E AO FINAL DO PROCESSO
            </Heading>
            <Box backgroundColor="trasnparent" 
                position="absolute" 
                top="150px"
                left="250px"
                width="900px">
              <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={15}>
                <Box>
                  <Text fontWeight="600"
                        fontSize="24px"
                        textAlign="center"
                        color="#000000" >NOTA MÉDIA INICIAL</Text>
                  <CircularProgress sx={{position:'absolute', left:'70px', color: 'grey.300' }}
                                    variant="determinate" 
                                    value={100} 
                                    size={300}
                                    thickness={6}/>
                  <CircularProgress sx={{position:'absolute', left:'70px', color: ( allResultsPresentation?.color_palette?.variant1 ? allResultsPresentation.color_palette.variant1 : '#019DAA')}} 
                                    variant="determinate" 
                                    value={allResultsPresentation?.total_expectation?.nota_media_inicial_percent ? allResultsPresentation.total_expectation.nota_media_inicial_percent*10 : 0} 
                                    size={300}
                                    thickness={6}/>
                  <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      top="180px"
                      left="110px"
                      width="215px">
                    <Text fontWeight="800"
                        fontSize="48px"
                        textAlign="center"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.nota_media_inicial}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Text fontWeight="600"
                        fontSize="24px"
                        textAlign="center"
                        color="#000000" >NOTA MÉDIA FINAL</Text>
                  <CircularProgress sx={{position:'absolute', left:'530px', color: 'grey.300'}}
                                    variant="determinate" 
                                    value={100} 
                                    size={300}
                                    thickness={6}/>
                  <CircularProgress sx={{position:'absolute', left:'530px', color: ( allResultsPresentation?.color_palette?.variant1 ? allResultsPresentation.color_palette.variant1 : '#019DAA')}}
                                    variant="determinate" 
                                    value={allResultsPresentation?.total_expectation?.nota_media_final_percent ? allResultsPresentation.total_expectation.nota_media_final_percent*10 : 0}
                                    size={300}
                                    thickness={6}/>
                  <Box backgroundColor="trasnparent" 
                      position="absolute" 
                      top="180px"
                      left="570px"
                      width="215px">
                    <Text fontWeight="800"
                        fontSize="48px"
                        textAlign="center"
                        color="#000000">
                      {allResultsPresentation?.total_expectation?.nota_media_final}
                    </Text>
                  </Box>
                </Box>
              </Grid>      
            </Box>
          </Slide>
        </Deck>
      </>
    ) : (
      <Alert severity="warning">Não há itens suficiente em seu programa para iniciar uma apresentação.</Alert>
    )
    )}
    </>
  );
};

/*

        <Slide
          transition={{
            from: {
              transform: 'scale(0.5) rotate(45deg)',
              opacity: 0
            },
            enter: {
              transform: 'scale(1) rotate(0)',
              opacity: 1
            },
            leave: {
              transform: 'scale(0.2) rotate(315deg)',
              opacity: 0
            }
          }}
          backgroundColor="tertiary"
          backgroundImage="url(slide-resultados.png)"
          backgroundOpacity={0.5}
        >
          <Heading>Custom Backgrounds</Heading>
          <UnorderedList>
            <ListItem>
              <CodeSpan>backgroundColor</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundImage</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundOpacity</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundSize</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundPosition</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundRepeat</CodeSpan>
            </ListItem>
          </UnorderedList>
        </Slide>
        <Slide>
          <Heading>Animated Elements</Heading>
          <OrderedList>
            <Appear>
              <ListItem>Elements can animate in!</ListItem>
            </Appear>
            <Appear>
              <ListItem>Out of order</ListItem>
            </Appear>
            <Appear priority={0}>
              <ListItem>
                Just identify the order with the prop <CodeSpan>priority</CodeSpan>!
              </ListItem>
            </Appear>
          </OrderedList>
        </Slide>
        <Slide>
          <FlexBox>
            <Text>These</Text>
            <Text>Text</Text>
            <Text color="secondary">Items</Text>
            <Text fontWeight="bold">Flex</Text>
          </FlexBox>
          <Grid gridTemplateColumns="1fr 2fr" gridColumnGap={15}>
            <Box backgroundColor="primary">
              <Text color="secondary">Single-size Grid Item</Text>
            </Box>
            <Box backgroundColor="secondary">
              <Text>Double-size Grid Item</Text>
            </Box>
          </Grid>
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            gridTemplateRows="1fr 1fr 1fr"
            gridRowGap={1}
          >
            {Array(9)
              .fill('')
              .map((_, index) => (
                <FlexBox paddingTop={0} key={`formidable-logo-${index}`} flex={1}>
                  <Image src={formidableLogo} width={100} />
                </FlexBox>
              ))}
          </Grid>
        </Slide>
        <SlideFragments />
        <Slide>
          <CodePane language="jsx">{`
            import { createClient, Provider } from 'urql';
            const client = createClient({ url: 'https://0ufyz.sse.codesandbox.io' });
            const App = () => (
              <Provider value={client}>
                <Todos />
              </Provider>
            );
            `}</CodePane>
          <Box height={20} />
          <CodePane language="java" showLineNumbers={false}>{`
            public class NoLineNumbers {
              public static void main(String[] args) {
                System.out.println("Hello");
              }
            }
            `}</CodePane>
        </Slide>
        <div>
          <Slide>
            <Heading>This is a slide embedded in a div</Heading>
          </Slide>
        </div>
        <MarkdownSlide componentProps={{ color: 'yellow' }}>
          {`
            # This is a Markdown Slide
            - You can pass props down to all elements on the slide.
            - Just use the \`componentProps\` prop.
            `}
        </MarkdownSlide>
        <MarkdownSlide animateListItems>
          {`
          # This is also a Markdown Slide
          It uses the \`animateListItems\` prop.
          - Its list items...
          - ...will appear...
          - ...one at a time.
          `}
        </MarkdownSlide>
        <Slide>
          <Grid
            gridTemplateColumns="50% 50%"
            gridTemplateRows="50% 50%"
            height="100%"
          >
            <FlexBox alignItems="center" justifyContent="center">
              <Heading>This is a 4x4 Grid</Heading>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Text textAlign="center">
                With all the content aligned and justified center.
              </Text>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Text textAlign="center">
                It uses Spectacle <CodeSpan>{'<Grid />'}</CodeSpan> and{' '}
                <CodeSpan>{'<FlexBox />'}</CodeSpan> components.
              </Text>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Box width={200} height={200} backgroundColor="secondary" />
            </FlexBox>
          </Grid>
        </Slide>
        <MarkdownSlideSet>
          {`
            # This is the first slide of a Markdown Slide Set
            ---
            # This is the second slide of a Markdown Slide Set
            `}
        </MarkdownSlideSet>
        <SlideLayout.List
          title="Slide layouts!"
          items={['Two-column', 'Lists', 'And more!']}
          animateListItems
        />

*/