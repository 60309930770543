import { LayoutDefault } from '../../shared/layouts';
import { EvaluationFinalItems } from './EvaluationFinalItems';

export const EvaluationFinal = () => {

  return (
    <LayoutDefault>
     <EvaluationFinalItems />
    </LayoutDefault>
  );
};
