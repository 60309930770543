
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, ListItem, ListItemAvatar, Avatar, ListItemText, Link } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IProgram } from '../../shared/types';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { UtilService, IList } from '../../shared/services/api/util/UtilService';

import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';
import { PeopleNewPerson } from '../people/PeopleNewPerson';
import { useNavigate } from 'react-router-dom';


type IProgramsNewProgram = {
    open: boolean;
    setOpen: (open:boolean) => void;
    setUpdateList:() => void;
};


export const ProgramsNewProgram:React.FC<IProgramsNewProgram> =  (({open, setOpen, setUpdateList}) => {

    const navigate = useNavigate();
    
    const [saveLoading, setSaveLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [program, setProgram] = useState<IProgram>({});
    const [programError, setProgramError] = useState<IProgram | null>(null);
    const [programTypes, setProgramTypes] = useState<Array<IList>>([]);

    const { user } = useAuthContext();


    const [openNewPerson, setOpenNewPerson] = useState(false);

    const [updateListPeople, setUpdateListPeople] = useState(false);

    const [people, setPeople] = useState<Array<IList>>([]);

/*
    useEffect(() => {
        async function getLists() {
            setIsLoading(true);
            const PeopleResponse = await UtilService.getUsers();
            if (PeopleResponse instanceof Error) {
                setPeople([]);
                setIsLoading(false);
            } else {
                if(PeopleResponse){
                    setPeople(PeopleResponse);
                    setIsLoading(false);            
                }
                else{
                    setPeople([]);
                    setIsLoading(false);
                }
            }
        }
        if(open==true)
            getLists();
    }, [open]);
  */
    const handleClose = () => {
      setProgram({});
      setProgramError(null);
      setOpen(false);
    };


    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        async function getLists() {
            const typesResponse = await UtilService.getMentoryTypes();
            setProgramTypes(typesResponse);
            setIsLoading(false);
        }
        getLists();        
    }, [open]);

    const isValidProgram = yup.object({
        name_program: yup.string().required('Este campo é obrigatório'),
        id_module: yup.string().required('Este campo é obrigatório'),
        start_program: yup.string().required('Este campo é obrigatório'),
        close_program: yup.string().required('Este campo é obrigatório')
    });
    
    const saveProgram = () => {
        isValidProgram
        .validate( program , { abortEarly: false })
        .then(validateProgram => { 
            setSaveLoading(true);
            if(validateProgram) {
                const newProgram: IProgram = {
                    status_program: '1',
                    start_program: validateProgram.start_program,
                    close_program: validateProgram.close_program,
                    name_program: validateProgram.name_program,
                    description_program: program?.description_program,
                    id_module: validateProgram.id_module,
                    mentor_program: program?.mentor_program,
                    enroll_program: program?.enroll_program,
                    user_register: user?.id,
                };            
             /*   ProgramsService.createProgram(newProgram)
                .then((ProgramResponse) => {
                    if (ProgramResponse instanceof Error) {
                        setSaveLoading(false);
                        enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
                    } else {
                        enqueueSnackbar('Programa cadastrado com sucesso!', { variant:'success'});
                        setOpen(false);
                        setSaveLoading(false);
                        navigate(`/programas/detalhes/${ProgramResponse}`);
                    }
                }).catch(() => {
                    setSaveLoading(false);
                    enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
                });*/
            }
            else{
                setSaveLoading(false);
                enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
            }
        })
        .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setProgramError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle>Cadastrar Novo Programa</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {saveLoading && (                   
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress variant='indeterminate' />
                        </Box>
                    )}
                    {!saveLoading && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EASInput
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                name_program: '',
                                                }))
                                            }
                                            label="Nome"
                                            type="text"
                                            value={program?.name_program}
                                            onChange={(e) => 
                                                setProgram(existingValues => ({
                                                    ...existingValues,
                                                    name_program: e.target.value,
                                                }))
                                            }
                                            error={!!programError?.name_program}
                                            helperText={programError?.name_program}
                                        />
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <EASInput
                                            isLoading={isLoading}
                                            multiline={true}
                                            onFocus={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                description_program: '',
                                                }))
                                            }
                                            label="Descrição"
                                            type='text'
                                            rows={3}
                                            value={program?.description_program}                                
                                            onChange={(e) => 
                                                setProgram(existingValues => ({
                                                    ...existingValues,
                                                    description_program: e.target.value,
                                                }))
                                            }
                                            error={!!programError?.description_program}
                                            helperText={programError?.description_program}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <EASSelect 
                                            isLoading={isLoading}
                                            options={programTypes}
                                            label="Tipo"
                                            getOptionLabel={(option) => option.text}
                                            value={programTypes.find(item => item.value == program?.id_module)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                if (value && value.value) {
                                                    setProgram(existingValues => ({
                                                        ...existingValues,
                                                        id_module: value.value,
                                                    }));
                                                }
                                            }
                                            }  
                                            error={!!programError?.id_module}
                                            helperText={programError?.id_module}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <EASRadio
                                            label="Ativar Inscrições"
                                            isLoading={isLoading}
                                            options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                            value={program?.enroll_program}
                                            onChange={(e) => 
                                                setProgram(existingValues => ({
                                                    ...existingValues,
                                                    enroll_program: e.target.value,
                                                }))
                                            }
                                        />
                                    </Grid> 
                                    <Grid item xs={12}>
                                        <EASSelect 
                                            isLoading={isLoading}
                                            options={people}
                                            label="Pré definir Mentor"
                                            getOptionLabel={(option) => option.text}
                                            renderOption={(props, option) => (
                                                    <ListItem {...props}>
                                                        <ListItemAvatar>
                                                            <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                                        </ListItemAvatar>
                                                        <ListItemText primary={option.text} />
                                                    </ListItem>
                                            )}
                                            value={people.find(item => item.value == program?.mentor_program)}                                  
                                            onChangeSelect={(event, value:IList) => {
                                                    if (value && value.value) {
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            mentor_program: value.value,
                                                        }));
                                                    }
                                                }
                                            }
                                            error={!!programError?.mentor_program}
                                            helperText={programError?.mentor_program}
                                        />
                                        <Box sx={{pt:1}} >
                                            <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                                Cadastrar nova pessoa
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASPicker 
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                start_program: '',
                                                }))
                                            }
                                            onOpen={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                start_program: '',
                                                }))
                                            }
                                            label="Data de Início"
                                            value={program?.start_program} 
                                            error={!!programError?.start_program}
                                            helperText={programError?.start_program}
                                            onChangePiker={(e) => {  
                                                                if(e instanceof Date) {         
                                                                setProgram(existingValues => ({
                                                                        ...existingValues,
                                                                        start_program: e.toISOString().split('T')[0],
                                                                    }));
                                                                }}
                                                            }
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <EASPicker 
                                            isLoading={isLoading}
                                            onFocus={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                close_program: '',
                                                }))
                                            }
                                            onOpen={() => 
                                                setProgramError(existingValues => ({
                                                ...existingValues,
                                                close_program: '',
                                                }))
                                            }
                                            label="Data de Término"
                                            value={program?.close_program} 
                                            error={!!programError?.close_program}
                                            helperText={programError?.close_program}
                                            onChangePiker={(e) => {  
                                                                if(e instanceof Date) {         
                                                                setProgram(existingValues => ({
                                                                        ...existingValues,
                                                                        close_program: e.toISOString().split('T')[0],
                                                                    }));
                                                                }}
                                                            }
                                            />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    </Box>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
                    <Button variant="contained" onClick={saveProgram} disabled={saveLoading}>Confirmar</Button>
                </DialogActions>
            </Dialog>
            <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} />
        </>
    );
});

