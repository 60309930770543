
import React, { useState , useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import {Box, Grid, Typography, Container, Alert, List, Card, CardContent, Skeleton  } from '@mui/material/';


import { EASToolsProximityMapItem } from './EASToolsProximityMapItem';
import { EASToolsProximityMapChart } from './EASToolsProximityMapChart';
import { useAuthContext, useMentoringContext } from '../../contexts';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { EASButton } from '../../forms';
import { EASDialogAlert, EASCard } from '../';



export const EASToolsProximityMap: React.FC = () => {

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();

  const navigate = useNavigate();

  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(true);

  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);
 
  useEffect(() => {
    async function getInfluencers() {
      if(mentory?.id_mentory){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers(mentory?.id_mentory);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);
          }
          else{
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, []);

  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };


  return (
    <EASCard 
      titleCard="Mapa de Proximidade"
      actionCard={<EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => navigate('/ferramentas')}>Sair</EASButton>}> 
      <Container>
        <Card sx={{borderRadius : 2, bgcolor: 'transparent', my:'30px'}} variant='outlined' elevation={0}>
          <CardContent>
            {isLoading && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', mt:'15px'}}>
                <Skeleton animation="wave" variant="rounded"  width={'80%'} height={18} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'40%'} height={18} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'80%'} height={58} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px', ml:'15px'}} width={150} height={58} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'35px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
            </>
            )}
            {!isLoading && (
            <Box>
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
                  Defina a proximidade que você tem de cada influenciador, sendo 10 para muito próximo e 0 para muito distante.
                </Typography>
              </Box>
              {mentory?.status_mentory=='2' && (
              <Box sx={{ p: 3 }}>
                <Typography variant="h5">Este programa foi encerrado</Typography>
                {mentory?.id_user_mentorado != user?.id && (
                  <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
                )}
              </Box>
              )}
              <Grid container alignItems="center" spacing={2} sx={{marginTop:'-10px', width:'100%'}}>
                <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} >
                  <List>
                    {!isLoading ?  (
                      <>
                        {allInfluencers && allInfluencers.length > 0 && (allInfluencers.map((influencer, index) => (
                          <EASToolsProximityMapItem 
                            key={influencer.id_influencer} 
                            influencer={influencer} 
                            index={index}
                            updateInfluencer={(e) => updateInfluencer(e)}
                          />
                        )))}
                      </>
                    ):(
                    <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
                    )}
                  </List>
                </Grid>
                <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} sx={{height: '100%'}}>
                  <EASToolsProximityMapChart allInfluencers={allInfluencers} />
                </Grid>
              </Grid>
            </Box>
            )}
            <EASDialogAlert 
              type="info"
              title="Excluir Influenciador"
              statusOpen={messageInfluencers}
              actionCancel={() => navigate('/ferramentas/influenciadores-de-reputacao')}
              titleCancel="Ir para o cadastro de influenciadores"            
              >
              <Typography>Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores</Typography>
            </EASDialogAlert>
          </CardContent>
        </Card>
      </Container>
    </EASCard>     
  );
};