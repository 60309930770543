import { Routes, Route, Navigate } from 'react-router-dom';
import {  Profile, SelectHumanResource, SelectHumanResourceNew } from '../pages';

export const IsAuthSelectHumanResource = () => {

  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/meus-programas" element={<SelectHumanResource />} />
      <Route path="/novo-Programa" element={<SelectHumanResourceNew />} />
      <Route path="*" element={<Navigate to="/meus-programas" />} />
    </Routes>
  );
};
