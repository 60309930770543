import { ITag, ITagsListResponse } from '../../../contexts/TagsContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const createTag = async (tag: ITag): Promise<void> => {
  const { data } = await Api.post('/tags', { Tag: tag } );
  return data;
};

const updateTag = async (tag: ITag): Promise<void> => {
  const { data } = await  Api.put(`/tags/${tag.id_tag}`, tag);
  return data;  
};

const deleteTag = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/tags/${id}`);
  return data;   
};


const getTag = async (id: string): Promise<ITag> => {
    const { data } = await Api.get(`/tags/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getTags = async (page = 1, search = '' ): Promise<ITagsListResponse> => {
  const  {data} = await Api.get(`/tags?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;

};

export const TagsService = {
  getTags,
  getTag,
  createTag,
  updateTag,
  deleteTag,

};
