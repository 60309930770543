import { useParams, useNavigate} from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { MentoryVideoDetail } from './MentoryVideoDetail';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

export const MentoryVideo = () => {

  const { id = ''} = useParams<'id'>();    
  const navigate = useNavigate();

  return (
    <LayoutDefault >
        <EASCard actionCard={<EASButton variant="outlined" onClick={() => navigate('/videos')}>Sair</EASButton>} >
        <MentoryVideoDetail VideoID={id}  />
        </EASCard>
    </LayoutDefault>
  );
};