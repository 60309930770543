import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { IMeeting } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';

type IMeetingRelatory = {
  open: boolean;
  handleCloseRelatory: () => void;
  //setUpdateList:() => void;
  meeting: IMeeting | null;
};



export const MeetingRelatory:React.FC<IMeetingRelatory> =  (({open, handleCloseRelatory, meeting}) => { 

  const [isLoading, setIsLoading] = useState(true);

  const [secao, setSecao] = useState<IMeeting | null>(null);

  useEffect(() => {
    async function getMeeting() {
      setIsLoading(true);
      if(meeting?.id_meeting) {  
        const MeetingResponse = await MeetingsService.getMeeting(meeting?.id_meeting);
        if (MeetingResponse instanceof Error) {
          setSecao(null);
          //setObjectiveSampleError(null);
          setIsLoading(false);
        } else {
          if(MeetingResponse){
              const myMeeting = JSON.parse(JSON.stringify(MeetingResponse));
              setSecao(myMeeting);
              //setObjectiveSampleError(null);
              setIsLoading(false);
          }
          else{
              setSecao(null);
              //setObjectiveSampleError(null);
              setIsLoading(false);
          }
        }
      }
    }
    if(open==true)
      getMeeting();
  }, [open]);

  const handleClose = () => {
    handleCloseRelatory();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        <Box>
          Relatório Sessão {meeting?.id_meeting}
        </Box>
        <Box>
          <Typography variant="caption">Sessão realizada em {meeting?.date_meeting && new Date(meeting?.date_meeting).toLocaleString('pt-BR', {dateStyle: 'medium', timeStyle: 'short'})}</Typography>
        </Box>
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <Grid container spacing={3}>
          {isLoading && (
          <>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height="50px"/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height="50px"/>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height="50px"/>
            </Grid>
          </>
          )}
          {!isLoading && ( 
            <>

            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold'}}>Principais Pontos Tratados</Typography>
              <Typography>{secao?.relatory_meeting}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold'}}>Lições Aprendidas</Typography>
              <Typography>{secao?.lessons_meeting}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontWeight: 'bold'}}>Homework</Typography>
              <Typography>{secao?.homework_meeting}</Typography>
            </Grid>
          </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>

  );
});