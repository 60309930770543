import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IObjectivesSample } from '../../../contexts/ObjectivesSampleContext/types';


interface IObjectivesSampleService {
  data?: IObjectivesSample;
}

interface IObjectivesSamplesListResponse extends IObjectivesSample {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IObjectivesSample> | null;
  tags?: string[] | undefined;
}

const getObjectiveSample = async (id:string): Promise<IObjectivesSampleService | Error> => {

    try {
      const { data } = await Api.get(`/objectivessample/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getObjectivesSample = async (page = 1, search = '', id_mentory = '', tags = '' ): Promise<IObjectivesSamplesListResponse | Error> => {
  try {
  
    const urlRelativa = `/objectivessample?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}&mentory=${id_mentory}&tags=${tags}`;

    const  {data} = await Api.get(urlRelativa);
  
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateObjectiveSample = async (ObjectivesSample: IObjectivesSample): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/objectivessample/${ObjectivesSample.id_objective_sample}`, ObjectivesSample);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteObjectiveSample = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/objectivessample/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const createObjectiveSample = async (objectivesample: IObjectivesSample): Promise<IObjectivesSampleService | Error> => {
  try {
    const { data } = await Api.post('/objectivessample', { ObjectiveSample: objectivesample } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


export const ObjectivesSamplesService = {
  getObjectiveSample,
  getObjectivesSample,
  updateObjectiveSample,
  deleteObjectiveSample,
  createObjectiveSample
};
