import { Box } from '@mui/material';
import { EASCard } from '../../shared/components';
import { LayoutDefault } from '../../shared/layouts';
import { LearningList } from './LearningList';
import { EASButton } from '../../shared/forms';

export const Learning = () => {

  return (
    <LayoutDefault >
      <LearningList />
    </LayoutDefault>
  );
};
