import { useState, useRef, useEffect } from 'react';
import * as yup from 'yup';

import { Box, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button, Theme, useMediaQuery, Grid } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { green } from '@mui/material/colors';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { EASCard } from '../../shared/components/eas-card/EASCard';
import { useLearningContext, useTeamContext, useCourseContext } from '../../shared/contexts';
import { ILearning } from '../../shared/contexts/LearningContext/types';
import { LearningService } from '../../shared/services/api/learning/LearningService';
import { VariantType, useSnackbar } from 'notistack';
import { EASButton, EASInput, EASPicker } from '../../shared/forms';

interface ILearningNewProps {
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;
  learning: ILearning | null;
}
export const LearningEdit: React.FC<ILearningNewProps> = ({ open, setOpen, setUpdateList, learning }) => {

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

 
  const [editLearning, setEditLearning] = useState<ILearning>({});
  const [editLearningError, setEditLearningError] = useState<ILearning | null>(null);

  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  
  useEffect(() => {
    function loadEditLearning() {
      if(learning){
        setEditLearning({});
        setEditLearningError(null);
        setEditLearning(learning);

        setIsLoading(false);
      }
    }
    loadEditLearning();
  }, [open]);

  const handleClose = () => {
    setIsLoading(true);
    setEditLearning({});
    setEditLearningError(null);
    setOpen(false);
  };

  const learningSchema = yup.object().shape({
    value_learning: yup.string().required('Informe uma Nota'),
    date_learning: yup.string().required('Informe uma Data'),
    text_learning: yup.string().required('Descreva suas ações')
  });

  const handleButtonSave = () => {
      learningSchema
      .validate( editLearning, { abortEarly: false })
      .then(validateValues => {
        setSaveLoading(true);
          const learning: ILearning = {
            id_learning: editLearning?.id_learning,
            id_mentory: editLearning?.id_mentory,
            unread_learning: editLearning?.unread_learning,
            status_learning: '1',
            date_learning: validateValues.date_learning,
            value_learning: validateValues.value_learning,
            text_learning: validateValues.text_learning,
            lack_learning: editLearning?.lack_learning,
            cause_learning: editLearning?.cause_learning,
            plan_learning: editLearning?.plan_learning
          };   
          LearningService.updateLearning(learning)
          .then((learningResponse) => {
            if (learningResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao atualizar Diário de Aprendizagem.', { variant:'error'});
            } else {
              setIsLoading(true);
              setSaveLoading(false);
              enqueueSnackbar('Diário de Aprendizagem atualizada com sucesso!', { variant:'success'});
              setEditLearning({});
              setEditLearningError(null);
              setOpen(false);
              setUpdateList();
            }
          }).catch((error) => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setEditLearningError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
      });
  };


  return (
    <Dialog 
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{'Editar Item do Diário de Aprendizagem'}</DialogTitle>
      <DialogContent>
        {saveLoading && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!saveLoading && (
            <Grid container spacing={3} sx={{py:2}} >
              <Grid item xs={12} md={8}>
                <EASPicker 
                  isLoading={isLoading}
                  onFocus={() => 
                    setEditLearningError(existingValues => ({
                      ...existingValues,
                      date_learning: '',
                      }))
                  }
                  onOpen={() => 
                    setEditLearningError(existingValues => ({
                      ...existingValues,
                      date_learning: '',
                      }))
                  }
                  label="Data Prevista"
                  value={editLearning?.date_learning} 
                  error={!!editLearningError?.date_learning}
                  helperText={editLearningError?.date_learning}
                  onChangePiker={(e) => {  
                                      if(e instanceof Date) {         
                                        setEditLearning(existingValues => ({
                                              ...existingValues,
                                              date_learning: e.toISOString().split('T')[0],
                                          }));
                                      }}
                                  }
                  />
              </Grid>
              <Grid item xs={12} md={4}>
                <EASInput
                    isLoading={isLoading}
                    onFocus={() => 
                      setEditLearningError(existingValues => ({
                        ...existingValues,
                        value_learning: '',
                        }))
                    }
                    label="Nota"
                    type='number' 
                    value={editLearning?.value_learning}                                
                    onChange={(e) => 
                      setEditLearning(existingValues => ({
                            ...existingValues,
                            value_learning: e.target.value,
                        }))
                    }
                    error={!!editLearningError?.value_learning}
                    helperText={editLearningError?.value_learning}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label={!xsDown && !smDown && !mdDown ? ('Justificativa da nota'):('Justificativa da nota')}
                  multiline
                  value={editLearning?.text_learning} 
                  onChange={(event) => setEditLearning(existingValues => ({
                                    ...existingValues,
                                    text_learning: event.target.value,
                                  }))
                  }  
                  onFocus={() => setEditLearningError(existingValues => ({
                                      ...existingValues,
                                      text_learning: '',
                                  }))
                  }
                  error={!!editLearningError?.text_learning}
                  helperText={editLearningError?.text_learning}
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label={!xsDown && !smDown && !mdDown ? ('Não conformidades'):('Não conformidades')}
                  multiline
                  value={editLearning?.lack_learning} 
                  onChange={(event) => setEditLearning(existingValues => ({
                                    ...existingValues,
                                    lack_learning: event.target.value,
                                  }))
                  }  
                  onFocus={() => setEditLearningError(existingValues => ({
                                      ...existingValues,
                                      lack_learning: '',
                                  }))
                  }
                  error={!!editLearningError?.lack_learning}
                  helperText={editLearningError?.lack_learning}
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label="Causas"
                  multiline
                  value={editLearning?.cause_learning} 
                  onChange={(event) => setEditLearning(existingValues => ({
                                    ...existingValues,
                                    cause_learning: event.target.value,
                                  }))
                  }  
                  onFocus={() => setEditLearningError(existingValues => ({
                                      ...existingValues,
                                      cause_learning: '',
                                  }))
                  }
                  error={!!editLearningError?.cause_learning}
                  helperText={editLearningError?.cause_learning}
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label="Plano de Melhoria"
                  multiline
                  value={editLearning?.plan_learning} 
                  onChange={(event) => setEditLearning(existingValues => ({
                                    ...existingValues,
                                    plan_learning: event.target.value,
                                  }))
                  }  
                  onFocus={() => setEditLearningError(existingValues => ({
                                      ...existingValues,
                                      plan_learning: '',
                                  }))
                  }
                  error={!!editLearningError?.plan_learning}
                  helperText={editLearningError?.plan_learning}
                  rows={3}
                />
              </Grid>
            </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{mr:2, mb:1}}>
        <EASButton onClick={() => handleClose()} variant="outlined">Cancelar</EASButton>
        <EASButton onClick={handleButtonSave} variant="contained" autoFocus >Salvar</EASButton>
      </DialogActions>
    </Dialog>

  );
};
