import React from 'react';
import { Grid } from '@mui/material';
import { EASDashboardTips, EASDashboardLigthCard } from '../../shared/components/eas-dashboard-panels';
import { ChartsEvaluation, ChartsStage } from '../../shared/components';
import { IDashboardMentory } from '../../shared/types';



interface IDashboardMentoryPanelProps {
    dashboardMenotity?:IDashboardMentory;
    isLoading:boolean;
} 

export const DashboardMentoryPanel: React.FC<IDashboardMentoryPanelProps> = ({dashboardMenotity, isLoading}) => {

    return (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Comportamentos Observaveis"
                                icon="psychology"
                                value={dashboardMenotity?.cards?.card1}
                                subtitle="Total de comportamentos"
                                backgroundIcon="transparent"
                                colorIcon="rgb(255, 10, 7)"
                                opcityIcon="1"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Sessões"
                                icon="group"
                                value={dashboardMenotity?.cards?.card2}
                                subtitle="Total de sessões"
                                backgroundIcon="transparent"
                                colorIcon="rgb(0, 200, 83)"
                                opcityIcon="1"
                            />
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Tarefas Planejadas"
                                icon="assignment_turned_in"
                                value={dashboardMenotity?.cards?.card3}
                                subtitle="total de tarefas"
                                backgroundIcon="transparent"
                                colorIcon="rgb(33, 150, 243)"
                                opcityIcon="1"
                            />
                        </Grid>
                        
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                            <EASDashboardLigthCard 
                                isLoading={isLoading}
                                title="Avaliações e Autoavaliações"
                                icon="insert_chart"
                                value={dashboardMenotity?.cards?.card4}
                                subtitle="total de avalições e autoavaliações"
                                backgroundIcon="transparent"
                                colorIcon="rgb(153 33 243)"
                                opcityIcon="1"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <EASDashboardTips
                                Tips={dashboardMenotity?.tips} 
                                isLoading={(isLoading)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sx={{display: 'flex'}}>
                            <ChartsStage                          
                                title="Tempo de Processo"
                                description="Percentual de tempo levando em conta a data de início e previsão de término do processo."
                                colorFixed="rgb(0, 200, 83)"
                                value={dashboardMenotity?.progress}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>  
            <ChartsEvaluation styleChart={{width:'100%'}} title="Avaliações Parciais" values={dashboardMenotity?.chart_evaluation} />
        </Grid>
    </Grid>
);
};