import { useState, useEffect } from 'react';
import { Grid, CircularProgress, Box, Dialog, DialogActions, DialogContent, DialogTitle, Theme, useMediaQuery }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { IObjectiveDetail } from '../../shared/contexts/ObjectiveContext/types';
import { ObjectiveDetailService } from '../../shared/services/api/objective-detail/ObjectiveDetailService';
import { EASButton, EASInput } from '../../shared/forms';
import { useMutation, useQueryClient } from 'react-query';
import { useMentoringContext } from '../../shared/contexts';

interface IDetailingObjectivesDetailEditProps {
  open: boolean;
  setOpen: (open:boolean) => void;
  objectiveDetail?: IObjectiveDetail | null;
}

export const DetailingObjectivesDetailEdit: React.FC<IDetailingObjectivesDetailEditProps> = ({ open, setOpen, objectiveDetail }) => {
  

  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mentory } = useMentoringContext();
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [objectiveDetailEdit, setObjectiveDetailEdit] = useState<IObjectiveDetail>({});
  const [objectiveDetailEditError, setObjectiveDetailEditError] = useState<IObjectiveDetail | null>(null);

  const objectiveDetailEditSchema = yup.object().shape({
    text_objective_detail: yup.string().required('Detalhe uma Excelência'),
    value_objective_detail: yup.string().required('Defina sua Nota.'),
  });


  const { mutateAsync:storeUpdateObjectiveDetail } = useMutation((ObjectiveDetail:IObjectiveDetail) =>   ObjectiveDetailService.updateObjectiveDetail(ObjectiveDetail), {
    onSuccess: () => {
      queryClient.invalidateQueries('objectives-detail-list');
      queryClient.invalidateQueries('task-plan-detailings');
      enqueueSnackbar('Detalhamento de comportamento atualizado com sucesso!', { variant:'success'});
      setLoadingDialog(false);

      setObjectiveDetailEdit({});
      setObjectiveDetailEditError(null);
      setOpen(false);
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar atualizar o detalhamento deste comportamento!', { variant:'error'});
    },
  });


  useEffect(() => {
    async function loadEditObjectiveDetail() {
      setIsLoading(true);
      if(objectiveDetail)
        setObjectiveDetailEdit(objectiveDetail);      
    }
    if(open==true)
      loadEditObjectiveDetail().then(e => {
        setIsLoading(false);
      });

  }, [open]);

  const dialogSave = () => {
    objectiveDetailEditSchema
      .validate(objectiveDetailEdit, { abortEarly: false })
      .then(objectiveDetailVvalidation => {
        
        setLoadingDialog(true);
        const updateObjectiveDetail: IObjectiveDetail = {
          id_objective_detail: objectiveDetailEdit?.id_objective_detail,
          text_objective_detail: objectiveDetailVvalidation.text_objective_detail,
          value_objective_detail: objectiveDetailVvalidation.value_objective_detail,
        };   
        storeUpdateObjectiveDetail(updateObjectiveDetail);
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setObjectiveDetailEditError(existingValues => ({
                                            ...existingValues,
                                            [keyError]: error.message
                                        }));                        
            }
        });
    });
  };

  function cancelEdit()
  {
    setObjectiveDetailEdit({});
    setObjectiveDetailEditError(null);
    setOpen(false);
  }


  return (
    <Dialog 
      open={open}
      keepMounted
      onClose={cancelEdit}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{'Editar Detalhamento de Competência'}</DialogTitle>
      <DialogContent>
        {loadingDialog && (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress variant='indeterminate' />
        </Box>
        )}
        {!loadingDialog && (
        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
          <Grid sx={{width:'100%'}} container spacing={2}>
            <Grid item xs={12} md={10} lg={10}>
              <EASInput
                isLoading={isLoading}
                label={!xsDown && !smDown && !mdDown ? ('Escreva aqui o que uma pessoa excelente nesta competência faria'):('Comportamento ')}
                multiline
                value={objectiveDetailEdit?.text_objective_detail}                
                onChange={(e) => setObjectiveDetailEdit(existingValues => ({
                                                        ...existingValues,
                                                        text_objective_detail: e.target.value,
                                                      }))}
                onFocus={() => setObjectiveDetailEditError(null)}
                fullWidth
                error={!!objectiveDetailEditError?.text_objective_detail}
                helperText={objectiveDetailEditError?.text_objective_detail}
              />    
            </Grid>  
            <Grid item xs={12} md={2} lg={2}>
              <EASInput 
                isLoading={isLoading}
                label={!xsDown && !smDown && !mdDown ? ('Sua nota') : ('Nota')}
                type='number' 
                value={objectiveDetailEdit?.value_objective_detail} 
                min={mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0'}
                max={mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10'}
                step={mentory?.step_grade_mentory ? mentory.step_grade_mentory : '1'}
                onChange={(e) => {
                    const min = mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0';
                    const max = mentory?.max_grade_mentory ? mentory.max_grade_mentory : '10';
                    setObjectiveDetailEdit(existingValues => ({
                      ...existingValues,
                      value_objective_detail: (parseFloat(e.target.value) > parseFloat(max) ? max : (parseFloat(e.target.value) < parseFloat(min) ? min : e.target.value))
                    }));
                  }
                }
                error={!!objectiveDetailEditError?.value_objective_detail}
                helperText={objectiveDetailEditError?.value_objective_detail}
                sx={{  mx: 'auto', width:'100%' }}

              />  
            </Grid>
          </Grid>
        </Box> 
        )}
      </DialogContent>
      <DialogActions>
        <EASButton onClick={cancelEdit} disabled={loadingDialog} variant="outlined">Cancelar</EASButton>
        <EASButton onClick={dialogSave} disabled={loadingDialog} variant="contained" autoFocus>Salvar</EASButton>
      </DialogActions>
    </Dialog>
  );
};
