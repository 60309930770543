import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Chip,
  Box,
  Skeleton,
  Pagination,
  Grid,
} from '@mui/material';

import { IFile } from '../../shared/contexts/FilesContext/types';
import { FilesService } from '../../shared/services/api/files/FilesService';
import { Environment } from '../../shared/environment';
import { FilesNewFile } from './FilesNewFile';
import { EASCard, EASDialogAlert, PaginationComponent} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';
import { EASButtonFile } from '../../shared/forms/EASButtonFile';
import { useMutation, useQuery, useQueryClient } from 'react-query';


export const FilesList:React.FC = (() => {


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);

  //const [isLoading, setIsLoading] = useState(true);
  const [initState, setInitState] = useState(false);

  const [allFiles, setAllFiles] = useState<Array<IFile> | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idFile, setIdFile] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);


const queryClient = useQueryClient();

const { data, isLoading } = useQuery(['list-file-operator', [search, page]],() =>  FilesService.getFiles(page, search),  {
  staleTime: Infinity,
  onError: () => {
    enqueueSnackbar('Ocorreu um problema carregar a lista de vídeos.', { variant:'error'});
  },
});

const { mutateAsync:storeBlockFile } = useMutation((blockFile:IFile) =>  FilesService.updateFile(blockFile), {
  onSuccess: () => {
    queryClient.invalidateQueries('list-file-operator');
    queryClient.invalidateQueries('list-file');
    enqueueSnackbar('Vídeo ' + (blockText ? 'desbloqueado' : 'bloqueado') + ' com sucesso!', { variant:'success'});
    setLoadingDialog(false);
    handleCancelDialog();
  },
  onError: () => {
    enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' o vídeo!', { variant:'error'});
  },
});


const { mutateAsync:storeRemoveFile } = useMutation((idFile:string) => FilesService.deleteFile(idFile), {
  onSuccess: () => {
    queryClient.invalidateQueries('list-file-operator');
    queryClient.invalidateQueries('list-file');
    enqueueSnackbar('Vídeo removido com sucesso!', { variant:'success'});
    setLoadingDialog(false);
    handleCancelDialog();      
  },
  onError: () => {
    enqueueSnackbar('Erro ao tentar remover o vídeo!', { variant:'error'});
    setLoadingDialog(false);
  },
});

  const handleClickRemoveFile = (id_user?:string) => {
    if(id_user){
      setIdFile(id_user);
      setTitleDialog('Excluir Arquivo');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene este arquivo? Não será mais possível recuperar este arquivo após sua exclusão.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockFile = (id_user?:string) => {
    if(id_user){
      setIdFile(id_user);
      setTitleDialog('Bloquear Arquivo');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso a este arquivo?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockFile = (id_user?:string) => {
    if(id_user){
      setIdFile(id_user);
      setTitleDialog('Desbloquear Arquivo');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso desta a este arquivo?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdFile(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeFile();
      break;
      case 'block':
        blockFile(false);
      break;
      case 'unblock':
        blockFile(true);
      break;
    }
  };

  const removeFile = () => {
    setLoadingDialog(true);
    if(idFile){
      storeRemoveFile(idFile);
    }
  };

  const blockFile = (block:boolean) => {
    setLoadingDialog(true);
    if(idFile){
      setBlockTest(block);
      const newBlockFile:IFile = {
        id_file:idFile,
        status_file:(block ? '1' : '0')
      };
      storeBlockFile(newBlockFile);
    }
  };

  return (   
      <EASCard 
        titleCard="Arquivos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }
      >
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Tags
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((file) => (
            <TableRow 
                hover 
                key={file.id_file} 
                tabIndex={-1} 
                role="checkbox"
            >
              <TableCell align="center">
                {file?.id_file}
              </TableCell>
              <TableCell scope="row" >
                <Grid container spacing={2}>
                  <Grid item>
                    <EASButtonFile file={file?.file_file} title="Visulizar/Baixar Arquivo" typeIcon={file?.extension_file} size="small" />
                  </Grid>
                  <Grid item zeroMinWidth sm={true}>
                    <Typography variant="subtitle2">
                      {file?.name_file}
                    </Typography>
                    <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      Última Atualização: {file?.date_update ? file?.date_update  : file?.date_register}
                    </Typography>
                  </Grid>
                </Grid> 
              </TableCell>
              <TableCell align="center">
                <Chip label={file?.status_file == '1'? 'Ativo' : 'Bloqueado'} color={file?.status_file == '1' ? 'success' : 'error'} />
              </TableCell>
              <TableCell align="center">
              <TableCell align="center">
                {file?.name_tags && file.name_tags.length > 0 && ( file.name_tags.map((tag, index) => (
                  tag ? <Chip key={index} label={tag} size="small"  /> : ''
                )))}
              </TableCell>
              </TableCell>
              <TableCell align="center">
                <EASButtonList 
                    title="Editar Arquivo"
                    onClick={() => navigate(`/arquivos/detalhes/${file.id_file}`)} 
                    typeIcon="edit"
                />
                {file?.status_file == '0' ? (
                <EASButtonList 
                    title="Desbloquear Arquivo"
                    onClick={() => handleClickUmBlockFile(file.id_file)} 
                    typeIcon="unblock"
                />
                ) : (
                <EASButtonList 
                  title="Bloquear Arquivo"
                  onClick={() => handleClickBlockFile(file.id_file)} 
                  typeIcon="block"
                />
                )}
                <EASButtonList 
                  title="Remover Arquivo"
                  onClick={() => handleClickRemoveFile(file.id_file)} 
                  typeIcon="remove"
                />
              </TableCell> 
            </TableRow>
            ))) : (
            <TableRow hover tabIndex={-1} role="checkbox" >
              <TableCell colSpan={6} scope="row" >
                Nenhum item encontrado 
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
      )}
      <FilesNewFile open={open} setOpen={(e) => setOpen(e)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
    </EASCard>
  );
});

