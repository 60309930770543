import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IDashboardMentory, IDashboardResourcesHuman } from '../../../types';

const getDashboardMentory = async ( mentory ='', module = '', team = ''): Promise<IDashboardMentory> => {
    const  { data } = await Api.get( `/dashboard?public_token=${Environment.PUBLIC_TOKEN}&mentory=${mentory}&type=mentory&module=${module}&team=${team}`);
    return data.data;
};

const getResourcesHumanDashboard = async ( program =''): Promise<IDashboardResourcesHuman> => {
  const  { data } = await Api.get( `/dashboard?public_token=${Environment.PUBLIC_TOKEN}&program=${program}&type=resourceshuman`);
  return data.data;
};

export const DashboardService = {
  getDashboardMentory,
  getResourcesHumanDashboard
};
