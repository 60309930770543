import { IModule, IModulesListResponse} from '../../../contexts/ModulesContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';


const createModule = async (modules: object): Promise<IModule> => {
  const { data } = await Api.post('/modules', { modules: modules } );
  return data;
};

const updateModule= async (modules: IModule): Promise<void> => {
  const { data } = await  Api.put(`/modules/${modules.id_module}`, modules);
  return data;  
};

const deleteModule = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/modules/${id}`);
  return data;   
};

const getModule = async (id: string): Promise<IModule> => {
  const { data } = await Api.get(`/modules/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getModules = async (): Promise<Array<IModule>> => {
  const { data } = await Api.get(`/modules?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};


export const ModulesService = {
  getModules,
  getModule,
  createModule,
  updateModule,
  deleteModule,
};
