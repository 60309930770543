import { EASThemeProvider, ConnectProvider, AuthProvider, ModulesProvider, MentoringProvider, HumanResourcesProvider } from './shared/contexts';
import { EASConnect } from './shared/components';
import { AppRoutes } from './routes';
import { SnackbarProvider} from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export const App = () => {
  
  return (
    <EASThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ConnectProvider>
          <EASConnect>
            <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'right',}}>
              <AuthProvider>
                <ModulesProvider>
                  <HumanResourcesProvider>
                    <MentoringProvider>
                      <AppRoutes />
                    </MentoringProvider>
                  </HumanResourcesProvider>
                </ModulesProvider>
              </AuthProvider>
            </SnackbarProvider>
          </EASConnect>
        </ConnectProvider>
      </QueryClientProvider>
    </EASThemeProvider>
  );
};