
import { LayoutDefault } from '../../shared/layouts';

import { MentoringList } from './MentoringList';

export const Mentoring = () => {


  return (
    <LayoutDefault>
        <MentoringList />
    </LayoutDefault>
  );
};

