import React, { useState } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { Paper, Grid, Typography, StepButton, Box, List, Button, Stack, CardContent, Skeleton, Stepper, Step, StepLabel, StepContent } from '@mui/material/';

import { IEvaluation } from '../../shared/types';
import { useMentoringContext, useAuthContext} from '../../shared/contexts';

import { EvaluationsEditItem } from './EvaluationsEditItem';
import { EvaluationsEditText } from './EvaluationsEditText';

import { EvaluationsService } from '../../shared/services/api/evaluations/EvaluationsService';
import { EvaluationsDetailService } from '../../shared/services/api/evaluations-detail/EvaluationsDetailService';
import { useSnackbar } from 'notistack';
import { EASCard } from '../../shared/components/eas-card/EASCard';
import { EASButton } from '../../shared/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EASDialogAlert } from '../../shared/components';

export const EvaluationEdit = () => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id = ''} = useParams<'id'>();

  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();
 
  const queryClient = useQueryClient();
  
  const [loadingDialogFinish, setLoadingDialogFinish] = useState(false);
  const [openDialogFinish, setOpenDialogFinish] = useState(false);

  const [loadingDialogReopen, setLoadingDialogReopen] = useState(false);
  const [openDialogReopen, setOpenDialogReopen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [evaluation, setEvaluation] = useState<IEvaluation>({});

  const { isLoading } = useQuery(['evaluation-autoevaluation', [mentory?.id_mentory, id]],() => EvaluationsDetailService.getEvaluationsDetail(mentory?.id_mentory, id), {
    staleTime: Infinity,
    onSuccess: data => {
      queryClient.invalidateQueries('evaluation-list');
      setEvaluation(data);
    },
    onError: () => {
      setEvaluation({});
      enqueueSnackbar('Não foi possivel carregar a Avaliação parcial', { variant:'error'});
    },
  });

  const { mutateAsync:updateEvaluation } = useMutation((evaluation:IEvaluation) => EvaluationsService.updateEvaluation(evaluation), {
    onSuccess: () => {
      enqueueSnackbar('Autoavaliação finalizada com sucesso', { variant:'success'});
      queryClient.invalidateQueries('evaluation-autoevaluation');
      setLoadingDialogReopen(false);
      setLoadingDialogFinish(false);
      handleCancelDialogFinish();
      handleCancelDialogReopen();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar finalizar esta autoavaliação', { variant: 'error' });
      setLoadingDialogFinish(false);
      setLoadingDialogReopen(false);
    },
  });

  const handleCancelDialogFinish = () => {
    setOpenDialogFinish(false);
    setLoadingDialogFinish(false);
  };

  const handleConfirmeDialogFinish = () => {
    setLoadingDialogFinish(true);
    const evaluation: IEvaluation = {
      id_evaluation: id,
      status_evaluation:'2'
    };
    updateEvaluation(evaluation);
  };

  const handleCancelDialogReopen = () => {
    setOpenDialogReopen(false);
    setLoadingDialogReopen(false);
  };

  const handleConfirmeDialogReopen = () => {
    setLoadingDialogReopen(true);
    const evaluation: IEvaluation = {
      id_evaluation: id,
      status_evaluation:'1'
    };
    updateEvaluation(evaluation);
  };

  const handleNext = () => {
    if((evaluation && evaluation?.evaluation && (activeStep < (evaluation.evaluation.length - 1)))) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    else{
      setOpenDialogFinish(true);
    }   
  };
 
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setOpenDialogReopen(true);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };


  return (
    <EASCard 
      titleCard="Avaliações"
      actionCard={(mentory?.status_mentory === '1') ? (
          <Stack minWidth='500px' spacing={2} direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
             <EASButton isLoading={isLoading} variant='contained' onClick={() =>  navigate('/avaliacoes')}>Sair</EASButton>
          </Stack>) : <></>
      }
    >
      <CardContent>
        {isLoading && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item zeroMinWidth sx={{minWidth:'40px', display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                  <Skeleton variant="circular" width={30} height={30} />
                </Grid>
                <Grid item >
                  <Skeleton variant="rectangular" width={160} height={20} sx={{borderRadius : 2, mt:'5px' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <Grid container spacing={1}>
                <Grid item zeroMinWidth sx={{minWidth:'40px', display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                  <Skeleton variant="rectangular" width={5} height={300} sx={{borderRadius : 2, }} />
                </Grid>
                <Grid item xs={true}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={true}>
                          <Skeleton variant="rectangular" width={660} height={15} sx={{borderRadius : 2, mt:'5px' }} />
                          <Skeleton variant="rectangular" width={100} height={15} sx={{borderRadius : 2, mt:'10px' }} />
                        </Grid>
                        <Grid item zeroMinWidth sx={{display: 'flex', alignItems: 'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="rectangular" width={180} height={30} />
                        </Grid>
                      </Grid> 
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width="100%" height={1} sx={{borderRadius : 2, mt:'10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={true}>
                          <Skeleton variant="rectangular" width={660} height={15} sx={{borderRadius : 2, mt:'5px' }} />
                          <Skeleton variant="rectangular" width={100} height={15} sx={{borderRadius : 2, mt:'10px' }} />
                        </Grid>
                        <Grid item zeroMinWidth sx={{display: 'flex', alignItems: 'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="rectangular" width={180} height={30} />
                        </Grid>
                      </Grid> 
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width="100%" height={1} sx={{borderRadius : 2, mt:'10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={true}>
                          <Skeleton variant="rectangular" width={660} height={15} sx={{borderRadius : 2, mt:'5px' }} />
                          <Skeleton variant="rectangular" width={100} height={15} sx={{borderRadius : 2, mt:'10px' }} />
                        </Grid>
                        <Grid item zeroMinWidth sx={{display: 'flex', alignItems: 'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="rectangular" width={180} height={30} />
                        </Grid>
                      </Grid> 
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width="100%" height={1} sx={{borderRadius : 2, mt:'10px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={true}>
                          <Skeleton variant="rectangular" width={660} height={15} sx={{borderRadius : 2, mt:'5px' }} />
                          <Skeleton variant="rectangular" width={100} height={15} sx={{borderRadius : 2, mt:'10px' }} />
                        </Grid>
                        <Grid item zeroMinWidth sx={{display: 'flex', alignItems: 'flex-end', flexDirection:'column'}}>
                          <Skeleton variant="rectangular" width={180} height={30} />
                        </Grid>
                      </Grid> 
                    </Grid>
                    <Grid item xs={12}>
                      <Skeleton variant="rectangular" width="100%" height={1} sx={{borderRadius : 2, mt:'10px' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>  
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item zeroMinWidth sx={{minWidth:'40px', display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                  <Skeleton variant="circular" width={30} height={30} />
                </Grid>
                <Grid item >
                  <Skeleton variant="rectangular" width={160} height={20} sx={{borderRadius : 2, mt:'5px' }} />
                </Grid>
              </Grid>
            </Grid> 
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item zeroMinWidth sx={{minWidth:'40px', display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                  <Skeleton variant="circular" width={30} height={30} />
                </Grid>
                <Grid item >
                  <Skeleton variant="rectangular" width={160} height={20} sx={{borderRadius : 2, mt:'5px' }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && (
        <>
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear={(evaluation?.type_evaluation === '0' && evaluation?.status_evaluation !== '2' && mentory?.id_user_mentorado === user?.id && mentory?.status_mentory === '2')  ? false : true} >
            {evaluation?.evaluation && evaluation.evaluation.length && (evaluation.evaluation.map((objective, index) => (
              <Step key={objective.id_objective}  completed={evaluation?.status_evaluation === '2' ||  mentory?.status_mentory =='1' || activeStep > index ? true : false} >
                {mentory?.status_mentory ? (
                  <StepButton color="inherit" onClick={handleStep(index)}>
                  {objective.text_objective}
                  </StepButton>
                ) : (
                  <StepLabel>
                  {objective.text_objective}
                  </StepLabel>
                )}
                <StepContent>
                  <List>
                  {objective.evaluation_detail && (objective.evaluation_detail.map((evaluationDetail) => (
                    <EvaluationsEditItem key={evaluationDetail.id_objective_detail} evaluationDetail={evaluationDetail} typeEvaluation={evaluation?.type_evaluation} statusEvaluation={evaluation?.status_evaluation} statusEvaluationFinal={mentory?.status_mentory} />
                  )))}
                  </List>
                  {((evaluation?.type_evaluation === '1' && mentory?.id_user_mentorado === user?.id) || evaluation?.type_evaluation === '0') && (
                  <EvaluationsEditText 
                    disabled={(evaluation?.type_evaluation === '1' || evaluation?.status_evaluation === '2' || mentory?.status_mentory === '2')} 
                    evaluationComment={objective.evaluation_comment} 
                  />
                  )}
                  {((evaluation?.type_evaluation === '0' && evaluation?.status_evaluation !== '2' && mentory?.status_mentory === '1')) && (
                  <Box sx={{ mb: 2 }}>
                    <div>
                      {((evaluation?.evaluation && index !== evaluation.evaluation.length - 1) || evaluation?.type_evaluation === '0') && (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}

                      >
                        {(evaluation?.evaluation && index == evaluation.evaluation.length - 1) ? 'Finalizar Avaliação' : 'Próxima Competência'}
                      </Button>
                      )}
                      {index !== 0 && (evaluation?.evaluation && evaluation.evaluation.length > 1) && (
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Voltar a Competência Anterior
                      </Button>
                        )}
                    </div>
                  </Box>
                  )}
                </StepContent>
              </Step>
            )))}
          </Stepper>
          {(mentory?.status_mentory === '1') ?
          ((evaluation?.status_evaluation === '2')  && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography variant="h5">Esta avaliação parcial foi marcada como concluída</Typography>
            {((user?.id === mentory?.id_user_mentorado && evaluation?.type_evaluation === '0' && evaluation?.status_evaluation === '2')  && (
              <Typography variant="subtitle1">Caso deseje reabrir esta Avaliação Parcial, solicite a seu mentor</Typography>
            ))}
            {((user?.id === mentory?.id_user_mentor && evaluation?.type_evaluation === '0' && evaluation?.status_evaluation === '2')  && (
            <Button variant="contained" onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reabrir Avaliação Parcial
            </Button>
            ))}
          </Paper>
          )):(
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography variant="h5">Este programa foi encerrado</Typography>
            {((user?.id === mentory?.id_user_mentorado) && (
              <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
            ))}
          </Paper>
          )}
        </>
        )}
      </CardContent>
      <EASDialogAlert
        type="warning"
        title="Finalizar Autoavaliação"
        statusOpen={openDialogFinish}
        actionCancel={handleCancelDialogFinish}
        actionConfirme={handleConfirmeDialogFinish}
        loading={loadingDialogFinish}
      >
        Você tem certeza que deseja finalizar esta autoavaliação. Uma vez finalizada, você não poderá mais alterar os valores informados.
      </EASDialogAlert>

      <EASDialogAlert
        type="warning"
        title="Reabrir Autoavaliação"
        statusOpen={openDialogReopen}
        actionCancel={handleCancelDialogReopen}
        actionConfirme={handleConfirmeDialogReopen}
        loading={loadingDialogReopen}
      >
        Você tem certeza que deseja reabrir esta autoavaliação.
      </EASDialogAlert>

    </EASCard>
  );
};