import { Box, Typography, LinearProgress, Grid, Chip } from '@mui/material';
import { red, orange, blue, green, grey } from '@mui/material/colors';

interface EASProgressListProps {
    progress?:string;
    dateInit?:string;
    dateFinish?:string;
    textProgress?:string;
  } 

export const EASProgressList: React.FC<EASProgressListProps> = ({...props}) => {

    const value = props.progress ? parseInt(props.progress) : 0;

    const colorRender = (()=> { 
        switch(true) {
          case (props.textProgress == 'Bloqueado'): return red[600];
          case (value === 0): return grey[600];
          case (value > 0 && value < 85): return blue[600];
          case (value > 85 && value < 100): return orange[600];
          case (value === 100): return green[600];
          case (value === 101): return red[600];
          default: return null;
    }})();
    
    const dateIniti = (()=> { 
      const tmp = props.dateInit ? new Date(props.dateInit) : new Date();
      tmp.setMinutes(tmp.getMinutes() + tmp.getTimezoneOffset());
      return tmp.toLocaleDateString('pt-BR');
    })();

    const dateFinish = (()=> { 
      const tmp = props.dateFinish ? new Date(props.dateFinish) : new Date();
      tmp.setMinutes(tmp.getMinutes() + tmp.getTimezoneOffset());
      return tmp.toLocaleDateString('pt-BR');
    })();

    return (

      <Grid container spacing={1}>
        <Grid item xs={8} >
          <Typography variant="caption" sx={{fontSize: '0.65rem'}}>De <Typography variant="caption" sx={{fontWeight:500}}>{dateIniti}</Typography> até <Typography variant="caption" sx={{fontWeight:500}}>{dateFinish}</Typography></Typography>
        </Grid>
        <Grid item xs={4} >
          <Chip sx={{width:'100%', color:'white', outline: '0px', textDecoration: 'none', border:'0px', verticalAlign: 'middle', boxSizing:'border-box',  borderRadius:'6px', height: '22px', backgroundColor:colorRender}} label={props.textProgress} />
        </Grid>
        <Grid item  xs={12}>  
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ minWidth: 50 }}>
                  <Typography variant="caption" color="text.secondary">Tempo:</Typography>
              </Box>
              <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate" sx={{
                      backgroundColor: grey[200],
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: colorRender
                      }
                      }} value={value > 100 ? 100 : value} />
              </Box>
              <Box sx={{ minWidth: 30 }}>
                  <Typography variant="caption" color="text.secondary">
                      {value > 100 ? '100%': `${Math.round(value)}%`}
                  </Typography>
              </Box>
            </Box>             
        </Grid>
      </Grid>

    );
};