import { LayoutDefault } from '../../shared/layouts';
import { MeetingsList } from './MeetingsList';

export const Meetings = () => {

  return (
    <LayoutDefault>
      <MeetingsList/>
    </LayoutDefault>
  );
};
