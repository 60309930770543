
import { useEffect } from 'react';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';
import { LayoutDefault } from '../../shared/layouts';
import { ProductBuyList } from './ProductBuyList';

export const ProductBuy = () => {

  const { markMentory } = useMentoringContext();
  const { user } = useAuthContext();
  
  useEffect(() => {
    markMentory(null);
   }, []);

  
  
  
  return (
    <LayoutDefault >
      <ProductBuyList />
    </LayoutDefault>
  );
};