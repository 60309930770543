import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IObjective, IObjectiveSample, IObjectiveList} from '../../../contexts/ObjectiveContext/types';

interface IObjectiveService {
  Teams?: string;
}

interface IObjectiveListResponse extends IObjective {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: IObjective | null;
}

interface IObjectiveSampleResponse extends IObjectiveSample {
  data?: Array<IObjectiveSample>;
  list?: Array<string>;
}

const createObjective = async (objective: object): Promise<IObjectiveService | Error> => {
  try {
    const { data } = await Api.post('/objective', { objective: objective } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {

    const errorSend = JSON.parse(JSON.stringify(error));
    let e = errorSend;
    if (errorSend.response) {
      e = errorSend.response.data;                   // data, status, headers
      if (errorSend.response.data && errorSend.response.data.error) {
        e = errorSend.response.data.error;           // my app specific keys override
      }
    } else if (errorSend.message) {
      e = JSON.stringify(error);
    } else {
      e = 'Unknown error occured - ' + JSON.stringify(error);
    }
    //alert(e);
    return Error(e);

   // const errorSend = JSON.parse(JSON.stringify(error));
    //return new Error(errorSend.message);
  }
};

const updateObjective = async (Objective: IObjective): Promise<void | Error> => {
    const { data } = await  Api.put(`/objective/${Objective.id_objective}`, Objective);
    return data; 
};

const deleteObjective = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/objective/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getObjectives = async (id_mentory = '', page = 1, search = '' ): Promise<IObjectiveList> => {
  const  {data} = await Api.get(`/objective?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&page=${page}&search=${search}&limit=${Environment.LIMITE_DE_LINHAS}`);
  return data;
};

const getObjective = async (userEnrollment: string, client_id: string): Promise<IObjectiveService | Error> => {
  try {
    const { data } = await Api.post('/objective', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getAllObjective = async (id_mentory = '', filterObjective = 0, page = 1, filter = '' ): Promise<IObjectiveListResponse | Error> => {
  try {
    if(!id_mentory)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/objective?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&f=${filterObjective}&page=${page}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getObjectivesSample = async (id_evento = ''): Promise<IObjectiveSampleResponse | Error> => {
  try {
    
    const urlRelativa = `/objectivesample?public_token=${Environment.PUBLIC_TOKEN}&c=${id_evento}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};



export const ObjectiveService = {
  getAllObjective,
  getObjectives,
  getObjective,
  createObjective,
  updateObjective,
  deleteObjective,
  getObjectivesSample,
};
