

import React, { RefObject, ReactElement } from 'react';
import { PercentCrop } from 'react-image-crop';

import { EASFileUploadDropZone } from './EASFileUploadDropZone';
import { EASFileUploadPreview } from './EASFileUploadPreview';


import { Box } from '@mui/material';

interface IEASFileUploadCropProps {  
  setFile: (file:File) => void;
  file?:File;
  acceptFile?:any;
  elementIsDragAccept?: ReactElement;
  elementIsDragReject?: ReactElement;
  elementIsNotDragActive?: ReactElement;
  elementIsDragActive?: ReactElement;
  titleButton?: string;
  isLoadingUpload: boolean;
  percentCrop: PercentCrop;
  previewCanvasRef?:RefObject<HTMLCanvasElement>;
  imgRef?:RefObject<HTMLImageElement>
  setPercentCrop: (PercentCrop:PercentCrop) => void;
}

export const EASFileUploadCrop:React.FC<IEASFileUploadCropProps> = ({...props}) => {
  
  const renderDropZone = () => {
    return (!props.file) ? (
      <EASFileUploadDropZone  
        onSelected={(e) => props.setFile(e)}
        acceptFile={props.acceptFile}
        elementIsDragAccept={props.elementIsDragAccept}
        elementIsDragReject={props.elementIsDragReject}
        elementIsNotDragActive={props.elementIsNotDragActive}
        elementIsDragActive={props.elementIsDragActive} 
      />
    ) : (
      <EASFileUploadPreview file={props.file} percentCrop={props.percentCrop} previewCanvasRef={props.previewCanvasRef} imgRef={props.imgRef} setPercentCrop={(e) => props.setPercentCrop(e)} isLoadingUpload={props.isLoadingUpload} />
    );
  };

  return (
      <Box sx={{ minWidth:'100% !important'}} >
        {renderDropZone()}
      </Box>
  );
  
};