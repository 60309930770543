import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  Stack,
  Avatar,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Chip,
  Box,
  Skeleton,
  Pagination,
  Grid,
} from '@mui/material';

import { IOrder } from '../../shared/contexts/OrdersContext/types';
import { OrdersService } from '../../shared/services/api/orders/OrdersService';

import { IPerson } from '../../shared/contexts/PeopleContext/types';
import { PeopleService } from '../../shared/services/api/people/PeopleService';

import { Environment } from '../../shared/environment';
import { OrdersNewOrder } from './OrdersNewOrder';
import { EASCard, EASDialogAlert} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';


export const OrdersList:React.FC = (() => {


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { debounce } = useDebounce(1000, true);
  const [isLoading, setIsLoading] = useState(true);
  const [initState, setInitState] = useState(false);

  const [allOrders, setAllOrders] = useState<Array<IOrder> | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);

  const [idOrder, setIdOrder] = useState<string | null>(null);
  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
      debounce(() => {
        if(initState) getOrders();
      });
  }, [search]);
  
  useEffect(() => {
    getOrders();
  }, [page,updateList]);

  async function getOrders() {
    setIsLoading(true);
    const ordersResponse = await OrdersService.getOrders(page, search);
    if (ordersResponse instanceof Error) {
      setAllOrders(null);
      setIsLoading(false);
      setTotalCount(0);
      setInitState(true);
    } else {
      if(ordersResponse.data && ordersResponse.total){
        const myOrders = JSON.parse(JSON.stringify(ordersResponse));
        setAllOrders(myOrders.data);
        if(ordersResponse.total)
          setTotalCount(ordersResponse.total);
        setIsLoading(false);
        setInitState(true);
      }
      else{
        setAllOrders(null);
        setIsLoading(false);
        setTotalCount(0);
        setInitState(true);
      }
    }
  }

  const handleClickRemoveOrder = (id_user?:string) => {
    if(id_user){
      setIdOrder(id_user);
      setTitleDialog('Excluir Operador');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir este operador? A exclusão do operador não afetará outros vínculos do cadastro desta pessoa.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockOrder = (id_user?:string) => {
    if(id_user){
      setIdOrder(id_user);
      setTitleDialog('Bloquear Operador/Pessoa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso deste operador/pessoa?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockOrder = (id_user?:string) => {
    if(id_user){
      setIdOrder(id_user);
      setTitleDialog('Desbloquear Operador/Pessoa');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso deste operador/pessoa?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 

  const handleClickResetOrder = (id_user?:string) => {
    if(id_user){
      setIdOrder(id_user);
      setTitleDialog('Redefinir Senha');
      setTextDialog(<Typography component="span">Você tem certeza que deseja redefinir a senha deste operador/pessoa? Ao confirmar, será gerado uma nova senha aleatória para este operador/pessoa.</Typography>);
      setExecuteDialog('reset');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdOrder(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeOrder();
      break;
      case 'block':
        blockOrder(false);
      break;
      case 'unblock':
        blockOrder(true);
      break;
      case 'reset':
        resetPassword();
      break;
    }
  };

  const removeOrder = () => {
    setLoadingDialog(true);
    if(idOrder){
      OrdersService.deleteOrder(idOrder).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar remover o operador! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant:'error'});
          setLoadingDialog(false);
        } else {
            enqueueSnackbar('Operador removido com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            setUpdateList(!updateList);
            handleCancelDialog();
        }
      });
    }
  };

  const blockOrder = (block:boolean) => {
    setLoadingDialog(true);
    if(idOrder){
      const blockPerson:IPerson = {
        id_user:idOrder,
        block_user:(block ? '0' : '1'),
        status_user:(block ? '1' : '0')
      };

    }
  };

  const resetPassword= () => {
    setLoadingDialog(true);
    if(idOrder){
      const randomstring = Math.random().toString(36).slice(-8);
      const resetOrder:IPerson = {
        id_user:idOrder,
        password_user:randomstring,
        activation_user:'1',
      };

    }
  };

  return (
    <>          
      <EASCard 
        titleCard="Meus Pedidos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Acesso
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (allOrders && allOrders.length) ? (allOrders.map((order) => (
              <TableRow 
                  hover 
                  key={order.id_user} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {order?.id_user}
                </TableCell>
                <TableCell scope="row" >
                  <Grid container spacing={2}>
                    <Grid item>
                      imagem
                    </Grid>
                    <Grid item zeroMinWidth sm={true}>
                      <Typography variant="subtitle2">
                        nome
                      </Typography>
                      <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        email
                      </Typography>
                    </Grid>
                  </Grid> 
                </TableCell>
                <TableCell align="center">
                  <Chip label='Ativo' color='success'/>
                </TableCell>
                <TableCell align="center">
                    {order?.name_user_group}
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Pessoa"
                      onClick={() => navigate(`/operadores/detalhes/${order.id_user}`)} 
                      typeIcon="edit"
                  />
                  <EASButtonList 
                      title="Redefinir Senha"
                      onClick={() => handleClickResetOrder(order.id_user)} 
                      typeIcon="reset"
                  />
                  <EASButtonList 
                    title="Bloquear Pessoa"
                    onClick={() => handleClickBlockOrder(order.id_user)} 
                    typeIcon="block"
                  />
                  <EASButtonList 
                    title="Remover Operador"
                    onClick={() => handleClickRemoveOrder(order.id_user)} 
                    typeIcon="remove"
                  />
                </TableCell>
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
            <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
              <Pagination
                page={page}
                count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })}
                variant="outlined" 
                shape="rounded"
                color="primary"
                siblingCount={3} 
                boundaryCount={1}
              />
            </Box>
            )}
      </EASCard>
      <OrdersNewOrder open={open} setOpen={(e) => setOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

