import { useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box, Grid, CircularProgress, ListItem, ListItemAvatar, Avatar, ListItemText} from '@mui/material';

import { useSnackbar } from 'notistack';
import { EASButton, EASInput, EASSelect } from '../../shared/forms';
import { IProgram, IProgramsMentoring } from '../../shared/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgramsMentoringService } from '../../shared/services/api/programsmentoring/ProgramsMentoringService';
import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';

interface IProgramResourcesHumanMentoringAddProps {
  program?: IProgram;
  open: boolean;
  setOpen: (open:boolean) => void;
}

export const ProgramResourcesHumanMentoringAdd: React.FC<IProgramResourcesHumanMentoringAddProps> = ({ program, open, setOpen}) => {
  
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [saveLoading, setSaveLoading] = useState(false);
  const [programsMentoring, setProgramsMentoring] = useState<IProgramsMentoring>({});
  const [programsMentoringError, setProgramsMentoringError] = useState<IProgramsMentoring | null>(null);

  const { data:guests, isLoading:isLoadingGuests  } = useQuery(['guest-list', program?.id_program],() => UtilService.getGuests(program?.id_program),  {
    staleTime: Infinity,
    onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista de convidados.', { variant:'error'});
    },
  });
  const { mutateAsync:storeCreate, } = useMutation((newProgramsMentoring:IProgramsMentoring) => ProgramsMentoringService.createProgramsMentoring(newProgramsMentoring), {
    onSuccess: () => {
        queryClient.invalidateQueries('program-mentoring-list');
        queryClient.invalidateQueries('operator-mentoring-list');
        enqueueSnackbar('Dupla salva com sucesso!', { variant:'success'});
        setOpen(false);
        setSaveLoading(false);
        setProgramsMentoring({});
        setProgramsMentoringError(null);

    },
    onError: () => {
        enqueueSnackbar('Erro ao tentar salvar adupla.', { variant:'error'});
        setSaveLoading(false);
    },
});



  const programsMentoringSchema = yup.object().shape({
    id_user_mentorado: yup.string().required('Selecione um Mentorado'),
    id_user_mentor: yup.string().required('Selecione um Mentor')
  });


  const handleCancelDialog = () => {
    setOpen(false);
    setSaveLoading(false);
    setProgramsMentoring({});
    setProgramsMentoringError(null);
  };


  const handleConfirmeDialog = async () => {
    programsMentoringSchema
    .validate( programsMentoring, { abortEarly: false })
    .then(validateValues => {
      setSaveLoading(true);
      const newProgramsMentoring: IProgramsMentoring = {
        id_program: program?.id_program,
        id_user_mentorado: validateValues.id_user_mentorado,
        id_user_mentor: validateValues.id_user_mentor,
        user_register: user?.id,
      }; 
      storeCreate(newProgramsMentoring);
    })
    .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setProgramsMentoringError(existingValues => ({
                                              ...existingValues,
                                              [keyError]: error.message
                                          }));                        
            }
        });
    });
  };
  
  return ( <Dialog
                open={open}
                onClose={handleCancelDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  {'Criar Nova Dupla'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Crie uma nova Dupla, escolhendo um mentor e um mentorado dentre as pessoas que se cadastraram e aceitaram o convite para participar deste programa.
                  </DialogContentText>
                  <Box sx={{mt:2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    {saveLoading && (                   
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress variant='indeterminate' />
                        </Box>
                    )}
                    {!saveLoading && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <EASSelect 
                            isLoading={isLoadingGuests}
                            options={guests ? guests : []}
                            
                            onFocus={() => 
                              setProgramsMentoringError(existingValues => ({
                                ...existingValues,
                                id_user_mentorado: '',
                              }))
                            }
                            label="Escolha um Mentorado"
                            getOptionLabel={(option) => option.text}
                            renderOption={(props, option) => (
                                    <ListItem {...props}>
                                        <ListItemAvatar>
                                            <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                        </ListItemAvatar>
                                        <ListItemText primary={option.text} />
                                    </ListItem>
                            )}
                            value={guests && guests.find(item => item.value == programsMentoring?.id_user_mentorado)}                                
                            onChangeSelect={(event, value:IList) => {
                                    if (value && value.value) {
                                        setProgramsMentoring(existingValues => ({
                                            ...existingValues,
                                            id_user_mentorado: value.value,
                                        }));
                                    } else {
                                        if(event.target.nodeName == 'svg') {
                                          setProgramsMentoring(existingValues => ({
                                                ...existingValues,
                                                id_user_mentorado: '',
                                            }));
                                        }

                                    }
                                }
                            }
                            error={!!programsMentoringError?.id_user_mentorado}
                            helperText={programsMentoringError?.id_user_mentorado}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EASSelect 
                            isLoading={isLoadingGuests}
                            options={guests ? guests : []}
                            
                            onFocus={() => 
                              setProgramsMentoringError(existingValues => ({
                                ...existingValues,
                                id_user_mentor: '',
                              }))
                            }
                            label="Escolha um Mentor"
                            getOptionLabel={(option) => option.text}
                            renderOption={(props, option) => (
                                    <ListItem {...props}>
                                        <ListItemAvatar>
                                            <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                        </ListItemAvatar>
                                        <ListItemText primary={option.text} />
                                    </ListItem>
                            )}
                            value={guests && guests.find(item => item.value == programsMentoring?.id_user_mentor)}                                
                            onChangeSelect={(event, value:IList) => {
                                    if (value && value.value) {
                                        setProgramsMentoring(existingValues => ({
                                            ...existingValues,
                                            id_user_mentor: value.value,
                                        }));
                                    } else {
                                        if(event.target.nodeName == 'svg') {
                                          setProgramsMentoring(existingValues => ({
                                                ...existingValues,
                                                id_user_mentor: '',
                                            }));
                                        }

                                    }
                                }
                            }
                            error={!!programsMentoringError?.id_user_mentor}
                            helperText={programsMentoringError?.id_user_mentor}
                        />
                      </Grid>
                    </Grid>
                    )}
                  </Box>            
                
                </DialogContent>
                <DialogActions sx={{mr:2, mb:1}}>
                  <EASButton onClick={handleCancelDialog} disabled={saveLoading} variant="outlined">Cancelar</EASButton>
                  <EASButton onClick={handleConfirmeDialog} disabled={saveLoading} variant="contained" autoFocus>
                    Salvar
                  </EASButton>
                </DialogActions>
              </Dialog>  

  );
};