import { ICompany, ICompaniesList } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const createCompany = async (companies: ICompany): Promise<ICompany> => {
  const { data } = await Api.post('/companies', { Company: companies } );
  return data;
};

const updateCompany = async (Company: ICompany): Promise<void> => {
  const { data } = await  Api.put(`/companies/${Company.id_company}`, Company);
  return data;  
};

const deleteCompany = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/companies/${id}`);
  return data;   
};

const getCompany = async (id?: string): Promise<ICompany> => {
  const { data } = await Api.get(`/companies/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getCompanies = async (page = 1, search = '' ): Promise<ICompaniesList> => {
  const  {data} = await Api.get(`/companies?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};

export const CompaniesService = {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
