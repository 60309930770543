import React, { ReactElement, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Stack, Avatar, TableHead, TableRow, TableBody, TableCell, TableContainer, Typography, Skeleton, Grid, Chip } from '@mui/material';


import { IProgram } from '../../shared/types';
import { ProgramsService } from '../../shared/services/api/programs/ProgramsService';

import { Environment } from '../../shared/environment';
import { ProgramsNewProgram } from './ProgramsNewProgram';
import { EASCard, EASDialogAlert, EASProgressList, PaginationComponent } from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';

import { useMutation, useQuery, useQueryClient } from 'react-query';

export const ProgramsList:React.FC = (() => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);

  const [idProgram, setIdProgram] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-program-list', [search, page]],() =>  ProgramsService.getPrograms(page, search), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de programas.', { variant:'error'});
    },
  });

  const { mutateAsync:storeBlockProgram } = useMutation((blockProgram:IProgram) =>  ProgramsService.updateProgram(blockProgram), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-program-list');
      enqueueSnackbar('Programa ' + (blockText ? 'desbloqueado' : 'bloqueado') + ' com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' o programa!', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemoveProgram } = useMutation((idProgram:string) => ProgramsService.deleteProgram(idProgram), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-program-list');
      enqueueSnackbar('Programa removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o programa!', { variant:'error'});
      setLoadingDialog(false);
    },
  });


  const handleClickBlockProgram = (id_program?:string) => {
    if(id_program){
      setIdProgram(id_program);
      setTitleDialog('Bloquear Programa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso das pessoa a este programa?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockProgram = (id_program?:string) => {
    if(id_program){
      setIdProgram(id_program);
      setTitleDialog('Desbloquear Programa');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso das pessoa a este programa?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  const handleClickRemoveProgram = (id_program?:string) => {
    if(id_program){
      setIdProgram(id_program);
      setTitleDialog('Excluir Programa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene este programa?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdProgram(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeProgram();
      break;
      case 'block':
        blockProgram(false);
      break;
      case 'unblock':
        blockProgram(true);
      break;
    }
  };

  
  const removeProgram = () => {
    setLoadingDialog(true);
    if(idProgram){
      storeRemoveProgram(idProgram);
    }
  };

  const blockProgram = (block:boolean) => {
    setLoadingDialog(true);
    if(idProgram){
      setBlockTest(block);
      const blockProgram:IProgram = {
        id_program:idProgram,
        status_program:(block ? '1' : '0')
      };
      storeBlockProgram(blockProgram);
    }
  };


  return (
    <>          
      <EASCard 
        titleCard="Programas"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }
      >   
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Período
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((program) => (
            <TableRow 
                hover 
                key={program.id_program} 
                tabIndex={-1} 
                role="checkbox"
            >
              <TableCell align="center">
                {program?.id_program}
              </TableCell>
              <TableCell scope="row" >
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar variant="square" alt={program?.name_program} src={program?.image_program ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + program?.image_program  :  '/'} />
                  </Grid>
                  <Grid item zeroMinWidth sm={true} >
                    <Typography variant="subtitle2">
                      {program?.name_program}
                    </Typography>
                    <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                      {program?.name_type_program}
                    </Typography>
                  </Grid>
                </Grid> 
              </TableCell>
              <TableCell align="center">
                <Chip label={program?.status_program == '1'? 'Ativo' : 'Bloqueado'} color={program?.status_program == '1' ? 'success' : 'error'} />
              </TableCell>
              <TableCell sx={{width:{xs:'100%', md:'300px'}}} >
                <EASProgressList
                    dateInit={program?.start_program}
                    dateFinish={program.close_program}
                    progress={program?.progress_program}
                    textProgress={program?.name_progress_program}
                />   
              </TableCell>
              <TableCell align="center">
                <EASButtonList 
                    title="Editar Programa"
                    onClick={() => navigate(`/programas/detalhes/${program.id_program}`)} 
                    typeIcon="edit"
                />
                {program?.status_program == '0' ? (
                <EASButtonList 
                    title="Desbloquear Programa"
                    onClick={() => handleClickUmBlockProgram(program.id_program)} 
                    typeIcon="unblock"
                />
                ) : (
                <EASButtonList 
                  title="Bloquear Programa"
                  onClick={() => handleClickBlockProgram(program.id_program)} 
                  typeIcon="block"
                />
                )}
                <EASButtonList 
                  title="Remover Programa"
                  onClick={() => handleClickRemoveProgram(program.id_program)} 
                  typeIcon="remove"
                />
              </TableCell>
            </TableRow>
            ))) : (
            <TableRow hover tabIndex={-1} role="checkbox" >
              <TableCell colSpan={6} scope="row" >
                Nenhum item encontrado 
              </TableCell>
            </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoading  && (
        <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
      <ProgramsNewProgram open={open} setOpen={(e) => setOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

