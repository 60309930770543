
import { useEffect } from 'react';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';
import { LayoutDefault } from '../../shared/layouts';
import { SelectMentoringList } from './SelectMentoringList';

export const SelectMentoring = () => {

  const { markMentory } = useMentoringContext();
  
  useEffect(() => {
    markMentory(null);
   }, []);
  
  return (
    <LayoutDefault hideSidebar={true}>
      <SelectMentoringList />
    </LayoutDefault>
  );
};