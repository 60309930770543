

import React, { useState } from 'react';
import { Card, Typography, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IToolsItemsCardProps {
    image: string;
    title: string;
    description: string;
    link: string;
}  
 

export const ToolsItemsCard: React.FC<IToolsItemsCardProps> = ({image, title, description, link}) => {

    const navigate = useNavigate();
    return (
        <Card sx={{height:'100%',display: 'flex', flexDirection: 'column',}}>
            <CardMedia
            sx={{ height: 170 }}
            image={image}
            title={title}
            />
            <CardContent>
                <Typography gutterBottom variant="h6" component="span" sx={{fontSize:16}}>
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions sx={{ mt:'auto'}}>
                <Button size="small" onClick={() => navigate(link)}>Acessar</Button>
            </CardActions>
        </Card>
    );
};