
import { useEffect } from 'react';
import { useModulesContext } from '../../shared/contexts';
import { LayoutDefault } from '../../shared/layouts';
import { SelectModuleList } from './SelectModuleList';

export const SelectModule = () => {

  const { markModule } = useModulesContext();
  
  useEffect(() => {
    markModule(null);
   }, []);

  
  
  
  return (
    <LayoutDefault hideSidebar={true}>
      <SelectModuleList />
    </LayoutDefault>
  );
};