import { useState, useEffect } from 'react';
import * as yup from 'yup';

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider} from '@mui/material';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { IMeeting } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { UtilService, IList } from '../../shared/services/api/util/UtilService';
import { useSnackbar } from 'notistack';
import { EASButton, EASInput, EASDateTimePicker, EASSelect } from '../../shared/forms';


import { useMutation, useQueryClient } from 'react-query';
import AddIcon from '@mui/icons-material/Add';

interface IMeetingNewProps {
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;

}
export const MeetingNew: React.FC<IMeetingNewProps> = ({open, setOpen, setUpdateList})  => {

  const [saveLoading, setSaveLoading] = useState(false);
  //const [isLoading, setIsLoading] = useState(true);

  const [meeting, setMeeting] = useState<IMeeting>({});
  const [meetingError, setMeetingError] = useState<IMeeting | null>(null);
  
  const [durations, setDurations] = useState<Array<IList>>([]);
  const [typesMeeting, setTypesMeeting] = useState<Array<IList>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();
  
  const handleClose = () => {
    setMeeting({});
    setMeetingError(null);
    setOpen(false);
  };

  useEffect(() => {
    async function getLists() {
      const durationsResponse = await UtilService.getDuration();
      setDurations(durationsResponse);
    }
    async function getTypeMeeting() {
      const typesMeetingResponse = await UtilService.getTypeMeeting();
      setTypesMeeting(typesMeetingResponse);
    }
    setMeeting({});
    setMeetingError(null);
    if(open == true)
      getLists().then(() => {
        getTypeMeeting();
      });  
  }, [open]);


  const meetingSchema = yup.object().shape({    
    subject_meeting: yup.string().required('Informe um assunto'),
    date_meeting: yup.string().required('Informe uma data'),
    duration_meeting: yup.string().required('Informe a duração')
  });

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation((Meeting:IMeeting) => MeetingsService.createMeeting(Meeting), {
    onSuccess: data => {
      queryClient.invalidateQueries('meeting-list');
      enqueueSnackbar('Nova sessao cadastrada com sucesso!', { variant:'success'});
      setSaveLoading(false);
      setOpen(false);
      setMeeting({});
      
    },
    onError: () => {
      enqueueSnackbar('Erro ao cadastrar nova sessão.', { variant:'error'});
    },
  });


  const handleButtonSave = () => {
    meetingSchema
      .validate( meeting, { abortEarly: false })
      .then(validateValues => {
        setSaveLoading(true);
        const newMeeting: IMeeting = {
          status_meeting: '1',
          date_meeting: new Date(validateValues?.date_meeting).toLocaleString('sv-SE',{timeZone:'America/Sao_Paulo',dateStyle:'short', timeStyle: 'short'}),
          duration_meeting: validateValues.duration_meeting,
          subject_meeting: validateValues.subject_meeting,
         // relatory_meeting?: string;
         // relatory_mentor_meeting?: string;
          //lessons_meeting ?: string;
         // homework_meeting?: string;
          type_meeting: validateValues.type_meeting,
          local_meeting: meeting?.local_meeting,
          id_mentory: mentory?.id_mentory,
          user_register: user?.id,

        };   
        mutate(newMeeting);
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setMeetingError(existingValues => ({
                    ...existingValues,
                    [keyError]: error.message
                }));                        
            }
        });
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Cadastrar Nova Sessão</DialogTitle>
      <Divider/>
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!saveLoading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EASInput 
                  isLoading={isLoading}
                  label="Tema da Sessão"
                  value={meeting?.subject_meeting} 
                  onChange={(event) => setMeeting(existingValues => ({
                                                  ...existingValues,
                                                  subject_meeting: event.target.value,
                                                }))
                          }  
                  onFocus={() => setMeetingError(existingValues => ({
                                                  ...existingValues,
                                                  subject_meeting: '',
                                              }))
                          }
                  error={!!meetingError?.subject_meeting}
                  helperText={meetingError?.subject_meeting}
                />  
              </Grid>
              <Grid item xs={12} md={6}>
                <EASDateTimePicker 
                  isLoading={isLoading}
                  onFocus={() => 
                    setMeetingError(existingValues => ({
                      ...existingValues,
                      date_meeting: '',
                      }))
                  }
                  onOpen={() => 
                    setMeetingError(existingValues => ({
                      ...existingValues,
                      date_meeting: '',
                      }))
                  }
                  label="Data"
                  value={meeting?.date_meeting} 
                  error={!!meetingError?.date_meeting}
                  helperText={meetingError?.date_meeting}
                  onChangePiker={(e) => {  
                                      if(e instanceof Date) {      
                                        setMeeting(existingValues => ({
                                              ...existingValues,
                                              date_meeting: e.toISOString(),
                                          }));
                                      }}
                                  }
                onChange={(e) => {    
                    setMeeting(existingValues => ({
                          ...existingValues,
                          date_meeting:e.target.value,
                      }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EASSelect 
                    isLoading={isLoading}
                    options={durations}
                    label="Duração"
                    getOptionLabel={(option) => option.text}
                    value={durations.find(item => item.value == meeting?.duration_meeting)}                                  
                    onChangeSelect={(event, value:IList) => {
                        if (value && value.value) {
                            setMeeting(existingValues => ({
                                ...existingValues,
                                duration_meeting: value.value,
                            }));
                        }
                    }
                    }  
                    error={!!meetingError?.duration_meeting}
                    helperText={meetingError?.duration_meeting}
                    />
              </Grid>
              
              <Grid item xs={12} md={4}>
                <EASSelect 
                    isLoading={isLoading}
                    options={typesMeeting}
                    label="Tipo"
                    getOptionLabel={(option) => option.text}
                    value={typesMeeting.find(item => item.value == meeting?.type_meeting)}                                  
                    onChangeSelect={(event, value:IList) => {
                        if (value && value.value) {
                            setMeeting(existingValues => ({
                                ...existingValues,
                                type_meeting: value.value,
                            }));
                        }
                    }
                    }  
                    error={!!meetingError?.type_meeting}
                    helperText={meetingError?.type_meeting}
                    />
              </Grid>
              <Grid item xs={12} md={8}>
                <EASInput
                  isLoading={isLoading}
                  label={'Local/Link'}
                  value={meeting?.local_meeting} 
                  onChange={(event) => setMeeting(existingValues => ({
                                                  ...existingValues,
                                                  local_meeting: event.target.value,
                                                }))
                          }  
                  onFocus={() => setMeetingError(existingValues => ({
                                                  ...existingValues,
                                                  local_meeting: '',
                                              }))
                          }
                  error={!!meetingError?.local_meeting}
                  helperText={meetingError?.local_meeting}
                />
              </Grid>


            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
            <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
            <EASButton 
                  isLoading={isLoading}
                  variant='contained'
                  disabled={saveLoading}
                  endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
                  onClick={handleButtonSave}>Inserir Sessão</EASButton>
      </DialogActions>
    </Dialog> 

  );
};
