
import { IHumanResource } from './types';

export function setHumanResourceLocalStorange(humanresource: IHumanResource | null) {
    if(humanresource)
        localStorage.setItem('eashumres', JSON.stringify(humanresource));
    else
        localStorage.removeItem('eashumres');
}

export function getHumanResourceLocalStorange() {
    const json = localStorage.getItem('eashumres');
    if(!json){
        return null;
    }
    const humanresource = JSON.parse(json);
    return humanresource ?? null;
}




