import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluation, IEvaluationDetail } from '../../../types';


const getEvaluationsDetail = async (id_mentory = '', id_evaluation = ''): Promise<IEvaluation> => {
    const  {data} = await Api.get(`/evaluationsdetail?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&evaluation=${id_evaluation}`);
    return data;
};

const createEvaluationDetail = async (evaluationDetail: object): Promise<void> => {
  const { data } = await Api.post('/evaluationsdetail', { evaluationDetail: evaluationDetail } );
  return data;
};

const updateEvaluationDetail = async (evaluationDetail: IEvaluationDetail): Promise<void> => {
  const { data } = await  Api.put(`/evaluationsdetail/${evaluationDetail.id_evaluation_detail}`, evaluationDetail);
  return data;  
};

export const EvaluationsDetailService = {
  getEvaluationsDetail,
  createEvaluationDetail,
  updateEvaluationDetail,
};
