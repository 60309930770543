import { useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import PersonTabProfile, { IRefProfile } from './PersonTabProfile';

import { IPerson } from '../../shared/contexts/PeopleContext/types';
import { PeopleService } from '../../shared/services/api/people/PeopleService';
import { UtilService} from '../../shared/services/api/util/UtilService';

export const Person = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refProfile = useRef<IRefProfile>(null);

    const storeDisplay = () => {
        if(refProfile.current)
            refProfile.current.savePerson();
    };

    const returnList = () => {
        queryClient.invalidateQueries('operator-operator-edit');
        navigate('/pessoas');
    };


    const { data:person, isFetching:isLoadingPerson } = useQuery(['operator-person-edit', id], () => PeopleService.getPerson(id), 
    {
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
      },
    });


    const { data:estados, isLoading:isLoadingEstados } = useQuery(['list-estados'], () =>  UtilService.getEstados(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:estadoCivil, isLoading:isLoadingEstadoCivils } = useQuery(['list-estadoCivil'], () =>  UtilService.getEstadoCivil(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:generos, isLoading:isLoadingGeneros } = useQuery(['list-generos'], () =>  UtilService.getGeneros(), {
        
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });
   
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updatePerson:IPerson) =>PeopleService.updatePerson(updatePerson), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-people-list');
            queryClient.invalidateQueries('operator-person-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar alterações.', { variant:'error'});
        },
    });


    return (
            <LayoutDefault >
                <EASCard 
                    titleCard={'Editar Pessoa'}
                    breadcrumbsCard=""
                    actionCard={<Stack direction="row" spacing={1}>
                                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                    <EASButton variant="outlined" onClick={returnList}>Sair</EASButton>
                                </Stack>} >
                    <Box sx={{mt: 2}}>
                        <PersonTabProfile 
                            personEdit={person} 
                            isLoading={ isLoadingPerson || isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros || isLoadingStore} 
                            estadoCivil={estadoCivil}
                            generos={generos}
                            estados={estados}
                            storeUpdate={(operator) => storeUpdate(operator)}
                            ref={refProfile} 
                        />
                    </Box>
                </EASCard>
            </LayoutDefault>
    );
};