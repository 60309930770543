import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IMeeting, IMeetingsList } from '../../../types';


const getMeeting = async (id: string): Promise<IMeeting> => {
  const { data } = await Api.get(`/meetings/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getMeetings = async (page = 1, id_mentory = '' ): Promise<IMeetingsList> => {

    const { data } = await Api.get(`/meetings?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&mentory=${id_mentory}`);
    return data;
};

const updateMeeting = async (Meeting: IMeeting): Promise<void> => {
    const { data } = await  Api.put(`/meetings/${Meeting.id_meeting}`, Meeting);
    return data;
};

const deleteMeeting = async (id: string): Promise<void> => {
    const { data } = await Api.delete(`/meetings/${id}`);
    return data;   
};

const createMeeting = async (meeting: IMeeting): Promise<void> => {
    await Api.post('/meetings', { Meeting: meeting } );
};

export const MeetingsService = {
  getMeeting,
  getMeetings,
  updateMeeting,
  deleteMeeting,
  createMeeting
};
