import React from 'react';

import { LayoutDefault } from '../../shared/layouts';

import { OrdersList } from './OrdersList';

export const Orders = () => {

  return (
    <LayoutDefault>  
      <OrdersList />        
    </LayoutDefault>
  );
};

