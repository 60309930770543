import { Routes, Route, Navigate } from 'react-router-dom';
import { EvaluationsExternal } from '../pages';

export const EvaluationExternal = () => {

  return (
    <Routes>
      <Route path="/" element={<EvaluationsExternal/>} />
    </Routes>
  );
};
