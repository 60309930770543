
import { useState, useMemo, useEffect, ReactElement } from 'react';
import { Box, Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Pagination, Skeleton } from '@mui/material';

import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { EASCard, EASDialogAlert } from '../../shared/components';
import { Environment } from '../../shared/environment';
import { TagsnewTag } from './TagsNewTag';
import { TagsEdit } from './TagsEdit';
import { TagsService } from '../../shared/services/api/tags/TagsService';
import { ITag } from '../../shared/contexts/TagsContext/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface ITagsListProps {
    children?: () => void;
  }  

export const TagsList: React.FC<ITagsListProps> = () => { 

    
    const { enqueueSnackbar } = useSnackbar();

    const [blockText, setBlockTest] = useState(false);



    const [open, setOpen] = useState(false);
    //const [isLoading, setIsLoading] = useState(false);
    const [tag, setTag] = useState<ITag | null>(null);
    //const [totalCount, setTotalCount] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
   // const [updateList, setUpdateList] = useState(false);

    const [loadingDialog, setLoadingDialog] = useState(false);
    const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [titleDialog, setTitleDialog] = useState<string | null>('');
    const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
    const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | null>(null);
    const [idTag, setIdTag] = useState<string | null>(null);

    const [editOpen, setEditOpen] = useState(false);

    const search = useMemo(() => {
      return searchParams.get('search') || '';
    }, [searchParams]);
    
    const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
    }, [searchParams]);


    const queryClient = useQueryClient();

    const { data, isLoading } = useQuery(['operator-tag-list', [page, search]],() =>  TagsService.getTags(page, search),  {
      /*staleTime: Infinity,*/
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista de tags.', { variant:'error'});
      },
    });
  
    const { mutateAsync:storeBlock } = useMutation((blockTag:ITag) =>  TagsService.updateTag(blockTag), {
      onSuccess: () => {
        queryClient.invalidateQueries('operator-tag-list');
        enqueueSnackbar('Tag ' + (blockText ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
        setLoadingDialog(false);
        handleCancelDialog();
      },
      onError: () => {
        enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' a tag!', { variant:'error'});
      },
    });
  
  
    const { mutateAsync:storeRemove } = useMutation((idVideo:string) => TagsService.deleteTag(idVideo), {
      onSuccess: () => {
        queryClient.invalidateQueries('operator-tag-list');
        enqueueSnackbar('Tag removida com sucesso!', { variant:'success'});
        setLoadingDialog(false);
        handleCancelDialog();      
      },
      onError: () => {
        enqueueSnackbar('Erro ao tentar remover a tag!', { variant:'error'});
        setLoadingDialog(false);
      },
    });
  
  
  
  
    const handleClickRemoveTag = (id_tag?:string) => {
      if(id_tag){
        setIdTag(id_tag);
        setTitleDialog('Excluir Tag');
        setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta tag?.</Typography>);
        setExecuteDialog('remove');
        setOpenDialog(true);
      }
    }; 
  
    const handleClickBlockTag = (id_tag?:string) => {
      if(id_tag){
        setIdTag(id_tag);
        setTitleDialog('Bloquear Tag');
        setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear esta tag?</Typography>);
        setExecuteDialog('block');
        setOpenDialog(true);
      }
    }; 
  
    const handleClickUmBlockTag = (id_tag?:string) => {
      if(id_tag){
        setIdTag(id_tag);
        setTitleDialog('Desbloquear Tag');
        setTextDialog(<Typography component="span">Você deseja desbloquear esta tag?</Typography>);
        setExecuteDialog('unblock');
        setOpenDialog(true);
      }
    }; 
    
    const handleCancelDialog = () => {
      setIdTag(null);
      setTitleDialog(null);
      setTextDialog(null);
      setExecuteDialog(null);
      setOpenDialog(false);
      setOpenDialogSuccess(false);
    };
  
  
    const handleConfirmeDialog = () => {
      switch(executeDialog){
        case 'remove':
          removeTag();
        break;
        case 'block':
          blockTag(false);
        break;
        case 'unblock':
          blockTag(true);
        break;
      }
    };
  
    const removeTag = () => {
      setLoadingDialog(true);
      if(idTag){
        storeRemove(idTag);
      }
    };
  
    const blockTag = (block:boolean) => {
      setLoadingDialog(true);
      if(idTag){
        setBlockTest(block);
        const newBlockTag:ITag = {
          id_tag:idTag,
          status_tag:(block ? '1' : '0')
        };
        storeBlock(newBlockTag);
      }
    };
  

    const handleEditTag = (editTag?:ITag) => {
      if(editTag){
        setTag(editTag);
        setEditOpen(true);
      }
    }; 
    
    return (
        <>
            <EASCard 
                titleCard="Tags"
                actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                                <EASInput label="Pesquisar" type='search'  value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                                <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                            </Stack>}
            >
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" align="center">
                                Id
                                </TableCell>
                                <TableCell component="th" scope="row">
                                Nome
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                Status
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                Ações
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {isLoading && (
                        <>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </TableCell>
                            </TableRow>
                        </>
                        )}
                            {!isLoading && ( data?.data &&  data?.data.length> 0 ? (data.data.map((tag) => (
                            <TableRow 
                                hover 
                                key={tag.id_tag} 
                                tabIndex={-1} 
                                role="checkbox"
                            >
                                <TableCell align="center">
                                    {tag?.id_tag}
                                </TableCell>
                                <TableCell scope="row" >
                                    <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                        {tag?.name_tag}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Chip label={tag?.status_tag == '1'? 'Ativo' : 'Bloqueado'} color={tag?.status_tag == '1' ? 'success' : 'error'} />
                                </TableCell>
                                <TableCell align="center">
                                    <EASButtonList 
                                        title="Editar Tag"
                                        onClick={() => handleEditTag(tag)} 
                                        typeIcon="edit"
                                    />
                                    {tag?.status_tag == '0' ? (
                                    <EASButtonList 
                                        title="Desbloquear Tag"
                                        onClick={() => handleClickUmBlockTag(tag.id_tag)} 
                                        typeIcon="unblock"
                                    />
                                    ) : (
                                    <EASButtonList 
                                        title="Bloquear Tag"
                                        onClick={() => handleClickBlockTag(tag.id_tag)} 
                                        typeIcon="block"
                                    />
                                    )}
                                    <EASButtonList 
                                        title="Remover Tag"
                                        onClick={() => handleClickRemoveTag(tag.id_tag)} 
                                        typeIcon="remove"
                                    />
                                </TableCell> 
                            </TableRow>
                            ))) : (
                            <TableRow hover tabIndex={-1} role="checkbox" >
                                <TableCell colSpan={6} scope="row" >
                                Nenhum item encontrado 
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(!isLoading && data?.total_pages && data.total_pages > 1) ? (
                <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
                    <Pagination
                        page={page}
                        count={data?.total_pages}
                        onChange={(_, newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })}
                        variant="outlined" 
                        shape="rounded"
                        color="primary"
                        siblingCount={3} 
                        boundaryCount={1}
                    />
                </Box>
               ) : (<Box></Box>)}
            </EASCard> 
            <TagsEdit open={editOpen} setOpen={(e) => setEditOpen(e)} editTag={tag} />
            <TagsnewTag open={open} setOpen={(e) => setOpen(e)} />
            <EASDialogAlert 
                type="warning"
                title={titleDialog}
                statusOpen={openDialog}
                actionCancel={handleCancelDialog}
                actionConfirme={handleConfirmeDialog}
                loading={loadingDialog}
            >
                {textDialog}
            </EASDialogAlert>
            <EASDialogAlert 
                type="success"
                title={titleDialog}
                statusOpen={openDialogSuccess}
                actionCancel={handleCancelDialog}
                loading={loadingDialog}
            >
                {textDialog}
            </EASDialogAlert>
        </>
    );
};