import * as React from 'react';
import {Paper, Typography, Button, Box, useTheme, makeStyles, MobileStepper} from '@mui/material/';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Preparar',
    imgPath:
      'slide1.png',
  },{
    label: 'Agir',
    imgPath:
      'slide2.png',
  },{
    label: 'Prepetuar',
    imgPath:
      'slide3.png',
  },
];

interface IPanelSliderWhatsProps {
    children?: React.ReactChild[];
}

export const EASPanelSliderWhats: React.FC<IPanelSliderWhatsProps> = () => {
  
  //const { themeName } = useEASThemeContext();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };


  return (
    <Box sx={{ width: '100%', height: 'auto', borderRadius:'10px 10px 10px 10px', mb:2}}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        interval={12000} autoplay={true}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        containerStyle={{ alignItems:'center', background:'no-repeat center',backgroundSize: 'cover'}}
      >
        {images.map((step, index) => (
          <Box key={step.label} >
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  borderRadius:'10px 10px 10px 10px',
                  overflow: 'hidden',
                  display: 'flex',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </Box>
        ))}
      </AutoPlaySwipeableViews>
     { /*
      <Paper
        square
        elevation={0}
        sx={{
          position:'absolute',
          top:'0',
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
      </Paper>
      <Paper
        square
        elevation={0}
        sx={{
          position:'absolute',
          top:'0',
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Paper>
      */}
    </Box>
  );
};
