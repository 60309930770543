import { LayoutDefault } from '../../shared/layouts';
import { ToolsItems } from './ToolsItems';

export const Tools = () => {

  return (
    <LayoutDefault>
      <ToolsItems />
    </LayoutDefault>
  );
};
