import React, { useState, ReactElement, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Grid, Rating, Box, Chip, LinearProgress, Card, IconButton, Menu, MenuItem  } from '@mui/material/';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { useSnackbar } from 'notistack';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { IEvaluation } from '../../shared/types';
import { EvaluationsService } from '../../shared/services/api/evaluations/EvaluationsService';
import { EASDialogAlert } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import { useMutation, useQueryClient } from 'react-query';

interface IEvaluatuponsListItemProps {
  evaluation: IEvaluation;
}
export interface IResend {
  resend?: string;
  id_evaluation?: string;
  mentory?: string;
}

export const EvaluationsListItem: React.FC<IEvaluatuponsListItemProps> =  ({evaluation}) => {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openEl = Boolean(anchorEl);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'resend' | null>(null);


  const [idEvaluation, setIdEvaluation] = useState<string | null>(null);

  const handleTeamSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const { mutateAsync:deleteEvaluation } = useMutation((idEvaluation:string) => EvaluationsService.deleteEvaluation(idEvaluation), {
    onSuccess: () => {
      queryClient.invalidateQueries('evaluation-list');
      enqueueSnackbar('Avaliação removida com sucesso!', { variant: 'success' });
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a avaliação! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant: 'error' });
      setLoadingDialog(false);
    },
  });

  const { mutateAsync:reSend } = useMutation((evaluation:IResend) => EvaluationsService.updateEvaluation(evaluation), {

    onSuccess: () => {
      enqueueSnackbar('Convite para avaliação parcial externa reenviado com sucesso.', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao reenviar convite para avaliação parcial externa.', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  const  handleResendEvaluation = (id_evaluation?:string) => {
    if(id_evaluation){
      setIdEvaluation(id_evaluation);
      setTitleDialog('Reenviar Convite de Avaliação Parcial');
      setTextDialog(<><Typography >Deseja reenviar o convite desta avaliação?</Typography><Typography sx={{display:'flex'}}>Email de destino:&nbsp;<Typography sx={{fontWeight:'bold'}}>{evaluation.mail_evaluation}</Typography></Typography></>);
      setExecuteDialog('resend');
      setOpenDialog(true);
    }
  }; 

  const  handleRemoveEvaluation = (id_evaluation?:string) => {
    if(id_evaluation){
      setIdEvaluation(id_evaluation);
      setTitleDialog('Excluir Avaliação');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta avaliação?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdEvaluation(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
  };

  const handleConfirmeDialog = () => {
    switch (executeDialog) {
      case 'remove':
        removeEvaluation();
        break;
      case 'resend':
        resendEvaluation();
        break;
    }
  };

  const removeEvaluation = () => {
    if (idEvaluation) {
        setLoadingDialog(true);
        deleteEvaluation(idEvaluation);
    }
  };

  const resendEvaluation = () => {
    if (idEvaluation) {
      setLoadingDialog(true);
      const evaluation: IResend = {
        id_evaluation: idEvaluation,
        mentory: mentory?.id_mentory,
        resend: '1',
      };  
      reSend(evaluation);
    }
  };


  return (
    <Card variant="outlined" sx={{ p:'25px', borderRadius: '8px', border:'none'}}>              
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
              <Typography variant="h6">{evaluation.type_evaluation == '0' ? 'Autoavaliação' : 'Avaliação Externa' }</Typography>
              <Typography variant="caption">Criada em {evaluation.date_evaluation ? new Date(evaluation.date_evaluation + 'T23:28:56.782Z').toLocaleDateString('pt-BR') : ''}</Typography>
              <Box >
                {evaluation.status_evaluation == '2' && (
                  <Chip label="Finalizada" size="small" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                )}
                {evaluation.status_evaluation != '2' && (
                    <Chip label="Em preenchimento" size="small" color="info" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                )}
              </Box>
            </Grid>
            <Grid item xs={true} >
              <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'-20px', marginRight:'-20px' }}>
                <IconButton
                  onClick={handleTeamSelect}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openEl ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openEl ? 'true' : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={openEl}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  {evaluation.type_evaluation =='1' && (
                  <MenuItem onClick={() => handleResendEvaluation(evaluation?.id_evaluation)}>
                    <Typography  sx={{fontSize:'12px'}} >
                      <strong>Reenviar</strong>
                    </Typography>
                  </MenuItem>
                  )}
                  <MenuItem onClick={() => handleRemoveEvaluation(evaluation?.id_evaluation)}>
                    <Typography  sx={{fontSize:'12px'}} >
                      <strong>Excluir</strong>
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',  flexDirection:'row'}}>
          <Grid container spacing={2} >
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Rating size="small" value={1} max={5} readOnly />
            </Grid>
            <Grid item zeroMinWidth sm={true}>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography variant="body2" color="text.secondary">{ (evaluation.star1_evaluation && parseInt(evaluation.star1_evaluation) > 1 ? parseInt(evaluation.star1_evaluation) : 0) + '%'}</Typography>
                </Grid>
                <Grid item zeroMinWidth sm={true} >
                  <LinearProgress color="error" variant="determinate" sx={{ background: 'transparent', '> span': { borderTopRightRadius:'12px', borderBottomRightRadius:'12px' }, mt:'5px', height:'10px'}} value={ (evaluation.star1_evaluation && parseInt(evaluation.star1_evaluation) > 1 ? parseInt(evaluation.star1_evaluation) : 0)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',  flexDirection:'row'}}>
          <Grid container spacing={2} >
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Rating size="small" value={2} max={5} readOnly />
            </Grid>
            <Grid item zeroMinWidth sm={true}>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography variant="body2" color="text.secondary">{ (evaluation.star2_evaluation && parseInt(evaluation.star2_evaluation) > 1 ? parseInt(evaluation.star2_evaluation) : 0) + '%'}</Typography>
                </Grid>
                <Grid item zeroMinWidth sm={true} >
                  <LinearProgress variant="determinate" sx={{ background: 'transparent', '> span': { borderTopRightRadius:'12px', borderBottomRightRadius:'12px' }, mt:'5px', height:'10px'}} value={ (evaluation.star2_evaluation && parseInt(evaluation.star2_evaluation) > 1 ? parseInt(evaluation.star2_evaluation) : 0)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',  flexDirection:'row'}}>
          <Grid container spacing={2} >
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Rating size="small" value={3} max={5} readOnly />
            </Grid>
            <Grid item zeroMinWidth sm={true}>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography variant="body2" color="text.secondary">{ (evaluation.star3_evaluation && parseInt(evaluation.star3_evaluation) > 1 ? parseInt(evaluation.star3_evaluation) : 0) + '%'}</Typography>
                </Grid>
                <Grid item zeroMinWidth sm={true} >
                  <LinearProgress color="secondary" variant="determinate" sx={{ background: 'transparent', '> span': { borderTopRightRadius:'12px', borderBottomRightRadius:'12px' }, mt:'5px', height:'10px'}} value={ (evaluation.star3_evaluation && parseInt(evaluation.star3_evaluation) > 1 ? parseInt(evaluation.star3_evaluation) : 0)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',  flexDirection:'row'}}>
          <Grid container spacing={2} >
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Rating size="small" value={4} max={5} readOnly />
            </Grid>
            <Grid item zeroMinWidth sm={true}>

              <Grid container spacing={1}>
                <Grid item >
                  <Typography variant="body2" color="text.secondary">{ (evaluation.star4_evaluation && parseInt(evaluation.star4_evaluation) > 1 ? parseInt(evaluation.star4_evaluation) : 0) + '%'}</Typography>
                </Grid>
                <Grid item zeroMinWidth sm={true} >
                  <LinearProgress color="info" variant="determinate" sx={{ background: 'transparent', '> span': { borderTopRightRadius:'12px', borderBottomRightRadius:'12px' }, mt:'5px', height:'10px'}}  value={ (evaluation.star4_evaluation && parseInt(evaluation.star4_evaluation) > 1 ? parseInt(evaluation.star4_evaluation) : 0)} />
                </Grid>
              </Grid>                      
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center',  flexDirection:'row'}}>
          <Grid container spacing={2} >
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Rating size="small" value={5} max={5} readOnly />
            </Grid>
            <Grid item zeroMinWidth sm={true}>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography variant="body2" color="text.secondary">{ (evaluation.star5_evaluation && parseInt(evaluation.star5_evaluation) > 1 ? parseInt(evaluation.star5_evaluation) : 0) + '%'}</Typography>
                </Grid>
                <Grid item zeroMinWidth sm={true} >
                  <LinearProgress color="success" variant="determinate" sx={{ background: 'transparent', '> span': { borderTopRightRadius:'12px', borderBottomRightRadius:'12px' }, mt:'5px', height:'10px'}} value={ (evaluation.star5_evaluation && parseInt(evaluation.star5_evaluation) > 1 ? parseInt(evaluation.star5_evaluation) : 0)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}  sx={{ display: 'flex', alignItems:'end',  flexDirection:'row'}} >
          <Grid container spacing={1}>
            <Grid item zeroMinWidth sm={true}>
              <Typography variant="caption" color="text.secondary" sx={{mb:'15px'}}>Progresso do preenchimento:</Typography>
              <LinearProgress variant="determinate" value={evaluation.completed_evaluation ? parseInt(evaluation.completed_evaluation) : 0} />
            </Grid>
            <Grid item sx={{display: 'flex', alignItems: 'baseline'}} >
              <Typography variant="body2" color="text.secondary" sx={{width:'25px', pt:'15px'}}>{(evaluation.completed_evaluation ? parseInt(evaluation.completed_evaluation) : 0) + '%'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EASButton 
                color="primary" 
                variant="outlined" 
                startIcon={<OpenInBrowserIcon sx={{pb: '4.3px'}}/>} 
                onClick={() => navigate(`/avaliacao/detalhes/${evaluation.id_evaluation}`)}
                fullWidth>Acessar
              </EASButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <EASDialogAlert 
          type="warning"
          title={titleDialog}
          statusOpen={openDialog}
          actionCancel={handleCancelDialog}
          actionConfirme={handleConfirmeDialog}
          loading={loadingDialog}
        >
        {textDialog}
      </EASDialogAlert>


    </Card>
  );
};