import { LayoutDefault } from '../../shared/layouts';
import { EASTips } from '../../shared/components';

export const Tips = () => {
    return (
        <LayoutDefault
        >
            <EASTips />
        </LayoutDefault>
        );
};