import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { IconButton, Typography, Grid, Box, Card, styled, Tooltip } from '@mui/material/';

import DeleteIcon from '@mui/icons-material/Delete';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { green } from '@mui/material/colors';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const IconButtonChecked = styled(IconButton)(() => ({
  color: green[500]
}));

interface ITaskPlanItensItemProps {
  task_plan: ITaskPlan;
  index: number;
  handlePresentation:(TaskPlan : ITaskPlan, status:boolean) => void;
  handleFinished:(TaskPlan : ITaskPlan, status:boolean) => void;
  removeTaskPlan:(idTaskPlan?:string) => void;
  setOpenEdit:(TaskPlan : ITaskPlan) => void;
}

export const TaskPlanItensItem: React.FC<ITaskPlanItensItemProps> = ({ task_plan, index, handlePresentation, handleFinished, removeTaskPlan, setOpenEdit})=> {


  return (
      <Draggable draggableId={(task_plan.id_task_plan ? task_plan.id_task_plan : 'id2')} index={index}>
        {provided => (
          <Card
            key={task_plan.id_task_plan}
            ref={provided.innerRef}
            {...provided.draggableProps}
            variant="outlined" 
            sx={{ p: '15px', mb:'20px', borderRadius: '12px'}}
           
          >

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item zeroMinWidth   >
                    <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'5px'}}>
                      <IconButton sx={{pointer:'grab'}} {...provided.dragHandleProps}  >
                        <OpenWithIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={true} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column'}}  onClick={() => setOpenEdit(task_plan)}>
                    <Typography variant="h6" sx={{fontSize:18}}>{task_plan.title_task_plan ? task_plan.title_task_plan : 'Nenhum título definido'}</Typography>
                    <Typography variant="caption">Criada em: {task_plan?.date_register && new Date(task_plan?.date_register).toLocaleDateString('pt-BR', {dateStyle: 'medium', timeZone: 'UTC'}) }</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{marginTop:1}}>
                {task_plan?.status_task_plan == '2'  ? (
                <Tooltip title="Marcar tarefa como não finalizada" >
                  <IconButtonChecked onClick={() => handleFinished(task_plan, false)}>
                    <CheckCircleOutlineIcon />
                  </IconButtonChecked>
                </Tooltip>
                ) :(
                <Tooltip title="Marcar tarefa como finalizada" >
                  <IconButton onClick={() => handleFinished(task_plan, true)}>
                    <CheckCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
                )}
                {task_plan?.presentation_task_plan == '1'  ? (
                <Tooltip title="Remover tarefa da apresentação final" >
                  <IconButtonChecked onClick={() => handlePresentation(task_plan, false)} disabled={task_plan?.status_task_plan != '2'}>
                    <CoPresentIcon />
                  </IconButtonChecked>
                </Tooltip>
                ) :(
                <Tooltip title="Adicionar tarefa na apresentação final" >
                  <IconButton onClick={() => handlePresentation(task_plan, true)} disabled={task_plan?.status_task_plan != '2'}>
                    <CoPresentIcon />
                  </IconButton>
                </Tooltip>
                )}  

                <Tooltip title="Ver Tarefa Completa" >
                  <IconButton aria-label="fingerprint" onClick={() => setOpenEdit(task_plan)}>
                    <MenuOpenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Remover Tarefa" >
                  <IconButton aria-label="fingerprint" onClick={() => removeTaskPlan(task_plan?.id_task_plan)} >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Card>
        )}
      </Draggable>
    );
  };
