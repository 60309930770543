import { useState, useEffect } from 'react';
import { CircularProgress, Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { EASButton, EASInput } from '../../shared/forms';
import { IList, UtilService } from '../../shared/services/api/util/UtilService';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';

import CheckIcon from '@mui/icons-material/Check';
import { TagsService } from '../../shared/services/api/tags/TagsService';
import { ITag } from '../../shared/contexts/TagsContext/types';
import { useMutation, useQueryClient } from 'react-query';


interface ITagsEditProps {
  editTag:ITag | null;
  open: boolean;
  setOpen: (open:boolean) => void;
}

export const TagsEdit: React.FC<ITagsEditProps> = ({ editTag, open, setOpen }) => {

  const { enqueueSnackbar } = useSnackbar();
  const [saveLoading, setSaveLoading] = useState(false);
  const [tag, setTag] = useState<ITag | null>(editTag);
  const [tagError, setTagError] = useState<ITag | null>(null);
  const { user } = useAuthContext();
  const queryClient = useQueryClient();


  const { mutateAsync:storeUpdate } = useMutation((tag:ITag) =>  TagsService.updateTag(tag), {
    onSuccess: () => {
      setSaveLoading(false);
      queryClient.invalidateQueries('operator-tag-list');
      enqueueSnackbar('Tag atualizada com sucesso!', { variant:'success'});
      setOpen(false);
      setTag(null);

    },
    onError: () => {
      setSaveLoading(false);
      enqueueSnackbar('Erro ao tentar atualizar tag!', { variant:'error'});
    },
  });



  const tagSchema = yup.object().shape({
    name_tag: yup.string().required('Campo obrigatório'),
  });

  const dialogSave = () => {
      tagSchema
        .validate( tag, { abortEarly: false })
          .then(validateEditValues => {
            setSaveLoading(true);
            const newEditTag: ITag = {
              id_tag: editTag?.id_tag,
              status_tag: tag?.status_tag,
              name_tag: validateEditValues.name_tag,
              user_update: user?.id,
            };   
            storeUpdate(newEditTag);

          /*
          TagsService.updateTag(EditTag)
          .then((tagResponse) => {
            if (tagResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
            } else {
              setSaveLoading(false);
              enqueueSnackbar('Tarefa atualizada com sucesso!', { variant:'success'});
              setOpen(false);
              setUpdateList();
            }
          }).catch(() => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
          });
          */
      })
      .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setTagError(existingValues => ({
                              ...existingValues,
                              [keyError]: error.message
                          }));                        
            }
        });
      });
  };

  function cancelEdit() {
    setSaveLoading(false);
    setOpen(false);
    setTag(null);
  }

  return (

    <Dialog 
      open={open}
      keepMounted
      onClose={() => cancelEdit()}
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{'Editar Tag'}</DialogTitle>
      <DialogContent>
        <Box sx={{display: 'flex',marginTop:'10px',flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!saveLoading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <EASInput    
                              onFocus={() => 
                                  setTagError(existingValues => ({
                                  ...existingValues,
                                  name_tag: '',
                                  }))
                              }
                              
                              label="Nome da Tag"
                              type="text"
                              value={!tag?.name_tag ? editTag?.name_tag : tag?.name_tag}
                              onChange={(e) => 
                                  setTag(existingValues => ({
                                      ...existingValues,
                                      name_tag: e.target.value,
                                  }))
                              }
                              error={!!tagError?.name_tag}
                              helperText={tagError?.name_tag}
                          />
                      </Grid>
                  </Grid>
              </Grid>
            </Grid>
          )}
        </Box>  
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
        <EASButton variant="outlined" onClick={() => cancelEdit()} disabled={saveLoading}>Cancelar</EASButton>
        <EASButton 
             /* isLoading={isLoading}*/
              variant='contained'
              disabled={saveLoading}
              endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <CheckIcon/>}
              onClick={dialogSave}>Salvar</EASButton>
      </DialogActions>
    </Dialog>
  );
};
