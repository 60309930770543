import React from 'react';
import { Card, CardContent, CardHeader, Divider, List, Typography, useTheme  } from '@mui/material';
import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { TaskPlanItensItem } from './TaskPlanItensItem';
import { Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';

interface ITaskPlanItensColumnProps {
  item: {
    id: string
    list: Array<ITaskPlan>
  };
  handlePresentation:(TaskPlan : ITaskPlan, status:boolean) => void;
  handleFinished:(TaskPlan : ITaskPlan, status:boolean) => void;
  removeTaskPlan:(idTaskPlan?:string) => void;
  updateTaskPlan:(TaskPlan : ITaskPlan) => void;
  setOpenEdit:(TaskPlan : ITaskPlan) => void;
}

const ItemPrevisto = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '1',
  gridRowEnd: '1',
  gridColumnEnd: '1',
});

const ItemExecutando = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '2',
  gridRowEnd: '1',
  gridColumnEnd: '2',
});

const ItemPausado = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '3',
  gridRowEnd: '1',
  gridColumnEnd: '3',
});

const ItemFinalizado = styled('div') ({
  gridRowStart: '1',
  gridColumnStart: '4',
  gridRowEnd: '1',
  gridColumnEnd: '4',
});


export const TaskPlanItensColumn: React.FC<ITaskPlanItensColumnProps> =  ({ item: { list, id }, handlePresentation, handleFinished, removeTaskPlan, setOpenEdit }) => {
  const theme = useTheme();
  return (
    <Droppable droppableId={id}>
      {provided => {
        switch(id) {
          case 'Previsto':
            return <ItemPrevisto>
                      <Card sx={{borderRadius : 2, border:'none',  backgroundColor: 'eas.backgroundTask',/*'& .MuiCardHeader-root': { backgroundColor: '#0288d1', color:'#fff'}*/}} variant='outlined' elevation={0}>
                        <CardHeader
                          title={id}
                          titleTypographyProps={{fontSize: 14, lineHeight:0.5, textTransform: 'uppercase', fontWeight: 'bold', textAlign:'center'}}
                        />
                        <CardContent sx={{minHeight:'320px'}} {...provided.droppableProps} ref={provided.innerRef}>
                          {list.map((task_plan, index) => (
                            <TaskPlanItensItem key={task_plan.id_task_plan} task_plan={task_plan} index={index} setOpenEdit={(open) => setOpenEdit(open)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} />
                          ))}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    </ItemPrevisto>;
          case 'Executando':
            return <ItemExecutando>
                      <Card sx={{borderRadius : 2, border:'none', backgroundColor: 'eas.backgroundTask' /*'& .MuiCardHeader-root': { backgroundColor: '#ed6c02', color:'#fff'}*/}} variant='outlined' elevation={0}>
                        <CardHeader
                          title={id}
                          titleTypographyProps={{fontSize: 14, lineHeight:0.5, textTransform: 'uppercase', fontWeight: 'bold', textAlign:'center'}}
                        />
                        <CardContent sx={{minHeight:'320px'}} {...provided.droppableProps} ref={provided.innerRef}>
                          {list.map((task_plan, index) => (
                            <TaskPlanItensItem key={task_plan.id_task_plan} task_plan={task_plan} index={index} setOpenEdit={(open) => setOpenEdit(open)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} />
                          ))}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    </ItemExecutando>;
          case 'Pausado':
            return <ItemPausado>
                      <Card sx={{borderRadius : 2, border:'none', backgroundColor: 'eas.backgroundTask',/*'& .MuiCardHeader-root': { backgroundColor: '#d32f2f', color:'#fff'}*/}} variant='outlined' elevation={0}>
                        <CardHeader
                          title={id}
                          titleTypographyProps={{fontSize: 14, lineHeight:0.5, textTransform: 'uppercase', fontWeight: 'bold', textAlign:'center'}}
                        />
                        <CardContent sx={{minHeight:'320px'}} {...provided.droppableProps} ref={provided.innerRef}>
                          {list.map((task_plan, index) => (
                            <TaskPlanItensItem key={task_plan.id_task_plan} task_plan={task_plan} index={index} setOpenEdit={(open) => setOpenEdit(open)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} />
                          ))}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    </ItemPausado>;
          case 'Finalizado':
            return <ItemFinalizado>
                      <Card sx={{borderRadius : 2, border:'none', backgroundColor: 'eas.backgroundTask',/*'& .MuiCardHeader-root': { backgroundColor: '#2e7d32', color:'#fff'}*/}} variant='outlined' elevation={0}>
                        <CardHeader
                          title={id}
                          titleTypographyProps={{fontSize: 14, lineHeight:0.5, textTransform: 'uppercase', fontWeight: 'bold', textAlign:'center'}}
                        />
                        <CardContent sx={{minHeight:'320px'}} {...provided.droppableProps} ref={provided.innerRef}>
                          {list.map((task_plan, index) => (
                            <TaskPlanItensItem key={task_plan.id_task_plan} task_plan={task_plan} index={index} setOpenEdit={(open) => setOpenEdit(open)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} />
                          ))}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    </ItemFinalizado>;   
          default:
            return <ItemPrevisto>
                      <Card sx={{borderRadius : 2, border:'none', '& .MuiCardHeader-root': { backgroundColor: '#0288d1', color:'#fff'}}} variant='outlined' elevation={0}>
                        <CardHeader
                          title={id}
                          titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign:'center'}}
                        />
                        <Divider/>
                        <CardContent sx={{minHeight:'480px'}} {...provided.droppableProps} ref={provided.innerRef}>
                          {list.map((task_plan, index) => (
                            <TaskPlanItensItem key={task_plan.id_task_plan} task_plan={task_plan} index={index} setOpenEdit={(open) => setOpenEdit(open)} removeTaskPlan={(idTaskPlan) => removeTaskPlan(idTaskPlan)} handlePresentation={(taskPlan, status) => handlePresentation(taskPlan, status)} handleFinished={(taskPlan, status) => handleFinished(taskPlan, status)} />
                          ))}
                          {provided.placeholder}
                        </CardContent>
                      </Card>
                    </ItemPrevisto>;
        }}}
      </Droppable>
  );
};