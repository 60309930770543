import {  useQuery } from 'react-query';
import { DashboardService } from '../../shared/services/api/dashboard/DashboardService';
import { useAuthContext, useMentoringContext, useModulesContext } from '../../shared/contexts';
import { LayoutDefault } from '../../shared/layouts';
import { DashboardMentoryPanel } from './DashboardMentoryPanel';
import { useSnackbar } from 'notistack';

export const DashboardMentory = () => {

  const { module } = useModulesContext();
  const { mentory } = useMentoringContext();
  const { user, isAuthenticated } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isFetching, isLoading } = useQuery(['mentory-dashboard',  [mentory?.id_mentory, mentory?.id_module, user?.id] ], () =>  DashboardService.getDashboardMentory( mentory?.id_mentory, module?.id_module, (user?.id == mentory?.id_user_mentorado ? '0' : '1')), 
  {
    enabled: (!!(isAuthenticated) && !!(mentory?.id_mentory) && !!(module?.id_module) && !!(user?.id) ),
    onError: () => { 
      enqueueSnackbar('Erro ao carregar dados do dashboard.', { variant:'error'});
    },
  });


  return (
    <LayoutDefault>
      <DashboardMentoryPanel dashboardMenotity={data} isLoading={isFetching || isLoading }/>
    </LayoutDefault>
  );
  

};
