import React, { useRef } from 'react';
import { Box, Stack } from '@mui/material';

import { useParams, useNavigate} from 'react-router-dom';

import { LayoutDefault } from '../../shared/layouts';

import RatingTabInfo, { IRefRating } from './RatingTabInfo';

import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

export const Rating = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();

    const refRating = useRef<IRefRating>(null);

    const storeDisplay = () => {
        if(refRating.current)
            refRating.current.saveRating();
    };

    return (
            <LayoutDefault >
                <EASCard 
                    titleCard={ id != '' ? 'Editar Pesquisa' : 'Nova Pesquisa'}
                    breadcrumbsCard=""
                    actionCard={<Stack direction="row" spacing={1}>
                                    <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                    <EASButton variant="outlined" onClick={() => navigate('/pesquisas-nps')}>Sair</EASButton>
                                </Stack>} >
                    <Box sx={{mt: 2}}>
                        <RatingTabInfo RatingID={id} ref={refRating} />
                    </Box>
                </EASCard>
            </LayoutDefault>
    );
};