import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluation, IEvaluationsList } from '../../../types';

const createEvaluation = async (evaluation: IEvaluation): Promise<void> => {
  const { data } = await Api.post('/evaluations', { evaluation: evaluation } );
  return data;
};

const updateEvaluation = async (evaluation: IEvaluation): Promise<void> => {
  const { data } = await  Api.put(`/evaluations/${evaluation.id_evaluation}`, evaluation);
  return data;  
};

const deleteEvaluation = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/evaluations/${id}`);
  return data;   
};

const getEvaluations = async (page = 1, id_mentory = ''): Promise<IEvaluationsList> => {
    const { data } = await Api.get(`/evaluations?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`);
    return data;
};

export const EvaluationsService = {
  getEvaluations,
  createEvaluation,
  updateEvaluation,
  deleteEvaluation,
};
