import React from 'react';

import { LayoutDefault } from '../../shared/layouts';
import { MaintenancePlanItems } from './MaintenancePlanItems';

export const MaintenancePlan: React.FC = () => {

  return (

    <LayoutDefault >
      <MaintenancePlanItems />
    </LayoutDefault>

  );
};