import { useState, useEffect } from 'react';
import * as yup from 'yup';

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider} from '@mui/material';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { ILearning } from '../../shared/contexts/LearningContext/types';
import { LearningService } from '../../shared/services/api/learning/LearningService';
import { useSnackbar } from 'notistack';
import { EASButton, EASInput, EASPicker } from '../../shared/forms';

interface ILearningNewProps {
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;

}
export const LearningNew: React.FC<ILearningNewProps> = ({open, setOpen, setUpdateList})  => {

  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [learning, setLearning] = useState<ILearning>({});
  const [learningError, setLearningError] = useState<ILearning | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();
  
  const handleClose = () => {
    setLearning({});
    setLearningError(null);
    setOpen(false);
  };

  useEffect(() => {
    setLearning({});
    setLearningError(null);
    setIsLoading(false);
  }, [open]);


  const learningSchema = yup.object().shape({
    value_learning: yup.string().required('Informe uma Nota'),
    date_learning: yup.string().required('Informe uma Data'),
    text_learning: yup.string().required('Descreva suas ações')
  });


  const handleButtonSave = () => {
    learningSchema
      .validate( learning, { abortEarly: false })
      .then(validateValues => {

        setSaveLoading(true);
        const newLearning: ILearning = {
          id_learning: '',
          id_mentory: mentory?.id_mentory,
          status_learning: '1',
          date_learning: validateValues.date_learning,
          value_learning: validateValues.value_learning,
          text_learning: validateValues.text_learning,
          lack_learning: learning?.lack_learning,
          cause_learning: learning?.cause_learning,
          plan_learning: learning?.plan_learning, 
          schedule_learning:'',
          unread_learning: (user?.id === mentory?.id_user_mentorado ? '0' : '1'),
          register_learning: user?.id,
        };    
        LearningService.createLearning(newLearning)
          .then((learningResponse) => {
            if (learningResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao cadastrar item no diário de aprendizagem.', { variant:'error'});
            } else {
              setSaveLoading(false);
              enqueueSnackbar('Diário de Aprendizagem cadastrado com sucesso!', { variant:'success'});
              setLearning({});
              setOpen(false);
              setUpdateList();
            }
          }).catch((learning) => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar item no diário de aprendizagem.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setLearningError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
    });

  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Cadastrar Nova Postagem no Diário</DialogTitle>
      <Divider/>
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight:'501px'}}>
          {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!saveLoading && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <EASPicker 
                  isLoading={isLoading}
                  onFocus={() => 
                    setLearningError(existingValues => ({
                      ...existingValues,
                      date_learning: '',
                      }))
                  }
                  onOpen={() => 
                    setLearningError(existingValues => ({
                      ...existingValues,
                      date_learning: '',
                      }))
                  }
                  label="Data"
                  value={learning?.date_learning} 
                  error={!!learningError?.date_learning}
                  helperText={learningError?.date_learning}
                  onChangePiker={(e) => {  
                                      if(e instanceof Date) {         
                                        setLearning(existingValues => ({
                                              ...existingValues,
                                              date_learning: e.toISOString().split('T')[0],
                                          }));
                                      }}
                                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <EASInput 
                  isLoading={isLoading}
                  label="Nota"
                  type='number'
                  value={learning?.value_learning} 
                  onChange={(event) => setLearning(existingValues => ({
                                                  ...existingValues,
                                                  value_learning: event.target.value,
                                                }))
                          }  
                  onFocus={() => setLearningError(existingValues => ({
                                                  ...existingValues,
                                                  value_learning: '',
                                              }))
                          }
                  error={!!learningError?.value_learning}
                  helperText={learningError?.value_learning}
                />  
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label={'O que justificativa esta nota'}
                  multiline
                  value={learning?.text_learning} 
                  onChange={(event) => setLearning(existingValues => ({
                                                  ...existingValues,
                                                  text_learning: event.target.value,
                                                }))
                          }  
                  onFocus={() => setLearningError(existingValues => ({
                                                  ...existingValues,
                                                  text_learning: '',
                                              }))
                          }
                  error={!!learningError?.text_learning}
                  helperText={learningError?.text_learning}
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label={'O que faltou para o "dez"'}
                  multiline
                  value={learning?.lack_learning} 
                  onChange={(event) => setLearning(existingValues => ({
                    ...existingValues,
                    lack_learning: event.target.value,
                    }))
                  }  
                  fullWidth
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label="Quais as Causas"
                  multiline
                  value={learning?.cause_learning} 
                  onChange={(event) => setLearning(existingValues => ({
                    ...existingValues,
                    cause_learning: event.target.value,
                    }))
                  }  
                  fullWidth
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                  isLoading={isLoading}
                  label="Qual o Plano de Melhoria"
                  multiline
                  value={learning?.plan_learning} 
                  onChange={(event) => setLearning(existingValues => ({
                    ...existingValues,
                    plan_learning: event.target.value,
                    }))
                  } 
                  fullWidth
                  rows={3}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
          <EASButton onClick={handleClose} disabled={isLoading} variant="contained">Cancelar</EASButton>
          <EASButton 
            isLoading={saveLoading}
            variant='contained'
            disabled={saveLoading}
            onClick={handleButtonSave} autoFocus>Inserir no Diário
          </EASButton>
      </DialogActions>
    </Dialog> 

  );
};
