import React from 'react';

import { LayoutDefault } from '../../shared/layouts';
import { RatingList } from './RatingList';


export const Ratings = () => {

  return (
    <LayoutDefault>  
      <RatingList />        
    </LayoutDefault>
  );
};

