import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDrawerContext } from '../shared/contexts';
import {
  MentoryVideos,
  MentoryVideo,
  Profile,
} from '../pages';



export const IsAuth4Routes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
      setDrawerOptions([       
        {
          icon: 'ondemand_video',
          path: '/videos',
          label: 'cursos',
        },
      ]);

      
  }, []);

  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/videos" element={<MentoryVideos />} />
      <Route path="/video/:id" element={<MentoryVideo />} />
      <Route path="*" element={<Navigate to="/videos" />} />
    </Routes>
  );
};
