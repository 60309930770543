import React, { Ref, useState, useImperativeHandle } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography, styled } from '@mui/material';

import { useAuthContext } from '../../shared/contexts';
import { EASInput, EASSelect, EASSwitch } from '../../shared/forms';
import { IVideo } from '../../shared/contexts/VideosContext/types';
import { IList } from '../../shared/services/api/util/UtilService';

import ReactPlayer from 'react-player';


import * as yup from 'yup';

export interface IRefVideo {
  saveVideo: () => void;
}

type IVideoTabProps = {
  videoEdit?: IVideo;
  tags?:Array<IList>;
  isLoading?:boolean;
  storeUpdate:(video:IVideo) => void;
};

const ReactPlayerStyled = styled(ReactPlayer)(() => ({
  width: '100% !important', height: 'auto !important', aspectRatio: '16/9', margin: '0 !important', padding: '0 !important'
}));

const VideoTab = React.forwardRef<IRefVideo, IVideoTabProps>(({videoEdit, tags, isLoading, storeUpdate}:IVideoTabProps, ref:Ref<IRefVideo>) => {


  const { user } = useAuthContext();
  
  const [videoError, setVideoError] = useState<IVideo | null>(null);

  const [video, setVideo] = useState<IVideo | undefined>(videoEdit);



  const isValidVideo = yup.object({
    name_video: yup.string().required('Este campo é obrigatório'),
    code_video: yup.string().required('Este campo é obrigatório'),
  });

  useImperativeHandle(ref, () => ({
    saveVideo,
  }));


  const saveVideo = () => {
      isValidVideo
        .validate( video , { abortEarly: false })
          .then(validateVideo => {
            const updateVideo: IVideo = {
              id_video: video?.id_video,
              name_video: validateVideo.name_video,
              code_video: validateVideo.code_video,
              description_video: video?.description_video,
              tags: video?.tags,
              option_video: video?.option_video,
              user_update: user?.id,
            };      
            storeUpdate(updateVideo);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setVideoError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
  };



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>      
                  {isLoading && (
                    <Skeleton variant="rectangular" width="100%" height="300px"/>
                  )}
                  {!isLoading && (
                    <ReactPlayerStyled url={`https://www.youtube.com/watch?v=${video?.code_video}`}  controls  />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isLoading && (
                    <>
                      <Skeleton variant="rectangular" width="40%"  height="30px" sx={{marginBottom:'5px'}}/>
                      <Skeleton variant="rectangular" width="60%"  height="15px"/>
                    </>
                  )}
                  {!isLoading && (
                    <>
                      <Typography>Título do Vídeo:</Typography>
                      <Typography variant='subtitle1'>{video?.title}</Typography>
                    </>
                  )}
                </Grid>
                {video?.description && (
                <Grid item xs={12}>
                  <Typography>Descrição do Vídeo:</Typography>
                  <Typography variant='caption'>{video?.description}</Typography>
                </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
            <CardHeader
              title='Informações do Vídeo'
              titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
            />
            <Divider/>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isLoading}
                    label="Código do Vídeo"
                    type="text"
                    onFocus={() => 
                        setVideoError(existingValues => ({
                        ...existingValues,
                        code_video: '',
                        }))
                    }
                    value={video?.code_video}
                    onChange={(e) => 
                        setVideo(existingValues => ({
                            ...existingValues,
                            code_video: e.target.value,
                        }))
                    }
                    error={!!videoError?.code_video}
                    helperText={videoError?.code_video}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isLoading}
                    label="Nome"
                    type="text"
                    value={video?.name_video}
                    onFocus={() => 
                        setVideoError(existingValues => ({
                        ...existingValues,
                        name_video: '',
                        }))
                    }
                    onChange={(e) => 
                        setVideo(existingValues => ({
                            ...existingValues,
                            name_video: e.target.value,
                        }))
                    }
                    error={!!videoError?.name_video}
                    helperText={videoError?.name_video}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isLoading}
                    label="Descrição"
                    type="text"
                    multiline
                    rows={3}
                    value={video?.description_video}
                    onChange={(e) => 
                      setVideo(existingValues => ({
                            ...existingValues,
                            description_video: e.target.value,
                        }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASSwitch                  
                    isLoading={isLoading}
                    label="Usar nome e descrição do youtube"
                    value={video?.option_video}
                    onChange={(e) => 
                      setVideo(existingValues => ({
                          ...existingValues,
                          option_video: e.target.checked ? '1' : '0',
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASSelect 
                    isLoading={isLoading}
                    options={tags ? tags : []}
                    label="Tags do Vídeo"
                    getOptionLabel={(option) => option.text}                                  
                    value={video?.tags && tags && video?.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                    onChangeSelect={(event, values:Array<IList>) => {
                        const NewValue:Array<string> = [];
                        values.map((value) => NewValue.push(value.value));
                        setVideo(existingValues => ({
                            ...existingValues,
                            tags: NewValue,
                        }));
                            } 
                        } 
                    fullWidth
                    multipleSelect
                    filterSelectedOptions
                    disableClearable
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

VideoTab.displayName = 'VideoTab';

export default VideoTab;
