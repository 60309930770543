import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { EASButton } from '../../shared/forms';
import { orange } from '@mui/material/colors';
import { Toolbar, Box, Container, Grid, Link, Typography } from '@mui/material';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

interface IHomeHeaderProps {
  sections: ReadonlyArray<{
    title: string;
    url: string;
  }>;
  title: string;
}

export const HomeHeader:React.FC<IHomeHeaderProps> = (({...props}) => {
  
  const { sections, title } = props;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      
      <Toolbar variant="dense" sx={{backgroundColor:orange[800] }} >
        <Container>
          <Grid container spacing={1} sx={{py:2}}>
            <Grid item xs={12} sm={12} md={4}>
              <Box sx={{display:'flex', color:'#fff'}}>
                <PhoneEnabledIcon  /> 
                <Typography
                    component="h3"
                    variant="body1"
                    color="inherit"
                    align="left"
                    noWrap
                    sx={{ mt:'5px', ml:'5px', fontSize: '12px'  }}
                  >SÃO PAULO +55 (11) 3230.8606
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}md={4}>
              <Box sx={{display:'flex', color:'#fff'}}>
                <PhoneEnabledIcon  /> 
                <Typography
                    component="h3"
                    variant="body1"
                    color="inherit"
                    align="left"
                    noWrap
                    sx={{ mt:'5px', ml:'5px', fontSize: '12px'  }}
                  >PORTO ALEGRE +55 (51) 3533.1270
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box sx={{display:'flex', ml:'40px', float:'right'}}>
                <Link sx={{ml: '10px', color:'#fff'}} target="_blank" href="https://www.instagram.com/aura.exo/">
                  <InstagramIcon fontSize="small" />
                </Link>
                <Link sx={{ml: '10px', color:'#fff'}} target="_blank" href="https://www.linkedin.com/company/aura-exo/">
                  <LinkedInIcon fontSize="small" />
                </Link>
                <Link sx={{ml: '10px', color:'#fff'}} target="_blank" href="https://contate.me/auraexomentoring">
                  <WhatsAppIcon fontSize="small" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>  
      </Toolbar>
      <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Container>
          <Grid container spacing={1} sx={{ mx:'auto', my: 2}} >
            <Grid item xs={true}>
              <Link onClick={() => navigate('/')} sx={{ cursor:'pointer', p: 1, flexShrink: 0 }} >
                <Box
                  component="img"
                  src={'/logo_auraexo.png'}
                  sx={{ mx:'auto', width: 100, height: 'auto'}}
                />
              </Link>

            </Grid>
            <Grid item zeroMinWidth>

              <EASButton onClick={() => navigate('/cadastre-se')}  variant="outlined" size="small">
                Cadastre-se
              </EASButton>
              <EASButton sx={{ml:'10px'}} onClick={() => navigate('/acessar')}  variant="outlined" size="small">
                Acessar
              </EASButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </React.Fragment>
  );
});
