
import { IModule } from './types';

export function setModuleLocalStorange(module: IModule | null) {
    localStorage.setItem('easmod', JSON.stringify(module));
}

export function getModuleLocalStorange() {
    const json = localStorage.getItem('easod');
    if(!json){
        return null;
    }
    const module = JSON.parse(json);
    return module ?? null;
}




