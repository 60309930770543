import { Api } from '../axios-config';
import { IEvaluationComment } from '../../../types';

interface IEvaluationsCommentService {
  Teams?: string;
}

const createEvaluationComment = async (evaluationComment: IEvaluationComment): Promise<IEvaluationsCommentService | Error> => {
  try {
    const { data } = await Api.post('/evaluationscomment', { evaluationComment: evaluationComment } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateEvaluationComment = async (evaluationComment: IEvaluationComment): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/evaluationscomment/${evaluationComment.id_evaluation_comment}`, evaluationComment);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getEvaluationComment = async (userEnrollment: string, client_id: string): Promise<IEvaluationsCommentService | Error> => {
  try {
    const { data } = await Api.post('/evaluations', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const EvaluationsCommentService = {
  getEvaluationComment,
  createEvaluationComment,
  updateEvaluationComment,
};
