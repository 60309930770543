import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext, useDrawerContext } from '../shared/contexts';
import {
  DashboardResourcesHuman,
  MentoringResourcesHuman,
  MentoryResourcesHuman,
  ProgramResourcesHuman,
  Profile,
  CompanyResourceHuman

} from '../pages';

export const IsAuth5Routes = () => {
  
  const { user } = useAuthContext();
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {


   const MenuItens = [  {
                          icon: 'home',
                          path: '/pagina-inicial',
                          label: 'Página inicial',
                        },
                        {
                          icon: 'group',
                          path: '/processos',
                          label: 'Processos',
                        },
                        {                          
                          icon: 'school',
                          path: '/dados-do-programa',
                          label: 'Dados do Programa',
                        },
                        {
                          icon: 'business',
                          path: '/perfil-da-empresa',
                          label: 'Perfil da Empresa',
                        },
                      ];
      setDrawerOptions(MenuItens);

      
  }, []);

  return (
    <Routes>
     
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/pagina-inicial" element={<DashboardResourcesHuman />} />

      <Route path="/processos" element={<MentoringResourcesHuman />} />
      <Route path="/processos/detalhes/:id" element={<MentoryResourcesHuman />} />

      <Route path="/dados-do-programa" element={<ProgramResourcesHuman />} />
      <Route path="/perfil-da-empresa" element={<CompanyResourceHuman />} />

      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
