import { Routes, Route, Navigate } from 'react-router-dom';
import { Profile, SelectMentoring, SelectMentoringNew } from '../pages';

export const IsAuthSelectMentoring = () => {
  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/meus-processos" element={<SelectMentoring />} />
      <Route path="/novo-processo" element={<SelectMentoringNew />} />
      <Route path="*" element={<Navigate to="/meus-processos" />} />
    </Routes>
  );
};
