import React, { useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Stack,
  Avatar,
  Typography,
  Box,
  Skeleton,
  Pagination,
  Grid,
  Card,
  useTheme,
  Tooltip,
  AvatarGroup,
  CardMedia,
  CardContent,
  Button,
  IconButton,
} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Environment } from '../../shared/environment';
import { EASCard, EASProgressList } from '../../shared/components';
import { EASButton, EASInput } from '../../shared/forms';


import { useAuthContext , useMentoringContext } from '../../shared/contexts';
import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { ProductBuyService } from '../../shared/services/api/productbuy/ProductBuyService';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { useQuery } from 'react-query';
import { ProductBuyNew } from './ProductBuyNew';

export const ProductBuyList:React.FC = (() => {


  const theme = useTheme();
  const navigate = useNavigate();
  
  //const [isLoading, setIsLoading] = useState(true);
  const [openNewProgram, setOpenNewProgram] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);
  const [mentoring, setMentoring] = useState<Array<IMentory> | null> (null);

  const [updateList, setUpdateList] = useState(false);


  const { user } = useAuthContext();
  const { markMentory } = useMentoringContext();

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);

/*
  const { data, isError, isLoading } = useQuery(['select-product-buy-list', page], 
                                                () => ProductBuyService.getProductBuys(page,search), 
                                                { keepPreviousData: true, 
                                                  retry:3,
                                                  refetchInterval:false  });*/
const { data, isError, isLoading } = useQuery(['select-product-buy-list', page, search], 
                                              () => ProductBuyService.getProductBuys(page, search), 
                                              { 
                                                onSuccess: data => {
                                                  setTotalCount(data?.total ? data.total : 0);
                                                 // const message = 'success';
                                                 //alert(data.total);
                                                },
                                                onError: () => {
                                                  alert('there was an error');
                                                },                                  
                                                keepPreviousData: true, 
                                                retry:3,
                                                refetchInterval:false  });

  const handleSelectMentory = (mentory:IMentory) => {
     markMentory(mentory);
     navigate('/pagina-inicial');
  };

  return (
    <>          
      <EASCard 
        titleCard="Nossas Soluções"
        bgcolorContent={theme.palette.background.default}
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}> 
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>
                    }>  
          <Grid container spacing={2}>
            {isLoading && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
              </Grid>
            </Grid>
            )}
            {!isLoading && ( (data && data?.data) ? (data?.data.map((productBuy) => (
            <Grid item xs={12} sm={6} lg={4} xl={3} key={productBuy.id_product_buy}> 
              <Card variant="outlined" sx={{ borderRadius:'12px' }}>
                <CardMedia
                  component="img"
                  height="194"
                  image="https://berrydashboard.io/assets/prod-5-Cl9js1ye.png"
                  alt="Paella dish"
                />
                <CardContent>

                  <Grid container spacing={2}>
                    <Grid item xs={12}> 
                      <Typography variant="subtitle1" color="text.secondary">{productBuy.name_product_buy}</Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Typography variant="body2" color="text.secondary">
                        {productBuy.description_product_buy}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}> 
                      <Grid container spacing={2}>
                        <Grid item zeroMinWidth xs={true}>
                            <NumericFormat
                              value={productBuy.value_product_buy}
                              thousandsGroupStyle="lakh"
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale={2} 
                              fixedDecimalScale 
                              displayType="text"
                              prefix="R$ "
                              renderText={(value) => <Typography variant="h6" color="text.secondary">{value}</Typography>}
                            />
                        </Grid> 
                        <Grid item> 
                          <EASButton variant="contained" onClick={() => navigate('/aura-shop/produto/' + productBuy.id_product_buy)}><ShoppingCartIcon fontSize="small" /></EASButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
             ))) : (
              <Grid item xs={12} > 
                  Nenhum item encontrado 
              </Grid>
             ))}
            </Grid>
            {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
            <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
              <Pagination
                page={page}
                count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })}
                variant="outlined" 
                shape="rounded"
                color="primary"
                siblingCount={3} 
                boundaryCount={1}
              />
            </Box>
            )}
            <ProductBuyNew open={openNewProgram} setOpen={(e) => setOpenNewProgram(e)}/>
      </EASCard>
  </>
  );
});

