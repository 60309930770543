import { useState, useEffect } from 'react';
import * as yup from 'yup';

import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Divider} from '@mui/material';

import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { IMaintenancePlanLesson } from '../../shared/contexts/MaintenancePlanContext/types';
import { MaintenancePlanService } from '../../shared/services/api/maintenance-plan/MaintenancePlanService';
import { useSnackbar } from 'notistack';
import { EASButton, EASInput} from '../../shared/forms';


import AddIcon from '@mui/icons-material/Add';

interface IMaintenancePlanItemLessonNewProps {
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;

}
export const MaintenancePlanItemLessonNew: React.FC<IMaintenancePlanItemLessonNewProps> = ({open, setOpen, setUpdateList})  => {

  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [maintenancePlanLesson, setMaintenancePlanLesson] = useState<IMaintenancePlanLesson>({});
  const [maintenancePlanLessonError, setMaintenancePlanLessonError] = useState<IMaintenancePlanLesson | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  const { mentory } = useMentoringContext();
  
  const handleClose = () => {
    setMaintenancePlanLesson({});
    setOpen(false);
  };

  useEffect(() => {
    setMaintenancePlanLesson({});
    setIsLoading(false);
  }, []);


  const maintenancePlanLessonSchema = yup.object().shape({    
    text_maintenance_plan_lesson: yup.string().required('Descreva o aprendizado'),
  });


  const handleButtonSave = () => {
    maintenancePlanLessonSchema
      .validate(  maintenancePlanLesson, { abortEarly: false })
      .then(validateValues => {
        setSaveLoading(true);
        const newMaintenancePlanLesson: IMaintenancePlanLesson = {
          text_maintenance_plan_lesson: validateValues.text_maintenance_plan_lesson,
          id_mentory: mentory?.id_mentory,
        };    
        MaintenancePlanService.createMaintenancePlanLesson(newMaintenancePlanLesson)
          .then((SessionResponse) => {
            if (SessionResponse instanceof Error) {
              setSaveLoading(false);
              enqueueSnackbar('Erro ao cadastrar novo aprendizado.', { variant:'error'});
            } else {
              setSaveLoading(false);
              enqueueSnackbar('Novo aprendizado cadastrado com sucesso!', { variant:'success'});
              setMaintenancePlanLesson({});
              setOpen(false);
              setUpdateList();
            }
          }).catch(() => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar novo aprendizado.', { variant:'error'});
          });
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setMaintenancePlanLessonError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
    });

  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Cadastrar Aprendizado</DialogTitle>
      <Divider/>
      <DialogContent>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
          )}
          {!saveLoading && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EASInput 
                  isLoading={isLoading}
                  label="Descrição do Aprendizado"
                  value={maintenancePlanLesson?.text_maintenance_plan_lesson} 
                  onChange={(event) => setMaintenancePlanLesson(existingValues => ({
                                                  ...existingValues,
                                                  text_maintenance_plan_lesson: event.target.value,
                                                }))
                          }  
                  onFocus={() => setMaintenancePlanLessonError(existingValues => ({
                                                  ...existingValues,
                                                  text_maintenance_plan_lesson: '',
                                              }))
                          }
                  error={!!maintenancePlanLessonError?.text_maintenance_plan_lesson}
                  helperText={maintenancePlanLessonError?.text_maintenance_plan_lesson}
                  multiline
                  rows={6}
                />  
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{mr:2, mb:1}}>
            <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
            <EASButton 
                  isLoading={isLoading}
                  variant='contained'
                  disabled={saveLoading}
                  endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
                  onClick={handleButtonSave}>Inserir Aprendizado</EASButton>
      </DialogActions>
    </Dialog> 

  );
};
