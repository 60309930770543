
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Avatar, ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IOrder } from '../../shared/contexts/OrdersContext/types';
import { OrdersService } from '../../shared/services/api/orders/OrdersService';

import { EASSelect, EASCheck } from '../../shared/forms';

import { UtilService, IList } from '../../shared/services/api/util/UtilService';

import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';

import { PeopleNewPerson } from '../people/PeopleNewPerson';



type IOrdersNewOrder = {
    open: boolean;
    setOpen: (open:boolean) => void;
    setUpdateList:() => void;
};

export interface IOrderError {
	id_user?: string;
	id_user_group?: string;
}

export const OrdersNewOrder:React.FC<IOrdersNewOrder> =  (({open, setOpen, setUpdateList}) => {

    const [openNewPerson, setOpenNewPerson] = useState(false);
    const [updateListPeople, setUpdateListPeople] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState<Array<IList>>([]);
    const [userGroups, setUserGroups] = useState<Array<IList>>([]);
    const [order, setOrder] = useState<IOrder>({});
    const [orderError, setOrderError] = useState<IOrderError | null>(null);

    const { user } = useAuthContext();

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOrder({});
      setOpen(false);
    };
  

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        async function getLists() {
            setOrderError(null);
            setIsLoading(true);
            const userGroupsResponse = await UtilService.getUserGroups();
            setUserGroups(userGroupsResponse);
            const orderResponse = await UtilService.getOperators();
            if (orderResponse instanceof Error) {
                setOrders([]);
                setIsLoading(false);
            } else {
                if(orderResponse){
                    const newOrders = JSON.parse(JSON.stringify(orderResponse));
                    setOrders(newOrders);
                    setIsLoading(false);
                }
                else{
                    setOrders([]);
                    setIsLoading(false);
                }
            }
        }
        if(open==true)
            getLists();
    }, [open]);

    const isValidOrder = yup.object({
        id_user: yup.string().required('Este campo é obrigatório'),
        id_user_group: yup.array().min(1,'Este campo é obrigatório').required('Este campo é obrigatório'),
    });
    

    const saveOrder = () => {
        isValidOrder
        .validate( order , { abortEarly: false })
        .then(validateOrder => { 
            setSaveLoading(true);
            if(validateOrder) {
                const newOrder: IOrder = {
                    id_user: validateOrder.id_user,
                    id_user_group: validateOrder.id_user_group,
                };            
                OrdersService.createOrder(newOrder)
                .then((OrderResponse) => {
                    if (OrderResponse instanceof Error) {
                        setSaveLoading(false);
                        enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
                    } else {
                        enqueueSnackbar('Operador cadastrado com sucesso!', { variant:'success'});
                        setOpen(false);
                        setUpdateList();
                        setSaveLoading(false);
                    }
                }).catch(() => {
                    setSaveLoading(false);
                    enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
                });
            }
            else{
                setSaveLoading(false);
                enqueueSnackbar('Erro ao cadastrar operador.', { variant:'error'});
            }
        })
        .catch((errors: yup.ValidationError) => {
            setIsLoading(false);
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setOrderError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    return (
        <>
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>Cadastrar Novo Operador</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', minWidth:'480px', minHeight:'280px'}}>
            {saveLoading && (                   
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant='indeterminate' />
                </Box>
            )}
            {!saveLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EASSelect 
                            isLoading={isLoading}
                            options={orders}
                            onFocus={() => 
                                setOrderError(existingValues => ({
                                ...existingValues,
                                id_user: '',
                                }))
                            }
                            label="Escolha uma Pessoa"
                            getOptionLabel={(option) => option.text}
                            renderOption={(props, option) => (
                                    <ListItem {...props}>
                                        <ListItemAvatar>
                                            <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                        </ListItemAvatar>
                                        <ListItemText primary={option.text} />
                                    </ListItem>
                            )}
                            value={orders.find(item => item.value == order?.id_user)}                                  
                            onChangeSelect={(event, value:IList) => {
                                if (value && value.value) {
                                    setOrder(existingValues => ({
                                        ...existingValues,
                                        id_user: value.value,
                                    }));
                                }
                            }
                            }
                            error={!!orderError?.id_user}
                            helperText={orderError?.id_user}
                        />
                        <Box sx={{pt:1}} >
                            <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                Cadastrar nova pessoa
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <EASCheck
                            label="Nível de Acesso"
                            isLoading={isLoading}
                            onFocus={() => 
                                setOrderError(existingValues => ({
                                ...existingValues,
                                id_user_group: '',
                                }))
                            }
                            options={userGroups}
                            value={order?.id_user_group}
                            onChange={(e) =>{
                                setOrderError(existingValues => ({
                                ...existingValues,
                                id_user_group: '',
                                }));
                                const newUserGroup:Array<string> = order?.id_user_group?.length ? 
                                    (e.target.checked === false ? 
                                        order.id_user_group.filter(item => item !== e.target.name)
                                        : order.id_user_group
                                    ) : [];

                                if(e.target.checked === true)
                                    newUserGroup.push(e.target.name);

                                setOrder(existingValues => ({
                                    ...existingValues,
                                    id_user_group:newUserGroup
                                })) ;
                            }}
                            error={!!orderError?.id_user_group}
                            helperText={orderError?.id_user_group}
                        />
                    </Grid>
                </Grid>
            )}
            </Box>            
        </DialogContent>
        <Divider/>
        <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
            <Button variant="contained" onClick={saveOrder} disabled={saveLoading}>Confirmar</Button>
        </DialogActions>
    </Dialog>   
    <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} />
    </>
    );
});

