import { IProgramsMentoring, IProgramsMentoringList } from '../../../types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

const createProgramsMentoring = async (programsMentoring: IProgramsMentoring): Promise<void> => {
  const { data } = await Api.post('/programsmentoring', { ProgramMentoring: programsMentoring } );
  return data;
};


const updateProgramsMentoring  = async (programsMentoring: IProgramsMentoring): Promise<void> => {
  const { data } = await  Api.put(`/programsmentoring/${programsMentoring.id_mentory}`, programsMentoring);
  return data;  
};


const deleteProgramsMentoring = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/programsmentoring/${id}`);
  return data;   
};

const getProgramsMentoring = async (program = '', page = 1, search = '' ): Promise<IProgramsMentoringList> => {
  const  {data} = await Api.get(`/programsmentoring?public_token=${Environment.PUBLIC_TOKEN}&program=${program}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}`);
  return data;
};
             
export const ProgramsMentoringService = {
  getProgramsMentoring,
  deleteProgramsMentoring,
  createProgramsMentoring,
  updateProgramsMentoring
};