import { IRating } from '../../../contexts/RatingsContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface IRatingsService {
  data?: IRating;
}

interface IRatingsListResponse extends IRating {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<IRating> | null;
}

const createRating = async (ratings: IRating): Promise<IRatingsService | Error> => {
  try {
    const { data } = await Api.post('/ratings', { Rating: ratings } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateRating = async (Rating: IRating): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/ratings/${Rating.id_rating}`, Rating);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteRating = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/ratings/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getRating = async (id: string): Promise<IRatingsService | Error> => {
  try {
    const { data } = await Api.get(`/ratings/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexão.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const getRatings = async (page = 1, filter = '' ): Promise<IRatingsListResponse | Error> => {
  try {
    const urlRelativa = `/ratings?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const RatingsService = {
  getRatings,
  getRating,
  createRating,
  updateRating,
  deleteRating,

};
