import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Stack, TableHead, TableRow, TableBody, TableCell, TableContainer, Typography, Chip, Box, Skeleton, Pagination } from '@mui/material';

import { Environment } from '../../shared/environment';
import { ObjectivesSampleNewObjectiveSample } from './ObjectivesSampleNewObjectiveSample';
import { EASCard, EASDialogAlert} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';
import { IObjectivesSample } from '../../shared/contexts/ObjectivesSampleContext/types';
import { ObjectivesSamplesService } from '../../shared/services/api/objectives-sample/ObjectivesSampleService';

export const ObjectivesSampleList:React.FC = (() => {


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { debounce } = useDebounce(1000, true);
  const [isLoading, setIsLoading] = useState(true);
  const [initState, setInitState] = useState(false);

  const [allObjectivesSample, setAllObjectivesSample] = useState<Array<IObjectivesSample> | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalCount, setTotalCount] = useState(0);

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idObjectivesSample, setIdObjectivesSample] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
      debounce(() => {
        if(initState) getObjectivesSample();
      });
  }, [search]);
  
  useEffect(() => {
    getObjectivesSample();
  }, [page,updateList]);

  async function getObjectivesSample() {
    setIsLoading(true);
    const objectivesSampleResponse = await ObjectivesSamplesService.getObjectivesSample(page, search);
    if (objectivesSampleResponse instanceof Error) {
      setAllObjectivesSample(null);
      setIsLoading(false);
      setTotalCount(0);
      setInitState(true);
    } else {
      if(objectivesSampleResponse.data && objectivesSampleResponse.total){
        const myObjectivesSample = JSON.parse(JSON.stringify(objectivesSampleResponse));
        setAllObjectivesSample(myObjectivesSample.data);
        if(objectivesSampleResponse.total)
          setTotalCount(objectivesSampleResponse.total);
        setIsLoading(false);
        setInitState(true);
      }
      else{
        setAllObjectivesSample(null);
        setIsLoading(false);
        setTotalCount(0);
        setInitState(true);
      }
    }
  }


  const handleClickRemoveObjectivesSample = (id_objective_sample?:string) => {
    if(id_objective_sample){
      setIdObjectivesSample(id_objective_sample);
      setTitleDialog('Excluir Sugestão de Ação');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta sugestão de ação?</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockObjectivesSample = (id_objective_sample?:string) => {
    if(id_objective_sample){
      setIdObjectivesSample(id_objective_sample);
      setTitleDialog('Bloquear Sugestão de Ação');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso a esta sugestão de ação?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockObjectivesSample = (id_objective_sample?:string) => {
    if(id_objective_sample){
      setIdObjectivesSample(id_objective_sample);
      setTitleDialog('Desbloquear Sugestão de Ação');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso desta a esta sugestão de ação?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdObjectivesSample(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeObjectivesSample();
      break;
      case 'block':
        blockObjectivesSample(false);
      break;
      case 'unblock':
        blockObjectivesSample(true);
      break;
    }
  };

  const removeObjectivesSample = () => {
    setLoadingDialog(true);
    if(idObjectivesSample){
      ObjectivesSamplesService.deleteObjectiveSample(idObjectivesSample).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar remover a sugestão de ação! Tente novamente. Caso o erro persista, contate o administrador do sistema', { variant:'error'});
          setLoadingDialog(false);
        } else {
            enqueueSnackbar('Sugestão de ação removido com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            setUpdateList(!updateList);
            handleCancelDialog();
        }
      });
    }
  };

  const blockObjectivesSample = (block:boolean) => {
    setLoadingDialog(true);
    if(idObjectivesSample){
      const blockObjectivesSample:IObjectivesSample = {
        id_objective_sample:idObjectivesSample,
        status_objective_sample:(block ? '1' : '0')
      };
      ObjectivesSamplesService.updateObjectiveSample(blockObjectivesSample).then((response) => {
        if (response instanceof Error) {
          enqueueSnackbar('Erro ao tentar ' + (block ? 'desbloquear' : 'bloquear') + ' a sugestão de ação!', { variant:'error'});
          setLoadingDialog(false);
        } else {
            enqueueSnackbar('Sugestão de ação ' + (block ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
            setLoadingDialog(false);
            setUpdateList(!updateList);
            handleCancelDialog();
        }
      });
    }
  };

  return (
    <>          
      <EASCard 
        titleCard="Ações Sugeridas"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Ação Sugerida
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Competência
                </TableCell>
                <TableCell component="th" scope="row" align="center" sx={{minWidth: 100}}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (allObjectivesSample && allObjectivesSample.length) ? (allObjectivesSample.map((objectiveSample) => (
              <TableRow 
                  hover 
                  key={objectiveSample.id_objective_sample} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {objectiveSample?.id_objective_sample}
                </TableCell>
                <TableCell>
                  {objectiveSample?.text_objective_sample}
                </TableCell>
                <TableCell align="center">
                  <Chip label={objectiveSample?.status_objective_sample == '1'? 'Ativo' : 'Bloqueado'} color={objectiveSample?.status_objective_sample == '1' ? 'success' : 'error'} />
                </TableCell>
                <TableCell align="center">
                  {objectiveSample?.name_objective_sample}
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Ação Sugerida"
                      onClick={() => navigate(`/acoes-sugeridas/detalhes/${objectiveSample.id_objective_sample}`)} 
                      typeIcon="edit"
                  />
                  {objectiveSample?.status_objective_sample == '0' ? (
                  <EASButtonList 
                      title="Desbloquear Ação Sugerida"
                      onClick={() => handleClickUmBlockObjectivesSample(objectiveSample.id_objective_sample)} 
                      typeIcon="unblock"
                  />
                  ) : (
                  <EASButtonList 
                    title="Bloquear Ação Sugerida"
                    onClick={() => handleClickBlockObjectivesSample(objectiveSample.id_objective_sample)} 
                    typeIcon="block"
                  />
                  )}
                  <EASButtonList 
                    title="Remover Ação Sugerida"
                    onClick={() => handleClickRemoveObjectivesSample(objectiveSample.id_objective_sample)} 
                    typeIcon="remove"
                  />
                </TableCell> 
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!isLoading && totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
            <Box style={{ display: 'flex', justifyContent: 'center', padding:'20px 0 20px 0' }}>
              <Pagination
                page={page}
                count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                onChange={(_, newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })}
                variant="outlined" 
                shape="rounded"
                color="primary"
                siblingCount={3} 
                boundaryCount={1}
              />
            </Box>
            )}
      </EASCard>
      <ObjectivesSampleNewObjectiveSample open={open} setOpen={(e) => setOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

