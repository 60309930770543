
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Hidden, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { ICompany } from '../../shared/types';
import { CompaniesService } from '../../shared/services/api/companies/CompaniesService';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { isValidCNPJ, UtilService, IList, uniqueCNPJ, uniqueMail } from '../../shared/services/api/util/UtilService';

import { useAuthContext } from '../../shared/contexts';
import { useMutation, useQuery, useQueryClient } from 'react-query';



type ICompaniesNewCompany = {
    open: boolean;
    setOpen: (open:boolean) => void;
};



export const CompaniesNewCompany:React.FC<ICompaniesNewCompany> =  (({open, setOpen}) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [saveLoading, setSaveLoading] = useState(false);
    const [company, setCompany] = useState<ICompany>({});
    const [companyError, setCompanyError] = useState<ICompany | null>(null);
    
    const { user } = useAuthContext();


    const handleClose = () => {
        setCompany({});
        setOpen(false);
    };
    
    const { data:estados, isLoading:isLoadingEstados } = useQuery(['list-estados'], () =>  UtilService.getEstados(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:cidades, isFetching:isFetchingCidades, isLoading:isLoadingCidades } = useQuery(['list-cidades-company' , [company?.id_estado]], () =>  UtilService.getCidades(company?.id_estado), {
        enabled:!(isLoadingEstados) && !!(company?.id_estado),
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de cidades.', { variant:'error'});
        },
    });
   
    const { mutateAsync:storeCreate, } = useMutation((newCompany:ICompany) => CompaniesService.createCompany(newCompany), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-company-list');
            queryClient.invalidateQueries('operator-company-edit');
            enqueueSnackbar('Empresa cadastrada com sucesso!', { variant:'success'});
            setOpen(false);
            setSaveLoading(false);
            setCompany({});

        },
        onError: () => {
            enqueueSnackbar('Erro ao tentar cadastrar nova empresa.', { variant:'error'});
            setSaveLoading(false);
        },
    });






    const isValidCompany = yup.object({
        razao_social_company: yup.string().required('Este campo é obrigatório'),
        email_company: yup.string().test('email_company', 'Email já cadastrado para outra empresa', (email) => (email ? uniqueMail(email, '') : false)).email('Informe um e-mail válido').required('Este campo é obrigatório'),
        //cnpj_company: yup.string().test('cnpj_company', 'CNPJ já cadastrado', (cnpj) => (cnpj ? (isValidCNPJ(cnpj) && uniqueCNPJ(cnpj, company?.id_company)) :  false)).test('cnpj_company', 'Informe um CNPJ válido', (cnpj) => (cnpj ? isValidCNPJ(cnpj) :  false)).required('Este campo é obrigatório'),
        //cpf_user: yup.string().test('cpf_user', 'CPF já cadastrado', (cpf) => (cpf ? (isValidCPF(cpf) && uniqueCPF(cpf, '')) :  false)).test('cpf_user', 'Informe um CPF válido', (cpf) => (cpf ? isValidCPF(cpf) :  false)).required('Este campo é obrigatório'),
        //data_nascimento_user: yup.string().required('Este campo é obrigatório')
    });
    





    const saveCompany = () => {
        isValidCompany
        .validate( company , { abortEarly: false })
        .then(validateCompany => { 
            setSaveLoading(true);
            const newCompany: ICompany = {
                status_company: '1',
                block_company: '0',
                cnpj_company: company?.cnpj_company,
                razao_social_company: validateCompany.razao_social_company,
                nome_fantasia_company: company?.nome_fantasia_company,
                pessoa_contato_company: company?.pessoa_contato_company,
                default_name_company: company?.default_name_company,
                //inscricao_estadual_company: company?.inscricao_estadual_company,
                //inscricao_municipal_company: company?.inscricao_municipal_company,
               // uf_inscricao_estadual_company: company?.uf_inscricao_estadual_company,
                id_estado: company?.id_estado,
                id_cidade: company?.id_cidade,
                cep_company: company?.cep_company,
                bairro_company: company?.bairro_company,
                logradouro_company: company?.logradouro_company,
                numero_company: company?.numero_company,
                complemento_company: company?.complemento_company,
                //site_company: company?.site_company,
                email_company: validateCompany.email_company,
                //telefone_company: company?.telefone_company,
                //celular_company: company?.celular_company,
               // logo_company: company?.logo_company,
               // obs_company: company?.obs_company,
                user_register: user?.id


                //status_company: '1',
                //razao_social_company: validateCompany.razao_social_company,
                //email_company: validateCompany.email_company,
                //block_company: '0',
                //public_user: '1',
                //password_user: '',
                //activation_user: '',
                //cnpj_company: company?.cnpj_company,
                //nome_fantasia_company: '',
                //npai_user: '',
                //tsangue_user: '',
                //rg_user: '',
                //sexo_user: company?.sexo_user,
                //data_nascimento_user: validateCompany.data_nascimento_user,
                //id_estado_civil: company?.id_estado_civil,
                //nacionalidade_user: '',
                //profissao_user: '',
                //celular_company: company?.celular_company,
                //telefone_company: '',
                //id_estado: company?.id_estado,
                //id_cidade: company?.id_cidade,
                //cep_company: company?.cep_company,
                //bairro_company: company?.bairro_company,
                //logradouro_company: company?.logradouro_company,
                //numero_company: company?.numero_company,
                //complemento_company: company?.complemento_company,
                //logo_company: '',
                //obs_company:'',
                
            };            
            storeCreate(newCompany);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setCompanyError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

/*
    const isValidCNPJ2 = (cnpj:string) => {

        if (typeof cnpj !== 'string') 
            return false;
      
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{10}/)) 
          return false;
      
       const validator = cnpj.split('')
       .filter((digit:string, index:number, array:Array<string>) => index >= array.length - 2 && digit)
       .map( el => + el );

       const toValidate = (pop:number) => cnpj.split('')
         .filter((digit:string, index:number, array:Array<string>) => index < array.length - pop && digit)
         .map(el => +el);
     
       const rest = (count:number, pop:number) => (toValidate(pop)
         .reduce((soma:number, el:number, i:number) => soma + el * (count - i), 0) * 10) % 11 % 10;


         alert( cnpj.split('').filter((digit:string, index:number, array:Array<string>) => index < array.length - 2 && digit).map(el => +el));

         alert( cnpj.split('').filter((digit:string, index:number, array:Array<string>) => index < array.length - 2 && digit).map(el => +el));
         
         /*
       var pos = tamanhoTotal - 7;
       for (i = tamanhoTotal; i >= 1; i--) {
           soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
           if (pos < 2)
               pos = 9;
       }



       resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
       if (resultado != digitosVerificadores.charAt(0))
           return false;
   
       tamanhoTotal = tamanhoTotal + 1;
       cnpjSemDigitos = cnpj.substring(0, tamanhoTotal);
       soma = 0;
       pos = tamanhoTotal - 7;
       for (i = tamanhoTotal; i >= 1; i--) {
           soma += cnpjSemDigitos.charAt(tamanhoTotal - i) * pos--;
           if (pos < 2)
               pos = 9;
       }
   
       resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
       if (resultado != digitosVerificadores.charAt(1))
           return false;
   
       return true;

*/



/*

       const toValidate = (pop:number) => cnpj.split('')
         .filter((digit:string, index:number, array:Array<string>) => index < array.length - pop && digit)
         .map(el => +el);
     
       const rest = (count:number, pop:number) => (toValidate(pop)
         .reduce((soma:number, el:number, i:number) => soma + el * (count - i), 0) * 10) % 11 % 10;

         alert(rest(11,2));
         alert(validator[1]);
       alert( !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1]));*/
     
   /*  };
*/
     const handleerror = () => {
        alert('nada');
    };
    

    return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}
    maxWidth={'md'}>
        <DialogTitle>Cadastrar Nova Empresa</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {saveLoading && (                   
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant='indeterminate' />
                </Box>
            )}
            {!saveLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    onFocus={() => 
                                        setCompanyError(existingValues => ({
                                        ...existingValues,
                                        razao_social_company: '',
                                        }))
                                    }
                                    label="Razão Social"
                                    type="text"
                                    value={company?.razao_social_company}
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            razao_social_company: e.target.value,
                                        }))
                                    }
                                    
                                    error={!!companyError?.razao_social_company}
                                    helperText={companyError?.razao_social_company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASInput
                                    id="cnpj_company"
                                    onFocus={() => 
                                        setCompanyError(existingValues => ({
                                        ...existingValues,
                                        cnpj_company: '',
                                        }))
                                    }
                                    mask={'maskCNPJ'}
                                    isLoading={isLoadingEstados}
                                    label='CNPJ'
                                    type='text'
                                    value={company?.cnpj_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            cnpj_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.cnpj_company}
                                    helperText={companyError?.cnpj_company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> 
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    onFocus={() => 
                                        setCompanyError(existingValues => ({
                                        ...existingValues,
                                        email_company: '',
                                        }))
                                    }
                                    label="E-mail"
                                    type='text'
                                    value={company?.email_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            email_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.email_company}
                                    helperText={companyError?.email_company}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    mask={'maskCEP'}
                                    label='CEP'
                                    type='text'
                                    value={company?.cep_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            cep_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.cep_company}
                                    helperText={companyError?.cep_company}
                                />
                            </Grid>
                            <Hidden lgDown>
                                <Grid item sm={6} lg={9}>
                                </Grid>
                            </Hidden>
                            <Grid item xs={8} sm={10}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    label='Logradouro'
                                    type='text'
                                    value={company?.logradouro_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            logradouro_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.logradouro_company}
                                    helperText={companyError?.logradouro_company}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    label='Número'
                                    type='text'
                                    value={company?.numero_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            numero_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.numero_company}
                                    helperText={companyError?.numero_company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    label='Complemento'
                                    type='text'
                                    value={company?.complemento_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            complemento_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.complemento_company}
                                    helperText={companyError?.complemento_company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASInput
                                    isLoading={isLoadingEstados}
                                    label='Bairro'
                                    type='text'
                                    value={company?.bairro_company}                                
                                    onChange={(e) => 
                                        setCompany(existingValues => ({
                                            ...existingValues,
                                            bairro_company: e.target.value,
                                        }))
                                    }
                                    error={!!companyError?.bairro_company}
                                    helperText={companyError?.bairro_company}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASSelect 
                                    isLoading={isLoadingEstados}
                                    options={estados ? estados : []}
                                    label="Estado"
                                    getOptionLabel={(option) => option.text || ''}
                                    value={estados ? estados.find(item => item.value == company?.id_estado) : ''}                                  
                                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setCompany(existingValues => ({
                                                ...existingValues,
                                                id_estado: value.value,
                                            }));
                                            
                                    }}}  
                                    error={!!companyError?.id_estado}
                                    helperText={companyError?.id_estado}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASSelect 
                                    isLoading={(isLoadingEstados) || (isFetchingCidades && isLoadingCidades)}
                                    options={cidades ? cidades : []}
                                    label="Cidade"
                                    getOptionLabel={(option) => option.text || ''}
                                    value={(cidades && company?.id_cidade) ? (cidades.find(item => item.value == company?.id_cidade) ? cidades.find(item => item.value == company?.id_cidade) : null) : null}                                  
                                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setCompany(existingValues => ({
                                                ...existingValues,
                                                id_cidade: value.value,
                                            }));
                                    }}}                              
                                    error={!!companyError?.id_cidade}
                                    helperText={companyError?.id_cidade}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            </Box>            
        </DialogContent>
        <Divider/>
        <DialogActions>
            
            {/* 
            <Button variant="outlined" onClick={() => company?.cnpj_company ? isValidCNPJ2(company.cnpj_company) : handleerror}>testar CNPJ</Button>
            */}
            <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
            <Button variant="contained" onClick={saveCompany} disabled={saveLoading}>Confirmar</Button>
        </DialogActions>
    </Dialog>   
    );
});

