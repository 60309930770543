import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDrawerContext } from '../shared/contexts';
import {
  ProductBuy,
  ProductBuyItem,
  Orders,
  Profile,
} from '../pages';


export const IsAuthAuraShoping = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
      setDrawerOptions([
        {
          icon: 'shopping_cart',
          path: '/meus-produtos',
          label: 'Meus Produtos',
        },
        {
          icon: 'emoji_objects',
          path: '/aura-shop',
          label: 'Nossas Soluções',
          group: 'Painel de Compras'
        }
        
      ]);

  }, []);


  return (
    <Routes>
      <Route path="/editar-perfil" element={<Profile />} />
      <Route path="/aura-shop" element={<ProductBuy />} />
      <Route path="/meus-produtos" element={<Orders />} />
      <Route path="/aura-shop/produto/:id" element={<ProductBuyItem />} />
      <Route path="*" element={<Navigate to="/meus-produtos" />} />
    </Routes>
  );
};
