
import React, { useMemo } from 'react';
import { useSearchParams} from 'react-router-dom';

import { Box, Pagination, Grid, LinearProgress, Alert, Stack, useTheme } from '@mui/material';
import { MentoryFileCard } from './MentoryFileCard';

import { useMentoringContext} from '../../shared/contexts';
import { Environment } from '../../shared/environment';
import { FilesService } from '../../shared/services/api/files/FilesService';
import {  EASInput } from '../../shared/forms';
import { EASCard, PaginationComponent } from '../../shared/components';
import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';

export const MentoryFilesList = () => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { mentory } = useMentoringContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const search = useMemo(() => {
      return searchParams.get('search') || '';
    }, [searchParams]);
  
    const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
    }, [searchParams]);
    
    const { data, isLoading } = useQuery(['list-file', [page, search, mentory?.id_mentory]],() =>  FilesService.getFiles(page, search,mentory?.id_mentory),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista de arquivos.', { variant:'error'});
      },
    });
       
    return(
      <EASCard 
        bgcolorContent={theme.palette.background.default} 
        titleCard="Arquivos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}> 
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>}
      >
  
        <Grid container alignItems="center" spacing={2} sx={{ mb: 2}} >
          {isLoading && (
          <Grid item xs={12}>
            <LinearProgress variant='indeterminate' />
          </Grid>
          )}
          {!isLoading && ((data?.data && data.data.length) ? (data.data.map((file, index) => (
          <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={3} >
            <MentoryFileCard key={index} file={file}/>
          </Grid>
          ))) : (
          <Grid item xs={12}>
            <Alert severity="warning">Nenhum arquivo encontrado!</Alert>
          </Grid> 
          ))}
        </Grid>
        {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
    );
};