
import React, { useState, useEffect } from 'react';
import {Box, Alert, Typography, Container, Grid, List, Card, CardContent, Skeleton } from '@mui/material';

import { useAuthContext, useMentoringContext } from '../../contexts';
import { EASToolsPerceptionOfInfluencersItem } from './EASToolsPerceptionOfInfluencersItem';
import { EASToolsPerceptionOfInfluencersChart } from './EASToolsPerceptionOfInfluencersChart';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useNavigate } from 'react-router-dom';

import { EASButton } from '../../forms';
import { EASDialogAlert, EASCard } from '../';

interface IColumnItem {
  id?: string;
  list?: Array<IInfluencer>;
}  

interface IColumnGroup extends IColumnItem {
  Influenciadores: IColumnItem;
  Promotores: IColumnItem;
  Neutros: IColumnItem;
  Detratores: IColumnItem;
}  

interface EASToolsPerceptionOfInfluencers {
  allInfluencers: Array<IInfluencer> | null;
  setAllInfluencers:(allInfluencers : Array<IInfluencer>) => void;
  updateList:() => void;
}  

export const EASToolsPerceptionOfInfluencers: React.FC = () => {
  
  const [messageInfluencers, setMessageInfluencers] = useState<boolean>(false);
  const [allInfluencers, setAllInfluencers] = useState<Array<IInfluencer> | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const { user } = useAuthContext();
  const { mentory } = useMentoringContext();



  const navigate = useNavigate();

  useEffect(() => {
    async function getInfluencers() {
      if(mentory?.id_mentory){
        setIsLoading(true);
        const influencers = await InfluencersService.getAllInfluencers(mentory?.id_mentory);
        if (influencers instanceof Error) {
          setAllInfluencers(null);
          setIsLoading(false);
        } else {
          if(influencers.data && influencers.total){
            const myInfluencers:Array<IInfluencer> = JSON.parse(JSON.stringify(influencers.data));
            setAllInfluencers(myInfluencers);
            setIsLoading(false);

          }
          else{
            setMessageInfluencers(true);
            setAllInfluencers(null);
            setIsLoading(false);
          }
        }
      }
    }
    getInfluencers();
  }, []);



  const updateInfluencer = (influencerUpdate?:IInfluencer) => {
    if (influencerUpdate && allInfluencers) {
      const newAllInfluencers = allInfluencers.map(influencer => {
        if (influencerUpdate.id_influencer !== influencer.id_influencer) {
          return influencer;
        } else {
          return influencerUpdate;
        }
      });
      setAllInfluencers(newAllInfluencers);
    }
  };

  return (    
    <EASCard 
      titleCard="Mapa de Proximidade"
      actionCard={<EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => navigate('/ferramentas')}>Sair</EASButton>}> 
      <Container>
        <Card sx={{borderRadius : 2, bgcolor: 'transparent', my:'30px'}} variant='outlined' elevation={0}>
          <CardContent>
            {isLoading && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', mt:'15px'}}>
                <Skeleton animation="wave" variant="rounded"  width={'80%'} height={18} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'40%'} height={18} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px'}} width={'80%'} height={58} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'15px', ml:'15px'}} width={150} height={58} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'35px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'row', mt:'15px', mx:'30px'}}>
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={15} height={35} />
                <Skeleton animation="wave" variant="circular" sx={{ml:'15px'}} width={40} height={40} />
                <Skeleton animation="wave" variant="rounded" sx={{mt:'5px', ml:'15px'}} width={350} height={15} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'40%'}} width={30} height={35} />
                <Skeleton animation="wave" variant="rounded" sx={{ml:'15px'}} width={30} height={35} />
              </Box> 
            </>
            )}
            {!isLoading && (
            <Box>
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" sx={{textAlign: 'center', marginBottom:'5px'}}>
                  Considerando o conceito de NPS, classifique de 0 a 10 a percepção que os influenciadores tem a seu respeito.
                </Typography>
                <Typography variant="body2" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
                  Use a escala de 0 a 10 para indicar se esse influenciador é promotor, detrator ou neutro em relação a você.
                </Typography>
                <Typography variant="caption" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
                • De 9 a 10 – Promotores: pessoas altamente propensas a lhe recomendar.
                </Typography>
                <Typography variant="caption" style={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
                • De 7 a 8 – Neutros: pessoas satisfeitas, mas menos entusiasmadas e menos propensas a lhe recomendar.
                </Typography>
                <Typography variant="caption" sx={{textAlign: 'center', marginBottom:'0', width:'100%', display:'block'}}>
                • De 0 a 6 – Detratores: pessoas insatisfeitas, que podem expressar opiniões negativas e afetar sua reputação na organização.
                </Typography>
              </Box>
              {mentory?.status_mentory=='2' && (
                <Box sx={{ p: 3 }}>
                  <Typography variant="h5">Este programa foi encerrado</Typography>
                  {mentory?.id_user_mentorado != user?.id && (
                    <Typography variant="subtitle1">Caso deseje reabrir, solicite a seu mentor</Typography>
                  )}
                </Box>
              )}
              <Grid container alignItems="center" spacing={2} sx={{marginTop:'-10px', width:'100%', pl: 5}}>
                <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} >
                  <List>
                    {!isLoading ?  (
                      <>
                        {allInfluencers && allInfluencers.length > 0 && (allInfluencers.map((influencer, index) => (
                          <EASToolsPerceptionOfInfluencersItem 
                            key={influencer.id_influencer} 
                            influencer={influencer} 
                            index={index}
                            updateInfluencer={(e) => updateInfluencer(e)}
                          />
                        )))}
                      </>
                    ):(
                    <Alert severity="warning">Nenhum influenciador cadastrado!</Alert>
                    )}
                  </List>
                </Grid>
                <Grid item xs={12} sm={12}  md={12}  lg={6} xl={6} sx={{height: '100%'}}>
                  <EASToolsPerceptionOfInfluencersChart allInfluencers={allInfluencers} />
                </Grid>
              </Grid>
            </Box>
            )}
            <EASDialogAlert 
              type="info"
              title="Excluir Influenciador"
              statusOpen={messageInfluencers}
              actionCancel={() => navigate('/ferramentas/influenciadores-de-reputacao')}
              titleCancel="Ir para o cadastro de influenciadores"            
              >
              <Typography>Para que você possa utilizar esta ferramenta, é necessário primeiro cadastrar os influenciadores</Typography>
            </EASDialogAlert>
          </CardContent>
        </Card>
      </Container>
    </EASCard>   
  );
};