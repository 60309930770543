import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { EASButton, EASInput } from '../../shared/forms';
import { ITag } from '../../shared/contexts/TagsContext/types';
import { TagsService } from '../../shared/services/api/tags/TagsService';
import { useSnackbar } from 'notistack';
import { useAuthContext } from '../../shared/contexts';
import { useMutation, useQueryClient } from 'react-query';

type ITagsnewTag = {
    open: boolean;
    setOpen: (open:boolean) => void;
};

export const TagsnewTag:React.FC<ITagsnewTag> =  (({open, setOpen}) => { 

    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const [saveLoading, setSaveLoading] = useState(false);
    const [tag, setTag] = useState<ITag>({});
    const [tagError, setTagError] = useState<ITag | null>(null);

    const queryClient = useQueryClient();

    const handleClose = () => {
        setTag({});
        setTagError(null);
        setOpen(false);
    };

    const { mutateAsync:storeNew } = useMutation((newTag:ITag) => TagsService.createTag(newTag), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-tag-list');
            setTag({});
            setSaveLoading(false);
            enqueueSnackbar('Tag cadastrada com sucesso!', { variant:'success'});
            setOpen(false);
        },
        onError: () => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar tag.', { variant:'error'});
        },
    });

    const tagSchema = yup.object().shape({
        name_tag: yup.string().required('Campo obrigatório')
    });
      
    const saveTag = () => {
        tagSchema
            .validate(tag, { abortEarly: false })
            .then(validateValues => {
            setSaveLoading(true);
            const newTag: ITag = {
                id_tag: '',
                status_tag: '1',
                name_tag: validateValues.name_tag,
                user_register: user?.id,
            }; 
            storeNew(newTag);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setTagError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
        });
    });
    
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>Cadastrar Nova Tag</DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <EASInput          
                                    onFocus={() => 
                                        setTagError(existingValues => ({
                                        ...existingValues,
                                        name_tag: '',
                                        }))
                                    }
                                    
                                    label="Nome da Tag"
                                    type="text"
                                    value={tag?.name_tag}
                                    onChange={(e) => 
                                        setTag(existingValues => ({
                                            ...existingValues,
                                            name_tag: e.target.value,
                                        }))
                                    }
                                    error={!!tagError?.name_tag}
                                    helperText={tagError?.name_tag}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
                <EASButton variant="contained" onClick={saveTag} disabled={saveLoading}>Confirmar</EASButton>
            </DialogActions>
        </Dialog>
    );
});