import { ReactNode } from 'react';
import { AppBar, Toolbar, Box, styled, useTheme } from '@mui/material';

import { Header } from './header';
import { SideBar } from './sideBar';
import { useDrawerContext, useModulesContext } from '../../contexts';

interface ILayoutDefaultProps {
  barraDeFerramentas?: ReactNode;
  children: React.ReactNode;
  hideSidebar?: boolean;
}
export const LayoutDefault: React.FC<ILayoutDefaultProps> = ({ children, hideSidebar }) => {

  const theme = useTheme();
  
  const { module } = useModulesContext();
  const { isDrawerOpen } = useDrawerContext();
  
  const Main = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
  }>(({ theme, open }) => ({

    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: '8px',
    transition: theme.transitions.create(
      'margin',
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }
    ),
    marginLeft: !hideSidebar && open ?  theme.spacing(29) : 15,
    width: `calc(100% - ${theme.spacing(31)})`,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(34)})`
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.spacing(34)})`,
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(29)})`,
      padding: '16px',
      marginRight: '10px'
    }
  }));


  return (
    <Box sx={{ display: 'flex'}}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          color: theme.palette.text.primary,
          bgcolor: theme.palette.background.paper,
          transition: theme.transitions.create('width')
        }}
      >
        <Toolbar>
          <Header titulo={module?.category_module + ' ' + module?.name_module} iconTitle={module?.icon_module} hideSidebar={hideSidebar} />
        </Toolbar>
      </AppBar>
      {!hideSidebar && (
        <SideBar />
      )} 
      
      <Main open={isDrawerOpen} component="main"  minHeight="calc(100vh - 115px);"  m={2} p={2} flex={1} display="flex" bgcolor={theme.palette.background.default} marginTop={theme.spacing(8)}>  
        {children}
      </Main>
    </Box>
  );













};
