import { createContext, useCallback, useContext, useState } from 'react';

import { IDrawerOption, IDrawerContextData, IDrawerProps } from './types';
import { useMediaQuery, useTheme } from '@mui/material';

const DrawerContext = createContext({} as IDrawerContextData);

export const DrawerProvider: React.FC<IDrawerProps> = ({ children }) => {
  const [drawerOptions, setDrawerOptions] = useState<IDrawerOption[]>([]);

  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(mdDown ? false: true);
  
  const toggleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(oldDrawerOpen => !oldDrawerOpen);
  }, []);

  const handleSetDrawerOptions = useCallback((newDrawerOptions: IDrawerOption[]) => {
    setDrawerOptions(newDrawerOptions);
  }, []);

  return (
    <DrawerContext.Provider value={{ isDrawerOpen, drawerOptions, toggleDrawerOpen, setDrawerOptions: handleSetDrawerOptions }}>
      {children}
    </DrawerContext.Provider>
  );

};

export const useDrawerContext = () => useContext(DrawerContext);