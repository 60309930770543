import { Badge, Tooltip, IconButtonProps, Link } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Environment } from '../../shared/environment';
import { red, orange, blue, green, grey } from '@mui/material/colors';

type EASButtonFileProps = {
    title:string;
    typeIcon?: 'ppt' | 'pptx' | 'xls' | 'xlsx' | 'pdf' | 'doc' | 'docx' | string;
    size: 'large' | 'medium' | 'small';
    file?: string;
} & IconButtonProps;

export const EASButtonFile: React.FC<EASButtonFileProps> = ({...props}) => {

     const colorRender = (()=> { 
        switch(props.typeIcon) {
          case 'doc':
          case 'docx':
            return blue[600];
          case 'pdf': 
            return red[600];
          case 'ppt': 
          case 'pptx': 
            return orange[600];
          case 'xls': 
          case 'xlsx': 
            return green[600];
          default: return grey[600];
     }})();

     const sizeFontRender = (()=> { 
        switch(props.size) {
          case 'small':
            return '9px';
          case 'medium': 
            return '20px';
          case 'large': 
            return '30px';
     }})();

     const sizeIconRender = (()=> { 
        switch(props.size) {
          case 'small':
            return 45;
          case 'medium': 
            return 90;
          case 'large': 
            return 140;
     }})();

    return (
        <Tooltip title={props.title} sx={{mr:0.5}}>
            {props.file ? (
            <Link
                href={(Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'files/stories/files/' + props.file} 
                target="_blank"
                underline="none"
            >
                <Badge badgeContent={props.typeIcon ? props.typeIcon : '???'} sx={{color: colorRender, '& .MuiBadge-badge': {top:'58%', right:'50%', fontSize: sizeFontRender}}} onClick={props.onClick}>
                    <InsertDriveFileOutlinedIcon sx={{color: colorRender, fontSize: sizeIconRender}}  />
                </Badge>  
            </Link>
            ) : (
            <Badge badgeContent={props.typeIcon ? props.typeIcon : '???'} sx={{color: colorRender, '& .MuiBadge-badge': {top:'58%', right:'50%', fontSize: sizeFontRender}}} onClick={props.onClick}>
                <InsertDriveFileOutlinedIcon sx={{color: colorRender, fontSize: sizeIconRender}}  />
            </Badge>  
            )}

        </Tooltip>
    );
};