import { IMentory, IMentoringListResponse} from '../../../contexts/MentoringContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';


const createMentory = async (mentory: IMentory): Promise<void> => {
  const { data } = await Api.post('/mentoring', { Mentory: mentory } );
  return data;
};

const updateMentory= async (mentory: IMentory): Promise<void> => {
  const { data } = await  Api.put(`/mentoring/${mentory.id_mentory}`, mentory);
  return data;  
};

const deleteMentory = async (id: string): Promise<void> => {
  const { data } = await Api.delete(`/mentoring/${id}`);
  return data;   
};


const getMentory = async (id: string): Promise<IMentory> => {
  const { data } = await Api.get(`/mentoring/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
  return data;
};

const getMentoring = async (page = 1, filter = '', id_user = '', id_module = '', id_program = '' ): Promise<IMentoringListResponse> => {
    const { data } = await Api.get(`/mentoring?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${filter}&user=${id_user}&module=${id_module}&program=${id_program}`);
    return data;
};

export const MentoringService = {
  getMentoring,
  getMentory,
  createMentory,
  updateMentory,
  deleteMentory,
};
