import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IEvaluationFinalResponse, IEvaluationFinal } from '../../../contexts/EvaluationFinalContext/types';


const createEvaluationFinal = async (evaluationFinal: object): Promise<string> => {
    const { data } = await Api.post('/evaluationfinal', { evaluationFinal: evaluationFinal } );
    return data;
};

const updateEvaluationFinal = async (evaluationFinal: IEvaluationFinal): Promise<void> => {
    const { data } = await  Api.put(`/evaluationfinal/${evaluationFinal.id_evaluation_final}`, evaluationFinal);
    return data;  
};

const deleteEvaluationFinal = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/evaluationfinal/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

/*
const getEvaluationFinal = async (userEnrollment: string, client_id: string): Promise<IEvaluationFinalService | Error> => {
  try {
    const { data } = await Api.post('/evaluationfinal', { userEnrollment: userEnrollment, client_id: client_id } );
    
    if (data) {
      return data;
    }

    return new Error('Erro de Conexao.');
  } catch (error) {    
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};
*/
const getEvaluationFinal = async (id_mentory?:string): Promise<IEvaluationFinalResponse> => {
    const urlRelativa = `/evaluationfinal?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_mentory}`;
    const  {data} = await Api.get(urlRelativa);
    return data;
};

export const EvaluationFinalService = {
 // getAllEvaluationFinal,
  getEvaluationFinal,
  createEvaluationFinal,
  updateEvaluationFinal,
  deleteEvaluationFinal,
};
