import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IFile, IFilesList } from '../../../contexts/FilesContext/types';

const getFiles = async (page = 1, search = '', id_mentory = '', tags = '' ): Promise<IFilesList> => {
  const urlRelativa = `/files?public_token=${Environment.PUBLIC_TOKEN}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&search=${search}&mentory=${id_mentory}&tags=${tags}`;
  const  {data} = await Api.get(urlRelativa);
  return data;
};

const createFile = async (File: IFile): Promise<IFile> => {
  const { data } = await Api.post('/files', { File: File } );
  return data;
};

const updateFile = async (File: IFile): Promise<void> => {
    const { data } = await  Api.put(`/files/${File.id_file}`, File);
    return data; 
};

const deleteFile = async (id: string): Promise<void> => {
    const { data } = await Api.delete(`/files/${id}`);
    return data;   
};


const getFile = async (id: string): Promise<IFile> => {
    const { data } = await Api.get(`/files/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};


export const FilesService = {
  getFiles,
  getFile,
  createFile,
  deleteFile,
  updateFile,

};
