import React from 'react';
import { Tab , Tabs, Theme, Typography, useMediaQuery } from '@mui/material/';
import { IObjective } from '../../shared/contexts/ObjectiveContext/types';
import { EASCard } from '../../shared/components/eas-card/EASCard';

interface IDetailingObjectivesSkillsProps {
  valueTab: number;
  objectives?: Array<IObjective> | null;
  handleChangeTable: (newValue: number) => void;
}  

export const DetailingObjectivesSkills: React.FC<IDetailingObjectivesSkillsProps> = ({valueTab, objectives, handleChangeTable}) => {
 
  const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const handleChange = (event: React.SyntheticEvent, valueTab: number) => {
    handleChangeTable(valueTab);
  };


  return (
    
    (!lgDown ? (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={valueTab}
      onChange={handleChange}
      sx={{'& .MuiTabs-indicator': {
              display: 'none',
            },
        }}
    >
      { (objectives && objectives.length ) && (objectives.map((objective) => (
      <Tab
        sx={{'&.Mui-selected': {
                      color:'primary',
                      background: 'rgba(239, 108, 0, 0.08);'
              },
              borderRadius:'8px',
              textTransform: 'none',
              textDecoration: 'none',
            }}
        key={objective.id_objective} 
        label={<Typography variant="subtitle2">{objective.text_objective}</Typography>}
        value={objective.id_objective} 
      />
      )))}
    </Tabs>
    ) : (
    <EASCard >
      <Tabs 
      sx={{mx:-2}}
        value={valueTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        { (objectives && objectives.length ) && (objectives.map((objective) => (
        <Tab 
          key={objective.id_objective} 
          label={objective.text_objective}
          value={objective.id_objective} 
        />
        )))}
      </Tabs>
    </EASCard>
    ))
  );
};