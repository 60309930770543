import { IColumnGroup, ITaskPlan } from '../../../contexts/TaskPlanContext/types';
import { Environment } from '../../../environment';
import { Api } from '../axios-config';

interface ITaskPlanService {
  data?: ITaskPlan;
}

interface ITaskPlanListResponse {
  page?: number | null;
  per_page?: number | null;
  total?: number | null;
  total_pages?: number | null;
  data?: Array<ITaskPlan> | null;
}


const createTaskPlan = async (taskPlan: object): Promise<ITaskPlanService | Error> => {
  try {
    const { data } = await Api.post('/taskplan', { taskPlan: taskPlan } );
  
    if (data) 
      return data;
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateTaskPlan = async (TaskPlan: ITaskPlan): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/taskplan/${TaskPlan.id_task_plan}`, TaskPlan);
    if (data) 
      return data;  
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteTaskPlan = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/taskplan/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getTaskPlan = async (id?: string): Promise<ITaskPlan> => {
    const { data } = await Api.get(`/taskplan/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getTaskPlansColumnGroup = async (mentory?: string, status = 0, page = 1, detail = '' ): Promise<IColumnGroup> => {
    const urlRelativa = `/taskplan?public_token=${Environment.PUBLIC_TOKEN}&mentory=${mentory}&status=${status}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&detail=${detail}`;
    const  {data} = await Api.get(urlRelativa);
    return data;
};

const getAllTaskPlan = async (mentory = '', status = 0, page = 1, detail = '' ): Promise<ITaskPlanListResponse | Error> => {
  try {
    if(!mentory)
      return new Error('Requisição imcompleta.');

    const urlRelativa = `/taskplan?public_token=${Environment.PUBLIC_TOKEN}&mentory=${mentory}&status=${status}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&detail=${detail}`;

    const  {data} = await Api.get(urlRelativa);
    
    if (data) {
      return data;
    }
    
    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

export const TaskPlanService = {
  getAllTaskPlan,
  getTaskPlansColumnGroup,
  getTaskPlan,
  createTaskPlan,
  updateTaskPlan,
  deleteTaskPlan,
};
