import { createContext, useCallback, useEffect, useMemo, useState, useContext } from 'react';

import { TeamService } from '../../services/api/team/TeamService';
import { Environment } from '../../environment';
import { ITeamContextData, ITeamProviderProps, ITeam } from './types';

import { useCourseContext } from '..';

export const TeamContext = createContext<ITeamContextData>({} as ITeamContextData);

export const TeamProvider: React.FC<ITeamProviderProps> = ({ children }) => {

  const { ...enrollment } = useCourseContext();

  const [idTeam, setIdTeam] = useState<string | null>(null);
  const [allTeams, setAllTeams] = useState<Array<ITeam> | null>(null);
  const [team, setTeam] = useState<ITeam | null>(null);

  useEffect(() => {
    async function getTeams() {
      if(enrollment.id_inscricao){
        const teams = await TeamService.getTeams(enrollment.id_inscricao, Environment.CLIENT_ID);
        if (teams instanceof Error) {
          setIdTeam('error');
          setTeam(null);
        } else {
          if(teams){
            const myTeams = JSON.parse(JSON.stringify(teams));
            setAllTeams(myTeams);
            
            if(Object.keys(myTeams).length > 1){
              setIdTeam(null);
              setTeam(null);
            }
            else{
              if(myTeams[0].id_inscricao){
                setTeam(myTeams[0]);
                setIdTeam(myTeams[0].id_inscricao);
              }
              else{
                setIdTeam('error');
                setTeam(null);
              }
            }
          }
          else{
            setIdTeam('error');
            setTeam(null);
          }
        }
      }
    }
    getTeams();
  }, []);

  const handleTeam = useCallback(async (teqm: ITeam) => {
    if(teqm.id_inscricao){
      setIdTeam(teqm.id_inscricao);
      setTeam(teqm);
    }
  }, []);



  const isSelected = useMemo(() => ((idTeam == 'error') ? false : (!(idTeam) && !(allTeams) ? null : true )), [idTeam]);

  return (
    <TeamContext.Provider value={{ isSelected, allTeams, ...team, accessTeam : handleTeam }}>
      {children}
    </TeamContext.Provider>
  );
};

export const useTeamContext = () => useContext(TeamContext);


