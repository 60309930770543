import React from 'react';
import { Typography, Card, CardContent, CardMedia, Chip, Stack } from '@mui/material';

import { IVideo } from '../../shared/contexts/VideosContext/types';
import { useNavigate } from 'react-router-dom';

interface IMentoryVideoCardProps {
  video?: IVideo | null;
}  

export const MentoryVideoCard: React.FC<IMentoryVideoCardProps> = ({video}) => {

  const navigate = useNavigate();
  return (
      <Card variant="outlined" onClick={() => navigate(`/video/${video?.id_video}`)} sx={{cursor:'pointer', borderRadius:'8px'}}>
        <CardMedia component="img" image={video?.thumbnails} alt={video?.name_video} />
        <CardContent sx={{ height: '106px', px:'12px', pb:'10px' }}>
          <Typography variant="h5" sx={{ pb:'12px', fontSize: '0.95rem', fontWeight: 500, lineheight:'1.334'}}>
            {video?.option_video == '1' ? (video?.title ? video.title.slice(0, 60) : 'erro titulo video') : (video?.name_video ? video.name_video.slice(0, 60) : 'erro titulo video')}
          </Typography>
          <Stack direction="row" spacing={1}>
          {video?.name_tags && video.name_tags.length > 0 && ( video.name_tags.map((tag, index) => (
            tag ? <Chip key={index} label={tag} size="small" /> : ''
          )))}
          </Stack>
        </CardContent>
      </Card>
  );
};
