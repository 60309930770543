
import React, { useState , useEffect } from 'react';
import { Typography, SvgIcon, Button, Grid, CardHeader, Avatar, Stack, Card, CardContent, CardActions } from '@mui/material/';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { IInfluencer } from '../../contexts/InfluencersContext/types';

interface IEASToolsLabelMappingItemProps {
  influencer: IInfluencer;
  setInfluencer:(Influencer : IInfluencer) => void;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  

export const EASToolsLabelMappingItem: React.FC<IEASToolsLabelMappingItemProps> = ({ influencer, setInfluencer, updateInfluencer }) => {

  const [itemInfluencer, setItemInfluencer] = useState<IInfluencer | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const colorOrderInfluencer = ['', 
        '#37A2DA',  '#32C5E9',  '#67E0E3',  '#9FE6B8',  '#FFDB5C',  '#ff9f7f',
        '#fb7293',  '#E062AE',  '#E690D1',  '#e7bcf3',  '#9d96f5',  '#8378EA',
        '#96BFFF',  '#3366E6',  '#991AFF',  '#B34D4D',  '#4D8000',  '#c23531',
        '#2f4554',  '#61a0a8',  '#d48265',  '#91c7ae',  '#749f83',  '#ca8622',
        '#bda29a',  '#6e7074',  '#546570',  '#c4ccd3',  '#759aa0',  '#e69d87',
        '#8dc1a9',  '#ea7e53',  '#eedd78',  '#73a373',  '#73b9bc',  '#7289ab',
        '#91ca8c',  '#f49f42'];
     
  useEffect(() => {
    async function loadItemInfluencer() {
      if(influencer){
        setItemInfluencer(influencer);
        setLoading(false);
      }
    }
    loadItemInfluencer();
  }, []);

  const handleClickEdit = (influencer?:IInfluencer) => {
    if(influencer){
      setInfluencer(influencer);
    }
  };

  return ( 
    <Grid item xs={12} sm={6} md={4}>
    {!loading && (
      <Card variant='outlined'>
        <CardHeader sx={{pb:0}}
          avatar={<Avatar sx={{backgroundColor: colorOrderInfluencer[(influencer.order_influencer ? parseInt(influencer.order_influencer) : 1)] }} >
                    {((influencer.order_influencer ? parseInt(influencer.order_influencer) : 1) + 9).toString(36).toUpperCase()}
                  </Avatar>}
          title={influencer.name_influencer}
          titleTypographyProps={{fontSize:'18px', fontWeight:'500'}}
        />
        <CardContent>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ borderRadius:'4px', border:'1px solid #d7d7d7' }} >
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <SvgIcon color={'success'} fontSize="small" sx={{ ml:2}} >
                { <ThumbUpIcon /> }
              </SvgIcon>
            </Stack>
            <Typography color="text.secondary" variant="caption" sx={{ width:'100%', p: 1,whiteSpace: 'break-spaces'}} style={{ overflowY:'auto', height:'70px'}}  >
              {influencer.positive_influencer}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={2} sx={{ mt:2, borderRadius:'4px', border:'1px solid #d7d7d7' }} >
            <Stack alignItems="center" direction="row" spacing={0.5} >
              <SvgIcon color={'error'} fontSize="small" sx={{ ml:2}} >
                { <ThumbDownIcon /> }
              </SvgIcon>
            </Stack>
            <Typography color="text.secondary" variant="caption" sx={{ width:'100%', p: 1, whiteSpace: 'break-spaces'}} style={{ overflowY:'auto', height:'70px'}} >
              {influencer.negative_influencer}
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{pt:0}}>
          <Button fullWidth variant="text" onClick={() => handleClickEdit(influencer)} >
            Editar Rótulos
          </Button>
        </CardActions>
      </Card>
    )}          
  </Grid>
  );
};