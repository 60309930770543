import React, { ReactElement, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Stack, Avatar, TableHead, TableRow, TableBody, TableCell, TableContainer, Typography, CardContent, Grid, Box, Skeleton, Pagination, Tooltip, AvatarGroup } from '@mui/material';

import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';

import { EASCard, EASDialogAlert, EASProgressList, PaginationComponent } from '../../shared/components';

import { Environment } from '../../shared/environment';
import { EASButton, EASButtonList, EASInput} from '../../shared/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHumanResourcesContext } from '../../shared/contexts';
import { MentoringAdd } from './MentoringAdd';


export const MentoringList:React.FC = (() => {

  const navigate = useNavigate();
  const { humanResource } = useHumanResourcesContext();

  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idMentory, setIdMentory] = useState<string | null>(null);

  const search = useMemo(() => {
      return searchParams.get('search') || '';
  }, [searchParams]);
  
  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-mentoring-list', [search, page]],() =>  MentoringService.getMentoring(page, search),  {
    //staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de processos.', { variant:'error'});
    },
  });

  const { mutateAsync:storeBlock } = useMutation((blockMentory:IMentory) => MentoringService.updateMentory(blockMentory), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-mentoring-list');
      enqueueSnackbar('Processo ' + (blockText ? 'desbloqueado' : 'bloqueado') + ' com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' o processo!', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemove } = useMutation((idMentory:string) => MentoringService.deleteMentory(idMentory), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-mentoring-list');
      queryClient.invalidateQueries('program-invitation-list');
      enqueueSnackbar('Processo removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o processo!', { variant:'error'});
      setLoadingDialog(false);
    },
  });
                                
  const handleClickRemoveMentory = (id_user?:string) => {
    if(id_user){
      setIdMentory(id_user);
      setTitleDialog('Excluir Pessoa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta processo? A exclusão removerá todo histórico vinculado.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockMentory = (id_user?:string) => {
    if(id_user){
      setIdMentory(id_user);
      setTitleDialog('Bloquear Pessoa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso a este processo?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockMentory = (id_user?:string) => {
    if(id_user){
      setIdMentory(id_user);
      setTitleDialog('Desbloquear Pessoa');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso a este processo?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdMentory(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };

  const removeMentory = () => {
    setLoadingDialog(true);
    if(idMentory){
      storeRemove(idMentory);
    }
  };

  const blockMentory = (block:boolean) => {
    setLoadingDialog(true);
    if(idMentory){
      setBlockTest(block);
      const newBlockMentory:IMentory = {
        id_mentory:idMentory,
        status_mentory:(block ? '1' : '0')
      };
      storeBlock(newBlockMentory);
    }
  };

  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeMentory();
      break;
      case 'block':
        blockMentory(false);
      break;
      case 'unblock':
        blockMentory(true);
      break;
    }
  };

  return (
      <EASCard 
        titleCard="Processos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpenNew(true)}>Novo</EASButton>
                    </Stack>}
      >
        <CardContent>
          <TableContainer >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Participantes
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    Processo
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    Status
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                <>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover tabIndex={-1} role="checkbox" >
                    <TableCell colSpan={6} scope="row" >
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </TableCell>
                  </TableRow>
                </>
                )}
                {!isLoading && ( (data && data?.data) ? (data?.data.map((mentoring) => (
                <TableRow 
                  hover 
                  key={mentoring.id_mentory} 
                  tabIndex={-1} 
                  role="checkbox"
                >
                  <TableCell sx={{width:{sm:'100%', md:'350px'}}} >
                    <Grid container spacing={2}>
                      <Grid item >
                        <AvatarGroup max={2}>
                        <Tooltip title={'Mentorado: ' + mentoring?.name_mentorado}>
                          <Avatar sx={{width:80, height:80}}  alt={mentoring?.name_mentorado} src={mentoring?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentoring?.image_mentorado  :  ''} />
                        </Tooltip>
                        <Tooltip title={'Mentor: ' + mentoring?.name_mentor}>
                          <Avatar sx={{ width: 80, height: 80 }} alt={mentoring?.name_mentor} src={mentoring?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentoring?.image_mentor  :  ''} />
                        </Tooltip>
                        </AvatarGroup>
                      </Grid>
                      <Grid item zeroMinWidth sm={true}>
                        <Grid container spacing={0} >
                          <Grid item xs={12} sx={{mt:0.2}}>
                            <Typography variant="caption" sx={{fontSize: '0.65rem'}}>Mentorado:</Typography>
                            <Typography component="h6" variant="subtitle2" sx={{fontWeight:500}}>{mentoring?.name_mentorado}</Typography>
                          </Grid>
                          <Grid item xs={12} sx={{mt:0.2}}>
                            <Typography variant="caption" sx={{fontSize: '0.65rem'}}>Mentor:</Typography>
                            <Typography component="h6" variant="caption">{mentoring?.name_mentor}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center" >
                    <Grid container spacing={0} >
                      <Grid item xs={12} sx={{mt:0.2}}>
                        <Typography variant="caption" sx={{fontSize: '0.65rem'}}>{mentoring?.id_program ? 'Programa' : 'Processo'}:</Typography>
                        <Typography component="h6" variant="subtitle2">{mentoring?.id_program ?  mentoring.name_program : mentoring?.name_mentory}</Typography>
                      </Grid>
                      {mentoring?.name_company && (
                      <Grid item xs={12} spacing={2}>
                        <Box sx={{display:'inline-flex', alignContent:'center', alignItems:'center'}}>
                          <Grid container spacing={1}>
                            <Grid item sx={{mt:0.5}}>
                              <Avatar variant="square" alt={mentoring?.name_company} src={mentoring?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + mentoring?.logo_company  :  '/'} />
                            </Grid>
                            <Grid item zeroMinWidth sx={{textAlign:'left'}}>
                              <Typography variant="caption" sx={{fontSize: '0.65rem'}}>Empresa</Typography>
                              <Typography variant="subtitle2">{mentoring?.name_company}</Typography>
                            </Grid>
                          </Grid> 
                        </Box>
                      </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell sx={{width:{xs:'100%', md:'300px'}}} >
                    <EASProgressList
                        dateInit={mentoring?.start_mentory}
                        dateFinish={mentoring.end_mentory}
                        progress={mentoring?.progress_mentory}
                        textProgress={mentoring?.name_progress_mentory}
                    />     
                  </TableCell>
                  <TableCell align="center">
                    <EASButtonList 
                        title="Editar Processo"
                        onClick={() => navigate(`/processos/detalhes/${mentoring.id_mentory}`)} 
                        typeIcon="edit"
                    />
                    {mentoring?.status_mentory == '0' ? (
                    <EASButtonList 
                        title="Desbloquear Processo"
                        onClick={() => handleClickUmBlockMentory(mentoring.id_mentory)} 
                        typeIcon="unblock"
                    />
                    ) : (
                    <EASButtonList 
                      title="Bloquear Processo"
                      onClick={() => handleClickBlockMentory(mentoring.id_mentory)} 
                      typeIcon="block"
                    />
                    )}
                    <EASButtonList 
                      title="Remover Processo"
                      onClick={() => handleClickRemoveMentory(mentoring.id_mentory)} 
                      typeIcon="remove"
                    />
                  </TableCell>
                </TableRow>
                ))) : (
                <TableRow hover tabIndex={-1} role="checkbox" >
                  <TableCell colSpan={6} scope="row" >
                    Nenhum item encontrado 
                  </TableCell>
                </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
          )}
        </CardContent>
        <MentoringAdd open={openNew} setOpen={(e) => setOpenNew(e)} />
        <EASDialogAlert 
          type="warning"
          title={titleDialog}
          statusOpen={openDialog}
          actionCancel={handleCancelDialog}
          actionConfirme={handleConfirmeDialog}
          loading={loadingDialog}
        >
          {textDialog}
        </EASDialogAlert>
        <EASDialogAlert 
          type="success"
          title={titleDialog}
          statusOpen={openDialogSuccess}
          actionCancel={handleCancelDialog}
          loading={loadingDialog}
        >
          {textDialog}
        </EASDialogAlert>
      </EASCard>
  );
});