import { useQuery } from 'react-query';
import { LayoutDefault } from '../../shared/layouts';
import { DashboardResourcesHumanPanel } from './DashboardResourcesHumanPanel';
import { DashboardService } from '../../shared/services/api/dashboard/DashboardService';
import { useHumanResourcesContext } from '../../shared/contexts';
import { useSnackbar } from 'notistack';

export const DashboardResourcesHuman = () => {
 
  
  const { humanResource } = useHumanResourcesContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data, isFetching, isLoading } = useQuery(['resource-human-dashboard', humanResource?.id_program ], () => DashboardService.getResourcesHumanDashboard(humanResource?.id_program), 
  {
    enabled: !!humanResource,  
    onError: () => { 
      enqueueSnackbar('Erro ao carregar dados do dashboard.', { variant:'error'});
    },
  });


  return (
    <LayoutDefault>
      <DashboardResourcesHumanPanel dashboardResourcesHuman={data} isLoading={isFetching || isLoading }/>
    </LayoutDefault>
  );
};
