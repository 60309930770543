import { IconButton, Tooltip, IconButtonProps, useTheme, Icon, Skeleton,  } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MailIcon from '@mui/icons-material/Mail';
import PreviewIcon from '@mui/icons-material/Preview';

import { red, orange, blue, green, grey } from '@mui/material/colors';

type EASButtonListProps = {
    title:string;
    typeIcon: 'edit' | 'remove' | 'block' | 'unblock' | 'reset' | 'move' | 'mail' | 'view' | string;
  } & IconButtonProps;

export const EASButtonList: React.FC<EASButtonListProps> = ({...props}) => {

    const iconRender = (()=> { 
        switch(props.typeIcon) {
          case 'view': return <PreviewIcon fontSize="small" />;
          case 'edit': return <EditIcon fontSize="small" />;
          case 'remove': return <DeleteForeverIcon fontSize="small" />;
          case 'block': return <DoneAllIcon fontSize="small" />;
          case 'unblock': return <BlockIcon fontSize="small" />;
          case 'reset': return <KeyIcon fontSize="small" />;
          case 'move': return <DragIndicatorIcon fontSize="small" />;
          case 'mail': return <MailIcon fontSize="small" />;
          default: return null;
     }})();

     const colorRender = (()=> { 
        switch(props.typeIcon) {
          case 'view': return blue[800];
          case 'edit': return blue[600];
          case 'remove': return red[600];
          case 'block': return green[600];
          case 'mail': return blue[900];
          case 'unblock': return grey[600];
          case 'reset': return orange[600];
          case 'move': return grey[600];
          default: return null;
     }})();

    return (
        <Tooltip title={props.title} sx={{mr:0.5}}>
            <IconButton sx={{color: colorRender, ...props.sx}} size="small" onClick={props.onClick} disabled={props.disabled} >
                {iconRender}
            </IconButton>
        </Tooltip>
    );
};